/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import LightGallery from "../../lib/lightgallery/react";
// import lgZoom from "lightgallery/plugins/zoom";
import "lightgallery/css/lightgallery-bundle.css";
import { FormControl, Button } from "react-bootstrap";
import { Component } from "react";
import ReactTooltip from "react-tooltip";
import MultiSelect from "react-multi-select-component";
import $ from "jquery";
import { convertEpochToDate } from "../../utils/date";
import {
  getAuthenticatedData,
  postAuthenticatedFormData
} from "../../utils/async";
import {
  getSites,
  getDiseases,
  getUser,
  getSiteLogo,
  getUserSignature,
  gradeExam
} from "../../constants/endpoints";
import { toast } from "react-toastify";
import PDF from "./PDF";
import { pdf } from "@react-pdf/renderer";
import imageCompression from "browser-image-compression";
import { withTranslation } from "react-i18next";
import { T_ENTITIES, T_EXAMS, T_GENERAL } from "../../constants/translations";
import mask from "../../assets/images/captureMaskWhite.png";

/**
 * This comonent contains the logic for the Grader Flow section of the app. <br />
 *
 * It is rendered only when the telemedicine entitlement is detected as enabled in sessionStorage. <br />
 *
 * @component
 */
class GraderFlow extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      container: null,
      leftEyeSelectedDiseases: [],
      rightEyeSelectedDiseases: [],
      deviceList: [
        { name: "Fundus On Phone", id: "fop" },
        { name: "Portable Slit Lamp", id: "psl" },
        { name: "Anterior Imaging Module", id: "aim" },
        { name: "Pristine", id: "pristine" },
        { name: "Others", id: "others" }
      ],
      images: [],
      selectedImages: [],
      availableDeviceList: [],
      rightEyeNonGradeable: false,
      leftEyeNonGradeable: false,
      rightEyeNoPathologyObserved: false,
      leftEyeNoPathologyObserved: false,
      rightEyeNotApplicable: false,
      leftEyeNotApplicable: false,
      indexLoaded: [],
      siteName: "",
      checkboxChecked: false,
      rightEyeComments: "",
      leftEyeComments: "",
      referRequired: "",
      isLoaded: true,
      disableButton: true,
      options: [],
      leftCommentChars: 220,
      rightCommentChars: 220,
      loadingFilter: true,
      enhancementEnabled: false,
      cupDiscEnabled: false,
      accessToken: "",
      currentDevice: "",
      userId: 0,
      showCupDisc: true,
      numberOfStandardImages: 0,
      visionAssessment: undefined,
      first: false,
      second: false,
      hasLogo: false,
      siteLogo: "",
      signatureExists: false,
      signature: "",
      replacedSelectedPristineRegularImages: [],
      replacedSelectedPristineMontageImages: []
    };
    this.onInit = this.onInit.bind(this);
    this.setContainerRef = this.setContainerRef.bind(this);
    this.setLeftEyeSelectedDisease = this.setLeftEyeSelectedDisease.bind(this);
    this.setRightEyeSelectedDisease = this.setRightEyeSelectedDisease.bind(
      this
    );
    this.onDeviceChange = this.onDeviceChange.bind(this);
    this.getLgComponent = this.getLgComponent.bind(this);
    this.onAfterSlide = this.onAfterSlide.bind(this);
    this.onSlideItemLoad = this.onSlideItemLoad.bind(this);
    this.addToReport = this.addToReport.bind(this);
    this.getSites = this.getSites.bind(this);
    this.onBeforeSlide = this.onBeforeSlide.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setSelectedLeft = this.setSelectedLeft.bind(this);
    this.setSelectedRight = this.setSelectedRight.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDiseases = this.getDiseases.bind(this);
    this.onEnhancementClick = this.onEnhancementClick.bind(this);
    this.onCupDiscClick = this.onCupDiscClick.bind(this);
    this.getToken = this.getToken.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.changeVA = this.changeVA.bind(this);
    this.getSignature = this.getSignature.bind(this);
    this.toBase64 = this.toBase64.bind(this);
  }

  componentDidMount() {
    this.lightGallery = React.createRef();
    this.populateAvailableDevices();
    this.showAppropriateGallery();
    this.getSites();
    this.getDiseases();
    this.getToken();
    this.getUserId();
    this.getSignature();
    this.setUpPristine();
    // setTimeout(() => {
    //   // eslint-disable-next-line no-undef
    //   wheelzoom(document.querySelectorAll("img"));
    //   // eslint-disable-next-line no-console
    //   console.log("here");
    //   this.state.indexLoaded.push(0);
    // }, 1000);
  }

  setUpPristine() {
    if (this.props.location.state.deviceType.includes("PRISTINE")) {
      this.setState({
        referRequired: "no",
        rightEyeNotApplicable: true,
        leftEyeNotApplicable: true
      });
    }
  }
  getUserId() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getUser).then(
      result => {
        this.setState({ userId: result?.data?.userId });
      }
    );
  }

  getSignature() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getUserSignature
    ).then(res => {
      if (res.status.statusCode === "NOT_FOUND") {
        this.setState({ signatureExists: false });
      } else {
        this.setState({
          signatureExists: true,
          signature: res.data
        });
      }
    });
  }

  /**
   * This method is necessary in order to make sure that the tooltip is rendered, since it isn't on mount.
   */
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  /**
   * This method fetches the CDSS token before rendering the LightGallery component
   */
  getToken() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + "/api/user/getCDSSAccessToken"
    ).then(res => {
      // eslint-disable-next-line no-console
      this.setState({
        accessToken: res.data.accessToken,
        loadingFilter: false
      });
    });
  }

  /**
   * Callback that is triggered when the enhancement button is clicked. <br />
   * It recreates the images array with the current slide replaced with the enhanced slide <br />
   * And then calls the updateSlide method
   */
  onEnhancementClick() {
    if (!this.state.enhancementEnabled) {
      this.setState({ enhancementEnabled: true, cupDiscEnabled: false });
      let images = this.state.images.map(a => Object.assign({}, a));
      images[this.lightGallery.current.index].src =
        sessionStorage.getItem("imgProcessingDomain") +
        "/contrast-enhancement/" +
        this.state.images[this.lightGallery.current.index].id +
        "?auth=" +
        this.state.accessToken;
      this.lightGallery.current.updateSlides(
        JSON.parse(JSON.stringify(images)),
        this.lightGallery.current.index
      );
      this.lightGallery.current.slide(this.lightGallery.current.index);
      wheelzoom(
        $(".lg-item")
          .eq(0)
          .find(".lg-image")[0]
      );
    } else {
      this.setState({ enhancementEnabled: false });
      this.lightGallery.current.updateSlides(
        JSON.parse(JSON.stringify(this.state.images)),
        this.lightGallery.current.index
      );
    }
  }

  /**
   * Callback that is triggered when the enhancement button is clicked. <br />
   * It recreates the images array with the current slide replaced with the enhanced slide <br />
   * And then calls the updateSlide method
   */
  onCupDiscClick() {
    if (!this.state.cupDiscEnabled) {
      this.setState({ cupDiscEnabled: true, enhancementEnabled: false });
      let images = this.state.images.map(a => Object.assign({}, a));
      images[this.lightGallery.current.index].src =
        sessionStorage.getItem("imgProcessingDomain") +
        "/cup-disc/" +
        this.state.images[this.lightGallery.current.index].id +
        "?auth=" +
        this.state.accessToken;
      this.lightGallery.current.updateSlides(
        JSON.parse(JSON.stringify(images)),
        this.lightGallery.current.index
      );
      this.lightGallery.current.slide(this.lightGallery.current.index);
      wheelzoom(
        $(".lg-item")
          .eq(0)
          .find(".lg-image")[0]
      );
    } else {
      this.setState({ cupDiscEnabled: false });
      this.lightGallery.current.updateSlides(
        JSON.parse(JSON.stringify(this.state.images)),
        this.lightGallery.current.index
      );
    }
  }
  /**
   * Gets the list of diseases
   */
  getDiseases() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getDiseases).then(
      res => {
        // eslint-disable-next-line no-console
        console.log(res.data);
        this.setState({
          options: res.data
            .map(el => ({
              label: el.displayName,
              value: el.icd10,
              searchBy: el.searchBy
            }))
            .filter(
              el => el.value !== "NP" && el.value !== "UG" && el.value !== "NA"
            )
        });
      }
    );
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = error => reject(error);
    });
  }

  async compressImage(url, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const compressedImage = await imageCompression(blob, options);
        const reader = new FileReader();
        reader.readAsDataURL(compressedImage);
        reader.onloadend = () => resolve(reader.result);
      } catch (error) {
        reject(error);
      }
    });
  }

  async loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Enable CORS for the image
      img.onload = () => resolve(img);
      img.onerror = () =>
        reject(new Error(`Failed to load the image from URL: ${url}`));
      img.src = url;
    });
  }

  calculateTargetSize(baseWidth, baseHeight, overlayWidth, overlayHeight) {
    const baseAspectRatio = baseWidth / baseHeight;
    const overlayAspectRatio = overlayWidth / overlayHeight;

    let targetWidth, targetHeight;

    if (overlayAspectRatio > baseAspectRatio) {
      // Overlay is wider than base
      targetWidth = baseWidth;
      targetHeight = baseWidth / overlayAspectRatio;
    } else {
      // Overlay is taller than base
      targetHeight = baseHeight;
      targetWidth = baseHeight * overlayAspectRatio;
    }

    return { targetWidth, targetHeight };
  }

  async resizeImage(image, targetWidth, targetHeight) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
    return canvas;
  }

  async removeBlackPixelsFromMask(maskCanvas) {
    const ctx = maskCanvas.getContext("2d");
    const imageData = ctx.getImageData(
      0,
      0,
      maskCanvas.width,
      maskCanvas.height
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
        // Check if the pixel is black
        data[i + 3] = 0; // Set alpha to 0 to make the pixel transparent
      }
    }

    ctx.putImageData(imageData, 0, 0);
  }

  async placeOverlayOnTop(baseImageUrl, overlayImageUrl) {
    try {
      const baseImage = await this.loadImage(baseImageUrl);
      const overlayImage = await this.loadImage(overlayImageUrl);

      // const { targetWidth, targetHeight } = this.calculateTargetSize(
      //   baseImage.width,
      //   baseImage.height,
      //   overlayImage.width,
      //   overlayImage.height
      // );

      const baseCanvas = document.createElement("canvas");
      const baseCtx = baseCanvas.getContext("2d");
      baseCanvas.width = baseImage.width;
      baseCanvas.height = baseImage.height;

      // Clear the canvas to avoid any artifacts
      baseCtx.clearRect(0, 0, baseCanvas.width, baseCanvas.height);

      baseCtx.drawImage(baseImage, 0, 2);

      const resizedOverlayCanvas = await this.resizeImage(
        overlayImage,
        baseImage.width,
        baseImage.height
      );
      await this.removeBlackPixelsFromMask(resizedOverlayCanvas);

      baseCtx.globalCompositeOperation = "source-over";

      // const xOffset = (baseImage.width - targetWidth) / 2;
      // const yOffset = (baseImage.height - targetHeight) / 2;

      baseCtx.drawImage(
        resizedOverlayCanvas,
        0,
        0,
        baseImage.width,
        baseImage.height
      );
      return baseCanvas.toDataURL();
    } catch (error) {
      // console.error(`Error processing image: ${error}`);
      return null; // Return null or some error indicator for failed load
    }
  }

  async onSubmit() {
    this.setState({ isLoaded: false });
    let leftDiagnoses, rightDiagnoses;
    if (this.state.leftEyeNonGradeable) {
      leftDiagnoses = ["Ungradeable"];
    } else if (this.state.leftEyeNoPathologyObserved) {
      leftDiagnoses = ["No Pathology Observed"];
    } else if (this.state.leftEyeNotApplicable) {
      leftDiagnoses = ["Not Applicable"];
    } else {
      leftDiagnoses = this.state.leftEyeSelectedDiseases.map(e => e.label);
    }
    leftDiagnoses = leftDiagnoses.reduce((acc, curr) => `${acc}, ${curr}`);
    if (this.state.rightEyeNonGradeable) {
      rightDiagnoses = ["Ungradeable"];
    } else if (this.state.rightEyeNoPathologyObserved) {
      rightDiagnoses = ["No Pathology Observed"];
    } else if (this.state.rightEyeNotApplicable) {
      rightDiagnoses = ["Not Applicable"];
    } else {
      rightDiagnoses = this.state.rightEyeSelectedDiseases.map(e => e.label);
    }
    rightDiagnoses = rightDiagnoses.reduce((acc, curr) => `${acc}, ${curr}`);
    let noOfAnteriorImages = this.state.selectedImages.filter(
      el => el.device === "AIM" || el.device === "PSL"
    ).length;
    let noOfFOPImages = this.state.selectedImages
      .filter(el => el.device === "FOP")
      .reduce((acc, curr) => (curr.edited ? acc + 2 : acc + 1), 0);
    let noOfPristineImages = this.state.selectedImages.filter(
      el => el.device === "PRISTINE"
    ).length;
    if (noOfPristineImages > 12) {
      toast.error(this.t(T_EXAMS.toast.pristineImageLimit));
      this.setState({ isLoaded: true });
    } else if (noOfAnteriorImages > 8) {
      toast.error(this.t(T_EXAMS.toast.anteriorImageLimit));
      this.setState({ isLoaded: true });
    } else if (noOfFOPImages > 8) {
      toast.error(this.t(T_EXAMS.toast.tooManyImages));
      this.setState({ isLoaded: true });
    } else {
      const compressionOptions = {
        maxSizeMB: 1, // Maximum file size (in MB) for compressed image
        maxWidthOrHeight: 500, // Maximum width or height of compressed image
        useWebWorker: true // Use Web Workers for better performance
      };
      const compressedImages = await Promise.all(
        this.state.selectedImages.map(async el => {
          let src = await this.compressImage(el.src, compressionOptions);
          return { ...el, src };
        })
      );
      const replacedSelectedPristineMontageImages = await Promise.all(
        this.state.replacedSelectedPristineMontageImages.map(async el => {
          let path = await this.replaceBlackWithTransparent(el);
          path = await this.compressImage(path, compressionOptions);

          return path;
        })
      );
      const replacedSelectedPristineRegularImages = await Promise.all(
        this.state.replacedSelectedPristineRegularImages.map(async el => {
          let path = await this.compressImage(el, compressionOptions);
          path = await this.placeOverlayOnTop(path, mask);
          return path;
        })
      );
      // eslint-disable-next-line no-console
      console.log(compressedImages);
      const blob = await pdf(
        <PDF
          exam={this.props.location.state.exam}
          leftDiagnoses={leftDiagnoses}
          rightDiagnoses={rightDiagnoses}
          diagnosis={this.state.rightEyeComments}
          replacedSelectedPristineMontageImages={
            replacedSelectedPristineMontageImages
          }
          replacedSelectedPristineRegularImages={
            replacedSelectedPristineRegularImages
          }
          examDate={convertEpochToDate(
            this.props.location.state.exam.examDetails.examDate
          )}
          age={Math.floor(
            (new Date() -
              new Date(
                this.props.location.state.exam.patientDetails.dateOfBirth
              ).getTime()) /
              3.15576e10
          )}
          selectedImages={compressedImages}
          hasLogo={this.state.hasLogo}
          siteLogo={this.state.siteLogo}
          siteName={this.state.siteName}
          referRequired={this.state.referRequired === "yes" ? true : false}
          showRefractive={
            this.props.location.state.hasAssessment &&
            (this.state.first || this.state.second)
          }
          visionAssessment={this.props.location.state.visionAssessment}
          showFirst={this.state.first}
          showSecond={this.state.second}
          signatureExists={this.state.signatureExists}
          signature={this.state.signature}
        ></PDF>
      ).toBlob();
      // eslint-disable-next-line no-console
      console.log(blob.size);
      // let leftDiagnoses, rightDiagnoses;
      if (this.state.leftEyeNonGradeable) {
        leftDiagnoses = ["UG"];
      } else if (this.state.leftEyeNoPathologyObserved) {
        leftDiagnoses = ["NP"];
      } else if (this.state.leftEyeNotApplicable) {
        leftDiagnoses = ["NA"];
      } else {
        leftDiagnoses = this.state.leftEyeSelectedDiseases.map(e => e.value);
      }
      if (this.state.rightEyeNonGradeable) {
        rightDiagnoses = ["UG"];
      } else if (this.state.rightEyeNoPathologyObserved) {
        rightDiagnoses = ["NP"];
      } else if (this.state.rightEyeNotApplicable) {
        rightDiagnoses = ["NA"];
      } else {
        rightDiagnoses = this.state.rightEyeSelectedDiseases.map(e => e.value);
      }
      var data = {
        examId: this.props.location.state.exam.examDetails.id,
        localId: this.props.location.state.exam.examDetails.localId + "-report",
        patientId: this.props.location.state.exam.patientDetails.id,
        leftEyeDiagnosis: {
          diagnoses: leftDiagnoses
        },
        rightEyeDiagnosis: {
          diagnoses: rightDiagnoses
        },
        prescriptionTreatment: "",
        imageIds: this.state.selectedImages.map(el => el.id),
        reportingDoctorId: this.state.userId,
        referRequired: this.state.referRequired === "yes" ? true : false,
        includeVAReading1:
          typeof this.state.visionAssessment !== "undefined" &&
          this.state.first,
        includeVAReading2:
          typeof this.state.visionAssessment !== "undefined" &&
          this.state.second,
        comments: this.state.rightEyeComments
      };
      const formData = new FormData();
      formData.append(
        "dto",
        new Blob([JSON.stringify(data)], { type: "application/json" })
      );
      formData.append("pdf", blob);

      postAuthenticatedFormData(
        sessionStorage.getItem("domain") + gradeExam,
        formData
      )
        .then(result => {
          this.setState({ isLoaded: true });
          if (result.status === 200) {
            toast.success(this.t(T_EXAMS.toast.generateSuccess));
            this.props.history.goBack();
          }
          return result.json();
        })
        .then(resp => {
          if (
            resp.status.statusCode !== "OK" &&
            resp.status.statusCode !== "FORBIDDEN" &&
            resp.status.statusCode !== "NOT_AUTHORIZED"
          ) {
            toast.error(resp.status.message);
          }
        })
        .catch(() => {
          this.setState({ isLoaded: true });
          toast.error(this.t(T_GENERAL.somethingWentWrong));
        });
    }
  }

  /**
   * Used as a common callback function for any change in the grader form
   */
  onChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "rightEyeNotApplicable") {
      this.setState({
        rightEyeNonGradeable: false,
        rightEyeNoPathologyObserved: false,
        rightEyeSelectedDiseases: []
      });
    }

    if (name === "leftEyeNotApplicable") {
      this.setState({
        leftEyeNonGradeable: false,
        leftEyeNoPathologyObserved: false,
        leftEyeSelectedDiseases: []
      });
    }

    if (name === "rightEyeNoPathologyObserved") {
      this.setState({
        rightEyeNonGradeable: false,
        rightEyeNotApplicable: false,
        rightEyeSelectedDiseases: []
      });
    }

    if (name === "leftEyeNoPathologyObserved") {
      this.setState({
        leftEyeNonGradeable: false,
        leftEyeNotApplicable: false,
        leftEyeSelectedDiseases: []
      });
    }

    if (name === "rightEyeNonGradeable") {
      this.setState({
        rightEyeNoPathologyObserved: false,
        rightEyeNotApplicable: false,
        rightEyeSelectedDiseases: []
      });
    }

    if (name === "leftEyeNonGradeable") {
      this.setState({
        leftEyeNoPathologyObserved: false,
        leftEyeNotApplicable: false,
        leftEyeSelectedDiseases: []
      });
    }

    if (name === "rightEyeNonGradeable") {
      this.setState({ rightEyeSelectedDiseases: [] });
    }

    if (name === "leftEyeNonGradeable") {
      this.setState({ leftEyeSelectedDiseases: [] });
    }
    if (
      !(
        (name === "rightEyeComments" || name === "leftEyeComments") &&
        value.length > 220
      )
    ) {
      this.setState(
        {
          [name]: value
        },
        () => {
          let chars = 0;
          if (value.length >= 220) {
            chars = 0;
          } else {
            chars = 220 - value.length;
          }
          this.setState({
            ...(name === "rightEyeComments" && {
              rightCommentChars: chars
            }),
            ...(name === "leftEyeComments" && { leftCommentChars: chars }),
            disableButton:
              (this.state.selectedImages.length === 0 &&
                !this.state.visionAssessment) ||
              (!this.state.rightEyeNonGradeable &&
                !this.state.rightEyeNoPathologyObserved &&
                !this.state.rightEyeNotApplicable &&
                this.state.rightEyeSelectedDiseases.length === 0) ||
              (!this.state.leftEyeNonGradeable &&
                !this.state.leftEyeNoPathologyObserved &&
                !this.state.leftEyeNotApplicable &&
                this.state.leftEyeSelectedDiseases.length === 0) ||
              !this.state.referRequired
          });
        }
      );
    }
  }

  changeVA(event) {
    if (event.target.checked && event.target.value === "first") {
      this.setState({ first: true });
    } else if (event.target.checked && event.target.value === "second") {
      this.setState({ second: true });
    } else if (!event.target.checked && event.target.value === "first") {
      this.setState({ first: false });
    } else {
      this.setState({ second: false });
    }
    this.setState(
      {
        [event.target.name]: event.target.checked
          ? event.target.value
          : undefined
      },
      () => {
        this.setState({
          disableButton:
            (this.state.selectedImages.length === 0 &&
              !this.state.visionAssessment) ||
            (!this.state.rightEyeNonGradeable &&
              !this.state.rightEyeNoPathologyObserved &&
              !this.state.rightEyeNotApplicable &&
              this.state.rightEyeSelectedDiseases.length === 0) ||
            (!this.state.leftEyeNonGradeable &&
              !this.state.leftEyeNoPathologyObserved &&
              !this.state.leftEyeNotApplicable &&
              this.state.leftEyeSelectedDiseases.length === 0) ||
            !this.state.referRequired
        });
      }
    );
  }

  getSites() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getSites)
      .then(res => {
        // eslint-disable-next-line no-console
        const siteArray = res?.data?.map?.(el => el.siteId);
        let index = 0;
        if (
          siteArray.includes(
            this.props.location.state.exam.patientDetails.siteId
          )
        ) {
          index = siteArray.indexOf(
            this.props.location.state.exam.patientDetails.siteId
          );
        }
        this.setState({
          siteName: res.data[index].siteName,
          hasLogo: res.data[index].hasLogo
        });
      })
      .then(() => {
        getAuthenticatedData(
          sessionStorage.getItem("domain") +
            getSiteLogo +
            "/" +
            this.props.location.state.exam.patientDetails.siteId
        ).then(
          result => {
            this.setState({ siteLogo: result.data });
          },
          () => {
            this.setState({
              isLoaded: true
            });
          }
        );
      });
  }

  setSelectedLeft(e) {
    if (e.length <= 3) {
      this.setState({ leftEyeSelectedDiseases: e }, () => {
        this.setState({
          disableButton:
            (this.state.selectedImages.length === 0 &&
              !this.state.visionAssessment) ||
            (!this.state.rightEyeNonGradeable &&
              !this.state.rightEyeNoPathologyObserved &&
              !this.state.rightEyeNotApplicable &&
              this.state.rightEyeSelectedDiseases.length === 0) ||
            (!this.state.leftEyeNonGradeable &&
              !this.state.leftEyeNoPathologyObserved &&
              !this.state.leftEyeNotApplicable &&
              this.state.leftEyeSelectedDiseases.length === 0) ||
            !this.state.referRequired
        });
      });
    } else {
      toast.error(this.t(T_EXAMS.toast.pathologyLimit));
    }
  }

  setSelectedRight(e) {
    if (e.length <= 3) {
      this.setState({ rightEyeSelectedDiseases: e }, () => {
        this.setState({
          disableButton:
            (this.state.selectedImages.length === 0 &&
              !this.state.visionAssessment) ||
            (!this.state.rightEyeNonGradeable &&
              !this.state.rightEyeNoPathologyObserved &&
              !this.state.rightEyeNotApplicable &&
              this.state.rightEyeSelectedDiseases.length === 0) ||
            (!this.state.leftEyeNonGradeable &&
              !this.state.leftEyeNotApplicable &&
              !this.state.leftEyeNoPathologyObserved &&
              this.state.leftEyeSelectedDiseases.length === 0) ||
            !this.state.referRequired
        });
      });
    } else {
      toast.error(this.t(T_EXAMS.toast.pathologyLimit));
    }
  }

  async replaceBlackWithTransparent(url) {
    const loadImage = url => {
      return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.crossOrigin = "Anonymous"; // Enable CORS for the image
        img.onload = () => resolve(img);
        img.onerror = () =>
          reject(new Error(`Failed to load the image from URL: ${url}`));
        img.src = url;
      });
    };

    const processImage = img => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        if (data[i] < 3 && data[i + 1] < 3 && data[i + 2] < 3) {
          // Check if the pixel is black
          data[i + 3] = 0; // Set alpha to 0 to make the pixel transparent
        }
      }

      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL();
    };

    try {
      const img = await loadImage(url);
      return processImage(img);
    } catch (error) {
      // console.error(`Error processing image: ${error}`);
      return null; // Return null or some error indicator for failed load
    }
  }
  /**
   * Logic for adding the current image to the selectedImages array
   */
  addToReport(e) {
    if (e.target.checked) {
      this.setState(
        {
          selectedImages: [
            ...this.state.selectedImages,
            this.state.images[this.lightGallery.current.index]
          ],
          checkboxChecked: true
        },
        async () => {
          const pristineImages = this.state.selectedImages.filter(
            el => el.device === "PRISTINE" && !el.edited
          );
          const pristineImagesEdited = this.state.selectedImages.filter(
            el => el.device === "PRISTINE" && el.edited
          );

          this.setState({
            replacedSelectedPristineRegularImages: pristineImages.map(
              el => el.src
            ),
            replacedSelectedPristineMontageImages: pristineImagesEdited.map(
              el => el.src
            ),
            disableButton:
              (this.state.selectedImages.length === 0 &&
                !this.state.visionAssessment) ||
              (!this.state.rightEyeNonGradeable &&
                !this.state.rightEyeNotApplicable &&
                !this.state.rightEyeNoPathologyObserved &&
                this.state.rightEyeSelectedDiseases.length === 0) ||
              (!this.state.leftEyeNonGradeable &&
                !this.state.leftEyeNotApplicable &&
                !this.state.leftEyeNoPathologyObserved &&
                this.state.leftEyeSelectedDiseases.length === 0) ||
              !this.state.referRequired
          });
        }
      );
    } else {
      this.setState(
        {
          selectedImages: this.state.selectedImages.filter(
            el =>
              el.id !== this.state.images[this.lightGallery.current.index]?.id
          ),
          checkboxChecked: false
        },
        async () => {
          const pristineImages = this.state.selectedImages.filter(
            el => el.device === "PRISTINE" && !el.edited
          );
          const pristineImagesEdited = this.state.selectedImages.filter(
            el => el.device === "PRISTINE" && el.edited
          );

          this.setState({
            replacedSelectedPristineRegularImages: pristineImages.map(
              el => el.src
            ),
            replacedSelectedPristineMontageImages: pristineImagesEdited.map(
              el => el.src
            ),
            disableButton:
              (this.state.selectedImages.length === 0 &&
                !this.state.visionAssessment) ||
              (!this.state.rightEyeNonGradeable &&
                !this.state.rightEyeNotApplicable &&
                !this.state.rightEyeNoPathologyObserved &&
                this.state.rightEyeSelectedDiseases.length === 0) ||
              (!this.state.leftEyeNonGradeable &&
                !this.state.leftEyeNotApplicable &&
                !this.state.leftEyeNoPathologyObserved &&
                this.state.leftEyeSelectedDiseases.length === 0) ||
              !this.state.referRequired
          });
        }
      );
    }
  }

  /**
   * Enable zoom functionality on the currently displayed slide immediately after changing a slide
   */
  onAfterSlide() {
    // wheelzoom(document.querySelectorAll("img"));
    this.setState({
      checkboxChecked: this.state.selectedImages
        .map(e => e.src)
        .includes(this.state.images[this.lightGallery.current.index].src)
    });
    if (this.state.enhancementEnabled || this.state.cupDiscEnabled) {
      this.lightGallery.current.updateSlides(
        JSON.parse(JSON.stringify(this.state.images)),
        this.lightGallery.current.index
      );
      this.setState({ enhancementEnabled: false, cupDiscEnabled: false });
    }
    wheelzoom($(".lg-current").find(".lg-image")[0]);
    document
      .querySelectorAll("img")
      .forEach(el => el.dispatchEvent(new CustomEvent("wheelzoom.reset")));
  }

  onBeforeSlide() {
    // // wheelzoom(document.querySelectorAll("img"));
    // if (
    //   $(".lg-item")
    //     .eq(index)
    //     .find(".lg-image")[0]
    // ) {
    //   document
    //     .querySelectorAll("img")
    //     .forEach(el => el.dispatchEvent(new CustomEvent("wheelzoom.reset")));
    // }
  }

  /**
   * Slightly redundany but enables zoom functionality even when the first slide loads unlike the previous method
   */
  onSlideItemLoad() {
    wheelzoom($(".lg-current").find(".lg-image")[0]);
  }

  /**
   * Refreshes the slides based on the device selected
   */
  onDeviceChange(e) {
    let editedImages, standardImages, allImages;
    switch (e.target.value) {
      case "Anterior Imaging Module":
        editedImages = this.props.location.state.exam.images.aimImages[
          "EDITED"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: el.editOperations.includes("MONTAGE"),
            device: "AIM",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        standardImages = this.props.location.state.exam.images.aimImages[
          "STANDARD"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: false,
            device: "AIM",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        allImages = standardImages.concat(editedImages);
        this.setState({
          currentDevice: "AIM",
          numberOfStandardImages: standardImages.length
        });
        break;

      case "Fundus On Phone":
        editedImages = this.props.location.state.exam.images.fopImages[
          "EDITED"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: el.editOperations.includes("MONTAGE"),
            device: "FOP",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        standardImages = this.props.location.state.exam.images.fopImages[
          "STANDARD"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: false,
            device: "FOP",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        allImages = standardImages.concat(editedImages);
        this.setState({
          currentDevice: "FOP",
          numberOfStandardImages: standardImages.length
        });
        break;

      case "Portable Slit Lamp":
        editedImages = this.props.location.state.exam.images.pslImages[
          "EDITED"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: el.editOperations.includes("MONTAGE"),
            device: "PSL",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        standardImages = this.props.location.state.exam.images.pslImages[
          "STANDARD"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: false,
            device: "PSL",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        allImages = standardImages.concat(editedImages);
        this.setState({
          currentDevice: "PSL",
          numberOfStandardImages: standardImages.length
        });
        break;
      case "Pristine":
        editedImages = this.props.location.state.exam.images.pristineImages[
          "EDITED"
        ]
          .filter(el => el.imageSegment === "POSTERIOR")
          .map(el => {
            return {
              id: el.id,
              src: el.path,
              edited: el.editOperations.includes("MONTAGE"),
              device: "PRISTINE",
              laterality: el.laterality,
              subHtml:
                el.laterality === "RIGHT"
                  ? "<p class='grader-eye-label'>" +
                    this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                    "</p>"
                  : "<p class='grader-eye-label'>" +
                    this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                    "</p>"
            };
          });
        standardImages = this.props.location.state.exam.images.pristineImages[
          "STANDARD"
        ]
          .filter(el => el.imageSegment === "POSTERIOR")
          .map(el => {
            return {
              id: el.id,
              src: el.path,
              edited: false,
              device: "PRISTINE",
              laterality: el.laterality,
              subHtml:
                el.laterality === "RIGHT"
                  ? "<p class='grader-eye-label'>" +
                    this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                    "</p>"
                  : "<p class='grader-eye-label'>" +
                    this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                    "</p>"
            };
          });
        allImages = standardImages.concat(editedImages);
        this.setState({
          currentDevice: "PRISTINE",
          numberOfStandardImages: standardImages.length
        });
        break;
      case "Others":
        editedImages = this.props.location.state.exam.images.otherImages[
          "EDITED"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: el.editOperations.includes("MONTAGE"),
            device: "Others",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        standardImages = this.props.location.state.exam.images.otherImages[
          "STANDARD"
        ].map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: false,
            device: "Others",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
        allImages = standardImages.concat(editedImages);
        this.setState({
          currentDevice: "Other",
          numberOfStandardImages: standardImages.length
        });
        break;
    }
    this.setState(
      {
        images: allImages,
        indexLoaded: [],
        enhancementEnabled: false,
        cupDiscEnabled: false
      },
      () => {
        // eslint-disable-next-line no-console
        console.log(this.lightGallery.current);
        this.lightGallery.current.slide(0);
        this.setState({
          checkboxChecked: this.state.selectedImages
            .map(e => e.src)
            .includes(this.state.images[this.lightGallery.current.index].src)
        });
        setTimeout(() => {
          this.lightGallery.current.updateSlides(allImages, 0);
          wheelzoom(
            $(".lg-item")
              .eq(0)
              .find(".lg-image")[0]
          );
        }, 1000);
      }
    );
  }

  /**
   * Decides which device to show images for on load. Checking in this order: AIM, FOP, PSL, Other. <br />
   * Shows the images for the first device that exists in that list
   */
  showAppropriateGallery() {
    // const items = this.props.location.state.big.map((img, i) => {
    //     return { id: i.toString(), src: img };
    //   });
    if (
      this.props.location.state.exam.images.aimImages["EDITED"].length +
        this.props.location.state.exam.images.aimImages["STANDARD"].length >
      0
    ) {
      let editedImages = this.props.location.state.exam.images.aimImages[
        "EDITED"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: el.editOperations.includes("MONTAGE"),
          device: "AIM",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let standardImages = this.props.location.state.exam.images.aimImages[
        "STANDARD"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: false,
          device: "AIM",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let allImages = standardImages.concat(editedImages);
      this.setState({
        images: allImages,
        currentDevice: "AIM",
        numberOfStandardImages: standardImages.length
      });
    } else if (
      this.props.location.state.exam.images.fopImages["EDITED"].length +
        this.props.location.state.exam.images.fopImages["STANDARD"].length >
      0
    ) {
      let editedImages = this.props.location.state.exam.images.fopImages[
        "EDITED"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: el.editOperations.includes("MONTAGE"),
          device: "FOP",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let standardImages = this.props.location.state.exam.images.fopImages[
        "STANDARD"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: false,
          device: "FOP",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let allImages = standardImages.concat(editedImages);
      this.setState({
        images: allImages,
        currentDevice: "FOP",
        numberOfStandardImages: standardImages.length
      });
    } else if (
      this.props.location.state.exam.images.pslImages["EDITED"].length +
        this.props.location.state.exam.images.pslImages["STANDARD"].length >
      0
    ) {
      let editedImages = this.props.location.state.exam.images.pslImages[
        "EDITED"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: el.editOperations.includes("MONTAGE"),
          device: "PSL",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let standardImages = this.props.location.state.exam.images.pslImages[
        "STANDARD"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: false,
          device: "PSL",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let allImages = standardImages.concat(editedImages);
      this.setState({
        images: allImages,
        currentDevice: "PSL",
        numberOfStandardImages: standardImages.length
      });
    } else if (
      this.props.location.state.exam.images.pristineImages["EDITED"].filter(
        el => el.imageSegment === "POSTERIOR"
      ).length +
        this.props.location.state.exam.images.pristineImages["STANDARD"].filter(
          el => el.imageSegment === "POSTERIOR"
        ).length >
      0
    ) {
      let editedImages = this.props.location.state.exam.images.pristineImages[
        "EDITED"
      ]
        .filter(el => el.imageSegment === "POSTERIOR")
        .map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: el.editOperations.includes("MONTAGE"),
            device: "PRISTINE",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
      let standardImages = this.props.location.state.exam.images.pristineImages[
        "STANDARD"
      ]
        .filter(el => el.imageSegment === "POSTERIOR")
        .map(el => {
          return {
            id: el.id,
            src: el.path,
            edited: false,
            device: "PRISTINE",
            laterality: el.laterality,
            subHtml:
              el.laterality === "RIGHT"
                ? "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                  "</p>"
                : "<p class='grader-eye-label'>" +
                  this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                  "</p>"
          };
        });
      let allImages = standardImages.concat(editedImages);
      this.setState({
        images: allImages,
        currentDevice: "PRISTINE",
        numberOfStandardImages: standardImages.length
      });
    } else if (
      this.props.location.state.exam.images.otherImages["EDITED"].length +
        this.props.location.state.exam.images.otherImages["STANDARD"].length >
      0
    ) {
      let editedImages = this.props.location.state.exam.images.otherImages[
        "EDITED"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: el.editOperations.includes("MONTAGE"),
          device: "Others",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let standardImages = this.props.location.state.exam.images.otherImages[
        "STANDARD"
      ].map(el => {
        return {
          id: el.id,
          src: el.path,
          edited: false,
          device: "Others",
          laterality: el.laterality,
          subHtml:
            el.laterality === "RIGHT"
              ? "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.rightEye) +
                "</p>"
              : "<p class='grader-eye-label'>" +
                this.t(T_EXAMS.examDetails.startGrading.leftEye) +
                "</p>"
        };
      });
      let allImages = standardImages.concat(editedImages);
      this.setState({
        images: allImages,
        currentDevice: "Other",
        numberOfStandardImages: standardImages.length
      });
    }
  }

  /**
   * Populates the device dropdown with the list of devices available
   */
  populateAvailableDevices() {
    let devices = [];
    if (
      this.props.location.state.exam.images.aimImages["EDITED"].length +
        this.props.location.state.exam.images.aimImages["STANDARD"].length >
      0
    ) {
      devices.push({ name: "Anterior Imaging Module", id: "aim" });
    }
    if (
      this.props.location.state.exam.images.fopImages["EDITED"].length +
        this.props.location.state.exam.images.fopImages["STANDARD"].length >
      0
    ) {
      devices.push({ name: "Fundus On Phone", id: "fop" });
    }
    if (
      this.props.location.state.exam.images.pslImages["EDITED"].length +
        this.props.location.state.exam.images.pslImages["STANDARD"].length >
      0
    ) {
      devices.push({ name: "Portable Slit Lamp", id: "psl" });
    }
    if (
      this.props.location.state.exam.images.pristineImages["EDITED"].filter(
        el => el.imageSegment === "POSTERIOR"
      ).length +
        this.props.location.state.exam.images.pristineImages["STANDARD"].filter(
          el => el.imageSegment === "POSTERIOR"
        ).length >
      0
    ) {
      devices.push({ name: "Pristine", id: "pristine" });
    }
    if (
      this.props.location.state.exam.images.otherImages["EDITED"].length +
        this.props.location.state.exam.images.otherImages["STANDARD"].length >
      0
    ) {
      devices.push({ name: "Others", id: "others" });
    }
    this.setState({ availableDeviceList: devices });
  }

  onInit(detail) {
    if (detail) {
      this.lightGallery.current = detail.instance;
      this.lightGallery.current.openGallery();
    }
  }

  setLeftEyeSelectedDisease(diseases) {
    this.setState({ leftEyeSelectedDiseases: diseases });
  }

  setRightEyeSelectedDisease(diseases) {
    this.setState({ rightEyeSelectedDiseases: diseases });
  }

  /**
   * sets the ref for the LightGallery container
   *
   *
   * @param {object} node      DOM node
   */
  setContainerRef(node) {
    if (node !== null) {
      this.setState({ container: node });
    }
  }
  /**
   * Renders the LightGallery component if a reference to the parent is available. If not, renders null.
   */
  getLgComponent() {
    if (this.state.container !== null) {
      return (
        <LightGallery
          plugins={[]}
          elementClassNames="custom-classname"
          dynamic
          dynamicEl={this.state.images}
          enableDrag={false}
          enableSwipe={false}
          onInit={this.onInit}
          download={false}
          closable={false}
          container={this.state.container}
          onAfterSlide={this.onAfterSlide}
          onBeforeSlide={this.onBeforeSlide}
          onSlideItemLoad={this.onSlideItemLoad}
          appendCounterTo={".counter-container"}
        ></LightGallery>
      );
    }
    return null;
  }

  render() {
    const filterOptions = (options, filter) => {
      if (!filter) {
        return options;
      }
      const re = new RegExp(filter, "i");
      return options.filter(
        ({ value, label, searchBy }) =>
          (value && value.match(re)) ||
          (label && label.match(re)) ||
          (searchBy && searchBy.match(re))
      );
    };
    let exam = this.props.location.state.exam;
    return (
      <div className="grader-container">
        <div className="slideshow-container">
          <div
            className={`loading-container ${
              this.state.loadingFilter ? " show" : ""
            }`}
          >
            <img src={require("../../assets/images/white-spinner.gif")} />
          </div>
          <div className="header">
            <select name="site" onChange={this.onDeviceChange}>
              {this.state.availableDeviceList.map(e => (
                <option value={e.name} key={e?.id}>
                  {e?.name}
                </option>
              ))}
            </select>
            <div className="checkbox">
              <FormControl
                className="check"
                type="checkbox"
                name="addToReport"
                checked={this.state.checkboxChecked}
                onChange={this.addToReport}
              />
              <span className="ungradeable-text">
                &nbsp;&nbsp;
                {this.t(T_EXAMS.examDetails.startGrading.addToReport)}
              </span>
            </div>
          </div>
          <div className="number-of-images">
            {this.t(T_EXAMS.examDetails.startGrading.imagesSelected, {
              count: this.state.selectedImages.length
            })}
          </div>
          <div className="image-container" ref={this.setContainerRef}></div>
          {this.getLgComponent()}
          <div className="counter-container">
            {this.lightGallery?.current?.index + 1}/{this.state.images.length}
          </div>
          {(() => {
            if (this.state.currentDevice === "FOP") {
              return (
                <div className="filter-container">
                  <div
                    className={`filter-item-container ${
                      this.state.enhancementEnabled ? "selected" : ""
                    }`}
                    data-tip={this.t(
                      T_EXAMS.examDetails.startGrading.enhancedView
                    )}
                    onClick={this.onEnhancementClick}
                  >
                    <img src={require("../../assets/images/enhanced.svg")} />
                  </div>
                  {(() => {
                    if (
                      !(
                        this.lightGallery?.current?.index + 1 >
                        this.state.numberOfStandardImages
                      )
                    ) {
                      return (
                        <div
                          className={`filter-item-container ${
                            this.state.cupDiscEnabled ? "selected" : ""
                          }`}
                          data-tip={this.t(
                            T_EXAMS.examDetails.startGrading.cupDiscRatio
                          )}
                          onClick={this.onCupDiscClick}
                        >
                          <img
                            src={require("../../assets/images/cup-disc.svg")}
                          />
                        </div>
                      );
                    }
                  })()}
                </div>
              );
            }
            return null;
          })()}
        </div>
        <div
          className="grader-report-container"
          onKeyDown={e => e.stopPropagation()}
        >
          <h6 className="grader-report-name">
            {exam.patientDetails.firstName} {exam.patientDetails.lastName}
          </h6>
          <div className="grader-details-container">
            <div>
              <span className="grader-report-heading">
                {this.t(T_ENTITIES.patient.mrn)}:
              </span>{" "}
              {exam.patientDetails.mrn}
            </div>
            <div>
              <span className="grader-report-heading">
                {this.t(T_ENTITIES.patient.dateOfBirth)}:
              </span>{" "}
              {convertEpochToDate(exam.patientDetails.dateOfBirth)}
            </div>
          </div>
          <div className="grader-details-container">
            <div>
              <span className="grader-report-heading">
                {this.t(T_ENTITIES.patient.gender)}:
              </span>{" "}
              {exam.patientDetails.gender}
            </div>
            <div>
              <span className="grader-report-heading">
                {this.t(T_ENTITIES.exam.site)}:
              </span>{" "}
              {this.state.siteName}
            </div>
          </div>
          <div className="grader-details-container">
            <div>
              <span className="grader-report-heading">
                {this.t(T_ENTITIES.exam.examDate)}:
              </span>{" "}
              {convertEpochToDate(exam.examDetails.examDate)}
            </div>
          </div>
          {(() => {
            if (this.props.location.state.hasAssessment) {
              return (
                <div>
                  <p className="grader-report-name">
                    {this.t(
                      T_EXAMS.examDetails.startGrading.objectiveRefraction
                    )}
                  </p>
                  {(() => {
                    if (
                      this.props.location.state.visionAssessment.readingSet1
                        ?.reReading?.average.sph
                    ) {
                      return (
                        <div className="grader-report-eye-container readings">
                          <div className="right-eye-heading">
                            <input
                              type="checkbox"
                              className="va-radio"
                              name="visionAssessment"
                              disabled={
                                !this.props.location.state.visionAssessment
                                  .readingSet1?.reReading?.average.sph
                              }
                              value={"first"}
                              checked={this.state.first}
                              onChange={this.changeVA}
                            />
                            <span className="va-subheading">
                              {this.t(
                                T_EXAMS.examDetails.startGrading.selectReading1
                              )}
                            </span>
                          </div>
                          <div className="right-eye-details">
                            <div className="va-selection-container">
                              <div className="aggregate-table-container-container">
                                <div className="aggregate-subheading">
                                  {this.t(
                                    T_EXAMS.examDetails.startGrading.rightEye
                                  )}
                                </div>
                                <div className="aggregate-table-container">
                                  <div className="aggregate-table-heading top-left">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.spherical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading
                                        .cylindrical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading top-right">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.axis
                                    )}
                                  </div>
                                  <div className="aggregate-table-contents bottom-left">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet1?.reReading?.average.sph
                                    }
                                  </div>
                                  <div className="aggregate-table-contents">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet1?.reReading?.average.cyl
                                    }
                                  </div>
                                  <div className="aggregate-table-contents bottom-right">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet1?.reReading?.average.axis
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="aggregate-table-container-container">
                                <div className="aggregate-subheading">
                                  {this.t(
                                    T_EXAMS.examDetails.startGrading.leftEye
                                  )}
                                </div>
                                <div className="aggregate-table-container">
                                  <div className="aggregate-table-heading top-left">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.spherical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading
                                        .cylindrical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading top-right">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.axis
                                    )}
                                  </div>
                                  <div className="aggregate-table-contents bottom-left">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet1?.leReading?.average.sph
                                    }
                                  </div>
                                  <div className="aggregate-table-contents">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet1?.leReading?.average.cyl
                                    }
                                  </div>
                                  <div className="aggregate-table-contents bottom-right">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet1?.leReading?.average.axis
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (
                      this.props.location.state.visionAssessment.readingSet2
                        ?.reReading?.average.sph
                    ) {
                      return (
                        <div className="grader-report-eye-container readings">
                          <div className="right-eye-heading">
                            <input
                              type="checkbox"
                              className="va-radio"
                              name="visionAssessment"
                              disabled={
                                !this.props.location.state.visionAssessment
                                  .readingSet2?.reReading?.average.sph
                              }
                              value={"second"}
                              checked={this.state.second}
                              onChange={this.changeVA}
                            />
                            <span className="va-subheading">
                              {this.t(
                                T_EXAMS.examDetails.startGrading.selectReading2
                              )}
                            </span>
                          </div>
                          <div className="right-eye-details">
                            <div className="va-selection-container">
                              <div className="aggregate-table-container-container">
                                <div className="aggregate-subheading">
                                  {this.t(
                                    T_EXAMS.examDetails.startGrading.rightEye
                                  )}
                                </div>
                                <div className="aggregate-table-container">
                                  <div className="aggregate-table-heading top-left">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.spherical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading
                                        .cylindrical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading top-right">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.axis
                                    )}
                                  </div>
                                  <div className="aggregate-table-contents bottom-left">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet2?.reReading?.average.sph
                                    }
                                  </div>
                                  <div className="aggregate-table-contents">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet2?.reReading?.average.cyl
                                    }
                                  </div>
                                  <div className="aggregate-table-contents bottom-right">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet2?.reReading?.average.axis
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="aggregate-table-container-container">
                                <div className="aggregate-subheading">
                                  {this.t(
                                    T_EXAMS.examDetails.startGrading.leftEye
                                  )}
                                </div>
                                <div className="aggregate-table-container">
                                  <div className="aggregate-table-heading top-left">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.spherical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading
                                        .cylindrical
                                    )}
                                  </div>
                                  <div className="aggregate-table-heading top-right">
                                    {this.t(
                                      T_EXAMS.examDetails.startGrading.axis
                                    )}
                                  </div>
                                  <div className="aggregate-table-contents bottom-left">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet2?.leReading?.average.sph
                                    }
                                  </div>
                                  <div className="aggregate-table-contents">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet2?.leReading?.average.cyl
                                    }
                                  </div>
                                  <div className="aggregate-table-contents bottom-right">
                                    {
                                      this.props.location.state.visionAssessment
                                        .readingSet2?.leReading?.average.axis
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}
                </div>
              );
            }
          })()}
          {(() => {
            if (!this.props.location.state?.deviceType.includes("PRISTINE")) {
              return (
                <>
                  <div className="diagnosis-heading">
                    {this.t(T_EXAMS.examDetails.startGrading.diagnosis)}
                  </div>
                  <div className="grader-report-eye-container">
                    <div className="right-eye-heading">
                      {this.t(T_EXAMS.examDetails.startGrading.rightEye)}
                    </div>
                    <div className="right-eye-details">
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="rightEyeNotApplicable"
                          checked={this.state.rightEyeNotApplicable}
                          onChange={this.onChange}
                        />
                        <span className="ungradeable-text">
                          &nbsp;&nbsp;
                          {this.t(
                            T_EXAMS.examDetails.startGrading.notApplicable
                          )}
                        </span>
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="rightEyeNonGradeable"
                          checked={this.state.rightEyeNonGradeable}
                          onChange={this.onChange}
                        />
                        <span className="ungradeable-text">
                          &nbsp;&nbsp;
                          {this.t(T_EXAMS.examDetails.startGrading.ungradeable)}
                        </span>
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="rightEyeNoPathologyObserved"
                          checked={this.state.rightEyeNoPathologyObserved}
                          onChange={this.onChange}
                        />
                        <span className="ungradeable-text">
                          &nbsp;&nbsp;
                          {this.t(
                            T_EXAMS.examDetails.startGrading.noPathologyObserved
                          )}
                        </span>
                      </div>
                      <MultiSelect
                        options={this.state.options}
                        filterOptions={filterOptions}
                        hasSelectAll={false}
                        value={this.state.rightEyeSelectedDiseases}
                        onChange={this.setSelectedRight}
                        labelledBy="Select"
                        disabled={
                          this.state.rightEyeNonGradeable ||
                          this.state.rightEyeNoPathologyObserved ||
                          this.state.rightEyeNotApplicable
                        }
                      />
                    </div>
                  </div>
                  <div className="grader-report-eye-container">
                    <div className="right-eye-heading">
                      {this.t(T_EXAMS.examDetails.startGrading.leftEye)}
                    </div>
                    <div className="right-eye-details">
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="leftEyeNotApplicable"
                          checked={this.state.leftEyeNotApplicable}
                          onChange={this.onChange}
                        />
                        <span className="ungradeable-text">
                          &nbsp;&nbsp;
                          {this.t(
                            T_EXAMS.examDetails.startGrading.notApplicable
                          )}
                        </span>
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="leftEyeNonGradeable"
                          checked={this.state.leftEyeNonGradeable}
                          onChange={this.onChange}
                        />
                        <span className="ungradeable-text">
                          &nbsp;&nbsp;
                          {this.t(T_EXAMS.examDetails.startGrading.ungradeable)}
                        </span>
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="leftEyeNoPathologyObserved"
                          checked={this.state.leftEyeNoPathologyObserved}
                          onChange={this.onChange}
                        />
                        <span className="ungradeable-text">
                          &nbsp;&nbsp;
                          {this.t(
                            T_EXAMS.examDetails.startGrading.noPathologyObserved
                          )}
                        </span>
                      </div>
                      <MultiSelect
                        options={this.state.options}
                        value={this.state.leftEyeSelectedDiseases}
                        filterOptions={filterOptions}
                        hasSelectAll={false}
                        onChange={this.setSelectedLeft}
                        labelledBy="Select"
                        disabled={
                          this.state.leftEyeNonGradeable ||
                          this.state.leftEyeNoPathologyObserved ||
                          this.state.leftEyeNotApplicable
                        }
                      />
                    </div>
                  </div>
                  <div className="grader-report-eye-container">
                    <div className="right-eye-heading">
                      {this.t(T_EXAMS.examDetails.startGrading.comments)}
                    </div>
                    <div className="right-eye-details">
                      <textarea
                        placeholder="Comments"
                        name="rightEyeComments"
                        onChange={this.onChange}
                        value={this.state.rightEyeComments}
                      ></textarea>
                      <div className="charcount">
                        {this.t(
                          T_EXAMS.examDetails.startGrading.charactersRemaining,
                          {
                            count: this.state.rightCommentChars
                          }
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="refer">
                    <div className="refer-heading">
                      {this.t(
                        T_EXAMS.examDetails.startGrading.referToSpecialist
                      )}
                    </div>
                    <div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="radio"
                          onChange={this.onChange}
                          name="referRequired"
                          value={"yes"}
                        />
                      </div>
                      <div className="radio-label first">
                        &nbsp;&nbsp;{this.t(T_GENERAL.yes)}
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="radio"
                          onChange={this.onChange}
                          name="referRequired"
                          value={"no"}
                        />
                      </div>
                      <div className="radio-label">
                        &nbsp;&nbsp;{this.t(T_GENERAL.no)}
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })()}
          <div className="generate-report-container">
            <Button
              onClick={this.onSubmit}
              className="loginbtn1 generate-report-button"
              disabled={this.state.disableButton || !this.state.isLoaded}
            >
              {this.state.isLoaded ? (
                this.t(T_EXAMS.examDetails.startGrading.generateReport)
              ) : (
                <img src={require("../../assets/images/button-loading.gif")} />
              )}
            </Button>
          </div>
        </div>
        <ReactTooltip />
      </div>
    );
  }
}

export default withTranslation()(GraderFlow);
