/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { remidioOrgIDs } from "../../config";
import {
  createUser,
  getSites,
  createAdminAccount
} from "../../constants/endpoints";
import MultiSelect from "react-multi-select-component";
import { getAuthenticatedData, postAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_ENTITIES, T_GENERAL, T_USERS } from "../../constants/translations";

/**
 * This is the component for the Add User modal in the Users Page, and well as <br>
 * Many components in the Service Pages. <br>
 * A prop called `makeAdmin` is passed when the current user is a service account <br>
 *
 *
 * @component
 */
class CustomModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      firstname: "",
      lastname: "",
      eid: "",
      email: "",
      emailAndDomain: "",
      redirect: false,
      disableButton: true,
      isLoaded: true,
      sites: [{ siteName: "Loading..." }],
      site: 0,
      domain: "",
      roles: "",
      editRoles: [],
      options: this.props.makeAdmin
        ? []
        : this.props.sites.map(el => {
            return { label: el.siteName, value: el.siteId };
          }),
      selected: [],
      allowOfflineUse: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }
  /**
   * Fetches a list of all sites in order to populate the dropdown
   *
   *
   *
   */
  componentDidMount() {
    getAuthenticatedData(
      this.props.makeAdmin
        ? sessionStorage.getItem("domain") +
            getSites +
            "?orgId=" +
            this.props.orgId
        : sessionStorage.getItem("domain") + getSites
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          site: result?.data[0]?.siteId,
          domain: result?.data[0]?.siteDomain,
          selected: []
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  setSelected(e) {
    this.setState({ selected: e });
  }
  /**
   * Handles changes in the form input and synchronizes state
   * @param {object} e event listener
   *
   *
   *
   */
  onChange(e) {
    if (e.target.name === "site") {
      this.setState({
        [e.target.name]: e.target.value,
        domain: this.state.sites.find(f => {
          return f?.siteId == e?.target?.value;
        }).siteDomain,
        site: parseInt(e.target.value)
      }),
        () => {
          this.setState({
            disableButton:
              this.state.firstname === "" ||
              this.state.lastname === "" ||
              this.state.eid === "" ||
              this.state.email === ""
          });
        };
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value
        },
        () => {
          this.setState({
            disableButton:
              this.state.firstname === "" ||
              this.state.lastname === "" ||
              this.state.eid === "" ||
              this.state.email === ""
          });
        }
      );
    }
  }
  /**
   * Submits the form and makes the appropriate API call
   *
   *
   *
   *
   */
  onSubmit() {
    var data = {
      employeeId: this.state.eid,
      email: this.state.email + "@" + this.state.domain,
      firstName: this.state.firstname,
      lastName: this.state.lastname,
      roles: this.props.makeAdmin
        ? ["ADMIN", "OPERATOR"]
        : this.state.editRoles,
      siteId: this.state.site,
      ...(sessionStorage.getItem("secondary") === "true" &&
        !this.props.makeAdmin && {
          secondarySiteIds:
            this.state.selected.length !== 0
              ? this.state.selected.map(el => el.value)
              : []
        }),
      allowOfflineUse: this.state.allowOfflineUse
    };
    this.setState({ isLoaded: false, disableButton: true });

    postAuthenticatedData(
      this.props.makeAdmin
        ? sessionStorage.getItem("domain") +
            createAdminAccount +
            "/" +
            this.props.orgId
        : sessionStorage.getItem("domain") + createUser,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true, disableButton: false });
        if (result.status === 200) {
          toast.success(this.t(T_USERS.toast.userAddedSuccessfully));
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleChange(e) {
    switch (e.currentTarget.name) {
      case "editdoctor":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(e => e !== "DOCTOR")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "DOCTOR"] });
        }
        break;
      case "editadmin":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(e => e !== "ADMIN")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "ADMIN"] });
        }
        break;
      case "editservice":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(e => e !== "SERVICE")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "SERVICE"] });
        }
        break;
      case "editoperator":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this.state.editRoles.filter(e => e !== "OPERATOR")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "OPERATOR"] });
        }
        break;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner create-modal">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_USERS.addAccount.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />

            <div className="firstpage">
              <FormGroup controlId="name" bsSize="medium">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.user.firstName)}
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      autoFocus
                      required
                      type="text"
                      name="firstname"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </FormGroup>

              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.user.lastName)}
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      required
                      type="text"
                      name="lastname"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.user.site)}
                  </div>
                  <div className="col-md-9">
                    <select name="site" onChange={this.onChange}>
                      {this.state.sites.map(e => (
                        <option value={e?.siteId} key={e?.siteId}>
                          {e?.siteName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </FormGroup>
              {(() => {
                if (
                  sessionStorage.getItem("secondary") === "true" &&
                  !this.props.makeAdmin
                ) {
                  return (
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.user.secondarySite)}
                        </div>
                        <div className="col-md-9">
                          <MultiSelect
                            options={this.state.options}
                            value={this.state.selected}
                            onChange={this.setSelected}
                            labelledBy="Select"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  );
                }
              })()}
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.user.registrationNumber)}
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      required
                      type="text"
                      name="eid"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </FormGroup>
              {(() => {
                if (!this.props.makeAdmin) {
                  return (
                    <>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row formfont">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.user.roles)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editadmin"
                              checked={this?.state?.editRoles?.includes?.(
                                "ADMIN"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.user.roleTypes.admin)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editdoctor"
                              checked={this?.state?.editRoles?.includes?.(
                                "DOCTOR"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.user.roleTypes.doctor)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editoperator"
                              checked={this?.state?.editRoles?.includes?.(
                                "OPERATOR"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.user.roleTypes.operator)}
                          </div>
                          {(() => {
                            if (remidioOrgIDs.includes(this.props.orgId)) {
                              return (
                                <>
                                  <div className="checkbox">
                                    <FormControl
                                      className="check"
                                      type="checkbox"
                                      name="editservice"
                                      checked={this?.state?.editRoles?.includes?.(
                                        "SERVICE"
                                      )}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <div className="checkbox-label formfont">
                                    {this.t(T_ENTITIES.user.roleTypes.service)}
                                  </div>
                                </>
                              );
                            }
                          })()}
                        </div>
                      </FormGroup>
                    </>
                  );
                }
              })()}
              <FormGroup controlId="email" bsSize="large">
                <div className="row input-email">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.user.emailID)}
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      required
                      type="text"
                      name="email"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col-md-6 domain-name">
                    {this.state.domain === "" ? "" : "@"}
                    {this.state.domain}
                  </div>
                </div>
              </FormGroup>
              {(() => {
                if (sessionStorage.getItem("ad")) {
                  return (
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_USERS.addAccount.offlineUse)}
                        </div>
                        <div className="col-md-9">
                          <BootstrapSwitchButton
                            width={100}
                            height={10}
                            checked={this.state.allowOfflineUse}
                            onstyle="success"
                            onChange={checked =>
                              this.setState({
                                allowOfflineUse: checked
                              })
                            }
                          />
                        </div>
                      </div>
                      <p className="offline-warning">
                        {this.t(T_USERS.addAccount.warning)}
                      </p>
                    </FormGroup>
                  );
                }
              })()}

              <Button
                onClick={this.onSubmit}
                className="loginbtn1 create-account-btn custom-modal-btn"
                disabled={this.state.disableButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.create)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomModal);
