/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomAddPatientModal from "./CustomAddPatientModal";
import { Button } from "react-bootstrap";
import { getPatients, getUser, getSites } from "../../constants/endpoints";
import { getAuthenticatedData } from "../../utils/async";
import { convertEpochToDate } from "../../utils/date";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import MultiSelect from "react-multi-select-component";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_GENERAL,
  T_PAGINATION,
  T_PATIENTS
} from "../../constants/translations";

/**
 * This is the component for the Patients page. <br>
 * Pagination and state are maintained using sessionStorage. <br>
 * The history API is used to check if this page is loaded using the back button <br>
 * or whether the user can in directly.
 * SRS IDs: <br>
 * AIM - 5.3.14.8, 5.3.14.9, 5.3.14.10, 5.3.14.24 <br>
 * PSL - 5.3.14.8, 5.3.14.9, 5.3.14.10, 5.3.14.24 <br>
 * FOP - 5.3.14.8, 5.3.14.9, 5.3.14.10, 5.3.14.25 <br>
 *
 * @component
 */
class PatientsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: this.props.history.action === "POP" ? true : false,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      currentSites:
        this.props.history.action === "POP"
          ? JSON.parse(sessionStorage.getItem("pDashCurrentSite"))
          : [],
      prevPage:
        this.props.history.action === "POP" &&
        sessionStorage.getItem("pDashPrevPage") !== "null"
          ? sessionStorage.getItem("pDashPrevPage")
          : null,
      nextPage:
        this.props.history.action === "POP" &&
        sessionStorage.getItem("pDashNextPage") !== "null"
          ? sessionStorage.getItem("pDashNextPage")
          : null,
      pageNumber:
        this.props.history.action === "POP" &&
        sessionStorage.getItem("pDashPageNumber") !== "null"
          ? sessionStorage.getItem("pDashPageNumber")
          : 1,
      patients: null,
      showAddPatientModal: false,
      suggestions: [],
      dates: [],
      currentUrl: sessionStorage.getItem("pDashUrl"),
      sites: [],
      userSite: null,
      isTableLoading: true,
      userRoles: [],
      searchText:
        this.props.history.action === "POP"
          ? sessionStorage.getItem("pDashSearchText")
          : "",
      showMultiSelect: false,
      options: [],
      selected:
        this.props.history.action === "POP"
          ? JSON.parse(sessionStorage.getItem("pDashSelected"))
          : [],
      patientSites: [],
      primarySiteDetails: [],
      clearedManually: false
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.getDates = this.getDates.bind(this);
    this.toggleAddPatientModal = this.toggleAddPatientModal.bind(this);
    this.resetList = this.resetList.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.generateSiteIdUrl = this.generateSiteIdUrl.bind(this);
    this.getPatientSites = this.getPatientSites.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
    this.getGenderTranslation = this.getGenderTranslation.bind(this);
  }

  componentDidMount() {
    this.getDates(this.state.patients);
    this.state.userResults
      .then(result => {
        // Added a callback to getPatientSites to ensure patients and sites have been loaded
        getAuthenticatedData(sessionStorage.getItem("domain") + getSites).then(
          res => {
            this.setState(
              {
                sites: res?.data,
                showMultiSelect: true,
                options: res?.data.map(el => {
                  return { label: el.siteName, value: el.siteId };
                }),
                primarySiteDetails: [
                  {
                    label: result?.data?.siteName,
                    value: result?.data.siteId
                  }
                ],
                selected:
                  sessionStorage.getItem("pDashSelected") &&
                  sessionStorage.getItem("pDashSelected") !== "null" &&
                  sessionStorage.getItem("pDashSelected") !== "[]"
                    ? JSON.parse(sessionStorage.getItem("pDashSelected"))
                    : [
                        {
                          label: result?.data?.siteName,
                          value: result?.data.siteId
                        }
                      ]
              },
              this.getPatientSites
            );
          }
        );
        this.setState({
          currentSite:
            this.props.history.action === "POP"
              ? JSON.parse(sessionStorage.getItem("pDashCurrentSite"))
              : result?.data?.siteId,
          userSite: result?.data?.siteId,
          userSiteName: result?.data?.siteName,
          userRoles: result?.data?.roles
        });
        if (this.props.history.action === "PUSH") {
          this.setState({
            currentUrl:
              sessionStorage.getItem("domain") +
              getPatients +
              "?siteId=" +
              result?.data?.siteId +
              "&paginate=true"
          });
        }
        return getAuthenticatedData(
          sessionStorage.getItem("domain") +
            getPatients +
            "?siteId=" +
            result?.data?.siteId +
            "&paginate=true"
        );
      })
      .then(
        result => {
          this.setState({ isTableLoading: true });
          if (this.props.history.action === "PUSH") {
            // Added a callback to getPatientSites to ensure patients and sites have been loaded
            this.setState(
              {
                patients: result?.data,
                isTableLoading: false,
                isLoaded: true,
                prevPage: result?.paging?.prevUrl,
                nextPage: result?.paging?.nextUrl
              },
              this.getPatientSites
            );
            this.getDates(result?.data);
            //this.getPatientSites(result?.data);
          } else {
            this.setInput(this.state.searchText);
            this.setState({ isTableLoading: true });
            // Added a callback to getPatientSites to ensure patients and sites have been loaded
            getAuthenticatedData(sessionStorage.getItem("pDashUrl")).then(
              result => {
                this.setState(
                  {
                    patients: result?.data,
                    isLoaded: true,
                    isTableLoading: false,
                    prevPage: result?.paging?.prevUrl,
                    nextPage: result?.paging?.nextUrl,
                    pageNumber: parseInt(
                      sessionStorage.getItem("pDashPageNumber")
                    ),
                    currentUrl: sessionStorage.getItem("pDashUrl")
                  },
                  this.getPatientSites
                );
                this.getDates(result?.data);
                //this.getPatientSites(result?.data);
              }
            );
          }
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }
  /**
   * Retains state from session storafe
   *
   *
   *
   */
  componentDidUpdate() {
    sessionStorage.setItem(
      "pDashCurrentSite",
      JSON.stringify(this.state.currentSites)
    );
    sessionStorage.setItem("pDashPrevPage", this.state.prevPage);
    sessionStorage.setItem("pDashNextPage", this.state.nextPage);
    sessionStorage.setItem("pDashPageNumber", this.state.pageNumber);
    sessionStorage.setItem("pDashSearchText", this.state.searchText);
    sessionStorage.setItem("pDashUrl", this.state.currentUrl);
    sessionStorage.setItem(
      "pDashSelected",
      JSON.stringify(this.state.selected)
    );
  }

  getGenderTranslation(gender) {
    switch (gender) {
      case "MALE":
        return this.t(T_ENTITIES.patient.genderTypes.male);
      case "FEMALE":
        return this.t(T_ENTITIES.patient.genderTypes.female);
    }
  }

  generateSiteIdUrl(siteIds) {
    if (siteIds.length === 0) {
      return "";
    }
    return (
      "siteId=" +
      siteIds.reduce((acc, el) => {
        return acc + "&siteId=" + el;
      })
    );
  }

  menuToggle(open) {
    if (!open) {
      this.clearInput();
      this.resetList();
    }
  }

  setSelected(e) {
    if (e.length === 0) {
      this.setState({
        selected: this.state.primarySiteDetails,
        currentSites: this.state.primarySiteDetails.map(el => el.value),
        clearedManually: true
      });
    } else {
      this.setState({
        selected: e,
        currentSites: e.map(el => el.value),
        clearedManually: true
      });
    }
  }

  toggleAddPatientModal() {
    this.setState({
      showAddPatientModal: !this.state.showAddPatientModal
    });
  }

  /**
   * Goes to the previous page
   *
   *
   *
   */
  goToPreviousPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.prevPage
    ).then(
      result => {
        this.setState({
          patients: result?.data,
          isTableLoading: false,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber - 1,
          currentUrl: sessionStorage.getItem("domain") + this.state.prevPage
        });
        this.getDates(result?.data);
        this.getPatientSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Goes to the next page
   *
   *
   *
   */
  goToNextPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.nextPage
    ).then(
      result => {
        this.setState({
          patients: result?.data,
          isTableLoading: false,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber + 1,
          currentUrl: sessionStorage.getItem("domain") + this.state.nextPage
        });
        this.getDates(result?.data);
        this.getPatientSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Clears the search field and reframes the API call accordingly
   *
   *
   *
   */
  handleClear() {
    if (!this.state.clearedManually) {
      this.setState({ isTableLoading: true });
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getPatients +
          "?" +
          this.generateSiteIdUrl(this.state.currentSites) +
          "&paginate=true"
      ).then(
        result => {
          this.setState({
            patients: result?.data,
            isTableLoading: false,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            pageNumber: 1,
            searchText: "",
            currentUrl:
              sessionStorage.getItem("domain") +
              getPatients +
              "?" +
              this.generateSiteIdUrl(this.state.currentSites) +
              "&paginate=true"
          });
          this.getDates(result?.data);
          this.getPatientSites(result?.data);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
    this.setState({ clearedManually: false });
  }
  /**
   * Triggers the search API call, keeping into account other filters
   * @param {string} value the search string
   *
   *
   *
   */
  handleSearch(value) {
    if (value) {
      this.setState({ isTableLoading: true });
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getPatients +
          "?" +
          this.generateSiteIdUrl(this.state.currentSites) +
          "&paginate=true&search=" +
          value
      ).then(
        result => {
          this.setState({
            patients: result?.data,
            isTableLoading: false,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            pageNumber: 1,
            searchText: value,
            currentUrl:
              sessionStorage.getItem("domain") +
              getPatients +
              "?" +
              this.generateSiteIdUrl(this.state.currentSites) +
              "&paginate=true&search=" +
              value
          });
          this.getDates(result?.data);
          this.getPatientSites(result?.data);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}
  /**
   * Converts all the dates from epochs to a readable string
   *
   * @param {array} items array containing a list of all patients
   *
   */
  getDates(items) {
    let dates = items?.map?.(item => convertEpochToDate(item?.dateOfBirth));
    this.setState({ dates });
  }

  getPatientSites() {
    // eslint-disable-next-line no-console
    console.log(this.state.sites);

    // Sites or patients have not been loaded yet
    if (
      !this.state.sites ||
      !this.state.patients ||
      this.state.sites.length === 0 ||
      this.state.patients.length === 0
    ) {
      return;
    }

    const siteArray = this.state.sites.map(el => el.siteId);
    let patientSites = this.state.patients?.map?.(item => {
      let index = 0;
      if (siteArray.includes(item.siteId)) {
        index = siteArray.indexOf(item.siteId);
      }
      return this.state.sites[index].siteName;
    });
    this.setState({ patientSites });
  }

  /**
   * Resets the list of patients and clears all filters
   *
   *
   *
   */
  resetList() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getPatients +
        "?" +
        this.generateSiteIdUrl(this.state.currentSites) +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          patients: result?.data,
          isTableLoading: false,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1,
          currentUrl:
            sessionStorage.getItem("domain") +
            getPatients +
            "?" +
            this.generateSiteIdUrl(this.state.currentSites) +
            "&paginate=true"
        });
        this.getDates(result?.data);
        this.getPatientSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  render() {
    const { isLoaded, patients } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="8" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                {(() => {
                  if (this.state.showMultiSelect) {
                    return (
                      <MultiSelect
                        options={this.state.options}
                        value={this.state.selected}
                        onChange={this.setSelected}
                        onMenuToggle={this.menuToggle}
                        labelledBy="Select"
                      />
                    );
                  }
                })()}
                {(() => {
                  return (
                    <div className="addaccount-container">
                      {(() => {
                        return (
                          <Button
                            className="addaccount"
                            onClick={this.toggleAddPatientModal}
                          >
                            {this.t(T_PATIENTS.addPatientButton)}
                          </Button>
                        );
                      })()}
                    </div>
                  );
                })()}
              </div>
              <h6 className="exam-heading">{this.t(T_PATIENTS.header)}</h6>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder={this.t(T_PATIENTS.searchPatients)}
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
                onRef={ref => {
                  this.clearInput = ref.clearInput;
                  this.setInput = ref.setInput;
                }}
              />
              {(() => {
                if (this.state.isTableLoading) {
                  return (
                    <div className="table-error">
                      <p>
                        <img src={require("../../assets/images/loading.gif")} />
                      </p>
                    </div>
                  );
                } else if (!patients || patients.length === 0) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_ENTITIES.patient.name)}</th>
                            <th>{this.t(T_ENTITIES.patient.gender)}</th>
                            <th>{this.t(T_ENTITIES.patient.mrn)}</th>
                            <th>{this.t(T_ENTITIES.patient.dateOfBirth)}</th>
                            <th>{this.t(T_ENTITIES.patient.site)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {patients?.map?.((item, index) => (
                            <tr key={item.id}>
                              <td id={"dName" + item.userId}>
                                <Link
                                  to={{
                                    pathname: "/patient-details",
                                    state: {
                                      mrn: item?.mrn,
                                      name:
                                        item?.firstName +
                                        (item?.lastName
                                          ? " " + item?.lastName
                                          : ""),
                                      siteId: item?.siteId
                                    }
                                  }}
                                >
                                  <span className="editbtn">
                                    {item?.firstName} {item?.lastName}
                                  </span>
                                </Link>
                              </td>
                              <td className="patient-gender">
                                {this.getGenderTranslation(item?.gender)}
                              </td>
                              <td>{item?.mrn}</td>
                              <td>{this.state.dates?.[index]}</td>
                              <td>{this.state.patientSites?.[index]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; {this.t(T_PAGINATION.previous)}</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          let startRow = this.state.pageNumber * 10 - 9;
                          let endRow = startRow + patients.length - 1;
                          return (
                            <span className="rowcount-text">
                              {this.t(T_PAGINATION.showingRows, {
                                from: startRow,
                                to: endRow
                              })}
                            </span>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>{this.t(T_PAGINATION.next)} &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showAddPatientModal ? (
                <CustomAddPatientModal
                  text='Click "Close Button" to hide popup'
                  closePopup={this.toggleAddPatientModal}
                  resetList={this.resetList}
                  sites={this.state.sites}
                  currentSite={this.state.primarySiteDetails[0].value}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(PatientsDashboard);
