/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Chart from "react-apexcharts";
import CustomStatisticsTableModal from "./CustomStatisticsTableModal";
import { getShortMonth } from "../../utils/date";
import { withTranslation } from "react-i18next";
import { T_STATISTICS } from "../../constants/translations";

/**
 * The component for the Total Exams tab of the statistics page. It uses react-apexcharts to render the graphs.
 *
 * @component
 */
class TotalExams extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      showStatisticsTableModal: false
    };

    this.openStatisticsTableModal = this.openStatisticsTableModal.bind(this);
    this.closeStatisticsTableModal = this.closeStatisticsTableModal.bind(this);
  }
  componentDidMount() {}

  openStatisticsTableModal() {
    this.setState({ showStatisticsTableModal: true });
  }

  closeStatisticsTableModal() {
    this.setState({ showStatisticsTableModal: false });
  }

  render() {
    let siteName = this.props.selectedSite;
    let siteCounters = this.props.analytics.siteCounters;
    let index;
    for (let i = 0; i < siteCounters.length; i++) {
      if (siteCounters[i].siteName === siteName) {
        index = i;
        break;
      }
    }
    let sevenDayOptions = siteCounters[index]?.dailySiteCounters?.map(el => {
      return [
        new Date(el.date).getDate() +
          " " +
          getShortMonth(new Date(el.date).getMonth())
      ];
    });
    let allSiteSeries = [
      {
        name: this.t(T_STATISTICS.totalExams),
        data: [...this.props.analytics.siteCounters]
          .sort((a, b) => b.totalExams - a.totalExams)
          .map(el => el.totalExams)
      }
    ];
    let sevenDaySeries = [...siteCounters[index]?.dailySiteCounters]
      // .sort((a, b) => b.totalExams - a.totalExams)
      .map(el => el.totalExams);
    let allSiteOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
        height: 350
      },
      colors: ["#25c881"],
      grid: { show: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          vertical: true,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        categories: [...this.props.analytics.siteCounters]
          .sort((a, b) => b.totalExams - a.totalExams)
          .map(el => el.siteName),
        labels: {
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        }
      }
    };

    let thirtyDaySpecificSiteOptions = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: { enabled: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      colors: ["#25c881"],
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        labels: {
          show: true,
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        },
        categories: sevenDayOptions
      },
      grid: { show: false },
      yaxis: {
        lines: {
          show: false
        },
        show: true
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };
    let thirtyDaySpecificSiteSeries = [
      {
        name: this.t(T_STATISTICS.totalExams),
        data: sevenDaySeries
      }
    ];
    let sevenDaySpecificSiteSeries = [
      {
        name: this.t(T_STATISTICS.totalExams),
        data: sevenDaySeries
      }
    ];
    let sevenDaySpecificSiteOptions = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: { enabled: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        }
      },
      colors: ["#25c881", "#afe8ca"],
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        labels: {
          show: true,
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        },
        categories: sevenDayOptions
      },
      grid: { show: false },
      yaxis: {
        lines: {
          show: false
        },
        show: true
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };
    if (this.props.siteRadio === "all-sites") {
      return (
        <>
          <div className="graph-container">
            {(() => {
              if (this.props.timelineRadio === "last-seven-days") {
                return (
                  <div className="chart-title">
                    {this.t(T_STATISTICS.totalExamsInLastDays, { count: 7 })}
                  </div>
                );
              } else if (this.props.timelineRadio === "last-thirty-days") {
                return (
                  <div className="chart-title">
                    {this.t(T_STATISTICS.totalExamsInLastDays, { count: 30 })}
                  </div>
                );
              } else {
                return (
                  <div className="chart-title">
                    {this.t(T_STATISTICS.totalExamsInMonth, {
                      month: this.props.month
                    })}
                  </div>
                );
              }
            })()}
            {(() => {
              if (
                allSiteSeries[0].data.reduce((acc, val) => acc + val, 0) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="chart-container">
                    <div className="chart">
                      <Chart
                        key={5}
                        options={allSiteOptions}
                        series={allSiteSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                );
              }
            })()}
            <div className="see-more-container">
              <button onClick={this.openStatisticsTableModal}>
                {this.t(T_STATISTICS.seeMoreButton)}
              </button>
            </div>
          </div>
          {this.state.showStatisticsTableModal ? (
            <CustomStatisticsTableModal
              closePopup={this.closeStatisticsTableModal}
              analytics={this.props.analytics}
              month={this.props.month}
              selectedSite={this.props.selectedSite}
              siteRadio={this.props.siteRadio}
              timelineRadio={this.props.timelineRadio}
            />
          ) : null}
        </>
      );
    } else if (this.props.siteRadio === "specific-site") {
      return (
        <div className="graph-container">
          {(() => {
            if (this.props.timelineRadio === "last-seven-days") {
              return (
                <div className="chart-title">
                  {this.t(T_STATISTICS.totalExamsInLastDays, { count: 7 })}
                </div>
              );
            } else if (this.props.timelineRadio === "last-thirty-days") {
              return (
                <div className="chart-title">
                  {this.t(T_STATISTICS.totalExamsInLastDays, { count: 30 })}
                </div>
              );
            } else {
              return (
                <div className="chart-title">
                  {this.t(T_STATISTICS.totalExamsInMonth, {
                    month: this.props.month
                  })}
                </div>
              );
            }
          })()}
          {(() => {
            if (this.props.timelineRadio === "last-seven-days") {
              if (
                sevenDaySpecificSiteSeries[0].data.reduce(
                  (acc, val) => acc + val
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <>
                  <div className="chart-container">
                    <div className="chart">
                      <Chart
                        key={6}
                        options={sevenDaySpecificSiteOptions}
                        series={sevenDaySpecificSiteSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                </>
              );
            } else if (this.props.timelineRadio === "last-thirty-days") {
              if (
                thirtyDaySpecificSiteSeries[0].data.reduce(
                  (acc, val) => acc + val,
                  0
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <>
                  <div className="chart-container">
                    <div className="chart thirty">
                      <Chart
                        key={7}
                        options={thirtyDaySpecificSiteOptions}
                        series={thirtyDaySpecificSiteSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                </>
              );
            } else if (this.props.timelineRadio === "specific-month") {
              {
                if (
                  thirtyDaySpecificSiteSeries[0].data.reduce(
                    (acc, val) => acc + val,
                    0
                  ) === 0
                ) {
                  return (
                    <div className="chart-container">
                      <div className="chart no-results-container">
                        <img
                          src={require("../../assets/images/no-results.svg")}
                        />
                        <div className="no-results">
                          {this.t(T_STATISTICS.noResultsFound)}
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <>
                    <div className="chart-container">
                      <div className="chart thirty">
                        <Chart
                          key={8}
                          options={thirtyDaySpecificSiteOptions}
                          series={thirtyDaySpecificSiteSeries}
                          type="bar"
                          height="300"
                        ></Chart>
                      </div>
                    </div>
                  </>
                );
              }
            }
          })()}
        </div>
      );
    }
  }
}

export default withTranslation()(TotalExams);
