import i18next from "i18next";

const getMonth = monthNumber => {
  const monthsEnglish = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const monthsItalian = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ];

  const isItalian = i18next.language === "it";
  return isItalian ? monthsItalian[monthNumber] : monthsEnglish[monthNumber];
};

export const getShortMonth = monthNumber => {
  const monthsEnglish = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const monthsItalian = [
    "Gen",
    "Feb",
    "Mar",
    "Apr",
    "Mag",
    "Giu",
    "Lug",
    "Ago",
    "Set",
    "Ott",
    "Nov",
    "Dic"
  ];

  const isItalian = i18next.language === "it";
  return isItalian ? monthsItalian[monthNumber] : monthsEnglish[monthNumber];
};

export const getDay = dayNumber => {
  const daysEnglish = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  const daysItalian = [
    "Domenica",
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica"
  ];

  const isItalian = i18next.language === "it";
  return isItalian ? daysItalian[dayNumber] : daysEnglish[dayNumber];
};

export const convertEpochToDate = epoch => {
  if (!epoch) {
    return null;
  }
  let date = new Date(epoch);
  let day = date.getDate();
  let monthNumber = date.getMonth();
  let month = getMonth(monthNumber);
  let year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const convertEpochToTime = epoch => {
  if (!epoch) {
    return "";
  }
  let date = new Date(epoch);
  let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  let hoursTwoDigits = String(hours).padStart(2, "0");
  let minutes = date.getMinutes();
  let minutesTwoDigits = String(minutes).padStart(2, "0");
  let division = date.getHours() >= 12 ? "PM" : "AM";
  return `${hoursTwoDigits}:${minutesTwoDigits} ${division}`;
};
