/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import CustomAboutScreenModal from "./CustomAboutScreenModal";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { postAuthenticatedData } from "../../utils/async";
import { logoutAPI } from "../../constants/endpoints";
import { withTranslation } from "react-i18next";
import { T_SIDEBAR } from "../../constants/translations";

/**
 * This is the component for the Sidebar <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.12, 5.3.14.18, 5.3.14.19, 5.3.14.21, 5.3.14.22 <br>
 * PSL - 5.3.14.12, 5.3.14.18, 5.3.14.19, 5.3.14.21, 5.3.14.22 <br>
 * FOP - 5.3.14.12, 5.3.14.18, 5.3.14.19, 5.3.14.21, 5.3.14.22, 5.3.14.23 <br>
 *
 * @component
 */
class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      showAdmin: false,
      bearerToken: "",
      firstName: "",
      lastName: "",
      redirect: false,
      siteId: 0,
      showService: false,
      showDoctor: false,
      showOperator: false,
      showAboutScreen: false
    };
    this.logout = this.logout.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.openAboutScreen = this.openAboutScreen.bind(this);
    this.closeAboutScreen = this.closeAboutScreen.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  openAboutScreen() {
    this.setState({ showAboutScreen: true });
  }

  closeAboutScreen() {
    this.setState({ showAboutScreen: false });
  }

  /**
   * Takes a promise from the props and fetches the logged in user's details to display in the sidebar
   *
   *
   *
   */
  fetchUser() {
    let promise;
    promise = this.props.results;
    promise.then(result => {
      if (result?.data?.roles?.includes("ADMIN")) {
        this.setState({ showAdmin: true });
      }
      if (result?.data?.roles?.includes("DOCTOR")) {
        this.setState({ showDoctor: true });
      }
      if (result?.data?.roles?.includes("OPERATOR")) {
        this.setState({ showOperator: true });
      }
      if (result?.data?.roles?.includes("SERVICE")) {
        this.setState({ showService: true });
      }
      this.setState({
        firstName: result?.data?.firstName,
        lastName: result?.data?.lastName,
        siteId: result?.data?.siteId,
        siteName: result?.data?.siteName
      });
    });
  }
  /**
   * Logs out the user
   *
   *
   *
   */
  logout() {
    postAuthenticatedData(
      sessionStorage.getItem("domain") + logoutAPI,
      {}
    ).then(
      () => {
        sessionStorage.clear();
        this.setState({ redirect: true });
      },
      () => {
        sessionStorage.clear();
        this.setState({ redirect: true });
      }
    );
  }

  render() {
    let adminTemplate,
      serviceTemplate,
      patientsTemplate,
      examsTemplate,
      statisticsTemplate,
      sitesTemplate = null;
    if (sessionStorage.getItem("tele") === "true" && this.state.showAdmin) {
      statisticsTemplate = (
        <Link to="/statistics">
          <div
            className={
              "adminoption" + (this?.props?.from === "9" ? " selected" : "")
            }
          >
            {this.t(T_SIDEBAR.statistics)}
          </div>
        </Link>
      );
    }
    if (this.state.showAdmin) {
      adminTemplate = (
        <Link to="/">
          <div
            className={
              "adminoption" + (this?.props?.from === "0" ? " selected" : "")
            }
          >
            {this.t(T_SIDEBAR.users)}
          </div>
        </Link>
      );
    }
    if (this.state.showDoctor || this.state.showOperator || this.showAdmin) {
      patientsTemplate = (
        <Link to="/patients-dashboard">
          <div
            className={
              "adminoption" + (this?.props?.from === "8" ? " selected" : "")
            }
          >
            {this.t(T_SIDEBAR.patients)}
          </div>
        </Link>
      );
    }
    if (this.state.showDoctor || this.state.showOperator || this.showAdmin) {
      examsTemplate = (
        <Link to="/exam-data">
          <div
            className={
              "adminoption" + (this?.props?.from === "3" ? " selected" : "")
            }
          >
            {this.t(T_SIDEBAR.exams)}
          </div>
        </Link>
      );
    }
    if (this.state.showService) {
      serviceTemplate = (
        <Link to="/service-dashboard">
          <div
            className={
              "adminoption" + (this?.props?.from === "7" ? " selected" : "")
            }
          >
            {this.t(T_SIDEBAR.service)}
          </div>
        </Link>
      );
    }
    if (this.state.showAdmin) {
      sitesTemplate = (
        <Link to="/sites">
          <div
            className={
              "adminoption" + (this?.props?.from === "2" ? " selected" : "")
            }
          >
            {this.t(T_SIDEBAR.sites)}
          </div>
        </Link>
      );
    }
    if (this?.state?.redirect) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div>
        <div className="marginside sidebar-name">
          <div className="adminname">
            {this?.state?.firstName} {this?.state?.lastName}
          </div>
        </div>
        <hr />
        <div className="marginside">
          {serviceTemplate}
          {adminTemplate}
          {sitesTemplate}
          {patientsTemplate}
          {examsTemplate}
          {statisticsTemplate}
          <Link to="/edit-admin">
            <div
              className={
                "adminoption" + (this?.props?.from === "1" ? " selected" : "")
              }
            >
              {this.t(T_SIDEBAR.userAccount)}
            </div>
          </Link>
          <a onClick={this.openAboutScreen}>
            <div className="adminoption">{this.t(T_SIDEBAR.about)}</div>
          </a>
          <button onClick={this.logout} className="adminoption logout">
            {this.t(T_SIDEBAR.logoutOnAllDevices)}
          </button>
        </div>
        {this.state.showAboutScreen ? (
          <CustomAboutScreenModal closePopup={this.closeAboutScreen} />
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(Sidebar);
