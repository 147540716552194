/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import AppNavbar from "./AppNavbar";
import Sidebar from "./Sidebar";
import {
  changePassword,
  deleteUserSignature,
  getUser,
  getUserSignature,
  uploadUserSignature
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  putAuthenticatedData,
  postAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { validatePass } from "../../utils/validatePassword";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { T_ACCOUNT, T_GENERAL } from "../../constants/translations";

/**
 * This is the component for the Edit Password Page <br>
 * It checks whether the password contains at least 8 characters, and matches the second field. <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.11 <br>
 * PSL - 5.3.14.11 <br>
 * FOP - 5.3.14.11 <br>
 *
 * @component
 */
class EditAdmin extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      currentPassword: "",
      reNewPassword: "",
      newPassword: "",
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      containsEightCharacters: false,
      containsBothCases: false,
      containsNumberOrSymbol: false,
      passwordsMatch: true,
      disableButton: true,
      showLoader: false,
      showConfirmModal: false,
      showDeleteSignatureConfirmation: false,
      confirmButtonDisabled: false,
      isLoaded: false,
      signatureExists: false,
      image: "",
      toggler: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(
      this
    );
    this.handleChangeCurrentPassword = this.handleChangeCurrentPassword.bind(
      this
    );
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addSignature = this.addSignature.bind(this);
    this.toggleDeleteSignatureConfirmation = this.toggleDeleteSignatureConfirmation.bind(
      this
    );
    this.deleteSignature = this.deleteSignature.bind(this);
    this.viewImage = this.viewImage.bind(this);
  }

  componentDidMount() {
    this.reset();
  }

  reset() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getUserSignature
    ).then(res => {
      if (res.status.statusCode === "NOT_FOUND") {
        this.setState({ isLoaded: true, signatureExists: false, image: "" });
      } else {
        this.setState({
          isLoaded: true,
          signatureExists: true,
          image: res.data,
          showDeleteSignatureConfirmation: false
        });
      }
    });
  }
  /**
   * Checks the entered password against the confirm password field
   * @param {string} e the entered value
   *
   *
   */
  validatePassword(e) {
    this.setState(validatePass(e, this.state.confirmPassword));
  }
  /**
   * Checks the entered confirm password field against the password field
   * @param {string} e the entered value
   *
   *
   */
  validateConfirmPassword(e) {
    let confirmPassword = e.target.value;
    let disableButton = !(
      this.state.containsEightCharacters &&
      this.state.containsBothCases &&
      this.state.containsNumberOrSymbol &&
      confirmPassword === this.state.newPassword
    );
    this.setState({
      passwordsMatch: confirmPassword === this.state.newPassword,
      disableButton
    });
  }

  showModal() {
    this.setState({ showConfirmModal: true });
  }
  closeModal() {
    this.setState({ showConfirmModal: false });
  }
  handleChangeCurrentPassword(e) {
    this.setState({ currentPassword: e.target.value });
  }
  handleChangePassword(e) {
    this.validatePassword(e);
    this.setState({ newPassword: e.target.value });
  }
  handleChangeConfirmPassword(e) {
    this.validateConfirmPassword(e);
    this.setState({
      reNewPassword: e.target.value
    });
  }
  logout() {
    sessionStorage.clear();
    this.setState({ redirect: true });
  }

  viewImage() {
    this.setState({ toggler: !this.state.toggler });
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = error => reject(error);
    });
  }

  async addSignature(e) {
    e.persist();
    const file = e.target.files[0];
    if (file === undefined) {
      // FileChooser closed
      return;
    }
    let imageReg = /[/.](gif|jpg|jpeg|tiff|png)$/i;
    if (imageReg.test(file.name)) {
      let data = {
        base64: await this.toBase64(file)
      };
      postAuthenticatedData(
        sessionStorage.getItem("domain") + uploadUserSignature,
        data
      ).then(
        result => {
          if (result.status === 200) {
            this.reset();
            toast.success(this.t(T_ACCOUNT.toast.uploadSuccessful));
          } else toast.error(this.t(T_GENERAL.somethingWentWrong));
        },
        () => {
          toast.error(this.t(T_GENERAL.somethingWentWrong));
          this.reset();
          this.setState({
            isLoaded: true
          });
        }
      );
    } else {
      toast.error(this.t(T_ACCOUNT.toast.unsupportedFileFormat));
    }
  }

  toggleDeleteSignatureConfirmation() {
    this.setState({ showDeleteSignatureConfirmation: true });
  }

  async deleteSignature() {
    deleteAuthenticatedData(
      sessionStorage.getItem("domain") + deleteUserSignature
    ).then(
      result => {
        if (result.status === 200) {
          this.reset();
          toast.success(this.t(T_ACCOUNT.toast.deleteSuccessful));
        } else toast.error(this.t(T_GENERAL.somethingWentWrong));
      },
      () => {
        toast.error(this.t(T_GENERAL.somethingWentWrong));
        this.reset();
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  onSubmit() {
    this.setState({ confirmButtonDisabled: true });
    let data = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      reNewPassword: this.state.reNewPassword
    };
    putAuthenticatedData(
      sessionStorage.getItem("domain") + changePassword,
      data
    )
      .then(result => {
        this.setState({
          confirmButtonDisabled: false,
          showConfirmModal: false
        });
        if (result.status === 200) {
          toast.success(this.t(T_ACCOUNT.toast.changePasswordSuccessful));
          this.logout();
        }
        return result.json();
      })
      .then(resp => {
        this.setState({ confirmButtonDisabled: false });
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  render() {
    const EightChars = () => {
      if (this.state.containsEightCharacters) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_ACCOUNT.regexCheck.eightChars)}
          </p>
        );
      }
    };
    const BothCases = () => {
      if (this.state.containsBothCases) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_ACCOUNT.regexCheck.bothCases)}
          </p>
        );
      }
    };
    const SpecialChars = () => {
      if (this.state.containsNumberOrSymbol) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_ACCOUNT.regexCheck.numberAndSymbol)}
          </p>
        );
      }
    };
    const Matching = () => {
      if (this.state.passwordsMatch) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_ACCOUNT.regexCheck.passwordShouldMatch)}
          </p>
        );
      }
    };
    if (this?.state?.redirect) {
      return <Redirect to={"/login"} />;
    }
    if (!this.state.isLoaded) {
      return <div className="loading"></div>;
    }
    return (
      <div>
        <AppNavbar />
        <div className="row">
          <div className="col-md-2 sidebar">
            <Sidebar from="1" results={this.state.userResults} />
          </div>
          <div className="col-md-10">
            <div className="editscreen">
              <h5 className="editheading">{this.t(T_ACCOUNT.header)}</h5>
              {(() => {
                if (!sessionStorage.getItem("ad")) {
                  return (
                    <>
                      <h6 className="editheading">
                        {this.t(T_ACCOUNT.editPassword)}
                      </h6>
                      <FormGroup controlId="password" bsSize="large">
                        <FormControl
                          autoFocus
                          required
                          type="password"
                          name="currentPassword"
                          placeholder={this.t(T_ACCOUNT.currentPassword)}
                          onChange={this.handleChangeCurrentPassword}
                        />
                      </FormGroup>
                      <FormGroup controlId="password" bsSize="large">
                        <FormControl
                          required
                          type="password"
                          name="newPassword"
                          placeholder={this.t(T_ACCOUNT.newPassword)}
                          onChange={this.handleChangePassword}
                        />
                      </FormGroup>
                      <FormGroup controlId="confirmpassword" bsSize="large">
                        <FormControl
                          required
                          type="password"
                          name="reNewPassword"
                          placeholder={this.t(T_ACCOUNT.confirmPassword)}
                          onChange={this.handleChangeConfirmPassword}
                        />
                      </FormGroup>
                      <EightChars></EightChars>
                      <BothCases></BothCases>
                      <SpecialChars></SpecialChars>
                      <Matching></Matching>
                      <Button
                        block
                        bsSize="large"
                        onClick={this.showModal}
                        type="submit"
                        className="loginbtn"
                        disabled={this.state.disableButton}
                      >
                        {this.state.showLoader ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          this.t(T_ACCOUNT.resetPassword)
                        )}
                      </Button>
                      <hr />
                    </>
                  );
                }
              })()}
              {(() => {
                if (sessionStorage.getItem("ad")) {
                  return (
                    <h6 className="editheading signature ad">
                      {this.t(T_ACCOUNT.addOrEditSignature)}
                    </h6>
                  );
                } else {
                  return (
                    <h6 className="editheading signature">
                      {this.t(T_ACCOUNT.addOrEditSignature)}
                    </h6>
                  );
                }
              })()}
              {(() => {
                if (!this.state.signatureExists) {
                  return (
                    <>
                      <p>{this.t(T_ACCOUNT.noSignature)}</p>
                      <td className="img-up-btn image-upload">
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.addSignature}
                        />
                        <Button block bsSize="large" className="loginbtn">
                          {this.t(T_ACCOUNT.addSignature)}
                        </Button>
                      </td>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="signature-container">
                        <img className="signature" src={this.state.image} />
                      </div>
                      <td className="img-up-btn image-upload">
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.addSignature}
                        />
                        <Button block bsSize="large" className="loginbtn">
                          {this.t(T_ACCOUNT.updateSignature)}
                        </Button>
                        {(() => {
                          if (!this.state.showDeleteSignatureConfirmation) {
                            return (
                              <Button
                                block
                                bsSize="large"
                                className="delete-signature-btn"
                                onClick={this.toggleDeleteSignatureConfirmation}
                              >
                                {this.t(T_ACCOUNT.deleteSignature)}
                              </Button>
                            );
                          } else {
                            return (
                              <>
                                <p className="delete-confirmation">
                                  {this.t(T_ACCOUNT.areYouSureDelete)}
                                </p>
                                <Button
                                  block
                                  bsSize="large"
                                  className="delete-signature-btn"
                                  onClick={this.deleteSignature}
                                >
                                  {this.t(T_ACCOUNT.yesDelete)}
                                </Button>
                              </>
                            );
                          }
                        })()}
                      </td>
                    </>
                  );
                }
              })()}
            </div>
          </div>
        </div>
        {this.state.showConfirmModal && (
          <div className="custommodal">
            <div className="custommodaldeleteuser">
              <form className="loginpad">
                <h6 className="modal-heading">
                  {this.t(T_ACCOUNT.editPassword)}
                </h6>
                <Button
                  // eslint-disable-next-line react/prop-types
                  onClick={this.closeModal}
                  className="cancle"
                >
                  {" "}
                  X{" "}
                </Button>
                <hr className="hrpadding" />
                <p className="delete-user-admin-text">
                  {this.t(T_ACCOUNT.editingWillLogout)}
                </p>
                <Button
                  onClick={this.onSubmit}
                  disabled={this.state.confirmButtonDisabled}
                  className={"loginbtn2 edit-account-btn warning fill"}
                >
                  {this.state.confirmButtonDisabled ? (
                    <img
                      src={require("../../assets/images/warning-button-loading.gif")}
                    />
                  ) : (
                    <>{this.t(T_ACCOUNT.proceed)}</>
                  )}
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(EditAdmin);
