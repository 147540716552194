/* eslint-disable react/prop-types */
/**
 * @file Contains a method to disallow access to login and related pages while authenticated
 * @author Arun Mahadevan
 *
 */
import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";

/**
 * Checks if the user is authenticated or not
 *
 *
 * @returns {boolean}
 */
const isAuthenticated = () => {
  const token = sessionStorage.getItem("bearerToken");
  if (token && token !== "") {
    return true;
  }
  return false;
};

/**
 * Decides whether to allow the user to access a login page or not. If not, redirects to /
 *
 * @param {object} props  the props to be passed through
 * @returns {component}
 */
const PrivateLoginRoute = props => {
  let routeProps = {
    path: props.path,
    component: props.component,
    exact: props.exact
  };

  return isAuthenticated() ? <Redirect to={"/"} /> : <Route {...routeProps} />;
};

export default PrivateLoginRoute;
