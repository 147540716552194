/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { createPatient } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import DatePicker from "react-datepicker";
import subYears from "date-fns/subYears";
import differenceInYears from "date-fns/differenceInYears";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { T_ENTITIES, T_PATIENTS } from "../../constants/translations";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
registerLocale("it", it);

/**
 * This is the component for the Add Patient modal <br>
 * Most of the code here is dedicated to form validations.
 * SRS IDs: <br>
 * AIM - 5.3.14.9 <br>
 * PSL - 5.3.14.9 <br>
 * FOP - 5.3.14.9 <br>
 *
 * @component
 */
class CustomAddPatientModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      mrn: "",
      firstname: "",
      lastname: "",
      gender: "MALE",
      dateOfBirth: undefined,
      disableFirstButton: true,
      site: this.props.currentSite
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
  }
  /**
   * Listens to changes in any field. <br>
   * The regex in the MRN field ensures that no special characters can be entered.
   *
   * @param {array} items      array of users
   */
  onChange(e) {
    if (
      e.target.name !== "age" &&
      (e.target.name !== "mrn" ||
        !/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(e.target.value))
    ) {
      this.setState(
        {
          [e.target.name]: e.target.value
        },
        () => {
          this.setState({
            disableFirstButton:
              this.state.mrn === "" ||
              this.state.firstname === "" ||
              !this.state.startDate
          });
        }
      );
    } else {
      if (
        (e.target.name === "age" && e.target.value === "") ||
        parseInt(e.target.value) < 100
      ) {
        this.setState(
          {
            [e.target.name]: e.target.value
          },
          () => {
            this.setState({
              disableFirstButton:
                this.state.mrn === "" ||
                this.state.firstname === "" ||
                !this.state.startDate,

              startDate: new Date(
                new Date().getFullYear() - this.state.age,
                0,
                1
              ).getTime()
            });
          }
        );
      }
    }
  }
  /**
   * Gets triggered on changing the date, and sets the state accordingly
   *
   *
   * @param {object} date      selected date
   */
  dateChange(date) {
    this.setState(
      {
        startDate: date?.getTime()
      },
      () => {
        this.setState({
          disableFirstButton:
            this.state.mrn === "" ||
            this.state.firstname === "" ||
            !this.state.startDate,
          age: isNaN(differenceInYears(new Date().getTime(), date?.getTime()))
            ? ""
            : differenceInYears(new Date().getTime(), date?.getTime())
        });
      }
    );
  }
  /**
   * Makes the API call and submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    let data = {
      mrn: this.state.mrn,
      firstName: this.state.firstname,
      lastName: this.state.lastname !== "" ? this.state.lastname : undefined,
      dateOfBirth: this.state.startDate,
      gender: this.state.gender,
      siteId: parseInt(this.state.site)
    };
    this.setState({ isLoaded: false, disableFirstButton: true });

    postAuthenticatedData(
      sessionStorage.getItem("domain") + createPatient,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 200) {
          toast.success(this.t(T_PATIENTS.toast.patientAddedSuccessfully));
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner custommodalinnerdatepicker">
          <form
            className="loginpad custom-modal-add-patient"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">
              {this.t(T_PATIENTS.addPatient.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="name" bsSize="medium">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.patient.mrn)}*
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      autoFocus
                      required
                      type="text"
                      name="mrn"
                      maxLength={20}
                      value={this.state.mrn}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </FormGroup>

              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.patient.firstName)}*
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      required
                      type="text"
                      name="firstname"
                      maxLength={30}
                      value={this.state.firstname}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </FormGroup>

              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.patient.lastName)}
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      required
                      type="text"
                      maxLength={30}
                      name="lastname"
                      value={this.state.lastname}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.patient.gender)}*
                  </div>
                  <div className="col-md-9">
                    <select name="gender" onChange={this.onChange}>
                      <option value="MALE" key="MALE">
                        {this.t(T_ENTITIES.patient.genderTypes.male)}
                      </option>
                      <option value="FEMALE" key="FEMALE">
                        {this.t(T_ENTITIES.patient.genderTypes.female)}
                      </option>
                    </select>
                  </div>
                </div>
              </FormGroup>
              <div className="new-dob">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.patient.dateOfBirth)}*
                  </div>
                  <div className="col-md-3">
                    <div className="customDatePicker">
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.dateChange}
                        minDate={subYears(new Date(), 100)}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={this.i18n.language}
                      />
                    </div>
                  </div>
                  <span className="col-md-3 or-word">
                    {this.t(T_PATIENTS.addPatient.or)}
                  </span>
                  <div className="col-md-1 formfont">
                    {this.t(T_ENTITIES.patient.age)}*
                  </div>
                  <div className="col-md-2">
                    <FormControl
                      required
                      type="text"
                      name="age"
                      value={this.state.age}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_ENTITIES.patient.site)}*
                  </div>
                  <div className="col-md-9">
                    <select
                      name="site"
                      onChange={this.onChange}
                      value={this.state.site}
                    >
                      {this.props.sites.map?.(e => (
                        <option value={e?.siteId} key={e?.siteId}>
                          {e?.siteName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </FormGroup>
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 create-account-btn submit-button"
                disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_PATIENTS.addPatient.proceed)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomAddPatientModal);
