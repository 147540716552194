const T_GENERAL = {
  yes: "Yes",
  no: "No",
  on: "On",
  off: "Off",
  or: "OR",
  selectAnOption: "Select An Option",
  back: "Back",
  edit: "Edit",
  submit: "Submit",
  update: "Update",
  delete: "Delete",
  create: "Create",
  download: "Download",
  add: "Add",
  block: "Block",
  unblock: "Unblock",
  search: "Search",
  somethingWentWrong: "Something went wrong!",
  noResultsToDisplay: "There are no results to display",
  le: "LE",
  re: "RE"
};

const T_ENTITIES = {
  organization: {
    organiation: "Organization",
    adEnabled: "AD Enabled"
  },
  site: {
    siteName: "Site Name",
    siteDomain: "Site Domain",
    siteTenantID: "Site Tenant ID"
  },
  user: {
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    site: "Site",
    secondarySite: "Secondary Site",
    emailID: "Email ID",
    registrationNumber: "Registration Number",
    roles: "Roles",
    roleTypes: {
      admin: "Admin",
      doctor: "Doctor",
      operator: "Operator",
      service: "Service"
    },
    status: "Status",
    statusTypes: {
      unverified: "Unverified",
      inactive: "Inactive",
      active: "Active",
      suspended: "Suspended"
    },
    lastActive: "Last Active"
  },
  patient: {
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    genderTypes: {
      male: "Male",
      female: "Female"
    },
    mrn: "MRN",
    dateOfBirth: "Date of Birth",
    dob: "DOB",
    age: "Age",
    site: "Site"
  },
  exam: {
    examCode: "Exam Code",
    examState: "Exam State",
    examStateTypes: {
      all: "All",
      active: "Active",
      assigned: "Assigned",
      graded: "Graded"
    },
    device: "Device",
    deviceType: "Device Type",
    deviceTypes: {
      fop: "FOP",
      psl: "PSL",
      aim: "AIM",
      instaref: "INSTAREF",
      pristine: "PRISTINE",
      other: "Other"
    },
    ocularHistory: "Ocular History",
    name: "Name",
    mrn: "MRN",
    examDate: "Exam Date",
    site: "Site",
    numberOfImages: "Number of Images"
  }
};

const T_LOGIN = {
  username: "Username",
  signInAD: "Sign in with Active Directory",
  or: "OR",
  next: "Next",
  password: "Password",
  confirmPassword: "Confirm Password",
  back: "Back",
  forgotPassword: "Forgot Password?",
  forgotPasswordTextTop: "Hi {{name}}, do you want to send a reset",
  forgotPasswordTextBottom: "password link to the registered email id?",
  sendLink: "Send Link",
  lastLogin: "Last Login Activity: ",
  neverLoggedIn: "Never Logged In",
  warningAttempt:
    "Warning: You have {{count}} attempts remaining before your account gets locked.",
  chooseNewPassword: "Choose a new password.",
  needToReverify: "Your account needs to be reverified.",
  reverifyMyAccount: "Reverify My Account",
  recoverAccount: "Recover Account",
  accountNotVerified: "Your account has not yet been verified.",
  resendVerificationEmail: "Resend Verification Email",
  accountLocked:
    "Your account has been locked after multiple unsuccessful attempts.",
  accountSuspended: "Your account is suspended.",
  userRegistered: {
    congratulationsTop: "Congratulations! You Password has been",
    congratulationsBottom: "updated successfully!",
    exit: "Exit"
  },
  welcomeUser: {
    welcome: "Welcome! Your account has been created.",
    pleaseProvidePassword: "Please provide a password."
  },
  regexCheck: {
    eightChars: "contains atleast eight characters",
    bothCases: "contains both lower(a-z) and upper case(A-Z) letters",
    numberAndSymbol: "contains atleast one number(0-9) and a symbol",
    passwordShouldMatch: "Password should match."
  },
  toast: {
    somethingWentWrong:
      "Oops, something went wrong. Please make sure you typed the username correctly!",
    pleaseFillEmail: "Please fill in an email address!",
    userNotFound:
      "User account not found or configured on the Remidio Platform.",
    resetEmail: "A reset email has been sent to your registered email account.",
    invalidPassword: "Invalid Password",
    accountIncorrectlyConfigured:
      "Account incorrectly configured. Please double check both the Active Directory and the Remidio configurations for this account.",
    inactivitySignOut:
      "You have been signed out due to a long period of inactivity. Please sign in again.",
    sessionTerminated:
      "Your session has been terminated due to an explicit log-out, inactivity, or an incorrect Active Directory configuration. Please sign in again.",
    adminInformedOfUnblockRequest:
      "The admin has been informed of your unblock request.",
    passwordResetSuccessfully:
      "Your password has been successfully reset. Try Logging in now.",
    somethingWentWrongResetLink:
      "Something went wrong. Make sure you copied the reset link correctly.",
    reverifyAccountSuccessfully: "Your account has been reverified.",
    checkEmailToVerify: "Check your email to verify your account."
  }
  // Testing properties
  // nest1: {
  //   testNested1: null,
  //   nest2: {
  //     testNested2: null
  //   }
  // },
  // test: null,
  // test_one: null,
  // test_other: null
};

const T_SIDEBAR = {
  service: "Service",
  users: "Users",
  sites: "Sites",
  patients: "Patients",
  exams: "Exams",
  statistics: "Statistics",
  userAccount: "User Account",
  about: "About",
  logoutOnAllDevices: "Logout on all devices"
};

const T_DATE = {
  datetimeMed: "{{date, DATETIME_MED}}",
  time: "{{date, TIME}}",
  date: "{{date, DATE}}"
};

const T_PAGINATION = {
  showingRows: "Showing rows {{from}} to {{to}}",
  previous: "Previous",
  next: "Next"
};

const T_SERVICE = {
  addOrganizationButton: "Add Organization",
  searchOrganizations: "Search Organizations",
  table: {
    edit: "Edit",
    viewSites: "View Sites",
    orgEntitlements: "Org Entitlements",
    viewAllAdmins: "View All Admins",
    delete: "Delete",
    emrPacsIntegrationSettings: "EMR PACS Integration Settings"
  },
  addOrganization: {
    header: "Create Organization",
    name: "Name",
    organizationTag: "Organization Tag",
    adEnabled: "AD Enabled",
    primarySite: "Primary Site",
    siteDomain: "Site Domain",
    siteName: "Site Name",
    siteTenantID: "Site Tenant ID",
    proceed: "Proceed",
    back: "Back",
    primaryUser: "Primary User",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    employeeId: "Employee ID"
  },
  edit: {
    header: "Edit Organization",
    organizationName: "Organization Name",
    edit: "Edit"
  },
  viewSites: {
    searchSites: "Search Sites",
    addSiteButton: "Add Site",
    table: {
      siteConfigurations: "Site Configurations",
      pacsGateway: "PACS Gateway",
      siteSettings: "Site Settings"
    },
    addSite: {
      header: "Add Site"
    },
    edit: {
      header: "Edit Site"
    },
    siteConfigurations: {
      header: "Configure Site Configurations"
    },
    pacsGateway: {
      header: "Configure Site Settings",
      onOrOff: "On/Off",
      siteIdentifier: "Site Identifier",
      notice:
        "Note: Please ensure that data sync for each device is turned on for proper functioning of the PACS / EMR gateway"
    },
    siteEntitlements: {
      header: "Configure Site Entitlements",
      drAi: "DR AI",
      glaucomaAi: "Glaucoma AI",
      autoCapture: "Autocapture",
      internalFixation: "Internal Fixation",
      montage: "Montage"
    },
    siteSubscription: {
      header: "Configure Site Subscription Settings",
      isAccountActive: "Is Account Active?",
      isOnSubscriptionPlan: "Is On Subscription Plan?",
      offlineCheckFrequency: "Offline Check Frequency",
      days: "days"
    },
    eyrisAISettings: {
      header: "EyRIS AI Settings",
      active: "Active",
      username: "Username",
      password: "Password"
    },
    delete: {
      header: "Delete Site",
      areYouSure: "Are you sure you want to delete "
    }
  },
  orgEntitlements: {
    header: "Organization Entitlements"
  },
  viewAllAdmins: {
    addAdminAccountButton: "Add Admin Account",
    addAdminAccount: {
      header: "Create Account"
    },
    table: {
      action: "Action",
      block: "Block",
      resendVerificationEmail: "Resend Verification Email",
      delete: "Delete"
    },
    block: {
      header: "Block",
      areYouSure: "Are you sure you want to block {{name}}?"
    },
    resendVerificationEmailText: "Resend Verification Email",
    resendVerificationEmail: {
      header: "Resend Verification Email",
      areYouSure:
        "Are you sure you want to resend a verification email to {{name}}?"
    },
    delete: {
      header: "Delete",
      areYouSure: "Are you sure you want to delete the user {{name}}?"
    },
    actionText: {
      unblock: "unblock",
      block: "block",
      delete: "delete the user",
      resendVerificationEmail: "resend a verification email to"
    }
  },
  delete: {
    header: "Delete Organization",
    areYouSure: "Are you sure you want to delete "
  },
  toast: {
    editOrgSuccessful: "The organization has been successfully edited.",
    addOrgSuccessful: "New organization added successfully!",
    deleteOrgSuccessful: "Organization successfully deleted.",
    cantDeleteWithoutDeletingSites:
      "You can't delete this organization without deleting all associated sites.",
    invalidEmail: "The submitted email address is an invalid email address.",
    emailInUse: "The email address you are submitting is already in use.",
    orgEntitlementsUpdated: "Organization entitlements updated successfully!",
    actionCompleted: "Action completed.",
    userDeletedSuccessfully: "User deleted successfully",
    verificationEmailSent: "Verification email sent!",
    eyrisAISettingsUpdatedSuccessfully:
      "EyRIS AI Settings updated successfully!",
    gatewaySettingsUpdatedSuccessfully: "Site settings updated successfully!",
    addSiteSuccessful: "New site added successfully!",
    deleteSiteSuccessful: "Site deleted successfully!",
    nameOrDomainInvalid: "Name or Domain invalid.",
    removeAllUsersBeforeDeleting:
      "Remove all users associated with this site before deleting."
  }
};

const T_USERS = {
  notificationSettingsButton: "Notification Settings",
  addAccountButton: "Add Account",
  searchUsers: "Search Users",
  offlineUse: "Offline Use",
  offlineUseWarning:
    "Warning: Turning the offline use setting on will leave the user account logged in on Remidio's medical devices and may put the account and data at risk.",
  table: {
    action: "Action"
  },
  notificationSettings: {
    header: "Notification Settings",
    enabled: "Enabled",
    timeToSend: "Time to Send",
    peopleToNotify: "People to notify"
  },
  addAccount: {
    header: "Create Account",
    offlineUse: "Offline Use",
    warning:
      "Warning: Turning the offline use setting on will leave the user account logged in on Remidio's medical devices and may put the account and data at risk."
  },
  edit: {
    header: "Edit Account",
    resendVerificationEmail: "Resend Verification Email",
    block: "Block",
    unblock: "Unblock",
    deleteAccount: "Delete Account",
    confirmBlock: "Confirm Block?",
    confirmUnblock: "Confirm Unblock?",
    confirmDelete: "Confirm Delete?"
  },
  toast: {
    verificationEmailSent: "Verification email sent!",
    userUpdatedSuccessfully: "User updated successfully",
    userDeletedSuccessfully: "User deleted successfully",
    userAddedSuccessfully: "New user added successfully!"
  }
};

const T_SITES = {
  securitySettingsButton: "Integration Settings",
  realSecuritySettingsButton: "Security Settings",
  searchSites: "Search Sites",
  table: {
    site: "site",
    viewSiteLogo: "View Site Logo",
    uploadSiteLogo: "Upload Site Logo",
    configureDataSync: "Configure Data Sync",
    configureGateway: "Configure Site Settings"
  },
  securitySettings: {
    header: "Integration Settings",
    highSecurity: "Cloud Data Queue",
    webhookSettings: "Webhook Settings",
    gatewayIp: "Gateway IP",
    gatewayPort: "Gateway Port",
    doctorReportWebhookEnabled: "Enable WebHook for Doctor Reports",
    aiReportWebhookEnabled: "Enable WebHook for AI Reports",
    imagesWebhookEnabled: "Enable WebHook for Images",
    webhookUrl: "WebHook URL",
    note:
      "Note: Disabling High Security Mode will provide the option to login via Touch ID, and will prevent auto-suspension and invalidating of accounts."
  },
  realSecuritySettings: {
    header: "Configure High Security Mode Settings",
    highSecurity: "High Security",
    note:
      "Note: Disabling High Security Mode will provide the option to login via Touch ID, and will prevent auto-suspension and invalidating of accounts."
  },
  configureDataSync: {
    header: "Data Sync Configuration",
    dataSync: "Data Storage Location",
    remidioCloud: "Remidio Cloud",
    externalDestination: "External Destination",
    disableDataSync: "Disable Data Sync",
    note:
      "Note: If you are using our hosted APIs to import data into your system, you will need to select Remidio Cloud"
  },
  configureGateway: {
    header: "Configure Gateway",
    onOrOff: "On/Off",
    siteIdentifier: "Site Identifier",
    note:
      "Note: Please ensure that data sync for each device is turned on for proper functioning of the PACS / EMR gateway"
  },
  toast: {
    dataSyncUpdatedSuccessfully: "Data Sync settings updated successfully!",
    securitySettingsUpdatedSuccessfully:
      "EMR PACS Integration settings updated successfully!",
    realSecuritySettingsUpdatedSuccessfully:
      "Security settings updated successfully!",
    notificationSettingsUpdatedSuccessfully:
      "Notification Settings updated successfully!",
    siteEntitlementsUpdatedSuccessfully:
      "Site Entitlements updated successfully!",
    siteConfigSettingsUpdatedSuccessfully:
      "Site Configuration settings updated successfully!",
    siteSubscriptionSettingsUpdatedSuccessfully:
      "Site Subscription Settings updated successfully!",
    uploadedImageSuccessfully: "Uploaded image successfully!",
    unsupportedFileFormat:
      "Unsupported file format. Only .jpg, .jpeg and .png formats are supported."
  }
};

const T_PATIENTS = {
  header: "Patients",
  addPatientButton: "Add Patient",
  searchPatients: "Search Patients",
  addPatient: {
    header: "Create Patient",
    or: "OR",
    proceed: "Proceed"
  },
  patientDetails: {
    header: "Patient Details",
    addExamButton: "Add Exam",
    chooseDevice: "Choose Device:",
    table: {
      examDate: "Exam Date",
      examCode: "Exam Code"
    },
    editPatient: {
      header: "Edit Patient",
      or: "OR",
      proceed: "Proceed"
    },
    addExam: {
      header: "Create Exam",
      prescribingDoctor: "Prescribing Doctor",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      rightEye: "Right Eye",
      leftEye: "Left Eye",
      ucva: "UCVA",
      visionWithPG: "Vision with PG",
      spherical: "Spherical",
      cylindrical: "Cylindrical",
      axis: "Axis",
      bcva: "BCVA",
      nearAdd: "Near Add",
      iop: "IOP (mm Hg)"
    }
  },
  toast: {
    examAddedSuccessfully: "New exam added successfully!",
    patientAddedSuccessfully: "New patient added successfully!",
    patientEditedSuccessfully: "Patient edited successfully!"
  }
};

const T_EXAMS = {
  addExamButton: "Add Exam",
  or: "OR",
  searchExamData: "Search Exam Data",
  table: {
    action: "Action",
    download: "Download"
  },
  download: {},
  assigned: {
    header: "Assigned Grader",
    graderName: "Grader Name",
    assignedTime: "Assigned Time",
    on: " on ",
    removeAssignment: "Remove Assignment",
    confirmRemoval: "Confirm Removal?"
  },
  addExam: {
    header: "Create Exam",
    proceed: "Proceed",
    patient: "Patient",
    or: "OR",
    exam: "Exam",
    prescribingDoctor: "Prescribing Doctor",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    medicalHistory: "Medical History",
    ocularHistory: "Ocular History",
    systemicHistory: "Systemic History",
    currentMedication: "Current Medication",
    allergies: "Allergies",
    rightEye: "Right Eye",
    leftEye: "Left Eye",
    ucva: "UCVA",
    visionWithPG: "Vision with PG",
    spherical: "Spherical",
    cylindrical: "Cylindrical",
    axis: "Axis",
    bcva: "BCVA",
    nearAdd: "Near Add",
    iop: "IOP (mm Hg)"
  },
  examDetails: {
    primaryExamDetailsTab: "Primary Exam Details",
    visionAssessmentTab: "Objective Refraction",
    imagesAndVideosTab: "Images & Videos",
    reportsTab: "Reports",
    startGradingButton: "Start Grading",
    downloadExamButton: "Download Exam",
    generateReportButton: "Generate Report",
    editPatient: {
      header: "Edit Patient",
      or: "OR",
      proceed: "Proceed"
    },
    primaryExamDetails: {
      none: "None",
      ocularHistory: "Ocular History:",
      editOcularHistory: {
        header: "Edit Ocular History"
      }
    },
    visionAssessment: {
      rightEye: "Right Eye",
      reading1: "Reading 1",
      spherical: "Spherical",
      cylindrical: "Cylindrical",
      axis: "Axis",
      reading2: "Reading 2",
      leftEye: "Left Eye",
      noValues:
        "Readings you've scanned via our Instaref Mobile Application shall be displayed here."
    },
    imagesAndVideos: {
      posterior: "Posterior",
      anterior: "Anterior",
      images: "Images",
      noImages: "There are no images to display.",
      editedImages: "Edited Images",
      videos: "Videos",
      noVideos: "There are no videos to display."
    },
    reports: {
      report: "Report",
      mediosAiReport: "Medios AI Report",
      drAiReport: "DR AI Report",
      glaucomaAiReport: "Glaucoma AI Report",
      eyrisReport: "EyRIS SELENA+ Report"
    },
    generateReport: {
      back: "Back",
      header: "Generate Report",
      objectiveRefraction: "Objective Refraction Reading",
      reading1: "Reading 1",
      reading2: "Reading 2",
      rightEye: "Right Eye",
      leftEye: "Left Eye",
      spherical: "Spherical",
      cylindrical: "Cylindrical",
      axis: "Axis",
      selectImages: "Select images to generate your report",
      proceed: "Proceed",
      diagnosis: "Diagnosis",
      addAnotherDiagnosis: "Add another diagnosis",
      comments: "Comments",
      miscellaneous: "Miscellaneous",
      referToSpecialist: "Refer to eye care specialist"
    },
    startGrading: {
      addToReport: "Add to report",
      imagesSelected: "{{count}} images selected",
      imagesSelected_one: "{{count}} image selected",
      imagesSelected_other: "{{count}} images selected",
      enhancedView: "Enhanced View",
      cupDiscRatio: "Cup Disc Ratio",
      objectiveRefraction: "Objective Refraction Readings",
      selectReading1: "Select Reading 1",
      selectReading2: "Select Reading 2",
      diagnosis: "Diagnosis",
      rightEye: "Right Eye",
      leftEye: "Left Eye",
      spherical: "Spherical",
      cylindrical: "Cylindrical",
      axis: "Axis",
      notApplicable: "Not Applicable",
      ungradeable: "Ungradeable",
      noPathologyObserved: "No Pathology Observed",
      comments: "Comments",
      charactersRemaining: "{{count}} characters remaining",
      select: "Select...",
      search: "Search",
      referToSpecialist: "Refer to eye care specialist",
      generateReport: "Generate Report"
    }
  },
  toast: {
    anteriorImageLimit:
      "Sorry, you may only select a maximum of 8 Anterior images",
    pristineImageLimit:
      "Sorry, you may only select a maximum of 8 Pristine images",
    tooManyImages:
      "Too many images selected. Please ensure you only select a maximum of 8 images. Montage images are treated as 2 images",
    generateSuccess: "Generated a report!",
    pathologyLimit: "Sorry, you may only select 3 pathologies per eye",
    examAddedSuccessfully: "New exam added successfully!",
    editedSuccessfully: " edited successfully!",
    patientExamEditedSuccessfully: "Patient Exam edited successfully!",
    removedAssignmentSuccessfully: "Removed assignment successfully!",
    examDownloadForPatient:
      "Exam download triggered for {{name}}. Your download will begin shortly.",
    tryAgainAfterSometime: "Something went wrong. Try again after sometime."
  }
};

const T_STATISTICS = {
  header: "Statistics",
  site: "Site",
  allSites: "All Sites",
  timeline: "Timeline",
  last7Days: "Last 7 days",
  last30Days: "Last 30 days",
  diagnosesInLastDays: "Diagnoses in {{name}} in the last {{count}} days",
  diagnosesInMonth: "Diagnoses in {{name}} in {{month}}",
  searchDisease: "Search Disease",
  diagnosis: "Diagnosis",
  numberOfCases: "Number of cases",
  action: "Action",
  seeDetails: "See Details",
  viewAllDiseases: "View All Diseases",
  noResultsFound: "No results found.",
  totalPatients: "Total Patients",
  totalPatientsInLastDays: "Patient visits in the last {{count}} days",
  totalPatientsInMonth: "Patient visits in {{month}}",
  totalExams: "Total Exams",
  totalExamsInLastDays: "Total exams in the last {{count}} days",
  totalExamsInMonth: "Total exams in {{month}}",
  gradedExams: "Graded Exams",
  gradedExamsInLastDays: "Graded exams in the last {{count}} days",
  gradedExamsInMonth: "Graded exams in {{month}}",
  topDiagnosesInLastDays: "Top diagnoses in the last {{count}} days",
  topDiagnosesInMonth: "Top diagnoses in {{month}}",
  referredExams: "Referred Exams",
  referredExamsInLastDays: "Referred exams in the last {{count}} days",
  referredExamsInMonth: "Referred exams in {{month}}",
  seeMoreButton: "See More",
  seeMore: {
    statisticsInLastDays: "Statistics for {{name}} in the last {{count}} days",
    statisticsInMonth: "Statistics for {{name}} in {{month}}",
    searchSite: "Search Site",
    site: "Site"
  }
};

const T_ACCOUNT = {
  header: "User Account",
  editPassword: "Edit Password",
  editingWillLogout:
    "Editing your password will log you out of all accounts. Are you sure you want to proceed?",
  proceed: "Proceed",
  currentPassword: "Current Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  regexCheck: {
    eightChars: "contains atleast eight characters",
    bothCases: "contains both lower(a-z) and upper case(A-Z) letters",
    numberAndSymbol: "contains atleast one number(0-9) and a symbol",
    passwordShouldMatch: "Password should match."
  },
  resetPassword: "Reset Password",
  addOrEditSignature: "Add or Edit Signature",
  noSignature: "There is no signature currently added to your account.",
  addSignature: "Add Signature",
  updateSignature: "Update Signature",
  deleteSignature: "Delete Signature",
  areYouSureDelete: "Are you sure you want to delete your signature?",
  yesDelete: "Yes, Delete Signature",
  toast: {
    changePasswordSuccessful: "Changed Password Successfully!",
    deleteSuccessful: "Deleted image successfully!",
    uploadSuccessful: "Uploaded image successfully!",
    unsupportedFileFormat:
      "Unsupported file format. Only .jpg, .jpeg and .png formats are supported."
  }
};

const T_ABOUT = {
  header: "About",
  email: "Email",
  website: "Website",
  madeInIndia: "MADE IN INDIA",
  instructionsForUse: "Instructions for Use"
};

const T_REPORT = {
  fundusScreeningReport: "Fundus Screening Report",
  patientName: "Patient Name",
  patientId: "Patient ID",
  date: "Date",
  age: "Age",
  gender: "Gender",
  male: "Male",
  female: "Female",
  rightEye: "Right Eye",
  leftEye: "Left Eye",
  doctor: "Doctor",
  referRequired: "Referral to an Eye Care Specialist Recommended",
  referNotRequired: "Referral to an Eye Care Specialist Not Necessary",
  anteriorScreeningReport: "Anterior Screening Report",
  refractiveErrorScreeningReport: "Refractive Error Screening Report",
  readingOne: "Reading One",
  rightEyeCaps: "RIGHT EYE",
  leftEyeCaps: "LEFT EYE",
  readingTwo: "Reading Two",
  re: "RE",
  le: "LE",
  retinalImagingReport: "RETINAL IMAGING REPORT",
  signature: "Signature",
  page: "Page",
  of: "of",
  montageImage: "Montage Image"
};

/**
 * Appends 'groupName.' in front of all keys
 */
function generateTranslationKeyPaths(ptr, basePath) {
  for (const key in ptr) {
    const groupObj = ptr[key];

    if (groupObj !== null && typeof groupObj === "object") {
      // Nested object. Recursively create the key paths
      generateTranslationKeyPaths(groupObj, basePath + "." + key);
    } else {
      // It is a property. Replace the value with the key path
      ptr[key] = basePath + "." + key;
    }
  }
}

/**
 * Converts all values to their key path in translation.json.
 * e.g. GROUP.key = "value" => GROUP.key = "GROUP.key"
 *
 * Use this in index.js to initialize i18n functionality.
 *
 * Alternatively, this allows you to turn off i18n by simply removing this function call,
 * without having to remove all t(GROUP.key) calls, as GROUP.key now returns the text in default language.
 */
function initializeTranslationKeys() {
  for (const groupKey in toExport) {
    generateTranslationKeyPaths(toExport[groupKey], groupKey);
  }
}

const toExport = {
  initializeTranslationKeys,
  T_GENERAL,
  T_ENTITIES,
  T_LOGIN,
  T_SIDEBAR,
  T_DATE,
  T_PAGINATION,
  T_SERVICE,
  T_USERS,
  T_SITES,
  T_PATIENTS,
  T_EXAMS,
  T_STATISTICS,
  T_ACCOUNT,
  T_ABOUT,
  T_REPORT
};

module.exports = toExport;
