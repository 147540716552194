/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Sidebar from "../Dashboard/Sidebar";
import AppNavbar from "../Dashboard/AppNavbar";
import {
  getUser,
  getSites,
  getAllSiteAnalytics,
  getCat2List
} from "../../constants/endpoints";
import { getAuthenticatedData } from "../../utils/async";
import MonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import TotalPatients from "./TotalPatients";
import TotalExams from "./TotalExams";
import GradedExams from "./GradedExams";
import ReferredExams from "./ReferredExams";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { T_STATISTICS } from "../../constants/translations";

/**
 * This is the component which houses all the filters for the statistics page. <br />
 * It acts as the parent component for all 4 tabs
 * @component
 */
class Statistics extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: false,
      month: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      selectedTab: 1,
      selectedSite: null,
      siteRadio: "all-sites",
      timelineRadio: "last-seven-days",
      sites: [],
      from: moment()
        .subtract(6, "d")
        .valueOf(),
      to: moment().valueOf(),
      analytics: {},
      diseases: {}
    };
    this.pickAMonth = React.createRef();
    this.showPicker = this.showPicker.bind(this);
    this.setTab = this.setTab.bind(this);
    this.radioOnChange = this.radioOnChange.bind(this);
    this.siteOnChange = this.siteOnChange.bind(this);
    this.handleAMonthChange = this.handleAMonthChange.bind(this);
    this.handleAMonthDismiss = this.handleAMonthDismiss.bind(this);
    this.getDiseases = this.getDiseases.bind(this);
  }

  componentDidMount() {
    this.makeGetSitesCall();
    this.fetchAnalytics();
    this.getDiseases();
  }

  /**
   * Memorizes the scroll position before switching tabs
   */
  getSnapshotBeforeUpdate() {
    return window.scrollY;
  }

  /**
   * Goes to the previous scroll position on switching tabs
   *
   *
   * @param {object} e      event listener
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      window.scrollTo(0, snapshot);
    }
  }

  getDiseases() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getCat2List).then(
      result => {
        this.setState({
          diseases: result?.data
        });
      }
    );
  }

  handleAMonthChange() {
    // console.log(e);
  }

  handleAMonthDismiss(e) {
    // eslint-disable-next-line no-console
    const fromDate = new Date(e.year, e.month - 1, 1);
    const fromEpoch = moment(fromDate).valueOf();
    let toDate;
    if (
      e.month === new Date().getMonth() + 1 &&
      e.year === new Date().getFullYear()
    ) {
      toDate = new Date(e.year, e.month - 1, new Date().getDate());
    } else {
      toDate = new Date(
        e.year,
        e.month - 1,
        new Date(e.year, e.month, 0).getDate()
      );
    }
    const toEpoch = moment(toDate).valueOf();
    this.setState(
      { from: fromEpoch, to: toEpoch, isLoaded: false, month: e },
      () => {
        this.fetchAnalytics();
      }
    );
  }

  makeGetSitesCall() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getSites).then(
      result => {
        this.setState({
          sites: result?.data,
          selectedSite: result?.data[0].siteName
        });
      }
    );
  }

  fetchAnalytics() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAllSiteAnalytics +
        "?from=" +
        this.state.from +
        "&to=" +
        this.state.to
    ).then(res => {
      this.setState({ isLoaded: true, analytics: res?.data });
    });
  }
  /**
   * Triggers API call on changing Statistics filter
   *
   *
   * @param {object} e      event listener
   */
  radioOnChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name === "timelineRadio" && value === "last-seven-days") {
      this.setState(
        {
          from: moment()
            .subtract(6, "d")
            .valueOf(),
          to: moment().valueOf(),
          isLoaded: false
        },
        () => {
          this.fetchAnalytics();
        }
      );
    } else if (name === "timelineRadio" && value === "last-thirty-days") {
      this.setState(
        {
          from: moment()
            .subtract(29, "d")
            .valueOf(),
          to: moment().valueOf(),
          isLoaded: false
        },
        () => {
          this.fetchAnalytics();
        }
      );
    } else if (name === "timelineRadio" && value === "specific-month") {
      const fromDate = new Date(
        this.state.month.year,
        this.state.month.month - 1,
        1
      );
      const fromEpoch = moment(fromDate).valueOf();
      let toDate;
      if (
        this.state.month.month === new Date().getMonth() + 1 &&
        this.state.month.year === new Date().getFullYear()
      ) {
        toDate = new Date(
          this.state.month.year,
          this.state.month.month - 1,
          new Date().getDate()
        );
      } else {
        toDate = new Date(
          this.state.month.year,
          this.state.month.month - 1,
          new Date(this.state.month.year, this.state.month.month, 0).getDate()
        );
      }
      const toEpoch = moment(toDate).valueOf();
      this.setState({ from: fromEpoch, to: toEpoch, isLoaded: false }, () => {
        this.fetchAnalytics();
      });
    }
  }

  siteOnChange(event) {
    this.setState({ selectedSite: event.target.value });
  }

  showPicker() {
    if (this.pickAMonth && this.pickAMonth.current) {
      this.pickAMonth.current.show();
    }
  }

  setTab(index) {
    this.setState({ selectedTab: index });
  }

  render() {
    const pickerLang = {
      months:
        this.i18n.language === "it"
          ? [
              "Gen",
              "Feb",
              "Mar",
              "Apr",
              "Mag",
              "Giu",
              "Lug",
              "Ago",
              "Set",
              "Ott",
              "Nov",
              "Dic"
            ]
          : [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
      from: "From",
      to: "To"
    };
    const makeText = m => {
      if (m && m.year && m.month)
        return pickerLang.months[m.month - 1] + " " + m.year;
      return "?";
    };
    const { isLoaded } = this.state;
    let totalPatientsTemplate,
      totalExamsTemplate,
      gradedExamsTemplate,
      referredExamsTemplate = null;
    if (this.state.selectedTab === 1) {
      totalPatientsTemplate = (
        <TotalPatients
          timelineRadio={this.state.timelineRadio}
          selectedSite={this.state.selectedSite}
          siteRadio={this.state.siteRadio}
          month={makeText(this.state.month)}
          analytics={this.state.analytics}
        ></TotalPatients>
      );
    }
    if (this.state.selectedTab === 2) {
      totalPatientsTemplate = (
        <TotalExams
          timelineRadio={this.state.timelineRadio}
          selectedSite={this.state.selectedSite}
          siteRadio={this.state.siteRadio}
          month={makeText(this.state.month)}
          analytics={this.state.analytics}
        ></TotalExams>
      );
    }
    if (this.state.selectedTab === 3) {
      totalPatientsTemplate = (
        <GradedExams
          timelineRadio={this.state.timelineRadio}
          selectedSite={this.state.selectedSite}
          siteRadio={this.state.siteRadio}
          month={makeText(this.state.month)}
          analytics={this.state.analytics}
          diseases={this.state.diseases}
        ></GradedExams>
      );
    }
    if (this.state.selectedTab === 4) {
      totalPatientsTemplate = (
        <ReferredExams
          timelineRadio={this.state.timelineRadio}
          selectedSite={this.state.selectedSite}
          siteRadio={this.state.siteRadio}
          month={makeText(this.state.month)}
          analytics={this.state.analytics}
        ></ReferredExams>
      );
    }
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="9" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding statistics-page">
              <div className="statistics-heading">
                {this.t(T_STATISTICS.header)}
              </div>
              <div className="filters-container">
                <div className="site-container">
                  <div className="site-container-heading">
                    {this.t(T_STATISTICS.site)}
                  </div>
                  <div className="site-options-container">
                    <div className="filter-group">
                      <input
                        type="radio"
                        id="all-sites-radio"
                        name="siteRadio"
                        value="all-sites"
                        checked={this.state.siteRadio === "all-sites"}
                        onChange={this.radioOnChange}
                      />
                      <label htmlFor="all-sites-radio">
                        {this.t(T_STATISTICS.allSites)}
                      </label>
                    </div>
                    <div className="filter-group">
                      <input
                        type="radio"
                        id="all-sites-radio"
                        name="siteRadio"
                        value="specific-site"
                        checked={this.state.siteRadio === "specific-site"}
                        onChange={this.radioOnChange}
                      />
                      <select
                        name="select-site"
                        className="statistics-select-site"
                        placeholder="Select a site..."
                        disabled={this.state.siteRadio !== "specific-site"}
                        value={this.state.selectedSite}
                        onChange={this.siteOnChange}
                      >
                        {this.state.sites.map(site => (
                          <option key={site.siteId}>{site.siteName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="timeline-container">
                  <div className="timeline-container-heading">
                    {this.t(T_STATISTICS.timeline)}
                  </div>
                  <div className="site-options-container">
                    <div className="filter-group">
                      <input
                        type="radio"
                        id="last-seven-days-timeline-radio"
                        name="timelineRadio"
                        value="last-seven-days"
                        checked={this.state.timelineRadio === "last-seven-days"}
                        onChange={this.radioOnChange}
                      />
                      <label htmlFor="all-sites-radio">
                        {this.t(T_STATISTICS.last7Days)}
                      </label>
                    </div>
                    <div className="filter-group">
                      <input
                        type="radio"
                        id="last-thirty-days-timeline-radio"
                        name="timelineRadio"
                        value="last-thirty-days"
                        checked={
                          this.state.timelineRadio === "last-thirty-days"
                        }
                        onChange={this.radioOnChange}
                      />
                      <label htmlFor="all-sites-radio">
                        {this.t(T_STATISTICS.last30Days)}
                      </label>
                    </div>
                    <div className="filter-group">
                      <input
                        type="radio"
                        id="select-month-radio"
                        name="timelineRadio"
                        value="specific-month"
                        checked={this.state.timelineRadio === "specific-month"}
                        onChange={this.radioOnChange}
                      />

                      <MonthPicker
                        ref={this.pickAMonth}
                        years={{
                          min: { year: 2021, month: 11 },
                          max: {
                            year: new Date().getFullYear(),
                            month: new Date().getMonth() + 1
                          }
                        }}
                        value={this.state.month}
                        lang={pickerLang.months}
                        theme="light"
                        onChange={this.handleAMonthChange}
                        onDismiss={this.handleAMonthDismiss}
                      >
                        <input
                          onClick={this.showPicker}
                          type="text"
                          value={makeText(this.state.month)}
                          disabled={
                            this.state.timelineRadio !== "specific-month"
                          }
                        />
                      </MonthPicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-section">
                <div className="tabs-container">
                  <div
                    onClick={() => {
                      this.setTab(1);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 1 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_STATISTICS.totalPatients)}
                        </div>
                        <div className="tab-data">
                          {(() => {
                            if (this.state.siteRadio === "specific-site") {
                              let siteName = this.state.selectedSite;
                              let siteCounters = this.state.analytics
                                .siteCounters;
                              let index;
                              for (let i = 0; i < siteCounters.length; i++) {
                                if (siteCounters[i].siteName === siteName) {
                                  index = i;
                                  break;
                                }
                              }
                              return this.state.analytics.siteCounters[index]
                                .totalPatients;
                            } else {
                              return this.state.analytics.totalPatients;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.setTab(2);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 2 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_STATISTICS.totalExams)}
                        </div>
                        <div className="tab-data">
                          {(() => {
                            if (this.state.siteRadio === "specific-site") {
                              let siteName = this.state.selectedSite;
                              let siteCounters = this.state.analytics
                                .siteCounters;
                              let index;
                              for (let i = 0; i < siteCounters.length; i++) {
                                if (siteCounters[i].siteName === siteName) {
                                  index = i;
                                  break;
                                }
                              }
                              return this.state.analytics.siteCounters[index]
                                .totalExams;
                            } else {
                              return this.state.analytics.totalExams;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.setTab(3);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 3 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_STATISTICS.gradedExams)}
                        </div>
                        <div className="tab-data">
                          {(() => {
                            if (this.state.siteRadio === "specific-site") {
                              let siteName = this.state.selectedSite;
                              let siteCounters = this.state.analytics
                                .siteCounters;
                              let index;
                              for (let i = 0; i < siteCounters.length; i++) {
                                if (siteCounters[i].siteName === siteName) {
                                  index = i;
                                  break;
                                }
                              }
                              return this.state.analytics.siteCounters[index]
                                .totalReports;
                            } else {
                              return this.state.analytics.totalReports;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.setTab(4);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 4 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_STATISTICS.referredExams)}
                        </div>
                        <div className="tab-data">
                          {(() => {
                            if (this.state.siteRadio === "specific-site") {
                              let siteName = this.state.selectedSite;
                              let siteCounters = this.state.analytics
                                .siteCounters;
                              let index;
                              for (let i = 0; i < siteCounters.length; i++) {
                                if (siteCounters[i].siteName === siteName) {
                                  index = i;
                                  break;
                                }
                              }
                              return this.state.analytics.siteCounters[index]
                                .totalReferred;
                            } else {
                              return this.state.analytics.totalReferred;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {totalPatientsTemplate}
                {totalExamsTemplate}
                {gradedExamsTemplate}
                {referredExamsTemplate}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(Statistics);
