/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image
} from "@react-pdf/renderer";

import OpenSansRegular from "../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansSemiBold from "../../assets/fonts/OpenSans-SemiBold.ttf";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsSemiBold from "../../assets/fonts/Poppins-SemiBold.ttf";
import Logo from "../../assets/images/remidio@3x.png";
import QRCode from "../../assets/images/qr-code.png";
import QRText from "../../assets/images/qr-text.png";
import { convertEpochToDate } from "../../utils/date";
import { withTranslation } from "react-i18next";
import { T_REPORT, T_GENERAL } from "../../constants/translations";

class PDF extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {};
  }

  render() {
    const styles = StyleSheet.create({
      header: {
        marginTop: 15,
        marginHorizontal: 29,
        marginRight: 36,
        marginBottom: 12,
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100vw",
        position: "relative"
      },

      headerLeft: {
        flexDirection: "row"
      },

      headerImageContainer: {
        maxWidth: 48,
        maxHeight: 49,
        marginRight: 8
      },

      headerImage: {
        objectFit: "contain"
      },

      hospitalDetails: {
        flexDirection: "column",
        justifyContent: "center"
      },

      headerText: {
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535"
      },

      headerBoldText: {
        fontFamily: "Open Sans",
        fontSize: 10,
        fontWeight: "bold",
        color: "#353535"
      },

      headerBoldTextRight: {
        fontFamily: "Open Sans",
        fontSize: 10,
        fontWeight: "bold",
        color: "#353535",
        textAlign: "right",
        width: "100%"
      },

      pageHeading: {
        backgroundColor: "#3fbe73",
        paddingVertical: 3
      },

      pageHeadingText: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        color: "white",
        textAlign: "center",
        fontSize: 10.5
      },

      pageBody: {
        paddingLeft: 23,
        paddingRight: 36
      },

      aimPageBody: {
        paddingLeft: 11,
        paddingRight: 11
      },

      patientDetailsContainer: {
        marginTop: 10,
        flexDirection: "row",
        paddingLeft: 10
      },
      patientDetailsItem: {
        marginRight: 25,
        flexDirection: "row"
      },

      patientHeading: {
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535"
      },

      patientDetail: {
        fontFamily: "Open Sans",
        fontSize: 10,
        fontWeight: "bold",
        color: "#353535"
      },

      diagnosisContainer: {
        backgroundColor: "#f1f1f1",
        marginTop: 10,
        borderRadius: 5,
        paddingHorizontal: 12,
        paddingTop: 12,
        paddingBottom: 12
      },

      diagnosisHeadings: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
      },

      diagnosisHeading: {
        fontFamily: "Open Sans",
        fontSize: 9,
        fontWeight: "bold",
        color: "#353535",
        // flex: 1,
        flexBasis: "45%"
      },

      diagnosis: {
        fontFamily: "Open Sans",
        fontSize: 9,
        color: "#353535",
        paddingTop:
          typeof this.props.referRequired === "undefined" ||
          this.props.diagnosis !== ""
            ? 8
            : 0,
        width: "93%"
        // letterSpacing: 0.4,
      },

      refer: {
        fontFamily: "Open Sans",
        fontSize: 9,
        color: "#353535",
        marginTop: 8,
        width: "93%",
        // letterSpacing: 0.4,
        fontWeight: 600,
        marginBottom: 0
      },

      row: {
        flexDirection: "row",
        marginTop: 8
      },

      normalImageBackground: {
        width: 264,
        height: 264,
        backgroundColor: "black",
        marginRight: 8,
        position: "relative"
      },
      aimImageBackground: {
        width: 282,
        height: 212,
        backgroundColor: "black",
        marginRight: 10,
        position: "relative"
      },
      aimImageBackgroundRight: {
        width: 282,
        height: 212,
        backgroundColor: "black",
        position: "relative"
      },
      montageImageBackground: {
        width: 536,
        height: 264,
        backgroundColor: "black",
        position: "relative"
      },
      normalImageBackgroundRight: {
        width: 264,
        height: 264,
        backgroundColor: "black",
        position: "relative"
      },
      label: {
        position: "absolute",
        bottom: 3,
        right: 3,
        height: 23,
        width: 31,
        backgroundColor: "#c4c4c4",
        borderRadius: 3,
        opacity: 0.5
      },
      labelText: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "white",
        opacity: 1,
        position: "relative",
        top: 4
      },
      image: {
        objectFit: "contain"
      },
      secondPageMargin: {
        marginTop: 30
      },
      footer: {
        position: "absolute",
        width: "100%",
        bottom: 0,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 23,
        paddingRight: 36,
        paddingBottom: 0
      },
      footerUnfixed: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 50,
        paddingLeft: 23,
        paddingRight: 36
      },
      pageNumber: {
        width: 22
      },
      pageNumberText: {
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535"
      },
      logo: {
        width: 69,
        height: 25,
        position: "relative",
        top: -13
      },
      logoWithSignature: {
        width: 69,
        height: 25,
        position: "relative",
        top: -13,
        left: 30
      },
      signature: {
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535"
      },
      signatureTextUnfixed: {
        textAlign: "right",
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535",
        marginRight: 36
      },
      signatureContainerUnfixed: {
        marginTop: 10,
        height: 40,
        flexDirection: "row-reverse",
        marginBottom: 0,
        marginRight: 36
      },
      signatureContainer: {
        position: "absolute",
        bottom: 25,
        paddingRight: 36,
        height: 40,
        width: "100vw",
        textAlign: "right",
        flexDirection: "row-reverse"
      },
      signatureImageUnfixed: {
        objectFit: "contain",
        textAlign: "right",
        maxWidth: 300
      },
      dateDetails: {
        position: "absolute",
        width: 200,
        height: "100%",
        right: 65,
        flexDirection: "column",
        justifyContent: "center"
      },
      signatureUnfixed: {
        textAlign: "right",
        marginRight: 36,
        marginTop: 30,
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535"
      },
      eyeHeadingContainer: {
        marginTop: 10
      },
      eyeHeading: {
        fontSize: 10,
        fontFamily: "Open Sans",
        color: "#353535",
        fontWeight: "bold"
      },
      table: {
        marginTop: 20,
        marginBottom: 0,
        display: "flex",
        flexDirection: "column"
      },
      tableRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      },
      tableItem: {
        flexBasis: "20%",
        backgroundColor: "#eee",
        marginRight: 1,
        marginBottom: 1,
        height: 48,
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
      },
      tableText: {
        textAlign: "center",
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535"
      },
      tableBold: {
        fontWeight: 600
      },
      tableFirst: {
        backgroundColor: "#c7cccf"
      },
      tableLast: {
        backgroundColor: "#c7cccf"
      },
      topLeft: {
        borderTopLeftRadius: 8
      },
      topRight: {
        borderTopRightRadius: 8
      },
      bottomLeft: {
        borderBottomLeftRadius: 8
      },
      bottomRight: {
        borderBottomRightRadius: 8
      },
      refFlexContainerContainer: {
        flexDirection: "row",
        justifyContent: "space-between"
      },
      refFlexContainer: {
        flexBasis: "45%"
      },
      reading: {
        fontFamily: "Open Sans",
        fontSize: 10,
        color: "#353535",
        fontWeight: "bold",
        marginTop: 30,
        marginBottom: 10
      },
      pristineHeader: {
        marginLeft: 21,
        marginTop: 14,
        marginRight: 21,
        height: 50,
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottomWidth: 1,
        borderBottomColor: "#000",
        borderBottomStyle: "solid"
      },
      pristineLeftHeader: {
        height: 40
      },
      pristineHospitalName: {
        fontFamily: "Poppins",
        fontWeight: "bold",
        fontSize: 8,
        marginBottom: 13
      },
      pristineMetadataContainer: {
        width: 530,
        flexDirection: "row",
        justifyContent: "space-between"
      },
      pristineMetadataHeading: {
        fontFamily: "Poppins",
        fontSize: 8
        // marginRight: 150,
      },
      pristineMetadataContent: {
        fontFamily: "Poppins",
        fontSize: 8,
        fontWeight: "bold"
      },
      pristineRemidioLogoImage: {
        width: 85,
        height: 33
      },
      pristineRegularImageContainer: {
        maxWidth: "50%"
      },
      pristineMontageImageContainer: {
        width: "100%",
        maxHeight: 400,
        flexDirection: "row",
        objectFit: "contain"
      },
      pristineMontageImage: {
        objectFit: "contain",
        alignSelf: "center",
        margin: "auto",
        maxHeight: 400
      },
      pristineImageContainer: {
        marginLeft: 21,
        marginTop: 14,
        marginRight: 21,
        flexDirection: "row",
        justifyContent: "space-between "
      },
      pristineLaterality: {
        position: "relative",
        bottom: 50,
        left: 50,
        fontFamily: "Poppins",
        fontSize: 16,
        fontWeight: "bold"
      },
      pristineMontageLaterality: {
        position: "absolute",
        bottom: 40,
        right: 200,
        textAlign: "right",
        fontFamily: "Poppins",
        fontSize: 16,
        fontWeight: "bold"
      },
      pristineMontageLabel: {
        position: "absolute",
        bottom: 0,
        right: 0,
        fontFamily: "Poppins",
        fontSize: 16,
        width: 200
      },
      pristineQRCodeContainer: {
        left: 17,
        bottom: 57,
        position: "absolute",
        right: 17
      },
      pristineQRCode: {
        width: 43,
        height: 43
      },
      pristineQRText: {
        width: 196,
        height: 20
      },
      pristineFooterContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 8,
        left: 17,
        right: 17,
        fontFamily: "Poppins",
        fontSize: 8,
        borderTopWidth: 1,
        borderTopColor: "#000",
        borderTopStyle: "solid",
        height: 40,
        alignItems: "center"
      },
      pristineSignatureContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      },
      pristineImage: {},
      pristineSignatureCont: {
        objectFit: "contain",
        maxWidth: 100,
        minWidth: 100
      }
    });

    Font.register({
      family: "Open Sans",
      fonts: [
        { src: OpenSansRegular },
        { src: OpenSansBold, fontWeight: "bold" },
        { src: OpenSansSemiBold, fontWeight: 600 }
      ]
    });
    Font.register({
      family: "Poppins",
      fonts: [
        { src: PoppinsRegular },
        { src: PoppinsBold, fontWeight: "bold" },
        { src: PoppinsSemiBold, fontWeight: 600 }
      ]
    });
    let selectedFOPMontageImages = this.props.selectedImages.filter(
      el => el.device === "FOP" && el.edited
    );
    let selectedFOPRegularImages = this.props.selectedImages.filter(
      el => el.device === "FOP" && !el.edited
    );
    let selectedAIMImages = this.props.selectedImages.filter(
      el => el.device === "AIM" || el.device === "PSL"
    );
    let selectedPristineMontageImages = this.props.selectedImages.filter(
      el => el.device === "PRISTINE" && el.edited
    );
    let selectedPristineRegularImages = this.props.selectedImages.filter(
      el => el.device === "PRISTINE" && !el.edited
    );
    let noOfAIMPages = Math.ceil(selectedAIMImages.length / 4);
    let noOfAIMRows = Math.ceil(selectedAIMImages.length / 2);
    let noOfFOPRows =
      selectedFOPMontageImages.length +
      Math.ceil(selectedFOPRegularImages.length / 2);
    let noOfFOPPages = Math.ceil(noOfFOPRows / 2);
    let pristinePages = [];
    let pristinePage;
    for (
      let i = 0;
      i < this.props.replacedSelectedPristineMontageImages.length;
      i++
    ) {
      pristinePage = (
        <Page size={"A4"} orientation="landscape" key={i}>
          <View style={styles.pristineHeader}>
            <View style={styles.pristineLeftHeader}>
              <Text style={styles.pristineHospitalName}>
                {sessionStorage.getItem("orgName")}
              </Text>
              <View style={styles.pristineMetadataContainer}>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.patientName)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {(
                      this.props.exam.patientDetails.firstName +
                      " " +
                      (this.props.exam.patientDetails.lastName
                        ? this.props.exam.patientDetails.lastName
                        : "")
                    ).replace(/(.{26})..+/, "$1...")}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.patientId)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {this.props.exam.patientDetails.mrn}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.age)} / {this.t(T_REPORT.gender)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {this.props.age ? this.props.age : "NA"} /{" "}
                    {this.props.exam.patientDetails.gender === "MALE"
                      ? this.t(T_REPORT.male)
                      : this.t(T_REPORT.female)}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.date)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {convertEpochToDate(new Date().getTime())}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.doctor)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {sessionStorage.getItem("userName")}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.rightHeader}>
              <Image src={Logo} style={styles.pristineRemidioLogoImage}></Image>
            </View>
          </View>
          <View style={styles.pristineImageContainer}>
            <View style={styles.pristineMontageImageContainer}>
              <Image
                style={styles.pristineMontageImage}
                src={this.props.replacedSelectedPristineMontageImages[i]}
              ></Image>
              <Text style={styles.pristineMontageLaterality}>
                {selectedPristineMontageImages[i].laterality === "RIGHT"
                  ? this.t(T_REPORT.re)
                  : this.t(T_REPORT.le)}
              </Text>
              <Text style={styles.pristineMontageLabel}>
                {this.t(T_REPORT.montageImage)}
              </Text>
            </View>
          </View>
          <View style={styles.pristineQRCodeContainer}>
            <Image style={styles.pristineQRCode} src={QRCode}></Image>
            <Image style={styles.pristineQRText} src={QRText}></Image>
          </View>
          <View style={styles.pristineFooterContainer}>
            <Text>{this.t(T_REPORT.retinalImagingReport)}</Text>
            <Text>
              {this.t(T_REPORT.page)} {i} {this.t(T_REPORT.of)}{" "}
              {this.props.replacedSelectedPristineMontageImages.length +
                Math.ceil(
                  this.props.replacedSelectedPristineRegularImages.length / 2
                )}
            </Text>
            <View style={styles.pristineSignatureContainer}>
              <Text>{this.t(T_REPORT.signature)}:</Text>
              {(() => {
                if (this.props.signatureExists) {
                  return (
                    <View style={styles.pristineSignatureCont}>
                      <Image
                        src={this.props.signature}
                        style={styles.pristineSignature}
                      ></Image>
                    </View>
                  );
                } else {
                  return <View style={styles.pristineSignatureCont}></View>;
                }
              })()}
            </View>
          </View>
        </Page>
      );
      pristinePages.push(pristinePage);
    }
    for (
      let i = 0;
      i < this.props.replacedSelectedPristineRegularImages.length;
      i += 2
    ) {
      pristinePage = (
        <Page size={"A4"} orientation="landscape" key={i}>
          <View style={styles.pristineHeader}>
            <View style={styles.pristineLeftHeader}>
              <Text style={styles.pristineHospitalName}>
                {sessionStorage.getItem("orgName")}
              </Text>
              <View style={styles.pristineMetadataContainer}>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.patientName)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {(
                      this.props.exam.patientDetails.firstName +
                      " " +
                      (this.props.exam.patientDetails.lastName
                        ? this.props.exam.patientDetails.lastName
                        : "")
                    ).replace(/(.{26})..+/, "$1...")}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.patientId)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {this.props.exam.patientDetails.mrn}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.age)} / {this.t(T_REPORT.gender)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {this.props.age ? this.props.age : "NA"} /{" "}
                    {this.props.exam.patientDetails.gender === "MALE"
                      ? this.t(T_REPORT.male)
                      : this.t(T_REPORT.female)}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.date)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {convertEpochToDate(new Date().getTime())}
                  </Text>
                </Text>
                <Text style={styles.pristineMetadataHeading}>
                  {this.t(T_REPORT.doctor)}:{" "}
                  <Text style={styles.pristineMetadataContent}>
                    {sessionStorage.getItem("userName")}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.rightHeader}>
              <Image src={Logo} style={styles.pristineRemidioLogoImage}></Image>
            </View>
          </View>
          <View style={styles.pristineImageContainer}>
            <View style={styles.pristineRegularImageContainer}>
              <Image
                style={styles.image}
                src={this.props.replacedSelectedPristineRegularImages[i]}
              ></Image>
              <Text style={styles.pristineLaterality}>
                {selectedPristineRegularImages[i].laterality === "RIGHT"
                  ? this.t(T_REPORT.re)
                  : this.t(T_REPORT.le)}
              </Text>
            </View>
            <View style={styles.pristineRegularImageContainer}>
              {(() => {
                if (
                  i + 1 <
                  this.props.replacedSelectedPristineRegularImages.length
                ) {
                  return (
                    <>
                      <Image
                        style={styles.image}
                        src={
                          this.props.replacedSelectedPristineRegularImages[
                            i + 1
                          ]
                        }
                      ></Image>
                      <Text style={styles.pristineLaterality}>
                        {selectedPristineRegularImages[i + 1].laterality ===
                        "RIGHT"
                          ? this.t(T_REPORT.re)
                          : this.t(T_REPORT.le)}
                      </Text>
                    </>
                  );
                }
              })()}
            </View>
          </View>
          <View style={styles.pristineQRCodeContainer}>
            <Image style={styles.pristineQRCode} src={QRCode}></Image>
            <Image style={styles.pristineQRText} src={QRText}></Image>
          </View>
          <View style={styles.pristineFooterContainer}>
            <Text>{this.t(T_REPORT.retinalImagingReport)}</Text>
            <Text>
              {this.t(T_REPORT.page)}{" "}
              {this.props.replacedSelectedPristineMontageImages.length +
                (i + 2) / 2}{" "}
              {this.t(T_REPORT.of)}{" "}
              {this.props.replacedSelectedPristineMontageImages.length +
                Math.ceil(
                  this.props.replacedSelectedPristineRegularImages.length / 2
                )}
            </Text>
            <View style={styles.pristineSignatureContainer}>
              <Text>{this.t(T_REPORT.signature)}:</Text>
              {(() => {
                if (this.props.signatureExists) {
                  return (
                    <View style={styles.pristineSignatureCont}>
                      <Image
                        src={this.props.signature}
                        style={styles.pristineSignature}
                      ></Image>
                    </View>
                  );
                } else {
                  return <View style={styles.pristineSignatureCont}></View>;
                }
              })()}
            </View>
          </View>
        </Page>
      );

      pristinePages.push(pristinePage);
    }
    return (
      <Document>
        {
          // FOP Page 1
        }
        {(() => {
          if (noOfFOPPages > 0) {
            return (
              <Page size="A4">
                <View style={styles.header}>
                  <View style={styles.headerLeft}>
                    {(() => {
                      if (this.props.hasLogo) {
                        return (
                          <>
                            <View style={styles.headerImageContainer}>
                              <Image
                                src={this.props.siteLogo}
                                style={styles.headerImage}
                              ></Image>
                            </View>
                          </>
                        );
                      }
                    })()}

                    <View style={styles.hospitalDetails}>
                      <Text style={styles.headerBoldText}>
                        {sessionStorage.getItem("orgName")}
                      </Text>
                      <Text style={styles.headerText}>
                        {this.props.siteName}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.dateDetails}>
                    <Text style={styles.headerBoldTextRight}>
                      {convertEpochToDate(new Date().getTime())}
                    </Text>
                    <Text style={styles.headerText}>&nbsp;</Text>
                  </View>
                  <View></View>
                </View>
                <View style={styles.pageHeading}>
                  <Text style={styles.pageHeadingText}>
                    {this.t(T_REPORT.fundusScreeningReport)}
                  </Text>
                </View>
                <View style={styles.pageBody}>
                  <View style={styles.patientDetailsContainer}>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>
                        {this.t(T_REPORT.patientName)}:{" "}
                      </Text>
                      <Text style={styles.patientDetail}>
                        {(
                          this.props.exam.patientDetails.firstName +
                          " " +
                          (this.props.exam.patientDetails.lastName
                            ? this.props.exam.patientDetails.lastName
                            : "")
                        ).replace(/(.{26})..+/, "$1...")}
                      </Text>
                    </View>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>MRN: </Text>
                      <Text style={styles.patientDetail}>
                        {this.props.exam.patientDetails.mrn.replace(
                          /(.{14})..+/,
                          "$1..."
                        )}
                      </Text>
                    </View>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>
                        {this.t(T_REPORT.age)} / {this.t(T_REPORT.gender)}:{" "}
                      </Text>
                      <Text style={styles.patientDetail}>
                        {this.props.age ? this.props.age : "NA"} /{" "}
                        {this.props.exam.patientDetails.gender === "MALE"
                          ? this.t(T_REPORT.male)
                          : this.t(T_REPORT.female)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.diagnosisContainer}>
                    <View style={styles.diagnosisHeadings}>
                      <Text style={styles.diagnosisHeading}>
                        {this.t(T_REPORT.rightEye)} -{" "}
                        {this.props.rightDiagnoses.replace(
                          /(.{128})..+/,
                          "$1..."
                        )}
                      </Text>
                      <Text style={styles.diagnosisHeading}>
                        {this.t(T_REPORT.leftEye)} -{" "}
                        {this.props.leftDiagnoses.replace(
                          /(.{128})..+/,
                          "$1..."
                        )}
                      </Text>
                    </View>
                    {(() => {
                      if (typeof this.props.referRequired !== "undefined") {
                        return (
                          <Text style={styles.refer}>
                            {this.props.referRequired
                              ? this.t(T_REPORT.referRequired)
                              : this.t(T_REPORT.referNotRequired)}
                          </Text>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.props.diagnosis !== "") {
                        return (
                          <Text style={styles.diagnosis}>
                            {this.props.diagnosis}
                          </Text>
                        );
                      }
                    })()}
                  </View>
                  {(() => {
                    if (selectedFOPMontageImages.length > 0) {
                      return (
                        <View style={styles.row}>
                          <View style={styles.montageImageBackground}>
                            <Image
                              style={styles.image}
                              src={selectedFOPMontageImages[0].src}
                            ></Image>
                            <View style={styles.label}>
                              <Text style={styles.labelText}>
                                {selectedFOPMontageImages[0].subHtml.includes(
                                  "Left"
                                )
                                  ? this.t(T_GENERAL.le)
                                  : this.t(T_GENERAL.re)}
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    } else {
                      return (
                        <View style={styles.row}>
                          <View style={styles.normalImageBackground}>
                            <Image
                              style={styles.image}
                              src={selectedFOPRegularImages[0].src}
                            ></Image>
                            <View style={styles.label}>
                              <Text style={styles.labelText}>
                                {selectedFOPRegularImages[0].subHtml.includes(
                                  "Left"
                                )
                                  ? this.t(T_GENERAL.le)
                                  : this.t(T_GENERAL.re)}
                              </Text>
                            </View>
                          </View>
                          {(() => {
                            if (selectedFOPRegularImages[1]) {
                              return (
                                <View style={styles.normalImageBackgroundRight}>
                                  <Image
                                    style={styles.image}
                                    src={selectedFOPRegularImages[1].src}
                                  ></Image>
                                  <View style={styles.label}>
                                    <Text style={styles.labelText}>
                                      {selectedFOPRegularImages[1].subHtml.includes(
                                        "Left"
                                      )
                                        ? this.t(T_GENERAL.le)
                                        : this.t(T_GENERAL.re)}
                                    </Text>
                                  </View>
                                </View>
                              );
                            }
                          })()}
                        </View>
                      );
                    }
                  })()}
                  {(() => {
                    if (selectedFOPMontageImages.length > 1) {
                      return (
                        <View style={styles.row}>
                          <View style={styles.montageImageBackground}>
                            <Image
                              style={styles.image}
                              src={selectedFOPMontageImages[1].src}
                            ></Image>
                            <View style={styles.label}>
                              <Text style={styles.labelText}>
                                {selectedFOPMontageImages[1].subHtml.includes(
                                  "Left"
                                )
                                  ? this.t(T_GENERAL.le)
                                  : this.t(T_GENERAL.re)}
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    } else if (
                      (selectedFOPMontageImages.length === 1 &&
                        selectedFOPRegularImages.length > 0) ||
                      (selectedFOPMontageImages.length === 0 &&
                        selectedFOPRegularImages.length > 2)
                    ) {
                      return (
                        <View style={styles.row}>
                          <View style={styles.normalImageBackground}>
                            <Image
                              style={styles.image}
                              src={
                                selectedFOPMontageImages.length === 1
                                  ? selectedFOPRegularImages[0].src
                                  : selectedFOPRegularImages[2].src
                              }
                            ></Image>
                            <View style={styles.label}>
                              <Text style={styles.labelText}>
                                {(selectedFOPMontageImages.length === 1
                                  ? selectedFOPRegularImages[0]
                                  : selectedFOPRegularImages[2]
                                ).subHtml.includes("Left")
                                  ? this.t(T_GENERAL.le)
                                  : this.t(T_GENERAL.re)}
                              </Text>
                            </View>
                          </View>
                          {(() => {
                            if (
                              (selectedFOPMontageImages.length === 1 &&
                                selectedFOPRegularImages[1]) ||
                              (selectedFOPMontageImages.length === 0 &&
                                selectedFOPRegularImages[3])
                            ) {
                              return (
                                <View style={styles.normalImageBackgroundRight}>
                                  <Image
                                    style={styles.image}
                                    src={
                                      selectedFOPMontageImages.length === 1
                                        ? selectedFOPRegularImages[1].src
                                        : selectedFOPRegularImages[3].src
                                    }
                                  ></Image>
                                  <View style={styles.label}>
                                    <Text style={styles.labelText}>
                                      {(selectedFOPMontageImages.length === 1
                                        ? selectedFOPRegularImages[1]
                                        : selectedFOPRegularImages[3]
                                      ).subHtml.includes("Left")
                                        ? this.t(T_GENERAL.le)
                                        : this.t(T_GENERAL.re)}
                                    </Text>
                                  </View>
                                </View>
                              );
                            }
                          })()}
                        </View>
                      );
                    }
                  })()}
                </View>
                {(() => {
                  if (noOfFOPRows === 1) {
                    if (this.props.signatureExists) {
                      return (
                        <>
                          <View style={styles.signatureContainerUnfixed}>
                            <Image
                              src={this.props.signature}
                              style={styles.signatureImageUnfixed}
                            ></Image>
                          </View>
                          <Text style={styles.signatureTextUnfixed}>
                            Dr. {sessionStorage.getItem("userName")}
                          </Text>
                        </>
                      );
                    }
                    return (
                      <Text style={styles.signatureUnfixed}>
                        Dr. {sessionStorage.getItem("userName")}
                      </Text>
                    );
                  }
                })()}
                <View style={styles.footer}>
                  <View style={styles.pageNumber}>
                    {(() => {
                      if (noOfFOPPages > 1) {
                        return <Text style={styles.pageNumberText}>(1/2)</Text>;
                      }
                    })()}
                  </View>
                  <Image src={Logo} style={styles.logoWithSignature}></Image>
                  {(() => {
                    if (noOfFOPPages > 1 || noOfFOPRows === 1) {
                      return (
                        <Text style={styles.signature}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Text>
                      );
                    } else {
                      return (
                        <Text style={styles.signature}>
                          Dr. {sessionStorage.getItem("userName")}
                        </Text>
                      );
                    }
                  })()}
                </View>
                {(() => {
                  if (
                    this.props.signatureExists &&
                    !(noOfFOPPages > 1 || noOfFOPRows === 1)
                  ) {
                    return (
                      <>
                        <View style={styles.signatureContainer}>
                          <Image
                            src={this.props.signature}
                            style={styles.signatureImageUnfixed}
                          ></Image>
                        </View>
                      </>
                    );
                  }
                })()}
              </Page>
            );
          }
        })()}
        {
          // FOP Page 2
        }
        {(() => {
          if (noOfFOPPages >= 2) {
            return (
              <Page size={"A4"}>
                <View style={styles.secondPageMargin}>
                  <View style={styles.pageBody}>
                    {(() => {
                      if (selectedFOPMontageImages.length > 2) {
                        return (
                          <View style={styles.row}>
                            <View style={styles.montageImageBackground}>
                              <Image
                                style={styles.image}
                                src={selectedFOPMontageImages[2].src}
                              ></Image>
                              <View style={styles.label}>
                                <Text style={styles.labelText}>
                                  {selectedFOPMontageImages[2].subHtml.includes(
                                    "Left"
                                  )
                                    ? this.t(T_GENERAL.le)
                                    : this.t(T_GENERAL.re)}
                                </Text>
                              </View>
                            </View>
                          </View>
                        );
                      } else {
                        return (
                          <View style={styles.row}>
                            <View style={styles.normalImageBackground}>
                              <Image
                                style={styles.image}
                                src={
                                  selectedFOPMontageImages.length === 2
                                    ? selectedFOPRegularImages[0].src
                                    : selectedFOPMontageImages.length === 1
                                    ? selectedFOPRegularImages[2].src
                                    : selectedFOPRegularImages[4].src
                                }
                              ></Image>
                              <View style={styles.label}>
                                <Text style={styles.labelText}>
                                  {(selectedFOPMontageImages.length === 2
                                    ? selectedFOPRegularImages[0]
                                    : selectedFOPMontageImages.length === 1
                                    ? selectedFOPRegularImages[2]
                                    : selectedFOPRegularImages[4]
                                  ).subHtml.includes("Left")
                                    ? this.t(T_GENERAL.le)
                                    : this.t(T_GENERAL.re)}
                                </Text>
                              </View>
                            </View>
                            {(() => {
                              if (
                                selectedFOPMontageImages.length === 2
                                  ? selectedFOPRegularImages[1]
                                  : selectedFOPMontageImages.length === 1
                                  ? selectedFOPRegularImages[3]
                                  : selectedFOPRegularImages[5]
                              ) {
                                return (
                                  <View
                                    style={styles.normalImageBackgroundRight}
                                  >
                                    <Image
                                      style={styles.image}
                                      src={
                                        selectedFOPMontageImages.length === 2
                                          ? selectedFOPRegularImages[1].src
                                          : selectedFOPMontageImages.length ===
                                            1
                                          ? selectedFOPRegularImages[3].src
                                          : selectedFOPRegularImages[5].src
                                      }
                                    ></Image>
                                    <View style={styles.label}>
                                      <Text style={styles.labelText}>
                                        {(selectedFOPMontageImages.length === 2
                                          ? selectedFOPRegularImages[1]
                                          : selectedFOPMontageImages.length ===
                                            1
                                          ? selectedFOPRegularImages[3]
                                          : selectedFOPRegularImages[5]
                                        ).subHtml.includes("Left")
                                          ? this.t(T_GENERAL.le)
                                          : this.t(T_GENERAL.re)}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }
                            })()}
                          </View>
                        );
                      }
                    })()}
                    {(() => {
                      if (selectedFOPMontageImages.length > 3) {
                        return (
                          <View style={styles.row}>
                            <View style={styles.montageImageBackground}>
                              <Image
                                style={styles.image}
                                src={selectedFOPMontageImages[3].src}
                              ></Image>
                              <View style={styles.label}>
                                <Text style={styles.labelText}>
                                  {selectedFOPMontageImages[3].subHtml.includes(
                                    "Left"
                                  )
                                    ? this.t(T_GENERAL.le)
                                    : this.t(T_GENERAL.re)}
                                </Text>
                              </View>
                            </View>
                          </View>
                        );
                      } else if (
                        (selectedFOPMontageImages.length === 3 &&
                          selectedFOPRegularImages.length > 0) ||
                        (selectedFOPMontageImages.length === 2 &&
                          selectedFOPRegularImages.length > 2) ||
                        (selectedFOPMontageImages.length === 1 &&
                          selectedFOPRegularImages.length > 4) ||
                        (selectedFOPMontageImages.length === 0 &&
                          selectedFOPRegularImages.length > 6)
                      ) {
                        return (
                          <View style={styles.row}>
                            <View style={styles.normalImageBackground}>
                              <Image
                                style={styles.image}
                                src={
                                  selectedFOPMontageImages.length === 3
                                    ? selectedFOPRegularImages[0].src
                                    : selectedFOPMontageImages.length === 2
                                    ? selectedFOPRegularImages[2].src
                                    : selectedFOPMontageImages.length === 1
                                    ? selectedFOPRegularImages[4].src
                                    : selectedFOPRegularImages[6].src
                                }
                              ></Image>
                              <View style={styles.label}>
                                <Text style={styles.labelText}>
                                  {(selectedFOPMontageImages.length === 3
                                    ? selectedFOPRegularImages[0]
                                    : selectedFOPMontageImages.length === 2
                                    ? selectedFOPRegularImages[2]
                                    : selectedFOPMontageImages.length === 1
                                    ? selectedFOPRegularImages[4]
                                    : selectedFOPRegularImages[6]
                                  ).subHtml.includes("Left")
                                    ? this.t(T_GENERAL.le)
                                    : this.t(T_GENERAL.re)}
                                </Text>
                              </View>
                            </View>
                            {(() => {
                              if (
                                (selectedFOPMontageImages.length === 3 &&
                                  selectedFOPRegularImages[1]) ||
                                (selectedFOPMontageImages.length === 2 &&
                                  selectedFOPRegularImages[3]) ||
                                (selectedFOPMontageImages.length === 1 &&
                                  selectedFOPRegularImages[5]) ||
                                (selectedFOPMontageImages.length === 0 &&
                                  selectedFOPRegularImages[7])
                              ) {
                                return (
                                  <View
                                    style={styles.normalImageBackgroundRight}
                                  >
                                    <Image
                                      style={styles.image}
                                      src={
                                        selectedFOPMontageImages.length === 3
                                          ? selectedFOPRegularImages[1].src
                                          : selectedFOPMontageImages.length ===
                                            2
                                          ? selectedFOPRegularImages[3].src
                                          : selectedFOPMontageImages.length ===
                                            1
                                          ? selectedFOPRegularImages[5].src
                                          : selectedFOPRegularImages[7].src
                                      }
                                    ></Image>
                                    <View style={styles.label}>
                                      <Text style={styles.labelText}>
                                        {(selectedFOPMontageImages.length === 3
                                          ? selectedFOPRegularImages[1]
                                          : selectedFOPMontageImages.length ===
                                            2
                                          ? selectedFOPRegularImages[3]
                                          : selectedFOPMontageImages.length ===
                                            1
                                          ? selectedFOPRegularImages[5]
                                          : selectedFOPRegularImages[7]
                                        ).subHtml.includes("Left")
                                          ? this.t(T_GENERAL.le)
                                          : this.t(T_GENERAL.re)}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }
                            })()}
                          </View>
                        );
                      }
                    })()}
                  </View>
                </View>
                {(() => {
                  if (this.props.signatureExists) {
                    return (
                      <>
                        <View style={styles.signatureContainerUnfixed}>
                          <Image
                            src={this.props.signature}
                            style={styles.signatureImageUnfixed}
                          ></Image>
                        </View>
                        <Text style={styles.signatureTextUnfixed}>
                          Dr. {sessionStorage.getItem("userName")}
                        </Text>
                      </>
                    );
                  }
                  return (
                    <Text style={styles.signatureUnfixed}>
                      Dr. {sessionStorage.getItem("userName")}
                    </Text>
                  );
                })()}
                <View style={styles.footer}>
                  <View style={styles.pageNumber}>
                    <Text style={styles.pageNumberText}>(2/2)</Text>;
                  </View>
                  <Image src={Logo} style={styles.logo}></Image>

                  <Text style={styles.signature}></Text>
                </View>
              </Page>
            );
          }
        })()}
        {
          // AIM Page 1
        }
        {(() => {
          if (noOfAIMPages > 0) {
            return (
              <Page size="A4">
                <View style={styles.header}>
                  <View style={styles.headerLeft}>
                    {(() => {
                      if (this.props.hasLogo) {
                        return (
                          <>
                            <View style={styles.headerImageContainer}>
                              <Image
                                src={this.props.siteLogo}
                                style={styles.headerImage}
                              ></Image>
                            </View>
                          </>
                        );
                      }
                    })()}

                    <View style={styles.hospitalDetails}>
                      <Text style={styles.headerBoldText}>
                        {sessionStorage.getItem("orgName")}
                      </Text>
                      <Text style={styles.headerText}>
                        {this.props.siteName}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.dateDetails}>
                    <Text style={styles.headerBoldTextRight}>
                      {convertEpochToDate(new Date().getTime())}
                    </Text>
                    <Text style={styles.headerText}>&nbsp;</Text>
                  </View>
                </View>
                <View style={styles.pageHeading}>
                  <Text style={styles.pageHeadingText}>
                    {this.t(T_REPORT.anteriorScreeningReport)}
                  </Text>
                </View>
                <View style={styles.aimPageBody}>
                  <View style={styles.patientDetailsContainer}>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>
                        {this.t(T_REPORT.patientName)}:{" "}
                      </Text>
                      <Text style={styles.patientDetail}>
                        {(
                          this.props.exam.patientDetails.firstName +
                          " " +
                          (this.props.exam.patientDetails.lastName
                            ? this.props.exam.patientDetails.lastName
                            : "")
                        ).replace(/(.{17})..+/, "$1...")}
                      </Text>
                    </View>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>MRN: </Text>
                      <Text style={styles.patientDetail}>
                        {this.props.exam.patientDetails.mrn.replace(
                          /(.{14})..+/,
                          "$1..."
                        )}
                      </Text>
                    </View>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>
                        {this.t(T_REPORT.age)} / {this.t(T_REPORT.gender)}:{" "}
                      </Text>
                      <Text style={styles.patientDetail}>
                        {this.props.age ? this.props.age : "NA"} /{" "}
                        {this.props.exam.patientDetails.gender === "MALE"
                          ? this.t(T_REPORT.male)
                          : this.t(T_REPORT.female)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.diagnosisContainer}>
                    <View style={styles.diagnosisHeadings}>
                      <Text style={styles.diagnosisHeading}>
                        {this.t(T_REPORT.rightEye)} -{" "}
                        {this.props.rightDiagnoses}
                      </Text>
                      <Text style={styles.diagnosisHeading}>
                        {this.t(T_REPORT.leftEye)} - {this.props.leftDiagnoses}
                      </Text>
                    </View>
                    {(() => {
                      if (typeof this.props.referRequired !== "undefined") {
                        return (
                          <Text style={styles.refer}>
                            {this.props.referRequired
                              ? this.t(T_REPORT.referRequired)
                              : this.t(T_REPORT.referNotRequired)}
                          </Text>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.props.diagnosis !== "") {
                        return (
                          <Text style={styles.diagnosis}>
                            {this.props.diagnosis}
                          </Text>
                        );
                      }
                    })()}
                  </View>

                  <View style={styles.row}>
                    <View style={styles.aimImageBackground}>
                      <Image
                        style={styles.image}
                        src={selectedAIMImages[0].src}
                      ></Image>
                      <View style={styles.label}>
                        <Text style={styles.labelText}>
                          {selectedAIMImages[0].subHtml.includes("Left")
                            ? this.t(T_GENERAL.le)
                            : this.t(T_GENERAL.re)}
                        </Text>
                      </View>
                    </View>
                    {(() => {
                      if (selectedAIMImages[1]) {
                        return (
                          <View style={styles.aimImageBackgroundRight}>
                            <Image
                              style={styles.image}
                              src={selectedAIMImages[1].src}
                            ></Image>
                            <View style={styles.label}>
                              <Text style={styles.labelText}>
                                {selectedAIMImages[1].subHtml.includes("Left")
                                  ? this.t(T_GENERAL.le)
                                  : this.t(T_GENERAL.re)}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    })()}
                  </View>
                  {(() => {
                    if (selectedAIMImages.length > 2) {
                      return (
                        <View style={styles.row}>
                          <View style={styles.aimImageBackground}>
                            <Image
                              style={styles.image}
                              src={selectedAIMImages[2].src}
                            ></Image>
                            <View style={styles.label}>
                              <Text style={styles.labelText}>
                                {selectedAIMImages[2].subHtml.includes("Left")
                                  ? this.t(T_GENERAL.le)
                                  : this.t(T_GENERAL.re)}
                              </Text>
                            </View>
                          </View>
                          {(() => {
                            if (selectedAIMImages[3]) {
                              return (
                                <View style={styles.aimImageBackgroundRight}>
                                  <Image
                                    style={styles.image}
                                    src={selectedAIMImages[3].src}
                                  ></Image>
                                  <View style={styles.label}>
                                    <Text style={styles.labelText}>
                                      {selectedAIMImages[3].subHtml.includes(
                                        "Left"
                                      )
                                        ? this.t(T_GENERAL.le)
                                        : this.t(T_GENERAL.re)}
                                    </Text>
                                  </View>
                                </View>
                              );
                            }
                          })()}
                        </View>
                      );
                    }
                  })()}
                </View>
                {(() => {
                  if (noOfAIMRows === 1) {
                    if (this.props.signatureExists) {
                      return (
                        <>
                          <View style={styles.signatureContainerUnfixed}>
                            <Image
                              src={this.props.signature}
                              style={styles.signatureImageUnfixed}
                            ></Image>
                          </View>
                          <Text style={styles.signatureTextUnfixed}>
                            Dr. {sessionStorage.getItem("userName")}
                          </Text>
                        </>
                      );
                    }
                    return (
                      <Text style={styles.signatureUnfixed}>
                        Dr. {sessionStorage.getItem("userName")}
                      </Text>
                    );
                  }
                })()}
                <View style={styles.footer}>
                  <View style={styles.pageNumber}>
                    {(() => {
                      if (noOfAIMPages > 1) {
                        return <Text style={styles.pageNumberText}>(1/2)</Text>;
                      }
                    })()}
                  </View>
                  <Image src={Logo} style={styles.logoWithSignature}></Image>
                  {(() => {
                    if (noOfAIMPages > 1 || noOfAIMRows === 1) {
                      return (
                        <Text style={styles.signature}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Text>
                      );
                    } else {
                      return (
                        <Text style={styles.signature}>
                          Dr. {sessionStorage.getItem("userName")}
                        </Text>
                      );
                    }
                  })()}
                </View>
                {(() => {
                  if (
                    this.props.signatureExists &&
                    !(noOfAIMPages > 1 || noOfAIMRows === 1)
                  ) {
                    return (
                      <>
                        <View style={styles.signatureContainer}>
                          <Image
                            src={this.props.signature}
                            style={styles.signatureImageUnfixed}
                          ></Image>
                        </View>
                      </>
                    );
                  }
                })()}
              </Page>
            );
          }
        })()}
        {
          // AIM Page 2
        }
        {(() => {
          if (noOfAIMPages >= 2) {
            return (
              <Page size={"A4"}>
                <View style={styles.secondPageMargin}>
                  <View style={styles.aimPageBody}>
                    <View style={styles.row}>
                      <View style={styles.aimImageBackground}>
                        <Image
                          style={styles.image}
                          src={selectedAIMImages[4].src}
                        ></Image>
                        <View style={styles.label}>
                          <Text style={styles.labelText}>
                            {selectedAIMImages[4].subHtml.includes("Left")
                              ? this.t(T_GENERAL.le)
                              : this.t(T_GENERAL.re)}
                          </Text>
                        </View>
                      </View>
                      {(() => {
                        if (selectedAIMImages[5]) {
                          return (
                            <View style={styles.aimImageBackgroundRight}>
                              <Image
                                style={styles.image}
                                src={selectedAIMImages[5].src}
                              ></Image>
                              <View style={styles.label}>
                                <Text style={styles.labelText}>
                                  {selectedAIMImages[5].subHtml.includes("Left")
                                    ? this.t(T_GENERAL.le)
                                    : this.t(T_GENERAL.re)}
                                </Text>
                              </View>
                            </View>
                          );
                        }
                      })()}
                    </View>
                    {(() => {
                      if (selectedAIMImages.length > 6) {
                        return (
                          <View style={styles.row}>
                            <View style={styles.aimImageBackground}>
                              <Image
                                style={styles.image}
                                src={selectedAIMImages[6].src}
                              ></Image>
                              <View style={styles.label}>
                                <Text style={styles.labelText}>
                                  {selectedAIMImages[6].subHtml.includes("Left")
                                    ? this.t(T_GENERAL.le)
                                    : this.t(T_GENERAL.re)}
                                </Text>
                              </View>
                            </View>
                            {(() => {
                              if (selectedAIMImages[7]) {
                                return (
                                  <View style={styles.aimImageBackgroundRight}>
                                    <Image
                                      style={styles.image}
                                      src={selectedAIMImages[7].src}
                                    ></Image>
                                    <View style={styles.label}>
                                      <Text style={styles.labelText}>
                                        {selectedAIMImages[7].subHtml.includes(
                                          "Left"
                                        )
                                          ? this.t(T_GENERAL.le)
                                          : this.t(T_GENERAL.re)}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }
                            })()}
                          </View>
                        );
                      }
                    })()}
                  </View>
                </View>
                {(() => {
                  if (this.props.signatureExists) {
                    return (
                      <>
                        <View style={styles.signatureContainerUnfixed}>
                          <Image
                            src={this.props.signature}
                            style={styles.signatureImageUnfixed}
                          ></Image>
                        </View>
                        <Text style={styles.signatureTextUnfixed}>
                          Dr. {sessionStorage.getItem("userName")}
                        </Text>
                      </>
                    );
                  }
                  return (
                    <Text style={styles.signatureUnfixed}>
                      Dr. {sessionStorage.getItem("userName")}
                    </Text>
                  );
                })()}
                <View style={styles.footer}>
                  <View style={styles.pageNumber}>
                    <Text style={styles.pageNumberText}>(2/2)</Text>;
                  </View>
                  <Image src={Logo} style={styles.logo}></Image>

                  <Text style={styles.signature}></Text>
                </View>
              </Page>
            );
          }
        })()}
        {
          // Refractive Error Page
        }
        {(() => {
          if (this.props.showRefractive) {
            return (
              <Page size="A4">
                <View style={styles.header}>
                  <View style={styles.headerLeft}>
                    {(() => {
                      if (this.props.hasLogo) {
                        return (
                          <>
                            <View style={styles.headerImageContainer}>
                              <Image
                                src={this.props.siteLogo}
                                style={styles.headerImage}
                              ></Image>
                            </View>
                          </>
                        );
                      }
                    })()}

                    <View style={styles.hospitalDetails}>
                      <Text style={styles.headerBoldText}>
                        {sessionStorage.getItem("orgName")}
                      </Text>
                      <Text style={styles.headerText}>
                        {this.props.siteName}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.dateDetails}>
                    <Text style={styles.headerBoldTextRight}>
                      {convertEpochToDate(new Date().getTime())}
                    </Text>
                    <Text style={styles.headerText}>&nbsp;</Text>
                  </View>
                </View>
                <View style={styles.pageHeading}>
                  <Text style={styles.pageHeadingText}>
                    {this.t(T_REPORT.refractiveErrorScreeningReport)}
                  </Text>
                </View>
                <View style={styles.pageBody}>
                  <View style={styles.patientDetailsContainer}>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>
                        {this.t(T_REPORT.patientName)}:{" "}
                      </Text>
                      <Text style={styles.patientDetail}>
                        {(
                          this.props.exam.patientDetails.firstName +
                          " " +
                          (this.props.exam.patientDetails.lastName
                            ? this.props.exam.patientDetails.lastName
                            : "")
                        ).replace(/(.{17})..+/, "$1...")}
                      </Text>
                    </View>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>MRN: </Text>
                      <Text style={styles.patientDetail}>
                        {this.props.exam.patientDetails.mrn.replace(
                          /(.{14})..+/,
                          "$1..."
                        )}
                      </Text>
                    </View>
                    <View style={styles.patientDetailsItem}>
                      <Text style={styles.patientHeading}>
                        {this.t(T_REPORT.age)} / {this.t(T_REPORT.gender)}:{" "}
                      </Text>
                      <Text style={styles.patientDetail}>
                        {this.props.age ? this.props.age : "NA"} /{" "}
                        {this.props.exam.patientDetails.gender === "MALE"
                          ? this.t(T_REPORT.male)
                          : this.t(T_REPORT.female)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.diagnosisContainer}>
                    <View style={styles.diagnosisHeadings}>
                      <Text style={styles.diagnosisHeading}>
                        {this.t(T_REPORT.rightEye)} -{" "}
                        {this.props.rightDiagnoses}
                      </Text>
                      <Text style={styles.diagnosisHeading}>
                        {this.t(T_REPORT.leftEye)} - {this.props.leftDiagnoses}
                      </Text>
                    </View>
                    {(() => {
                      if (typeof this.props.referRequired !== "undefined") {
                        return (
                          <Text style={styles.refer}>
                            {this.props.referRequired
                              ? this.t(T_REPORT.referRequired)
                              : this.t(T_REPORT.referNotRequired)}
                          </Text>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.props.diagnosis !== "") {
                        return (
                          <Text style={styles.diagnosis}>
                            {this.props.diagnosis}
                          </Text>
                        );
                      }
                    })()}
                  </View>
                  {(() => {
                    if (this.props.showFirst) {
                      return (
                        <>
                          <Text style={styles.reading}>
                            {this.t(T_REPORT.readingOne)}
                          </Text>
                          <View style={styles.refFlexContainerContainer}>
                            <View style={styles.refFlexContainer}>
                              <View style={styles.eyeHeadingContainer}>
                                <Text style={styles.eyeHeading}>
                                  {this.t(T_REPORT.rightEyeCaps)}
                                </Text>
                              </View>
                              <View style={styles.table}>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.topLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Sph</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading1?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading2?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading3?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.topRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.average?.sph
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Cyl</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading1?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading2?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading3?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[styles.tableItem, styles.tableLast]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.average?.cyl
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.bottomLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Ax</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading1?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading2?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.reading3?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.bottomRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.reReading?.average?.axis
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View style={styles.refFlexContainer}>
                              <View style={styles.eyeHeadingContainer}>
                                <Text style={styles.eyeHeading}>
                                  {this.t(T_REPORT.leftEyeCaps)}
                                </Text>
                              </View>
                              <View style={styles.table}>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.topLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Sph</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading1?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading2?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading3?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.topRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.average?.sph
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Cyl</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading1?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading2?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading3?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[styles.tableItem, styles.tableLast]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.average?.cyl
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.bottomLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Ax</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading1?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading2?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.reading3?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.bottomRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet1
                                          ?.leReading?.average?.axis
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </>
                      );
                    }
                  })()}
                  {(() => {
                    if (this.props.showSecond) {
                      return (
                        <>
                          <Text style={styles.reading}>
                            {this.t(T_REPORT.readingTwo)}
                          </Text>
                          <View style={styles.refFlexContainerContainer}>
                            <View style={styles.refFlexContainer}>
                              <View style={styles.eyeHeadingContainer}>
                                <Text style={styles.eyeHeading}>
                                  {this.t(T_REPORT.rightEyeCaps)}
                                </Text>
                              </View>
                              <View style={styles.table}>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.topLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Sph</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading1?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading2?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading3?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.topRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.average?.sph
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Cyl</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading1?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading2?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading3?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[styles.tableItem, styles.tableLast]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.average?.cyl
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.bottomLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Ax</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading1?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading2?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.reading3?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.bottomRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.reReading?.average?.axis
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View style={styles.refFlexContainer}>
                              <View style={styles.eyeHeadingContainer}>
                                <Text style={styles.eyeHeading}>
                                  {this.t(T_REPORT.leftEyeCaps)}
                                </Text>
                              </View>
                              <View style={styles.table}>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.topLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Sph</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading1?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading2?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading3?.sph
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.topRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.average?.sph
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Cyl</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading1?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading2?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading3?.cyl
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[styles.tableItem, styles.tableLast]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.average?.cyl
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableFirst,
                                      styles.bottomLeft
                                    ]}
                                  >
                                    <Text style={styles.tableText}>Ax</Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading1?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading2?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View style={styles.tableItem}>
                                    <Text style={styles.tableText}>
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.reading3?.axis
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tableItem,
                                      styles.tableLast,
                                      styles.bottomRight
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.tableText,
                                        styles.tableBold
                                      ]}
                                    >
                                      {
                                        this.props.visionAssessment?.readingSet2
                                          ?.leReading?.average?.axis
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </>
                      );
                    }
                  })()}
                </View>
                {(() => {
                  if (this.props.signatureExists) {
                    return (
                      <>
                        <View style={styles.signatureContainerUnfixed}>
                          <Image
                            src={this.props.signature}
                            style={styles.signatureImageUnfixed}
                          ></Image>
                        </View>
                        <Text style={styles.signatureTextUnfixed}>
                          Dr. {sessionStorage.getItem("userName")}
                        </Text>
                      </>
                    );
                  }
                  return (
                    <Text style={styles.signatureUnfixed}>
                      Dr. {sessionStorage.getItem("userName")}
                    </Text>
                  );
                })()}
                <View style={styles.footer}>
                  <View style={styles.pageNumber}></View>
                  <Image src={Logo} style={styles.logo}></Image>

                  <Text style={styles.signature}></Text>
                </View>
              </Page>
            );
          }
        })()}
        {/* Pristine Page */}
        {(() => {
          if (
            this.props.replacedSelectedPristineMontageImages.length +
              this.props.replacedSelectedPristineRegularImages.length >
            0
          ) {
            return <>{pristinePages}</>;
          }
        })()}
      </Document>
    );
  }
}

export default withTranslation()(PDF);
