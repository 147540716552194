/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { forgotPassword } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import history from "../../utils/history";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the Forgot Password page
 *
 * @component
 */
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      userId: "",
      name: "",
      disableButton: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  /**
   * Takes the userID from the previous component
   *
   *
   *
   */
  componentDidMount() {
    const { userId } = this.props.location;
    this.setState({ userId, name });
  }
  /**
   * Submits the Forgot Password form
   * @param {object} e event listener
   *
   *
   */
  onSubmit(e) {
    this.setState({ disableButton: true });
    e.preventDefault();
    postAuthenticatedData(
      sessionStorage.getItem("domain") +
        forgotPassword +
        "/" +
        this.props.location.email
    )
      .then(result => {
        this.setState({ disableButton: false });
        if (result.status === 200) {
          toast.success(this.t(T_LOGIN.toast.resetEmail));
          history.push("/login");
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
    //UVA_API_CALL
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </p>

          <p className="center">
            {this.t(T_LOGIN.forgotPasswordTextTop, { name: this.state.name })}{" "}
          </p>
          <p className="centeruser">
            {this.t(T_LOGIN.forgotPasswordTextBottom)}{" "}
          </p>

          <Button
            block
            bsSize="large"
            onClick={this.onSubmit}
            type="submit"
            className="loginbtn"
          >
            {this.state.disableButton ? (
              <img src={require("../../assets/images/button-loading.gif")} />
            ) : (
              this.t(T_LOGIN.sendLink)
            )}
          </Button>
          <Link to="/">
            <p className="backrecover">{this.t(T_LOGIN.back)}</p>
          </Link>
        </form>
      </div>
    );
  }
}

export default withTranslation()(ForgotPassword);
