/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateSiteSubscriptionSettings } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SERVICE, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Site Entitlement Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: true,
      isAccountActive: this.props.subscriptionSettings.isAccountActive,
      isOnSubscriptionPlan: this.props.subscriptionSettings
        .isOnSubscriptionPlan,
      offlineCheckFrequency: this.props.subscriptionSettings
        .offlineCheckFrequency
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (
      e.target.name === "offlineCheckFrequency" &&
      (/^[0-9\b]+$/.test(e.target.value) || e.target.value === "")
    ) {
      this.setState({
        [e.target.name]: e.target.value,
        disableButton: e.target.value === ""
      });
    } else if (e.target.name !== "offlineCheckFrequency") {
      this.setState({
        [e.target.name]: e.target.value,
        disableButton: e.target.value === ""
      });
    }
  }
  componentDidMount() {}
  /**
   * Submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    var data = {
      isAccountActive: this.state.isAccountActive,
      isOnSubscriptionPlan: this.state.isOnSubscriptionPlan,
      offlineCheckFrequency: this.state.offlineCheckFrequency
    };
    this.setState({ isLoaded: false, disableButton: true });

    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateSiteSubscriptionSettings +
        "/" +
        this.props.siteId,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.t(T_SITES.toast.siteSubscriptionSettingsUpdatedSuccessfully)
          );
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner entitlement-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SERVICE.viewSites.siteSubscription.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(
                      T_SERVICE.viewSites.siteSubscription.isAccountActive
                    )}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.isAccountActive}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ isAccountActive: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(
                      T_SERVICE.viewSites.siteSubscription.isOnSubscriptionPlan
                    )}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.isOnSubscriptionPlan}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ isOnSubscriptionPlan: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(
                      T_SERVICE.viewSites.siteSubscription.offlineCheckFrequency
                    )}
                  </div>
                  <div className="col-md-7">
                    <FormControl
                      required
                      type="text"
                      name="offlineCheckFrequency"
                      value={this.state.offlineCheckFrequency}
                      onChange={this.onChange}
                    />
                  </div>
                  <span className="col-md-2 formfont">
                    {this.t(T_SERVICE.viewSites.siteSubscription.days)}
                  </span>
                </div>
              </FormGroup>
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomSubscriptionModal);
