import React, { Component } from "react";
import {
  Nav,
  Navbar
  // NavItem,
  // NavLink
} from "reactstrap";
// import { getAuthenticatedData } from "../../utils/async";
// import { getSites } from "../../constants/endpoints";
import RemidioLogo from "../../assets/images/remidio.svg";
// import VFILogo from "../../assets/images/vfiLogo.png";
/**
 * This is the component for the Top Green Strip containing the logo
 *
 * @component
 */
class AppNavbar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // getAuthenticatedData(
    //   sessionStorage.getItem("domain") + getSites + "?paginate=true"
    // ).then(
    //   result => {
    //     this.setState({
    //       sites: result?.data,
    //       isLoaded: true,
    //       prevPage: result?.paging?.prevUrl,
    //       nextPage: result?.paging?.nextUrl
    //     });
    //   },
    //   () => {
    //     this.setState({
    //       isLoaded: true
    //     });
    //   }
    // );
  }
  render() {
    return (
      <Navbar color="#ffffff" expand="md">
        <Nav className="logo-container col-md-12" navbar>
          <img
            className="logos remidio-logo"
            src={RemidioLogo}
            alt="Remidio Logo"
          />
          {/* <img className="logos vfi-logo" src={VFILogo} alt="VFI Logo" /> */}
        </Nav>
      </Navbar>
    );
  }
}

export default AppNavbar;
