/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateCloudSyncSettings } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Data Sync modal in the Sites Page <br>
 * It uses a toggle button from the BootstrapSwitchButton component. <br>
 * It receives an object called `settings` as a prop, which contain the existing Data Sync settings <br>
 *
 *
 * @component
 */
class CustomDataSyncModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: false,
      settings: this.props.settings !== "NONE"
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {}
  /**
   * Listens to changes in the identifier field
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    this.setState({
      identifier: e.target.value
    });
  }
  /**
   * Constructs the object and triggers the API call to submit the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    var data = {
      syncEnabled: this.state.settings
    };
    this.setState({ isLoaded: false, disableButton: true });

    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateCloudSyncSettings +
        "/" +
        this.props.siteId,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(this.t(T_SITES.toast.dataSyncUpdatedSuccessfully));
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner gateway-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SITES.configureDataSync.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SITES.configureDataSync.dataSync)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.settings}
                      onstyle="success"
                      onChange={checked => {
                        this.setState({ settings: checked });
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <p className="data-sync-note">
                {this.t(T_SITES.configureDataSync.note)}
              </p>
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomDataSyncModal);
