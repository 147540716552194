/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateIntegrationConfigs } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Gateway Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomLowSecurityModeModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: false,
      dataQueueEnabled: this.props.dataQueueEnabled,
      gatewayIPPort: this.props.gatewayIPPort,
      imagesWebhookEnabled: this.props.imagesWebhookEnabled,
      doctorReportWebhookEnabled: this.props.doctorReportWebhookEnabled,
      aiReportWebhookEnabled: this.props.aiReportWebhookEnabled,
      webhookURL: this.props.webhookURL || "",
      webhookURLdisabled:
        !this.props.imagesWebhookEnabled &&
        !this.props.doctorReportWebhookEnabled &&
        !this.props.aiReportWebhookEnabled,
      orgId: this.props.orgId,
      ip: "",
      port: ""
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeIP = this.onChangeIP.bind(this);
    this.onChangePort = this.onChangePort.bind(this);
    this.onChangeURL = this.onChangeURL.bind(this);
  }
  componentDidMount() {
    let regex = /^http:\/\/([\d.]+):(\d+)\/?$/;

    // Extract IP address and port using regex
    let match = this.state.gatewayIPPort?.match(regex);

    if (match) {
      this.setState({ ip: match[1] });
      this.setState({ port: match[2] });
    }
  }
  /**
   * Handles changes to the identifier
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    this.setState({
      identifier: e.target.value
    });
  }

  onChangeIP(e) {
    this.setState({ ip: e.target.value });
  }

  onChangePort(e) {
    this.setState({ port: e.target.value });
  }

  onChangeURL(e) {
    this.setState({ webhookURL: e.target.value });
  }
  /**
   * Submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    var data = {
      dataQueueEnabled: this.state.dataQueueEnabled,
      gatewayIPPort: "http://" + this.state.ip + ":" + this.state.port,
      ...(this.state.webhookURL !== "" && {
        webhookSettings: {
          imagesWebhookEnabled: this.state.imagesWebhookEnabled,
          doctorReportWebhookEnabled: this.state.doctorReportWebhookEnabled,
          aiReportWebhookEnabled: this.state.aiReportWebhookEnabled,
          ...(this.state.webhookURL !== "" && {
            webhookURL: this.state.webhookURL
          })
        }
      })
    };
    this.setState({ isLoaded: false, disableButton: true });

    postAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateIntegrationConfigs +
        "/" +
        this.state.orgId,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.t(T_SITES.toast.securitySettingsUpdatedSuccessfully)
          );
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner gateway-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SITES.securitySettings.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SITES.securitySettings.highSecurity)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.dataQueueEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ dataQueueEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_SITES.securitySettings.gatewayIp)}
                  </div>
                  <div className="col-md-4">
                    <FormControl
                      required
                      type="text"
                      name="gatewayIPPort"
                      value={this.state.ip}
                      onChange={this.onChangeIP}
                    />
                  </div>
                  <div className="col-md-2 formfont">
                    {this.t(T_SITES.securitySettings.gatewayPort)}
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      required
                      type="text"
                      name="gatewayIPPort"
                      value={this.state.port}
                      onChange={this.onChangePort}
                    />
                  </div>
                </div>
              </FormGroup>
              <h6 className="modal-heading">
                {this.t(T_SITES.securitySettings.webhookSettings)}
              </h6>
              <br />
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SITES.securitySettings.imagesWebhookEnabled)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.imagesWebhookEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ imagesWebhookEnabled: checked }, () => {
                          if (
                            !this.state.imagesWebhookEnabled &&
                            !this.state.doctorReportWebhookEnabled &&
                            !this.state.aiReportWebhookEnabled
                          ) {
                            this.setState({
                              webhookURL: "",
                              webhookURLdisabled: true
                            });
                          } else {
                            this.setState({ webhookURLdisabled: false });
                          }
                        })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(
                      T_SITES.securitySettings.doctorReportWebhookEnabled
                    )}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.doctorReportWebhookEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState(
                          { doctorReportWebhookEnabled: checked },
                          () => {
                            if (
                              !this.state.imagesWebhookEnabled &&
                              !this.state.doctorReportWebhookEnabled &&
                              !this.state.aiReportWebhookEnabled
                            ) {
                              this.setState({
                                webhookURL: "",
                                webhookURLdisabled: true
                              });
                            } else {
                              this.setState({ webhookURLdisabled: false });
                            }
                          }
                        )
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SITES.securitySettings.aiReportWebhookEnabled)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.aiReportWebhookEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState(
                          { aiReportWebhookEnabled: checked },
                          () => {
                            if (
                              !this.state.imagesWebhookEnabled &&
                              !this.state.doctorReportWebhookEnabled &&
                              !this.state.aiReportWebhookEnabled
                            ) {
                              this.setState({
                                webhookURL: "",
                                webhookURLdisabled: true
                              });
                            } else {
                              this.setState({ webhookURLdisabled: false });
                            }
                          }
                        )
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SITES.securitySettings.webhookUrl)}
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      required
                      type="text"
                      name="webhookURL"
                      value={this.state.webhookURL}
                      disabled={this.state.webhookURLdisabled}
                      onChange={this.onChangeURL}
                    />
                  </div>
                </div>
              </FormGroup>
              {/* <p className="low-security-note">
                {this.t(T_SITES.securitySettings.note)}
              </p> */}
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomLowSecurityModeModal);
