/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "./assets/styles/App.css";
import ForgotPassword from "./components/Login/ForgotPassword";
import Loginusername from "./components/Login/Loginusername";
import LoginPassword from "./components/Login/LoginPassword";
import { Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ProtectedLoginRoute from "./components/Routes/ProtectedLoginRoute";
import WelcomeUser from "./components/User/WelcomeUser";
import UserRegistered from "./components/User/UserRegistered";
import Recover from "./components/Login/Recover";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import EditAdmin from "./components/Dashboard/EditAdmin";
import PatientsDashboard from "./components/Dashboard/PatientsDashboard";
import ServiceDashboard from "./components/Dashboard/ServiceDashboard";
import ServiceAdmins from "./components/Dashboard/ServiceAdmins";
import ServiceSites from "./components/Dashboard/ServiceSites";
import ResetPassword from "./components/Login/ResetPassword";
import VerifyEmail from "./components/Login/VerifyEmail";
import ReverifyAccount from "./components/Login/ReverifyAccount";
import Sites from "./components/Dashboard/Sites";
import Statistics from "./components/Statistics";
import ExamData from "./components/Dashboard/ExamData";
import GraderFlow from "./components/Dashboard/GraderFlow";
import ExamDetails from "./components/Dashboard/ExamDetails";
import PatientDetails from "./components/Dashboard/PatientDetails";
import RedirectHandler from "./components/Login/RedirectHandler";
import PDF from "./components/Dashboard/PDF";
import history from "./utils/history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import { withIdleTimer } from "react-idle-timer";
import { withTranslation } from "react-i18next";
import { T_LOGIN } from "./constants/translations";

class App extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.idleTimer = null;
  }

  onIdle() {
    if (sessionStorage.getItem("lowSecurityModeEnabled") === null) {
      if (sessionStorage.getItem("bearerToken")) {
        sessionStorage.removeItem("bearerToken");
      }
      if (!sessionStorage.getItem("calledLogoutOnce")) {
        // this.t is not a function upon idling
        toast.error(this.t(T_LOGIN.toast.inactivitySignOut));
      }
      sessionStorage.clear();
      sessionStorage.setItem("calledLogoutOnce", true);
      history.push("/login");
    }
  }
  render() {
    return (
      <Router history={history}>
        <Switch>
          <ProtectedLoginRoute
            path="/login"
            exact={true}
            component={Loginusername}
          />
          <ProtectedLoginRoute
            path="/login-password"
            exact={true}
            component={LoginPassword}
          />
          <ProtectedRoute
            path="/edit-admin"
            exact={true}
            component={EditAdmin}
          />
          <Route path="/confirm-account" exact={true} component={WelcomeUser} />
          <Route
            path="/user-registered"
            exact={true}
            component={UserRegistered}
          />
          <ProtectedRoute path="/" exact={true} component={AdminDashboard} />
          <ProtectedRoute
            path="/patients-dashboard"
            exact={true}
            component={PatientsDashboard}
          />
          <ProtectedRoute path="/sites" exact={true} component={Sites} />
          <ProtectedRoute
            path="/service-dashboard"
            exact={true}
            component={ServiceDashboard}
          />
          <ProtectedRoute
            path="/service-admins"
            exact={true}
            component={ServiceAdmins}
          />
          <ProtectedRoute
            path="/service-sites"
            exact={true}
            component={ServiceSites}
          />
          <ProtectedRoute
            path="/statistics"
            exact={true}
            component={Statistics}
          />
          <ProtectedRoute path="/exam-data" exact={true} component={ExamData} />
          <ProtectedRoute
            path="/exam-details"
            exact={true}
            component={ExamDetails}
          />
          <ProtectedRoute
            path="/patient-details"
            exact={true}
            component={PatientDetails}
          />
          <ProtectedRoute path="/pdf" exact={true} component={PDF} />
          <Route
            path="/forgot-password"
            exact={true}
            component={ForgotPassword}
          />
          <Route path="/recover" exact={true} component={Recover} />
          <Route
            path="/reset-password"
            exact={true}
            component={ResetPassword}
          />
          <Route path="/verify-email" exact={true} component={VerifyEmail} />
          <Route
            path="/reverify-account"
            exact={true}
            component={ReverifyAccount}
          />
          <Route path="/grader-flow" exact={true} component={GraderFlow} />
          <Route
            path="/redirect-handler"
            exact={true}
            component={RedirectHandler}
          />
        </Switch>
      </Router>
    );
  }
}

export default withTranslation()(withIdleTimer(App));
