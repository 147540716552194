/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import { Button } from "react-bootstrap";
import CustomGatewaySettingsModal from "./CustomGatewaySettingsModal";
import CustomLowSecurityModeModal from "./CustomLowSecurityModeModal";
import CustomDataSyncModal from "./CustomDataSyncModal";
import CustomEMRPacsModal from "./CustomEMRPacsModal";
import {
  getSites,
  getUser,
  uploadSiteLogo,
  getSiteLogo,
  getSecurityMode,
  getUserAndOrg
} from "../../constants/endpoints";
import { getAuthenticatedData, postAuthenticatedData } from "../../utils/async";
import FsLightbox from "fslightbox-react";
// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_PAGINATION, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Sites page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.17, 5.3.14.24 <br>
 * PSL - 5.3.14.17, 5.3.14.24 <br>
 * FOP - 5.3.14.17, 5.3.14.25 <br>
 *
 * @component
 */
class Sites extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: false,
      isTableLoading: true,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      prevPage: null,
      nextPage: null,
      pageNumber: 1,
      searchValue: "",
      sites: null,
      images: [],
      toggler: false,
      suggestions: [],
      siteId: 0,
      showGatewaySettingsModal: false,
      showDataSyncModal: false,
      showEMRPacsModal: false,
      dataSyncSettings: {},
      lowSecurityModeEnabled: false,
      showLowSecurityModeModal: false,
      orgId: 0,
      gatewayIPPort: "",
      dataQueueEnabled: false,
      imagesWebhookEnabled: false,
      doctorReportWebhookEnabled: false,
      aiReportWebhookEnabled: false,
      webhookURL: ""
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.toBase64 = this.toBase64.bind(this);
    this.viewImages = this.viewImages.bind(this);
    this.openGatewayModal = this.openGatewayModal.bind(this);
    this.closeGatewayModal = this.closeGatewayModal.bind(this);
    this.openDataSyncModal = this.openDataSyncModal.bind(this);
    this.closeDataSyncModal = this.closeDataSyncModal.bind(this);
    this.resetList = this.resetList.bind(this);
    this.openEMRPacsModal = this.openEMRPacsModal.bind(this);
    this.closeEMRPacsModal = this.closeEMRPacsModal.bind(this);
    this.openLowSecurityModeModal = this.openLowSecurityModeModal.bind(this);
    this.closeLowSecurityModeModal = this.closeLowSecurityModeModal.bind(this);
  }
  /**
   * Loads all the sites on component mount
   *
   *
   *
   */
  componentDidMount() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSites + "?paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSecurityMode
    ).then(result => {
      this.setState({
        lowSecurityModeEnabled: result.data.lowSecurityModeEnabled
      });
    });

    getAuthenticatedData(sessionStorage.getItem("domain") + getUserAndOrg).then(
      result => {
        this.setState({
          orgId: result?.data?.orgAndSiteResponse?.orgId,
          dataQueueEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.dataQueueEnabled,
          imagesWebhookEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.webhookSettings?.imagesWebhookEnabled,
          doctorReportWebhookEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.webhookSettings?.doctorReportWebhookEnabled,
          aiReportWebhookEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.webhookSettings?.aiReportWebhookEnabled,
          webhookURL:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.webhookSettings?.webhookURL,
          gatewayIPPort:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings?.gatewayIPPort
        });
      }
    );
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  /**
   * Resets the list (triggers the site API call again)
   *
   *
   *
   */
  resetList() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSites + "?paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );

    getAuthenticatedData(sessionStorage.getItem("domain") + getUserAndOrg).then(
      result => {
        this.setState({
          orgId: result?.data?.orgAndSiteResponse?.orgId,
          dataQueueEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.dataQueueEnabled,
          imagesWebhookEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.imagesWebhookEnabled,
          doctorReportWebhookEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.doctorReportWebhookEnabled,
          aiReportWebhookEnabled:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings
              ?.aiReportWebhookEnabled,
          webhookURL:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings?.webhookURL,
          gatewayIPPort:
            result?.data?.orgAndSiteResponse?.orgGatewaySettings?.gatewayIPPort
        });
      }
    );
  }
  /**
   * Converts an image to base64
   * @param {file} the image file
   * @returns {promise} promise that resolves to a base64 string
   *
   *
   */
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = error => reject(error);
    });
  }
  /**
   * Opens the gallery to view the site logo
   *
   * @param {string} image image url
   * @param {siteId} number site ID
   *
   */

  openEMRPacsModal() {
    this.setState({ showEMRPacsModal: true });
  }
  closeEMRPacsModal() {
    this.setState({ showEMRPacsModal: false });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSecurityMode
    ).then(result => {
      this.setState({
        lowSecurityModeEnabled: result.data.lowSecurityModeEnabled
      });
    });
  }

  openLowSecurityModeModal() {
    this.setState({ showLowSecurityModeModal: true });
  }
  closeLowSecurityModeModal() {
    this.setState({ showLowSecurityModeModal: false });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSecurityMode
    ).then(result => {
      this.setState({
        lowSecurityModeEnabled: result.data.lowSecurityModeEnabled
      });
    });
  }

  viewImages(siteId) {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSiteLogo + "/" + siteId
    ).then(
      result => {
        this.setState({ images: [result?.data], siteId }, () => {
          this.setState({ toggler: !this.state.toggler });
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  /**
   * Opens the data sync modal
   * @param {boolean} aim aim data sync status
   * @param {boolean} fop fop data sync status
   * @param {boolean} psl psl data sync status
   * @param {number} siteId site ID
   *
   *
   */
  openDataSyncModal(setting, siteId) {
    this.setState({
      showDataSyncModal: true,
      dataSyncSettings: setting,
      dataSyncSite: siteId
    });
  }
  /**
   * Closes the Data Sync Modal
   *
   *
   *
   */
  closeDataSyncModal() {
    this.setState({ showDataSyncModal: false });
  }

  /**
   * Opens the Gateway Modal
   * @param {object} gatewaySettings Gateway Settings
   * @param {number} siteId Site ID
   *
   *
   *
   */
  openGatewayModal(gatewaySettings, siteId, dataStorageLocation) {
    this.setState({
      showGatewaySettingsModal: true,
      gatewaySettings,
      gatewaySite: siteId,
      dataStorageLocation: dataStorageLocation
    });
  }

  closeGatewayModal() {
    this.setState({ showGatewaySettingsModal: false });
  }

  /**
   * Triggers the API call to upload an image and handles failure cases
   *
   *
   *
   */
  async uploadImage(e) {
    e.persist();
    const file = e.target.files[0];
    let imageReg = /[/.](gif|jpg|jpeg|tiff|png)$/i;
    if (imageReg.test(file.name)) {
      let data = {
        base64: await this.toBase64(file)
      };
      postAuthenticatedData(
        sessionStorage.getItem("domain") +
          uploadSiteLogo +
          "/" +
          e?.target?.name,
        data
      ).then(
        result => {
          if (result.status === 200) {
            getAuthenticatedData(
              sessionStorage.getItem("domain") + getSites + "?paginate=true"
            ).then(
              result => {
                this.setState({
                  sites: result?.data,
                  isLoaded: true,
                  prevPage: result?.paging?.prevUrl,
                  nextPage: result?.paging?.nextUrl,
                  pageNumber: 1
                });
                this.handleSearch(this.state.searchValue);
              },
              () => {
                this.setState({
                  isLoaded: true
                });
              }
            );
            toast.success(this.t(T_SITES.toast.uploadedImageSuccessfully));
          } else toast.error(this.t(T_GENERAL.somethingWentWrong));
        },
        () => {
          toast.error(this.t(T_GENERAL.somethingWentWrong));
          this.setState({
            isLoaded: true
          });
        }
      );
    } else {
      toast.error(this.t(T_SITES.toast.unsupportedFileFormat));
    }
  }

  goToPreviousPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.prevPage
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber - 1
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  goToNextPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.nextPage
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber + 1
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleClear() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getSites + "?paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1,
          searchValue: ""
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleSearch(value) {
    if (value) {
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getSites +
          "?paginate=true&search=" +
          value
      ).then(
        result => {
          this.setState({
            sites: result?.data,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            pageNumber: 1,
            searchValue: value
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}

  render() {
    const { isLoaded, sites, toggler, images } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="2" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                <div>
                  <Button
                    className="green-button"
                    onClick={this.openLowSecurityModeModal}
                  >
                    {this.t(T_SITES.realSecuritySettingsButton)}
                  </Button>
                  <Button
                    className="green-button"
                    onClick={this.openEMRPacsModal}
                  >
                    {this.t(T_SITES.securitySettingsButton)}
                  </Button>

                  {/* {this.state.showNotificationSettingsModal ? (
                    <CustomLowSecurityModeModal
                      closePopup={this.closeNotificationSettingsModal}
                      resetList={this.resetTelemedList}
                    />
                  ) : null} */}
                  {this.state.showPopup ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.togglePopup}
                      resetList={this.resetList}
                    />
                  ) : null}
                </div>
              </div>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder={this.t(T_SITES.searchSites)}
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
              />
              {(() => {
                if (this.state.isTableLoading) {
                  return (
                    <div className="table-error">
                      <p>
                        <img src={require("../../assets/images/loading.gif")} />
                      </p>
                    </div>
                  );
                } else if (!sites || sites.length === 0) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_SITES.table.site)}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {sites?.map?.(item => (
                            <tr key={item.siteId}>
                              <td id={"dName" + item.userId}>
                                {item.siteName}
                              </td>
                              {(() => {
                                if (item?.hasLogo) {
                                  return (
                                    <td>
                                      <a
                                        className="editbtn"
                                        onClick={() =>
                                          this.viewImages(item?.siteId)
                                        }
                                      >
                                        {this.t(T_SITES.table.viewSiteLogo)}
                                      </a>
                                    </td>
                                  );
                                } else {
                                  return <td>-</td>;
                                }
                              })()}
                              <td className="image-upload">
                                <input
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  name={item.siteId}
                                  onChange={this.uploadImage}
                                />
                                <a>{this.t(T_SITES.table.uploadSiteLogo)}</a>
                              </td>
                              {/* <td>
                                <a
                                  className="editbtn"
                                  onClick={() =>
                                    this.openDataSyncModal(
                                      item.dataStorageLocation,
                                      item.siteId
                                    )
                                  }
                                >
                                  {this.t(T_SITES.table.configureDataSync)}
                                </a>
                              </td> */}
                              <td>
                                <a
                                  className="editbtn"
                                  onClick={() =>
                                    this.openGatewayModal(
                                      item.gatewaySettings,
                                      item.siteId,
                                      item.dataStorageLocation
                                    )
                                  }
                                >
                                  {this.t(T_SITES.table.configureGateway)}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; {this.t(T_PAGINATION.previous)}</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          let startRow = this.state.pageNumber * 10 - 9;
                          let endRow = startRow + sites.length - 1;
                          return (
                            <span className="rowcount-text">
                              {this.t(T_PAGINATION.showingRows, {
                                from: startRow,
                                to: endRow
                              })}
                            </span>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>{this.t(T_PAGINATION.next)} &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showGatewaySettingsModal ? (
                <CustomGatewaySettingsModal
                  closePopup={this.closeGatewayModal}
                  resetList={this.resetList}
                  gatewaySettings={this.state.gatewaySettings}
                  siteId={this.state.gatewaySite}
                  dataStorageLocation={this.state.dataStorageLocation}
                />
              ) : null}
              {this.state.showEMRPacsModal ? (
                <CustomEMRPacsModal
                  closePopup={this.closeEMRPacsModal}
                  resetList={this.resetList}
                  orgId={this.state.orgId}
                  dataQueueEnabled={this.state.dataQueueEnabled}
                  gatewayIPPort={this.state.gatewayIPPort}
                  imagesWebhookEnabled={this.state.imagesWebhookEnabled}
                  doctorReportWebhookEnabled={
                    this.state.doctorReportWebhookEnabled
                  }
                  aiReportWebhookEnabled={this.state.aiReportWebhookEnabled}
                  webhookURL={this.state.webhookURL}
                />
              ) : null}
              {this.state.showLowSecurityModeModal ? (
                <CustomLowSecurityModeModal
                  closePopup={this.closeLowSecurityModeModal}
                  resetList={this.resetList}
                  lowSecurityModeEnabled={this.state.lowSecurityModeEnabled}
                />
              ) : null}
              {this.state.showDataSyncModal ? (
                <CustomDataSyncModal
                  closePopup={this.closeDataSyncModal}
                  resetList={this.resetList}
                  settings={this.state.dataSyncSettings}
                  siteId={this.state.dataSyncSite}
                />
              ) : null}
              <FsLightbox
                toggler={toggler}
                sources={images}
                type="image"
                key={this.state.siteId}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(Sites);
