/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateExamMetadata } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import { withTranslation } from "react-i18next";
import { T_EXAMS, T_GENERAL } from "../../constants/translations";

/**
 * This is a common component that handles editing of the ocular history, <br>
 * systemic history, current medication and allergies from the Exam Details page.
 *
 * The attribute to be edited is passed as a prop called `attr`.
 * SRS IDs: <br>
 * AIM - 5.3.14.29 <br>
 * PSL - 5.3.14.29 <br>
 *
 * @component
 */
class CustomEditExamDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.textarea = React.createRef();
    this.state = {
      isLoaded: true,
      newValue: this.props[this.props.attr + "Edited"]
        ? this.props[this.props.attr + "EditedValue"]
        : this.props.selectedAttribute?.currentValue,
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  /**
   * This method focuses the cursor at the end of the text field if a value already exists
   */
  componentDidMount() {
    let element = this.textarea.current;
    element.focus();
    element.setSelectionRange(element.value.length, element.value.length);
  }
  /**
   * Updates state when the field value changes
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    this.setState({
      newValue: e.target.value
    });
  }
  /**
   * Submits the firm and triggers the API call
   *
   *
   */
  onSubmit() {
    var data = { newValue: this.state.newValue };
    this.setState({ isLoaded: false, disableButton: true });

    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateExamMetadata +
        "/" +
        this.props.examId +
        "/" +
        this.props.selectedAttribute?.enum,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.props.selectedAttribute?.text +
              this.t(T_EXAMS.toast.editedSuccessfully)
          );
          this.props.closePopup();
          this.props.updateValue(this.props.attr, this.state.newValue);
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner custommodaleditexamdetails">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_GENERAL.edit)} {this.props.selectedAttribute?.text}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <textarea
                    name="field"
                    onChange={this.onChange}
                    value={this.state.newValue}
                    ref={this.textarea}
                  ></textarea>
                </div>
              </FormGroup>

              <Button
                onClick={this.onSubmit}
                className="loginbtn1 create-account-btn create-org-btn"
                disabled={this.state.disableButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomEditExamDetailsModal);
