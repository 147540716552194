/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Chart from "react-apexcharts";
import CustomStatisticsTableModal from "./CustomStatisticsTableModal";
import CustomStatisticsChartModal from "./CustomStatisticsChartModal";
import { getShortMonth } from "../../utils/date";
import { withTranslation } from "react-i18next";
import { T_STATISTICS } from "../../constants/translations";

/**
 * The component for the Graded Exams tab of the statistics page. It uses react-apexcharts to render the graphs.
 *
 * @component
 */
class GradedExams extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      page: 1,
      showStatisticsTableModal: false,
      showTable: true,
      index: 0
    };

    this.goToNextPage = this.goToNextPage.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.openStatisticsTableModal = this.openStatisticsTableModal.bind(this);
    this.closeStatisticsTableModal = this.closeStatisticsTableModal.bind(this);
    this.openStatisticsChartModal = this.openStatisticsChartModal.bind(this);
    this.closeStatisticsChartModal = this.closeStatisticsChartModal.bind(this);
  }

  componentDidMount() {}

  openStatisticsTableModal() {
    this.setState({ showStatisticsTableModal: true });
  }

  closeStatisticsTableModal() {
    this.setState({ showStatisticsTableModal: false });
  }

  openStatisticsChartModal(showTable, index) {
    this.setState({ showStatisticsChartModal: true, showTable, index });
  }

  closeStatisticsChartModal() {
    this.setState({ showStatisticsChartModal: false });
  }

  goToPreviousPage() {
    this.setState({ page: 1 });
  }

  goToNextPage() {
    this.setState({ page: 2 });
  }

  render() {
    let diseases;
    if (this.props.siteRadio === "specific-site") {
      diseases = this.props.analytics.siteCounters
        .find(el => el.siteName === this.props.selectedSite)
        .cat2Counters.map(el => ({
          categoryName: el.name,
          diseases: this.props.analytics.siteCounters
            .find(el => el.siteName === this.props.selectedSite)
            .diseaseCounters.map(el => ({
              name: el.name,
              count: el.count,
              icd10: el.icd10
            }))
            .filter(filterEl => {
              let cat2Code = el.cat2Code;
              let obj = this.props.diseases?.filter(
                dis => dis.categoryCode === cat2Code
              )[0];
              return obj.diseases.find(some => some.icd10 === filterEl.icd10);
            })
        }));
    } else {
      diseases = this.props.analytics.cat2Counters.map(el => ({
        categoryName: el.name,
        diseases: this.props.analytics.diseaseCounters
          .map(el => ({ name: el.name, count: el.count, icd10: el.icd10 }))
          .filter(filterEl => {
            let cat2Code = el.cat2Code;
            let obj = this.props.diseases?.filter(
              dis => dis.categoryCode === cat2Code
            )[0];
            return obj.diseases.find(some => some.icd10 === filterEl.icd10);
          })
      }));
    }

    let siteName = this.props.selectedSite;
    let siteCounters = this.props.analytics.siteCounters;
    let index;
    for (let i = 0; i < siteCounters.length; i++) {
      if (siteCounters[i].siteName === siteName) {
        index = i;
        break;
      }
    }
    let sevenDayOptions = siteCounters[index]?.dailySiteCounters?.map(el => {
      return [
        new Date(el.date).getDate() +
          " " +
          getShortMonth(new Date(el.date).getMonth())
      ];
    });
    let allSiteExamSeries = [
      {
        name: this.t(T_STATISTICS.gradedExams),
        data: [...this.props.analytics.siteCounters]
          ?.sort((a, b) => b.totalReports - a.totalReports)
          .map(el => el.totalReports)
      }
    ];
    let sevenDaySpecificSiteSeries = [
      {
        name: this.t(T_STATISTICS.gradedExams),
        data: siteCounters[index]?.dailySiteCounters
          // ?.sort((a, b) => b.totalReports - a.totalReports)
          .map(el => el.totalReports)
      }
    ];
    let allSiteExamOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
        height: 350
      },
      colors: ["#25c881"],
      grid: { show: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          vertical: true,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        labels: {
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        },
        categories: [...this.props.analytics.siteCounters]
          ?.sort((a, b) => b.totalReports - a.totalReports)
          .map(el => el.siteName)
      }
    };
    let allSiteDiagnosisOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
        events: {
          click: (event, chartContext, config) => {
            // eslint-disable-next-line no-console
            console.log(event, chartContext, config);
            this.openStatisticsChartModal(false, config.dataPointIndex);
          }
        }
      },
      colors: ["#25c881"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          vertical: true,
          borderRadius: 10,
          ...((this.props.siteRadio === "specific-site"
            ? this.props.analytics.siteCounters
                .find(el => el.siteName === this.props.selectedSite)
                .cat2Counters.sort((a, b) => b.count - a.count)
                .map(el => el.name)
                .slice(0, 10).length === 1
            : this.props.analytics.cat2Counters
                .sort((a, b) => b.count - a.count)
                .map(el => el.name)
                .slice(0, 10).length === 1) && { columnWidth: "20%" })
        }
      },
      grid: { show: false },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        type: "category",
        tickPlacement: "between",
        labels: {
          show: true,
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        },
        categories:
          this.props.siteRadio === "specific-site"
            ? this.props.analytics.siteCounters
                .find(el => el.siteName === this.props.selectedSite)
                .cat2Counters.sort((a, b) => b.count - a.count)
                .map(el => el.name)
                .slice(0, 10)
            : this.props.analytics.cat2Counters
                .sort((a, b) => b.count - a.count)
                .map(el => el.name)
                .slice(0, 10)
      }
    };
    let allSiteDiagnosisSeries =
      this.props.siteRadio === "specific-site"
        ? [
            {
              name: this.t(T_STATISTICS.gradedExams),
              data: this.props.analytics.siteCounters
                .find(el => el.siteName === this.props.selectedSite)
                .cat2Counters.map(el => el.count)
                .sort((a, b) => b - a)
                .slice(0, 10)
            }
          ]
        : [
            {
              name: this.t(T_STATISTICS.gradedExams),
              data: this.props.analytics.cat2Counters
                .map(el => el.count)
                .sort((a, b) => b - a)
                .slice(0, 10)
            }
          ];
    let sevenDaySpecificSiteOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
        height: 350
      },
      colors: ["#25c881"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          vertical: true,
          borderRadius: 10
        }
      },
      grid: { show: false },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        categories: sevenDayOptions,
        labels: {
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        }
      }
    };
    // let thirtyDaySpecificSiteOptions = {
    //   chart: {
    //     type: "bar",
    //     toolbar: {
    //       show: false
    //     },
    //     height: "500px"
    //   },
    //   tooltip: {
    //     theme: "dark",
    //     x: {
    //       show: false
    //     },
    //     y: {
    //       title: {
    //         formatter: function() {
    //           return "";
    //         }
    //       }
    //     }
    //   },
    //   colors: ["#82D0A1"],
    //   plotOptions: {
    //     bar: {
    //       vertical: true
    //     }
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   xaxis: {
    //     categories: sevenDayOptions
    //   }
    // };
    // let thirtyDaySpecificSiteSeries = [
    //   {
    //     data: [
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       32,
    //       11
    //     ]
    //   }
    // ];
    if (this.props.siteRadio === "all-sites") {
      return (
        <div className="graph-container">
          <div className="title-container">
            {(() => {
              if (this.state.page === 1) {
                return (
                  <div className="previous disabled">
                    <img src={require("../../assets/images/left-arrow.svg")} />
                  </div>
                );
              } else {
                return (
                  <div className="previous" onClick={this.goToPreviousPage}>
                    <img src={require("../../assets/images/left-arrow.svg")} />
                  </div>
                );
              }
            })()}
            {(() => {
              if (this.props.timelineRadio === "last-seven-days") {
                if (this.state.page === 1) {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.gradedExamsInLastDays, { count: 7 })}
                    </div>
                  );
                } else {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.topDiagnosesInLastDays, {
                        count: 7
                      })}
                    </div>
                  );
                }
              } else if (this.props.timelineRadio === "last-thirty-days") {
                if (this.state.page === 1) {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.gradedExamsInLastDays, {
                        count: 30
                      })}
                    </div>
                  );
                } else {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.topDiagnosesInLastDays, {
                        count: 30
                      })}
                    </div>
                  );
                }
              } else {
                if (this.state.page === 1) {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.gradedExamsInMonth, {
                        month: this.props.month
                      })}
                    </div>
                  );
                } else {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.topDiagnosesInMonth, {
                        month: this.props.month
                      })}
                    </div>
                  );
                }
              }
            })()}
            {(() => {
              if (this.state.page === 1) {
                return (
                  <div className="next" onClick={this.goToNextPage}>
                    <img src={require("../../assets/images/right-arrow.svg")} />
                  </div>
                );
              } else {
                return (
                  <div className="next disabled">
                    <img src={require("../../assets/images/right-arrow.svg")} />
                  </div>
                );
              }
            })()}
          </div>
          {(() => {
            if (this.state.page === 1) {
              if (
                allSiteExamSeries[0].data.reduce((acc, val) => acc + val, 0) ===
                0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="chart-container">
                  <div className="chart">
                    <Chart
                      key={9}
                      options={allSiteExamOptions}
                      series={allSiteExamSeries}
                      type="bar"
                      height="300"
                    ></Chart>
                  </div>
                </div>
              );
            } else {
              if (
                allSiteDiagnosisSeries[0].data.reduce(
                  (acc, val) => acc + val,
                  0
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="chart-container">
                  <div className="chart">
                    <Chart
                      key={10}
                      options={allSiteDiagnosisOptions}
                      series={allSiteDiagnosisSeries}
                      type="bar"
                      height="300"
                    ></Chart>
                  </div>
                </div>
              );
            }
          })()}
          {(() => {
            if (this.state.page === 1) {
              return (
                <div className="see-more-container">
                  <button onClick={this.openStatisticsTableModal}>
                    {this.t(T_STATISTICS.seeMoreButton)}
                  </button>
                </div>
              );
            } else {
              return (
                <div className="see-more-container">
                  <button onClick={() => this.openStatisticsChartModal(true)}>
                    {this.t(T_STATISTICS.seeMoreButton)}
                  </button>
                </div>
              );
            }
          })()}

          {this.state.showStatisticsTableModal ? (
            <CustomStatisticsTableModal
              closePopup={this.closeStatisticsTableModal}
              analytics={this.props.analytics}
              month={this.props.month}
              selectedSite={this.props.selectedSite}
              siteRadio={this.props.siteRadio}
              timelineRadio={this.props.timelineRadio}
            />
          ) : null}
          {this.state.showStatisticsChartModal ? (
            <CustomStatisticsChartModal
              closePopup={this.closeStatisticsChartModal}
              analytics={this.props.analytics}
              table={this.state.showTable}
              chartNumber={this.state.index + 1}
              diseases={diseases}
              month={this.props.month}
              selectedSite={this.props.selectedSite}
              siteRadio={this.props.siteRadio}
              timelineRadio={this.props.timelineRadio}
            />
          ) : null}
        </div>
      );
    } else if (this.props.siteRadio === "specific-site") {
      return (
        <div className="graph-container">
          <div className="title-container">
            {(() => {
              if (this.state.page === 1) {
                return (
                  <div className="previous disabled">
                    <img src={require("../../assets/images/left-arrow.svg")} />
                  </div>
                );
              } else {
                return (
                  <div className="previous" onClick={this.goToPreviousPage}>
                    <img src={require("../../assets/images/left-arrow.svg")} />
                  </div>
                );
              }
            })()}
            {(() => {
              if (this.props.timelineRadio === "last-seven-days") {
                if (this.state.page === 1) {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.gradedExamsInLastDays, { count: 7 })}
                    </div>
                  );
                } else {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.topDiagnosesInLastDays, {
                        count: 7
                      })}
                    </div>
                  );
                }
              } else if (this.props.timelineRadio === "last-thirty-days") {
                if (this.state.page === 1) {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.gradedExamsInLastDays, {
                        count: 30
                      })}
                    </div>
                  );
                } else {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.topDiagnosesInLastDays, {
                        count: 30
                      })}
                    </div>
                  );
                }
              } else {
                if (this.state.page === 1) {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.gradedExamsInMonth, {
                        month: this.props.month
                      })}
                    </div>
                  );
                } else {
                  return (
                    <div className="chart-title paginated">
                      {this.t(T_STATISTICS.topDiagnosesInMonth, {
                        month: this.props.month
                      })}
                    </div>
                  );
                }
              }
            })()}
            {(() => {
              if (this.state.page === 1) {
                return (
                  <div className="next" onClick={this.goToNextPage}>
                    <img src={require("../../assets/images/right-arrow.svg")} />
                  </div>
                );
              } else {
                return (
                  <div className="next disabled">
                    <img src={require("../../assets/images/right-arrow.svg")} />
                  </div>
                );
              }
            })()}
          </div>
          {(() => {
            if (this.state.page === 1) {
              if (this.props.timelineRadio === "last-seven-days") {
                if (
                  sevenDaySpecificSiteSeries[0].data.reduce(
                    (acc, val) => acc + val,
                    0
                  ) === 0
                ) {
                  return (
                    <div className="chart-container">
                      <div className="chart no-results-container">
                        <img
                          src={require("../../assets/images/no-results.svg")}
                        />
                        <div className="no-results">
                          {this.t(T_STATISTICS.noResultsFound)}
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="chart-container">
                    <div className="chart">
                      <Chart
                        key={11}
                        options={sevenDaySpecificSiteOptions}
                        series={sevenDaySpecificSiteSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                );
              } else if (this.props.timelineRadio === "last-thirty-days") {
                if (
                  sevenDaySpecificSiteSeries[0].data.reduce(
                    (acc, val) => acc + val,
                    0
                  ) === 0
                ) {
                  return (
                    <div className="chart-container">
                      <div className="chart no-results-container">
                        <img
                          src={require("../../assets/images/no-results.svg")}
                        />
                        <div className="no-results">
                          {this.t(T_STATISTICS.noResultsFound)}
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="chart-container">
                    <div className="chart thirty">
                      <Chart
                        key={12}
                        options={sevenDaySpecificSiteOptions}
                        series={sevenDaySpecificSiteSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                );
              } else if (this.props.timelineRadio === "specific-month") {
                if (
                  sevenDaySpecificSiteSeries[0].data.reduce(
                    (acc, val) => acc + val,
                    0
                  ) === 0
                ) {
                  return (
                    <div className="chart-container">
                      <div className="chart no-results-container">
                        <img
                          src={require("../../assets/images/no-results.svg")}
                        />
                        <div className="no-results">
                          {this.t(T_STATISTICS.noResultsFound)}
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="chart-container">
                    <div className="chart thirty">
                      <Chart
                        key={5}
                        options={sevenDaySpecificSiteOptions}
                        series={sevenDaySpecificSiteSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                );
              }
            } else {
              if (
                allSiteDiagnosisSeries[0].data.reduce(
                  (acc, val) => acc + val,
                  0
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <>
                  <div className="chart-container">
                    <div className="chart">
                      <Chart
                        key={2}
                        options={allSiteDiagnosisOptions}
                        series={allSiteDiagnosisSeries}
                        type="bar"
                        height="300"
                      ></Chart>
                    </div>
                  </div>
                  <div className="see-more-container">
                    <button onClick={() => this.openStatisticsChartModal(true)}>
                      {this.t(T_STATISTICS.seeMoreButton)}
                    </button>
                  </div>
                </>
              );
            }
          })()}
          {this.state.showStatisticsChartModal ? (
            <CustomStatisticsChartModal
              closePopup={this.closeStatisticsChartModal}
              analytics={this.props.analytics}
              table={this.state.showTable}
              chartNumber={this.state.index + 1}
              diseases={diseases}
              siteRadio={this.props.siteRadio}
              selectedSite={this.props.selectedSite}
              month={this.props.month}
              timelineRadio={this.props.timelineRadio}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default withTranslation()(GradedExams);
