/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { createExam } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_GENERAL,
  T_PATIENTS
} from "../../constants/translations";

/**
 * This is the component for the Add Exam modal on the Patient Details page. <br>
 * It uses the FormGroup component of React Bootstrap in order to construct the form. <br>
 * It takes a prop named `device` which contains the device type selected in the parent component. <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.3 <br>
 * PSL - 5.3.14.3 <br>
 * FOP - 5.3.14.3 <br>
 *
 * @component
 */
class CustomAddExamModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableFirstButton: true,
      startDate: new Date().getTime(),
      editDeviceType: [this.props.device],
      examCustomId: "",
      doctorfirstname: "",
      doctorlastname: "",
      doctoremail: "",
      medicalhistory: "",
      ocularhistory: "",
      currentmedication: "",
      allergies: "",
      leftucva: "",
      rightucva: "",
      leftsph: "",
      rightsph: "",
      leftcyl: "",
      rightcyl: "",
      leftaxis: "",
      rightaxis: "",
      leftbcva: "",
      rightbcva: "",
      leftadd: "",
      rightadd: "",
      leftiop: "",
      rightiop: "",
      leftpg: "",
      rightpg: "",
      activePage: 1
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
    this.onFirstPageProceed = this.onFirstPageProceed.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   * Goes to the second page of the modal
   */
  onFirstPageProceed() {
    this.setState({ activePage: 2 });
  }
  /**
   * Goes to the first page of the modal
   */
  goToFirstPage() {
    this.setState({ activePage: 1 });
  }
  /**
   * Gets triggered when any of the text fields in the form are changed
   *
   * @param {object} e      the event listener
   */
  onChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          disableFirstButton:
            this.state.editDeviceType.length === 0 ||
            this.state.doctorfirstname === "" ||
            this.state.doctoremail === ""
        });
      }
    );
  }
  /**
   * Handles changes to the device selection in the form
   *
   *
   * @param {object} e      event listener
   */
  handleChange(e) {
    switch (e.currentTarget.name) {
      case "editfop":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "FOP"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "FOP"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editpsl":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "PSL"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "PSL"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editaim":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "AIM"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "AIM"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editinstaref":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "INSTAREF"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "INSTAREF"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editpristine":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "PRISTINE"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: ["PRISTINE"]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editother":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "OTHER"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "OTHER"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.editDeviceType.length === 0 ||
                  this.state.doctorfirstname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
    }
  }
  /**
   * Constructs the object to be sent in the API call and submits the form <br>
   * Left and Right Eye values are optional, so there are checks in place <br>
   * to decide whether to send them
   *
   */
  onSubmit() {
    let devices = [];
    for (let el of this.state.editDeviceType) {
      devices.push(el);
    }
    let optionalStuff = {};
    if (this.state.medicalhistory !== "") {
      optionalStuff.medicalHistory = this.state.medicalhistory;
    }
    if (this.state.ocularhistory !== "") {
      optionalStuff.ocularHistory = this.state.ocularhistory;
    }
    if (this.state.currentmedication !== "") {
      optionalStuff.currentMedication = this.state.currentmedication;
    }
    if (this.state.allergies !== "") {
      optionalStuff.allergies = this.state.allergies;
    }
    if (
      this.state.leftucva !== "" ||
      this.state.leftpg !== "" ||
      this.state.leftsph !== "" ||
      this.state.leftcyl !== "" ||
      this.state.leftaxis !== "" ||
      this.state.leftbcva !== "" ||
      this.state.leftadd !== "" ||
      this.state.leftiop !== ""
    ) {
      optionalStuff.leftEyeVisionAssessment = {};
    }
    if (this.state.leftucva !== "") {
      optionalStuff.leftEyeVisionAssessment.ucva = this.state.leftucva;
    }
    if (this.state.leftpg !== "") {
      optionalStuff.leftEyeVisionAssessment.visionWithPG = this.state.leftpg;
    }
    if (this.state.leftsph !== "") {
      optionalStuff.leftEyeVisionAssessment.sph = this.state.leftsph;
    }
    if (this.state.leftcyl !== "") {
      optionalStuff.leftEyeVisionAssessment.cyl = this.state.leftcyl;
    }
    if (this.state.leftaxis !== "") {
      optionalStuff.leftEyeVisionAssessment.axis = this.state.leftaxis;
    }
    if (this.state.leftbcva !== "") {
      optionalStuff.leftEyeVisionAssessment.bcva = this.state.leftbcva;
    }
    if (this.state.leftadd !== "") {
      optionalStuff.leftEyeVisionAssessment.nearAdd = this.state.leftadd;
    }
    if (this.state.leftiop !== "") {
      optionalStuff.leftEyeVisionAssessment.iop = this.state.leftiop;
    }
    if (
      this.state.rightucva !== "" ||
      this.state.rightpg !== "" ||
      this.state.rightsph !== "" ||
      this.state.rightcyl !== "" ||
      this.state.rightaxis !== "" ||
      this.state.rightbcva !== "" ||
      this.state.rightadd !== "" ||
      this.state.rightiop !== ""
    ) {
      optionalStuff.rightEyeVisionAssessment = {};
    }
    if (this.state.rightucva !== "") {
      optionalStuff.rightEyeVisionAssessment.ucva = this.state.rightucva;
    }
    if (this.state.rightpg !== "") {
      optionalStuff.rightEyeVisionAssessment.visionWithPG = this.state.rightpg;
    }
    if (this.state.rightsph !== "") {
      optionalStuff.rightEyeVisionAssessment.sph = this.state.rightsph;
    }
    if (this.state.rightcyl !== "") {
      optionalStuff.rightEyeVisionAssessment.cyl = this.state.rightcyl;
    }
    if (this.state.rightaxis !== "") {
      optionalStuff.rightEyeVisionAssessment.axis = this.state.rightaxis;
    }
    if (this.state.rightbcva !== "") {
      optionalStuff.rightEyeVisionAssessment.bcva = this.state.rightbcva;
    }
    if (this.state.rightadd !== "") {
      optionalStuff.rightEyeVisionAssessment.nearAdd = this.state.rightadd;
    }
    if (this.state.rightiop !== "") {
      optionalStuff.rightEyeVisionAssessment.iop = this.state.rightiop;
    }
    if (this.state.examCustomId !== "") {
      optionalStuff.examCustomId = this.state.examCustomId;
    }
    if (
      this.state.doctorfirstname !== "" ||
      this.state.doctorlastname !== "" ||
      this.state.doctoremail !== ""
    ) {
      optionalStuff.orderingProvider = {
        firstName: this.state.doctorfirstname,
        lastName: this.state.doctorlastname,
        email: this.state.doctoremail
      };
    }
    let data = {
      examLocalId: "WEB-DASHBOARD" + "::" + new Date().getTime(),
      examDate: new Date().getTime(),
      deviceType: devices,
      patientId: this?.props?.patientId,
      ...optionalStuff
    };
    this.setState({ isLoaded: false, disableSecondButton: true });

    postAuthenticatedData(sessionStorage.getItem("domain") + createExam, data)
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 200) {
          toast.success(this.t(T_PATIENTS.toast.examAddedSuccessfully));
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner">
          {(() => {
            if (this.state.activePage === 2) {
              return (
                <a className="add-org-back" onClick={this.goToFirstPage}>
                  &lt; {this.t(T_GENERAL.back)}
                </a>
              );
            }
          })()}
          <form
            className="loginpad custom-add-exam-modal"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">
              {this.t(T_PATIENTS.patientDetails.addExam.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            {(() => {
              if (this.state.activePage === 1) {
                return (
                  <div className="firstpage">
                    <div className="firstpage">
                      <FormGroup controlId="customidentifier" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.exam.examCode)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              autoFocus
                              required
                              type="text"
                              name="examCustomId"
                              maxLength="20"
                              value={this.state.examCustomId}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row formfont">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.exam.deviceType)}*
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editfop"
                              checked={this?.state?.editDeviceType?.includes?.(
                                "FOP"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.exam.deviceTypes.fop)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editpsl"
                              checked={this?.state?.editDeviceType?.includes?.(
                                "PSL"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.exam.deviceTypes.psl)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editaim"
                              checked={this?.state?.editDeviceType?.includes?.(
                                "AIM"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.exam.deviceTypes.aim)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editinstaref"
                              checked={this?.state?.editDeviceType?.includes?.(
                                "INSTAREF"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.exam.deviceTypes.instaref)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editpristine"
                              checked={this?.state?.editDeviceType?.includes?.(
                                "PRISTINE"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.exam.deviceTypes.pristine)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="checkbox"
                              name="editother"
                              checked={this?.state?.editDeviceType?.includes?.(
                                "OTHER"
                              )}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="checkbox-label formfont">
                            {this.t(T_ENTITIES.exam.deviceTypes.other)}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.exam.ocularHistory)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              required
                              as="textarea"
                              name="ocularhistory"
                              value={this.state.ocularhistory}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <h6 className="custom-heading">
                        {this.t(
                          T_PATIENTS.patientDetails.addExam.prescribingDoctor
                        )}
                      </h6>
                      <FormGroup controlId="name" bsSize="medium">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(
                              T_PATIENTS.patientDetails.addExam.firstName
                            )}
                            *
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              required
                              type="text"
                              maxLength={30}
                              name="doctorfirstname"
                              value={this.state.doctorfirstname}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_PATIENTS.patientDetails.addExam.lastName)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              required
                              type="text"
                              name="doctorlastname"
                              maxLength={30}
                              value={this.state.doctorlastname}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_PATIENTS.patientDetails.addExam.email)}*
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              required
                              type="text"
                              name="doctoremail"
                              value={this.state.doctoremail}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>

                      <Button
                        onClick={this.onSubmit}
                        className="loginbtn1 create-account-btn submit-button"
                        disabled={!this.state.isLoaded}
                      >
                        {this.state.isLoaded ? (
                          this.t(T_GENERAL.submit)
                        ) : (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="firstpage">
                    <table className="visual-acuity-container">
                      <thead>
                        <th></th>
                        <th>
                          {this.t(T_PATIENTS.patientDetails.addExam.rightEye)}
                        </th>
                        <th>
                          {this.t(T_PATIENTS.patientDetails.addExam.leftEye)}
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {this.t(T_PATIENTS.patientDetails.addExam.ucva)}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightucva"
                                value={this.state.rightucva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftucva"
                                value={this.state.leftucva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(
                              T_PATIENTS.patientDetails.addExam.visionWithPG
                            )}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightpg"
                                value={this.state.rightpg}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftpg"
                                value={this.state.leftpg}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(
                              T_PATIENTS.patientDetails.addExam.spherical
                            )}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightsph"
                                value={this.state.rightsph}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftsph"
                                value={this.state.leftsph}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(
                              T_PATIENTS.patientDetails.addExam.cylindrical
                            )}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightcyl"
                                value={this.state.rightcyl}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftcyl"
                                value={this.state.leftcyl}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(T_PATIENTS.patientDetails.addExam.axis)}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightaxis"
                                value={this.state.rightaxis}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftaxis"
                                value={this.state.leftaxis}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(T_PATIENTS.patientDetails.addExam.bcva)}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightbcva"
                                value={this.state.rightbcva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftbcva"
                                value={this.state.leftbcva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(T_PATIENTS.patientDetails.addExam.nearAdd)}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightadd"
                                value={this.state.rightadd}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftadd"
                                value={this.state.leftadd}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {this.t(T_PATIENTS.patientDetails.addExam.iop)}
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightiop"
                                value={this.state.rightiop}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftiop"
                                value={this.state.leftiop}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn submit-button"
                      disabled={!this.state.isLoaded}
                    >
                      {this.state.isLoaded ? (
                        this.t(T_GENERAL.submit)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomAddExamModal);
