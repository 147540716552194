/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateSiteSettings } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SERVICE, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Gateway Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomGatewaySettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: false,
      location: this.props.dataStorageLocation,
      identifier: this.props.gatewaySettings.siteCustomIdentifier
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }
  componentDidMount() {}
  /**
   * Handles changes to the identifier
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    this.setState({
      identifier: e.target.value
    });
  }

  onChangeLocation(e) {
    this.setState({ location: e.target.value });
  }
  /**
   * Submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    var data = {
      dataStorageLocation: this.state.location,
      ...(this.state.identifier !== "" && {
        siteCustomIdentifier: this.state.identifier
      })
    };
    this.setState({ isLoaded: false, disableButton: true });

    postAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateSiteSettings +
        "/" +
        this.props.siteId,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.t(T_SERVICE.toast.gatewaySettingsUpdatedSuccessfully)
          );
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner gateway-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SERVICE.viewSites.pacsGateway.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
              disabled={this.state.disableButton}
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              {/* <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SERVICE.viewSites.pacsGateway.onOrOff)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.enabled}
                      onstyle="success"
                      onChange={checked => this.setState({ enabled: checked })}
                      disabled={this.state.disableButton}
                    />
                  </div>
                </div>
              </FormGroup> */}
              <FormGroup controlId="name" bsSize="medium">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_SERVICE.viewSites.pacsGateway.siteIdentifier)}
                  </div>
                  <div className="col-md-9">
                    <FormControl
                      autoFocus
                      required
                      type="text"
                      name="mrn"
                      value={this.state.identifier}
                      onChange={this.onChange}
                      disabled={this.state.disableButton}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SITES.configureDataSync.dataSync)}
                  </div>
                  <div className="col-md-9">
                    <select
                      name="location"
                      className="form-control"
                      value={this.state.location}
                      onChange={this.onChangeLocation}
                    >
                      <option value="CLOUD" key="CLOUD">
                        {this.t(T_SITES.configureDataSync.remidioCloud)}
                      </option>
                      <option value="EXTERNAL_GATEWAY" key="EXTERNAL_GATEWAY">
                        {this.t(T_SITES.configureDataSync.externalDestination)}
                      </option>
                      <option value="NONE" key="NONE">
                        {this.t(T_SITES.configureDataSync.disableDataSync)}
                      </option>
                    </select>
                  </div>
                </div>
              </FormGroup>
              <p className="data-sync-note">
                {this.t(T_SITES.configureDataSync.note)}
              </p>
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomGatewaySettingsModal);
