/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import { remidioOrgIDs } from "../../config";
import { Button, FormGroup, FormControl, Dropdown } from "react-bootstrap";
import {
  getAllUsers,
  getUser,
  updateUser,
  updateStatus,
  deleteUser,
  getSites,
  resendVerificationEmail,
  getTeleMedConfig
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  putAuthenticatedData,
  postAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import CustomNotificationSettingsModal from "./CustomNotificationSettingsModal";
import { toast } from "react-toastify";
import { convertEpochToDate } from "../../utils/date";
import { roles } from "../../constants/roles";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import MultiSelect from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_GENERAL,
  T_LOGIN,
  T_PAGINATION,
  T_USERS
} from "../../constants/translations";

/**
 * This is the component for the Users page. It is only visible for User Admins. <br>
 * It contains methods related to listing all the users within an organization, <br>
 * editing their roles and permissions, as well as updating their status. <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.13, 5.3.14.14, 5.3.14.24 <br>
 * PSL - 5.3.14.13, 5.3.14.14, 5.3.14.24 <br>
 * FOP - 5.3.14.13, 5.3.14.14, 5.3.14.25 <br>
 *
 * @component
 */
class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: false,
      isTableLoading: true,
      items: [],
      dName: "",
      dEmail: "",
      firstName: "",
      lastName: "",
      editFirstName: "",
      editLastName: "",
      editEid: "",
      editUserId: "",
      editEmail: "",
      editRoles: "",
      editStatus: "",
      editSite: "",
      editSiteId: 0,
      editOrgId: 0,
      editSecondarySiteIds: [],
      clickedOnce: false,
      buttonText: "",
      isOpen: false,
      showPopup: false,
      showPopup1: false,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      btnMood: "",
      btnFill: false,
      showEmailBtn: false,
      disableColoredBtn: false,
      disableUpdateBtn: false,
      sites: [],
      prevPage: null,
      nextPage: null,
      pageNumber: 1,
      suggestions: [],
      userOrgId: 0,
      disableEmailBtn: false,
      options: [],
      selected: [],
      currentUrl: "",
      showNotificationSettingsModal: false,
      telemedData: {},
      telemedUsers: {},
      editAllowOfflineUse: false
    };
    this.toggle = this.toggle.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.togglePopup1 = this.togglePopup1.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleActionButtonClick = this.handleActionButtonClick.bind(this);
    this.resetList = this.resetList.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleEmailButtonClick = this.handleEmailButtonClick.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.resetTelemedList = this.resetTelemedList.bind(this);
    this.openNotificationSettingsModal = this.openNotificationSettingsModal.bind(
      this
    );
    this.closeNotificationSettingsModal = this.closeNotificationSettingsModal.bind(
      this
    );
    this.getRoleTranslation = this.getRoleTranslation.bind(this);
  }
  /**
   * Shows the Add Account Modal
   *
   */
  onChange() {
    this.setState({ showModal: true });
  }

  resetTelemedList() {
    if (sessionStorage.getItem("tele") === "true") {
      getAuthenticatedData(
        sessionStorage.getItem("domain") + getTeleMedConfig
      ).then(res => {
        this.setState({ telemedData: res.data });
      });
      getAuthenticatedData(
        sessionStorage.getItem("domain") + getAllUsers + "?paginate=false"
      ).then(res => {
        this.setState({ telemedUsers: res.data });
      });
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem("tele") === "true") {
      getAuthenticatedData(
        sessionStorage.getItem("domain") + getTeleMedConfig
      ).then(res => {
        this.setState({ telemedData: res.data });
      });
      getAuthenticatedData(
        sessionStorage.getItem("domain") + getAllUsers + "?paginate=false"
      ).then(res => {
        this.setState({ telemedUsers: res.data });
      });
    }
    this.state.userResults
      .then(result => {
        this.setState({
          userOrgId: result?.data?.organizationId
        });
        if (result?.data?.roles?.includes("ADMIN")) {
          this.resetList();
          return Promise.resolve(true);
        } else {
          return Promise.reject(false);
        }
      })
      .then(() => {
        return getAuthenticatedData(
          sessionStorage.getItem("domain") + getSites
        );
      })
      .then(
        result => {
          this.setState({
            isLoaded: true,
            isTableLoading: false,
            sites: result?.data,
            options: result?.data.map(el => {
              return { label: el.siteName, value: el.siteId };
            })
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
          this.props.history.push("/exam-data");
        }
      );
  }

  getRoleTranslation(role) {
    switch (role) {
      case "Service":
        return this.t(T_ENTITIES.user.roleTypes.service);
      case "Doctor":
        return this.t(T_ENTITIES.user.roleTypes.doctor);
      case "Operator":
        return this.t(T_ENTITIES.user.roleTypes.operator);
      case "Admin":
        return this.t(T_ENTITIES.user.roleTypes.admin);
    }
  }

  getStatusTranslation(status) {
    let translation = "";
    switch (status) {
      case "UNVERIFIED":
        translation = this.t(T_ENTITIES.user.statusTypes.unverified);
        break;
      case "ACTIVE":
        translation = this.t(T_ENTITIES.user.statusTypes.active);
        break;
      case "INACTIVE":
        translation = this.t(T_ENTITIES.user.statusTypes.inactive);
        break;
      case "SUSPENDED":
        translation = this.t(T_ENTITIES.user.statusTypes.suspended);
        break;
    }
    return translation.toLocaleUpperCase();
  }

  /**
   * Makes the getAllUsers API call again to update the list
   *
   */
  resetList() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getAllUsers + "?paginate=true"
    ).then(
      result => {
        this.setState({
          isLoaded: true,
          items: result?.data,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1
        });
        this.getDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Used to set the string value of the epoch for all dates in the array
   *
   * @param {array} items      array of users
   */
  getDates(items) {
    let dates = items?.map?.(item => convertEpochToDate(item.lastActiveDate));
    this.setState({ dates });
  }

  setSelected(e) {
    this.setState({ selected: e });
  }
  /**
   * Opens the Edit Account Modal
   *
   * @param {object} item      the user object to be edited
   */
  openModal(item) {
    // this.setState({currentSelectedObject: items})
    // eslint-disable-next-line no-console
    let status = "";
    switch (item?.accountStatus) {
      case "ACTIVE":
        status = this.i18n.language === "it" ? "Attivo" : "Active";
        this.setState({
          buttonText: this.t(T_GENERAL.block),
          btnMood: "meh",
          btnFill: false,
          showEmailBtn: false
        });
        break;

      case "INACTIVE":
        status = this.i18n.language === "it" ? "Inattivo" : "Inactive";
        this.setState({ showEmailBtn: true, buttonText: "" });
        break;

      case "UNVERIFIED":
        status = this.i18n.language === "it" ? "Non verificato" : "Unverified";
        this.setState({
          buttonText: this.t(T_USERS.edit.deleteAccount),
          btnMood: "warning",
          btnFill: false,
          showEmailBtn: true
        });
        break;

      case "SUSPENDED":
        status = this.i18n.language === "it" ? "Sospeso" : "Suspended";
        this.setState({
          buttonText: this.t(T_USERS.edit.unblock),
          btnMood: "success",
          btnFill: false,
          showEmailBtn: false
        });
        break;

      default:
        status = "";
        break;
    }
    this.setState({
      showModal: true,
      editFirstName: item?.firstName,
      editLastName: item?.lastName,
      editEid: item?.employeeId,
      editEmail: item?.email,
      editRoles: item?.roles,
      editStatus: status,
      editUserId: item?.userId,
      editSite: this.state.sites.find(e => e.siteId == item?.siteId).siteName,
      editSiteId: item?.siteId,
      editOrgId: item?.organizationId,
      editAllowOfflineUse: item?.allowOfflineUse,
      selected: Array.isArray(item?.secondarySiteIds)
        ? item?.secondarySiteIds.map(el => {
            let siteIds = this.state.sites.map(el => el.siteId);
            let index = siteIds.indexOf(el);
            let label = this.state.sites[index]?.siteName;
            return { label, value: el };
          })
        : []
    });
  }
  /**
   * Sends a verification email
   */
  handleEmailButtonClick() {
    this.setState({ disableEmailBtn: true });
    postAuthenticatedData(
      sessionStorage.getItem("domain") +
        resendVerificationEmail +
        "/" +
        this.state.editEmail,
      {}
    )
      .then(result => {
        if (result.status === 200) {
          toast.success(this.t(T_USERS.toast.verificationEmailSent));
        }
        this.setState({ disableEmailBtn: false });
        this.resetList();
        return result.json();
      })
      .then(resp => {
        this.setState({ disableEmailBtn: false });
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }
  /**
   * Logic to show a confirmation, with different scenarios based on the account status
   */
  handleActionButtonClick() {
    if (!this.state.clickedOnce) {
      this.setState({ clickedOnce: true });
      switch (this.state.buttonText) {
        case this.t(T_GENERAL.block):
          this.setState({
            buttonText: this.t(T_USERS.edit.confirmBlock),
            btnFill: true
          });
          break;
        case this.t(T_USERS.edit.deleteAccount):
          this.setState({
            buttonText: this.t(T_USERS.edit.confirmDelete),
            btnFill: true
          });
          break;
        case this.t(T_USERS.edit.unblock):
          this.setState({
            buttonText: this.t(T_USERS.edit.confirmUnblock),
            btnFill: true
          });
          break;
      }
    } else {
      let data = {};
      this.setState({ disableColoredBtn: true });
      switch (this.state.buttonText) {
        case this.t(T_USERS.edit.confirmBlock):
          data = {
            userId: this.state.editUserId,
            accountStatus: "SUSPENDED"
          };
          putAuthenticatedData(
            sessionStorage.getItem("domain") + updateStatus,
            data
          ).then(result => {
            this.setState({ disableColoredBtn: false });
            if (result.status === 200) {
              this.closeModal();
              toast.success(this.t(T_USERS.toast.userUpdatedSuccessfully));
              this.resetList();
            } else {
              this.closeModal();
              toast.error(this.t(T_GENERAL.somethingWentWrong));
            }
            return result.json();
          });
          break;
        case this.t(T_USERS.edit.confirmDelete):
          deleteAuthenticatedData(
            sessionStorage.getItem("domain") +
              deleteUser +
              "/" +
              this.state.editUserId
          ).then(result => {
            this.setState({ disableColoredBtn: false });
            if (result.status === 200) {
              this.closeModal();
              toast.success(this.t(T_USERS.toast.userDeletedSuccessfully));
              this.resetList();
            } else {
              this.closeModal();
              toast.error(this.t(T_GENERAL.somethingWentWrong));
            }
          });
          break;
        case this.t(T_USERS.edit.confirmUnblock):
          data = {
            userId: this.state.editUserId,
            accountStatus: "ACTIVE"
          };
          putAuthenticatedData(
            sessionStorage.getItem("domain") + updateStatus,
            data
          ).then(result => {
            this.setState({ disableColoredBtn: false });
            if (result.status === 200) {
              this.closeModal();
              toast.success(this.t(T_USERS.toast.userUpdatedSuccessfully));
              this.resetList();
            } else {
              this.closeModal();
              toast.error(this.t(T_GENERAL.somethingWentWrong));
            }
            return result.json();
          });
          break;
      }
    }
  }
  /**
   * Closes the Edit User Modal
   */
  closeModal() {
    this.setState({ showModal: false, clickedOnce: false });
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }
  togglePopup1() {
    this.setState({
      showPopup1: !this.state.showPopup1
    });
  }

  openNotificationSettingsModal() {
    this.setState({ showNotificationSettingsModal: true });
  }

  closeNotificationSettingsModal() {
    this.setState({ showNotificationSettingsModal: false });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  /**
   * Submits the Edit User Modal
   */
  onSubmitEdit() {
    const data = {
      employeeId: this.state.editEid,
      userId: this.state.editUserId,
      firstName: this.state.editFirstName,
      lastName: this.state.editLastName,
      roles: this.state.editRoles,
      siteId: this.state.editSiteId,
      allowOfflineUse: this.state.editAllowOfflineUse,
      ...(sessionStorage.getItem("secondary") === "true" && {
        secondarySiteIds:
          this.state.selected.length !== 0
            ? this.state.selected.map(el => el.value)
            : []
      })
    };
    this.setState({ disableUpdateBtn: true });
    putAuthenticatedData(sessionStorage.getItem("domain") + updateUser, data)
      .then(result => {
        this.setState({ disableUpdateBtn: false });
        if (result.status === 200) {
          this.closeModal();
          toast.success(this.t(T_USERS.toast.userUpdatedSuccessfully));
          let url =
            this.state.currentUrl !== ""
              ? this.state.currentUrl
              : sessionStorage.getItem("domain") +
                getAllUsers +
                "?paginate=true";
          getAuthenticatedData(url).then(resultI => {
            this.setState({
              isLoaded: true,
              items: resultI?.data
            });
          });
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }
  /**
   * Gets triggered the moment there is a change in any of the Edit User fields
   *
   * @param {object} e      event handler
   */
  handleChange(e) {
    switch (e.currentTarget.name) {
      case "editfirstname":
        this.setState({ editFirstName: e.currentTarget.value });
        break;
      case "editlastname":
        this.setState({ editLastName: e.currentTarget.value });
        break;
      case "editeid":
        this.setState({ editEid: e.currentTarget.value });
        break;
      case "editdoctor":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(e => e !== "DOCTOR")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "DOCTOR"] });
        }
        break;
      case "editadmin":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(e => e !== "ADMIN")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "ADMIN"] });
        }
        break;
      case "editservice":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(e => e !== "SERVICE")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "SERVICE"] });
        }
        break;
      case "editoperator":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this.state.editRoles.filter(e => e !== "OPERATOR")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "OPERATOR"] });
        }
        break;
    }
  }

  /**
   * Triggers the API call to go to the previous page in the users list
   *
   */
  goToPreviousPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.prevPage
    ).then(
      result => {
        this.setState({
          items: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber - 1,
          currentUrl: sessionStorage.getItem("domain") + this.state.prevPage
        });
        this.getDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Triggers the API call to go to the next page in the users list
   */
  goToNextPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.nextPage
    ).then(
      result => {
        this.setState({
          items: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber + 1,
          currentUrl: sessionStorage.getItem("domain") + this.state.nextPage
        });
        this.getDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Gets triggered when the search field is cleared or the input is backspaced
   */
  handleClear() {
    // eslint-disable-next-line no-console
    this.resetList();
  }
  /**
   * Performs the API call to search for a particular user
   *
   * @param {string} value      the string to search for
   */
  handleSearch(value) {
    if (value) {
      // eslint-disable-next-line no-console
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getAllUsers +
          "?paginate=true&search=" +
          value
      ).then(
        result => {
          this.setState({
            isLoaded: true,
            items: result?.data,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            pageNumber: 1
          });
          this.getDates(result?.data);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}

  render() {
    const { isLoaded, items } = this.state;
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        className="secondary-sites-arrow"
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    ));
    // eslint-disable-next-line react/display-name
    const CustomMenu = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        const [value] = useState("");

        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                child =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );
    // const HtmlTooltip = withStyles(theme => ({
    //   tooltip: {
    //     backgroundColor: "#fff",
    //     color: "rgba(77, 77, 77, 1)",
    //     fontSize: theme.typography.pxToRem(12),
    //     fontWeight: "normal",
    //     fontFamily:
    //       "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
    //     border: "1px solid #dadde9",
    //     boxShadow: "2px 2px 2px 0px rgba(0,0,0,0.25);"
    //   }
    // }))(Tooltip);

    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="0" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                {(() => {
                  if (sessionStorage.getItem("tele") === "true") {
                    return (
                      <div className="grader-users-heading-container">
                        <Button
                          className="green-button"
                          onClick={this.openNotificationSettingsModal}
                        >
                          {this.t(T_USERS.notificationSettingsButton)}
                        </Button>
                        {this.state.showNotificationSettingsModal ? (
                          <CustomNotificationSettingsModal
                            closePopup={this.closeNotificationSettingsModal}
                            resetList={this.resetTelemedList}
                            hourToSend={this.state.telemedData.hourToSend}
                            notificationsEnabled={
                              this.state.telemedData.notificationsEnabled
                            }
                            subscribedUsers={
                              this.state.telemedData.subscribedUsers
                            }
                            telemedUsers={this.state.telemedUsers}
                          />
                        ) : null}
                        <Button
                          className="green-button"
                          onClick={this.togglePopup}
                        >
                          {this.t(T_USERS.addAccountButton)}{" "}
                        </Button>
                        {this.state.showPopup ? (
                          <CustomModal
                            text='Click "Close Button" to hide popup'
                            closePopup={this.togglePopup}
                            resetList={this.resetList}
                            orgId={this.state.userOrgId}
                            sites={this.state.sites}
                          />
                        ) : null}
                      </div>
                    );
                  } else {
                    return (
                      <div className="addaccount-container">
                        <Button
                          className="addaccount"
                          onClick={this.togglePopup}
                        >
                          {this.t(T_USERS.addAccountButton)}{" "}
                        </Button>
                        {this.state.showPopup ? (
                          <CustomModal
                            text='Click "Close Button" to hide popup'
                            closePopup={this.togglePopup}
                            resetList={this.resetList}
                            orgId={this.state.userOrgId}
                            sites={this.state.sites}
                          />
                        ) : null}
                      </div>
                    );
                  }
                })()}
              </div>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder={this.t(T_USERS.searchUsers)}
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
              />
              {(() => {
                if (this.state.isTableLoading) {
                  return (
                    <div className="table-error">
                      <p>
                        <img src={require("../../assets/images/loading.gif")} />
                      </p>
                    </div>
                  );
                } else if (!items || items.length === 0) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_ENTITIES.user.name)}</th>
                            <th>{this.t(T_ENTITIES.user.site)}</th>
                            <th>{this.t(T_ENTITIES.user.emailID)}</th>
                            <th>
                              {this.t(T_ENTITIES.user.registrationNumber)}
                            </th>
                            <th>{this.t(T_ENTITIES.user.roles)}</th>
                            <th>{this.t(T_ENTITIES.user.status)}</th>
                            <th>{this.t(T_ENTITIES.user.lastActive)}</th>
                            {(() => {
                              if (sessionStorage.getItem("ad")) {
                                return (
                                  <th>{this.t(this.t(T_USERS.offlineUse))}</th>
                                );
                              }
                            })()}
                            <th>{this.t(T_USERS.table.action)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map?.((item, index) => (
                            <tr key={item.userId}>
                              <td id={"dName" + item.userId}>
                                {item.firstName} {item.lastName}
                              </td>
                              <td id={"dName" + item.userId}>
                                {
                                  this.state.sites.find(
                                    e => e.siteId == item.siteId
                                  )?.siteName
                                }
                                {(() => {
                                  if (Array.isArray(item?.secondarySiteIds)) {
                                    return (
                                      <span className="secondary-sites-users">
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="dropdown-custom-components"
                                          >
                                            &nbsp;(+
                                            {item?.secondarySiteIds?.length})
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu as={CustomMenu}>
                                            {item?.secondarySiteIds?.map(
                                              sSite => {
                                                return (
                                                  <p
                                                    className="secondary-site-tooltip-item"
                                                    key={sSite}
                                                  >
                                                    {
                                                      this.state.sites.find(
                                                        e => e.siteId == sSite
                                                      )?.siteName
                                                    }
                                                  </p>
                                                );
                                              }
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </span>
                                    );
                                  }
                                })()}
                              </td>
                              <td id={"dEmail" + item.userId}>{item.email}</td>
                              <td id={"userType" + item.userId}>
                                {item.employeeId}
                              </td>
                              <td id={"id" + item.userId}>
                                {item.roles
                                  .map(e => this.getRoleTranslation(roles[e]))
                                  .reduce((acc, val) => `${acc} / ${val}`)}
                              </td>
                              <td id={"status" + item.userId}>
                                {this.getStatusTranslation(item.accountStatus)}
                              </td>
                              <td id={"created_at" + item.userId}>
                                {this.state.dates?.[index]
                                  ? this.state.dates?.[index]
                                  : this.t(T_LOGIN.neverLoggedIn)}
                              </td>
                              {(() => {
                                if (sessionStorage.getItem("ad")) {
                                  return (
                                    <td
                                      id={"offline_use_allowed" + item.userId}
                                    >
                                      {item.allowOfflineUse
                                        ? this.t(T_GENERAL.yes)
                                        : this.t(T_GENERAL.no)}
                                    </td>
                                  );
                                }
                              })()}
                              <td className="tablemodal">
                                <a
                                  id={item.userId}
                                  className="editbtn"
                                  onClick={() => this.openModal(item)}
                                >
                                  {this.t(T_GENERAL.edit)}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; {this.t(T_PAGINATION.previous)}</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          let startRow = this.state.pageNumber * 10 - 9;
                          let endRow = startRow + items.length - 1;
                          return (
                            <span className="rowcount-text">
                              {this.t(T_PAGINATION.showingRows, {
                                from: startRow,
                                to: endRow
                              })}
                            </span>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>{this.t(T_PAGINATION.next)} &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          {this.state.showModal && (
            <div className="custommodal">
              <div className="custommodalinner edit-act-modal">
                <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                  <h6 className="modal-heading">
                    {this.t(T_USERS.edit.header)}
                  </h6>
                  <Button
                    // eslint-disable-next-line react/prop-types
                    onClick={this.closeModal}
                    className="cancle"
                  >
                    {" "}
                    X{" "}
                  </Button>
                  <hr className="hrpadding" />
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.emailID)}
                      </div>
                      <div className="col-md-9">
                        <FormControl
                          disabled
                          value={this.state.editEmail}
                          onChange={this.handleChange.bind("editEmail")}
                          type="text"
                          name="lastname"
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="name" bsSize="medium">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.firstName)}
                      </div>
                      <div className="col-md-9">
                        <FormControl
                          autoFocus
                          type="text"
                          value={this.state.editFirstName}
                          onChange={this.handleChange}
                          name="editfirstname"
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.lastName)}
                      </div>
                      <div className="col-md-9">
                        <FormControl
                          type="text"
                          name="editlastname"
                          value={this.state.editLastName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.site)}
                      </div>
                      <div className="col-md-9">
                        <FormControl
                          type="text"
                          name="editsite"
                          disabled
                          value={this.state.editSite}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  {(() => {
                    if (sessionStorage.getItem("secondary") === "true") {
                      return (
                        <FormGroup controlId="email" bsSize="large">
                          <div className="row">
                            <div className="col-md-3 formfont">
                              {this.t(T_ENTITIES.user.secondarySite)}
                            </div>
                            <div className="col-md-9">
                              <MultiSelect
                                options={this.state.options}
                                value={this.state.selected}
                                onChange={this.setSelected}
                                labelledBy="Select"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      );
                    }
                  })()}
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.registrationNumber)}
                      </div>
                      <div className="col-md-9">
                        <FormControl
                          value={this.state.editEid}
                          onChange={this.handleChange}
                          type="text"
                          name="editeid"
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row formfont">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.roles)}
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="editadmin"
                          checked={this?.state?.editRoles?.includes?.("ADMIN")}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="checkbox-label formfont">
                        {this.t(T_ENTITIES.user.roleTypes.admin)}
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="editdoctor"
                          checked={this?.state?.editRoles?.includes?.("DOCTOR")}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="checkbox-label formfont">
                        {this.t(T_ENTITIES.user.roleTypes.doctor)}
                      </div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="editoperator"
                          checked={this?.state?.editRoles?.includes?.(
                            "OPERATOR"
                          )}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="checkbox-label formfont">
                        {this.t(T_ENTITIES.user.roleTypes.operator)}
                      </div>
                      {(() => {
                        if (remidioOrgIDs.includes(this.state.editOrgId)) {
                          return (
                            <>
                              <div className="checkbox">
                                <FormControl
                                  className="check"
                                  type="checkbox"
                                  name="editservice"
                                  checked={this?.state?.editRoles?.includes?.(
                                    "SERVICE"
                                  )}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="checkbox-label formfont">
                                {this.t(T_ENTITIES.user.roleTypes.service)}
                              </div>
                            </>
                          );
                        }
                      })()}
                    </div>
                  </FormGroup>
                  {(() => {
                    if (sessionStorage.getItem("ad")) {
                      return (
                        <FormGroup controlId="email" bsSize="large">
                          <div className="row">
                            <div className="col-md-3 formfont">
                              {this.t(T_USERS.offlineUse)}
                            </div>
                            <div className="col-md-9">
                              <BootstrapSwitchButton
                                width={100}
                                height={10}
                                checked={this.state.editAllowOfflineUse}
                                onstyle="success"
                                onChange={checked =>
                                  this.setState({
                                    editAllowOfflineUse: checked
                                  })
                                }
                              />
                            </div>
                          </div>
                          <p className="offline-warning">
                            {this.t(T_USERS.offlineUseWarning)}
                          </p>
                        </FormGroup>
                      );
                    }
                  })()}
                  <FormGroup>
                    <Button
                      onClick={this.onSubmitEdit}
                      className="loginbtn1 edit-account-btn"
                      disabled={this.state.disableUpdateBtn}
                    >
                      {this.state.disableUpdateBtn ? (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      ) : (
                        this.t(T_GENERAL.update)
                      )}
                    </Button>
                  </FormGroup>
                  <hr className="edit-line" />
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.user.status)}
                      </div>
                      <div className="col-md-9">
                        <b>{this.state.editStatus}</b>
                      </div>
                    </div>
                  </FormGroup>
                  {(() => {
                    if (this.state.showEmailBtn) {
                      return (
                        <>
                          <FormGroup>
                            <Button
                              onClick={this.handleEmailButtonClick}
                              disabled={this.state.disableEmailBtn}
                              className={"loginbtn2 edit-account-btn loginbtn1"}
                            >
                              {this.state.disableEmailBtn ? (
                                <img
                                  src={require("../../assets/images/" +
                                    "button-loading.gif")}
                                />
                              ) : (
                                this.t(T_USERS.edit.resendVerificationEmail)
                              )}
                            </Button>
                          </FormGroup>
                          <hr className="edit-line" />
                        </>
                      );
                    }
                  })()}
                  {(() => {
                    if (this.state.buttonText) {
                      return (
                        <FormGroup>
                          <Button
                            onClick={this.handleActionButtonClick}
                            disabled={this.state.disableColoredBtn}
                            className={
                              "loginbtn2 edit-account-btn change-state-btn " +
                              this.state.btnMood +
                              (this.state.btnFill ? " fill" : "")
                            }
                          >
                            {this.state.disableColoredBtn ? (
                              <img
                                src={require("../../assets/images/" +
                                  this.state.btnMood +
                                  "-button-loading.gif")}
                              />
                            ) : (
                              this.state.buttonText
                            )}
                          </Button>
                        </FormGroup>
                      );
                    }
                  })()}
                </form>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default withTranslation()(AdminDashboard);
