/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomAddExamModal from "./CustomAddExamModal";
import CustomEditPatientModal from "./CustomEditPatientModal";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPatientWithExams, getUser } from "../../constants/endpoints";
import { getAuthenticatedData } from "../../utils/async";
import { convertEpochToDate } from "../../utils/date";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_GENERAL,
  T_PATIENTS
} from "../../constants/translations";

// import { toast } from "react-toastify";
/**
 * This is the component for the Patient Details page. <br>
 * It uses sessionStorage to maintain pagination and state. <br>
 * The history API is used to check if this page is loaded using the back button <br>
 * or whether the user can in directly.
 *
 * @component
 */
class PatientDetails extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: this.props.history.action === "POP" ? true : false,
      exams: null,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      patient: {},
      mrn: undefined,
      prevPage:
        this.props.history.action === "POP" &&
        sessionStorage.getItem("pDetPrevPage") !== "null"
          ? sessionStorage.getItem("pDetPrevPage")
          : null,
      nextPage:
        this.props.history.action === "POP" &&
        sessionStorage.getItem("pDetNextPage") !== "null"
          ? sessionStorage.getItem("pDetNextPage")
          : null,
      examId: 0,
      gradedDates: [],
      showEditPatientModal: false,
      myExam: false,
      fromText: "",
      suggestions: [],
      type: "",
      currentUrl: sessionStorage.getItem("pDetUrl"),
      queryObjectId: "",
      sortBy: 0,
      id: 0,
      siteId: 0,
      showAddExamModal: false,
      deviceType:
        this.props.history.action === "POP"
          ? sessionStorage.getItem("pDetDeviceType")
          : "FOP",
      dates: []
    };
    this.openAddExamModal = this.openAddExamModal.bind(this);
    this.toggleAddExamModal = this.toggleAddExamModal.bind(this);
    this.changeDeviceType = this.changeDeviceType.bind(this);
    this.resetList = this.resetList.bind(this);
    this.getDates = this.getDates.bind(this);
    this.toggleEditPatientModal = this.toggleEditPatientModal.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }
  /**
   * Retains state from session storage
   *
   *
   *
   */
  getData() {
    this.getDates(this.state.exams);
    if (this?.props?.location?.state?.name) {
      this.setState({ fromText: this?.props?.location?.state?.name });
    }
    if (this.props.history.action === "PUSH") {
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getPatientWithExams +
          `/${this.props?.location?.state?.mrn}?deviceType=${this.state
            .deviceType +
            (this?.props?.location?.state?.siteId
              ? "&siteId=" + this?.props?.location?.state?.siteId
              : "")}`
      ).then(
        result => {
          this.setState({
            exams: result?.data?.exams,
            patient: result?.data?.patient,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            currentUrl:
              sessionStorage.getItem("domain") +
              getPatientWithExams +
              `/${this.props?.location?.state?.mrn}?deviceType=${this.state
                .deviceType +
                (this?.props?.location?.state?.siteId
                  ? "&siteId=" + this?.props?.location?.state?.siteId
                  : "")}`
          });
          this.getDates(result?.data?.exams);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    } else {
      getAuthenticatedData(sessionStorage.getItem("pDetUrl")).then(
        result => {
          this.setState({
            exams: result?.data?.exams,
            patient: result?.data?.patient,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl
          });
          this.getDates(result?.data?.exams);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  componentDidUpdate() {
    sessionStorage.setItem("pDetPrevPage", this.state.prevPage);
    sessionStorage.setItem("pDetNextPage", this.state.nextPage);
    sessionStorage.setItem("pDetDeviceType", this.state.deviceType);
    sessionStorage.setItem("pDetUrl", this.state.currentUrl);
  }

  /**
   * Triggered on change of device type and syncs the component state accordingly
   * @param {object} e the event listener
   *
   *
   *
   */
  changeDeviceType(e) {
    this.setState({
      deviceType: e.target.getAttribute("name"),
      currentUrl:
        sessionStorage.getItem("domain") +
        getPatientWithExams +
        `/${
          this.state.mrn ? this.state.mrn : this.props?.location?.state?.mrn
        }?deviceType=${e.target.getAttribute("name") +
          (this?.props?.location?.state?.siteId
            ? "&siteId=" + this?.props?.location?.state?.siteId
            : "")}`
    });
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getPatientWithExams +
        `/${
          this.state.mrn ? this.state.mrn : this.props?.location?.state?.mrn
        }?deviceType=${e.target.getAttribute("name") +
          (this?.props?.location?.state?.siteId
            ? "&siteId=" + this?.props?.location?.state?.siteId
            : "")}`
    ).then(
      result => {
        this.setState({
          exams: result?.data?.exams,
          patient: result?.data?.patient,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
        this.getDates(result?.data?.exams);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  getDates(items) {
    let dates = items?.map?.(item =>
      convertEpochToDate(item?.examDetails?.examDate)
    );
    this.setState({ dates });
  }

  openAddExamModal() {
    this.setState({ showAddExamModal: true });
  }

  // viewReport(item) {
  //   getAuthenticatedData(
  //     sessionStorage.getItem("domain") + getPdfLink + `/${item.examDetails.id}`
  //   ).then(
  //     result => {
  //       window.open(result.data, "_blank");
  //     },
  //     () => {
  //       this.setState({
  //         isLoaded: true
  //       });
  //     }
  //   );
  // }

  toggleAddExamModal() {
    this.setState({
      showAddExamModal: !this.state.showAddExamModal
    });
  }

  toggleEditPatientModal() {
    this.setState({ showEditPatientModal: !this.state.showEditPatientModal });
  }
  /**
   * Resets the patient details list
   *
   *
   *
   */
  resetList(mrn) {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getPatientWithExams +
        `/${mrn ? mrn : this.props?.location?.state?.mrn}?deviceType=${this
          .state.deviceType +
          (this?.props?.location?.state?.siteId
            ? "&siteId=" + this?.props?.location?.state?.siteId
            : "")}`
    ).then(
      result => {
        this.setState({
          exams: result?.data?.exams,
          patient: result?.data?.patient,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          fromText:
            result?.data?.exams[0].patientDetails.firstName +
            " " +
            (result?.data?.exams[0].patientDetails.lastName
              ? result?.data?.exams[0].patientDetails.lastName
              : ""),
          mrn: mrn,
          currentUrl:
            sessionStorage.getItem("domain") +
            getPatientWithExams +
            `/${mrn ? mrn : this.props?.location?.state?.mrn}?deviceType=${this
              .state.deviceType +
              (this?.props?.location?.state?.siteId
                ? "&siteId=" + this?.props?.location?.state?.siteId
                : "")}`
        });
        this.getDates(result?.data?.exams);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  render() {
    const { isLoaded, exams, dates, fromText, patient } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from={"8"} results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable custom-flex-wrapper">
                <a className="back-link" onClick={this.props.history.goBack}>
                  &lt; {this.t(T_GENERAL.back)}
                </a>
                <div className="addaccount-container">
                  <Button
                    className="addaccount"
                    onClick={this.openAddExamModal}
                  >
                    {this.t(T_PATIENTS.patientDetails.addExamButton)}
                  </Button>
                  {this.state.showAddExamModal ? (
                    <CustomAddExamModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.toggleAddExamModal}
                      resetList={this.resetList}
                      makeAdmin={true}
                      orgId={this?.props?.location?.state?.id}
                      device={this?.state?.deviceType}
                      patientId={this?.state?.patient?.id}
                    />
                  ) : null}
                </div>
              </div>
              <h4 className="exam-heading">{fromText}</h4>
              <div className="patient-details-heading-container">
                <div className="patient-details-heading">
                  {this.t(T_PATIENTS.patientDetails.header)}
                </div>
                <div
                  className="edit-patient-details"
                  onClick={() => this.toggleEditPatientModal()}
                >
                  <img src={require("../../assets/images/edit-icon.svg")} />
                </div>
              </div>
              <div className="patient-details-container">
                <div>
                  <span className="patient-details-heading">
                    {this.t(T_ENTITIES.patient.gender)}:{" "}
                  </span>
                  <div className="patient-gender">{patient?.gender}</div>
                </div>
                <div>
                  <span className="patient-details-heading">
                    {this.t(T_ENTITIES.patient.mrn)}:{" "}
                  </span>
                  {patient?.mrn}
                </div>
              </div>
              <div className="patient-details-container last">
                <div>
                  <span className="patient-details-heading">
                    {this.t(T_ENTITIES.patient.dob)}:{" "}
                  </span>
                  {convertEpochToDate(patient?.dateOfBirth)}
                </div>
              </div>
              <div className="device-selection-container">
                <div className="device-selection-heading">
                  {this.t(T_PATIENTS.patientDetails.chooseDevice)}
                </div>
                {(() => {
                  if (this.state.deviceType === "FOP") {
                    return (
                      <div name="FOP" className="device-selection-box selected">
                        FOP
                      </div>
                    );
                  }
                  return (
                    <div
                      name="FOP"
                      onClick={this.changeDeviceType}
                      className="device-selection-box"
                    >
                      FOP
                    </div>
                  );
                })()}
                {(() => {
                  if (this.state.deviceType === "PSL") {
                    return (
                      <div name="PSL" className="device-selection-box selected">
                        PSL
                      </div>
                    );
                  }
                  return (
                    <div
                      name="PSL"
                      onClick={this.changeDeviceType}
                      className="device-selection-box"
                    >
                      PSL
                    </div>
                  );
                })()}
                {(() => {
                  if (this.state.deviceType === "AIM") {
                    return (
                      <div name="AIM" className="device-selection-box selected">
                        AIM
                      </div>
                    );
                  }
                  return (
                    <div
                      name="AIM"
                      onClick={this.changeDeviceType}
                      className="device-selection-box"
                    >
                      AIM
                    </div>
                  );
                })()}
                {(() => {
                  if (this.state.deviceType === "INSTAREF") {
                    return (
                      <div
                        name="INSTAREF"
                        className="device-selection-box selected"
                      >
                        INSTAREF
                      </div>
                    );
                  }
                  return (
                    <div
                      name="INSTAREF"
                      onClick={this.changeDeviceType}
                      className="device-selection-box"
                    >
                      INSTAREF
                    </div>
                  );
                })()}
                {(() => {
                  if (this.state.deviceType === "PRISTINE") {
                    return (
                      <div
                        name="PRISTINE"
                        className="device-selection-box selected"
                      >
                        PRISTINE
                      </div>
                    );
                  }
                  return (
                    <div
                      name="PRISTINE"
                      onClick={this.changeDeviceType}
                      className="device-selection-box"
                    >
                      PRISTINE
                    </div>
                  );
                })()}
                {(() => {
                  if (this.state.deviceType === "OTHER") {
                    return (
                      <div
                        name="OTHER"
                        className="device-selection-box selected"
                      >
                        Other
                      </div>
                    );
                  }
                  return (
                    <div
                      name="OTHER"
                      onClick={this.changeDeviceType}
                      className="device-selection-box"
                    >
                      Other
                    </div>
                  );
                })()}
              </div>
              {(() => {
                if (!exams || exams.length === 0 || typeof exams !== "object") {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_ENTITIES.patient.name)}</th>
                            <th>{this.t(T_ENTITIES.patient.gender)}</th>
                            <th>{this.t(T_ENTITIES.patient.mrn)}</th>
                            <th>
                              {this.t(T_PATIENTS.patientDetails.table.examDate)}
                            </th>
                            <th>
                              {this.t(T_PATIENTS.patientDetails.table.examCode)}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {exams?.map?.((item, index) => (
                            <tr key={item.examDetails.id}>
                              <td>
                                <Link
                                  to={{
                                    pathname: "/exam-details",
                                    state: {
                                      exam: item,
                                      deviceType: this.state.deviceType
                                    }
                                  }}
                                >
                                  <span className="editbtn">
                                    {item.patientDetails.firstName}{" "}
                                    {item.patientDetails.lastName
                                      ? item.patientDetails.lastName
                                      : ""}
                                  </span>
                                </Link>
                              </td>
                              <td className="patient-gender">
                                {item.patientDetails.gender}
                              </td>
                              <td>{item.patientDetails.mrn}</td>
                              <td>{dates?.[index] ? dates?.[index] : "-"}</td>
                              <td>
                                {item?.examDetails?.examCustomId &&
                                item?.examDetails?.examCustomId !== ""
                                  ? item?.examDetails?.examCustomId
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  );
                }
              })()}
              {this.state.showEditPatientModal ? (
                <CustomEditPatientModal
                  text='Click "Close Button" to hide popup'
                  closePopup={this.toggleEditPatientModal}
                  resetList={this.resetList}
                  patient={this.state.patient}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(PatientDetails);
