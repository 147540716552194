/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { loginPassword, getUserAndOrg } from "../../constants/endpoints";
import {
  postUnauthenticatedData,
  getAuthenticatedData
} from "../../utils/async";
//import { convertEpochToDate, convertEpochToTime } from "../../utils/date";
import { withTranslation } from "react-i18next";
import { T_DATE, T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the Password page.  <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.1, 5.3.14.2 <br>
 * PSL - 5.3.14.1, 5.3.14.2, 5.3.14.31 <br>
 * FOP - 5.3.14.1, 5.3.14.2 <br>
 *
 * @component
 */
class LoginPassword extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      password: "",
      email: "",
      name: "",
      userId: "",
      isLoaded: true,
      statusCheckSuccessful: false,
      disableButton: false,
      attemptsRemaining: undefined,
      wrongPassword: false,
      showAttemptsRemaining: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.forgot = this.forgot.bind(this);
  }
  /**
   * Ensures that the user did not directly enter the route through the URL
   *
   *
   *
   */
  componentDidMount() {
    sessionStorage.removeItem("ad");
    if (!this.props.location.email) {
      this.props.history.push({
        pathname: "/login"
      });
    }
    const { name, email, userId, lastActiveDate } = this.props.location;
    // console.log("userid: " + userId);

    this.setState({ name, email, userId, lastActiveDate });
  }
  /**
   * Listens to changes in the password field
   * @param {object} e event listener
   *
   *
   */
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  /**
   * Goes to the Forgot Password route
   *
   *
   *
   */
  forgot() {
    this.props.history.push({
      pathname: "/forgot-password",
      userId: this.state.userId,
      name: this.state.name,
      email: this.state.email
    });
  }
  /**
   * Submits the form to login, and stores the token in sessionStorage
   *
   *
   *
   */
  handleSubmit() {
    event.preventDefault();
    var data = {
      emailAddress: this.state.email,
      password: this.state.password,
      deviceId: ""
    };
    if (this.state.password != "") {
      this.setState({ disableButton: true });
    }
    postUnauthenticatedData(
      sessionStorage.getItem("domain") + loginPassword,
      data
    )
      .then(result => {
        if (result.status === 200) {
          this.setState({ statusCheckSuccessful: true });
          return result.json();
        } else {
          this.setState({ password: "", disableButton: false });
          if (result.status === 401) {
            this.setState({ wrongPassword: true });
            toast.error(this.t(T_LOGIN.toast.invalidPassword));
          } else if (result.status === 403) {
            this.props.history.push({
              pathname: "/recover",
              email: this.state.email,
              cameFromPasswordPage: true
            });
          }
          return result.json();
        }
      })
      .then(responseJson => {
        if (this.state.statusCheckSuccessful) {
          window.sessionStorage.setItem("bearerToken", responseJson.data);
          sessionStorage.removeItem("calledLogoutOnce");
          return Promise.resolve(true);
        } else if (this.state.wrongPassword) {
          this.setState({
            attemptsRemaining: responseJson.data.attemptsRemaining,
            showAttemptsRemaining: responseJson.data.showAttemptsRemaining
          });
        } else if (
          responseJson.status.statusCode !== "OK" &&
          responseJson.status.statusCode !== "FORBIDDEN" &&
          responseJson.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(responseJson.status.message);
        }
        return Promise.reject(false);
      })
      .then(
        () => {
          getAuthenticatedData(
            sessionStorage.getItem("domain") + getUserAndOrg
          ).then(result => {
            sessionStorage.setItem(
              "orgName",
              result?.data?.orgAndSiteResponse?.orgName
            );
            sessionStorage.setItem(
              "userName",
              result?.data?.userInfo?.firstName +
                " " +
                result?.data?.userInfo?.lastName
            );
            if (
              result?.data?.orgAndSiteResponse?.entitlements?.includes(
                "TELE_MEDICINE"
              )
            ) {
              sessionStorage.setItem("tele", "true");
            } else {
              sessionStorage.setItem("tele", "false");
            }
            if (result?.data?.orgAndSiteResponse?.lowSecurityModeEnabled) {
              sessionStorage.setItem("lowSecurityModeEnabled", "true");
            }
            if (
              result?.data?.orgAndSiteResponse?.entitlements?.includes(
                "SECONDARY_SITES"
              )
            ) {
              sessionStorage.setItem("secondary", "true");
            } else {
              sessionStorage.setItem("secondary", "false");
            }
            if (
              result?.data?.userInfo?.roles?.includes("DOCTOR") ||
              result?.data?.userInfo?.roles?.includes("OPERATOR")
            ) {
              this.props?.history?.push?.("/exam-data");
            } else {
              this.props?.history?.push?.("/edit-admin");
            }
          });
        },
        () => {}
      );
  }

  render() {
    let dateText = "";
    if (!this.state.lastActiveDate) {
      dateText = this.t(T_LOGIN.neverLoggedIn);
    } else {
      // let date = convertEpochToDate(this.state.lastActiveDate);
      // let time = convertEpochToTime(this.state.lastActiveDate);
      // dateText = `${date} at ${time}`;
      dateText = this.t(T_DATE.datetimeMed, { date: new Date() });
    }
    return (
      <div className="Login">
        <form
          className="loginpad1 password-screen"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </p>

          <p className="center login-email">{this.state.email}</p>
          {(() => {
            if (
              this.state.showAttemptsRemaining &&
              typeof this.state.attemptsRemaining !== "undefined"
            ) {
              return (
                <p className="center login-attempts">
                  {this.t(T_LOGIN.warningAttempt, {
                    count: this.state.attemptsRemaining
                  })}
                </p>
              );
            }
          })()}
          <FormGroup controlId="email" bsSize="large">
            <FormControl
              autoFocus
              required
              type="password"
              name="password"
              placeholder={this.t(T_LOGIN.password)}
              autoComplete="off"
              value={this.state.password}
              onChange={this.onChange}
              disabled={this.state.disableButton}
            />
          </FormGroup>
          <div>
            <Button
              block
              bsSize="large"
              type="submit"
              className="loginbtn"
              disabled={this.state.disableButton}
            >
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                this.t(T_LOGIN.next)
              )}
            </Button>

            <span className="forgotspan" onClick={this.forgot}>
              <p
                className={
                  this.state.disableButton ? "disabled-link forgot" : "forgot"
                }
              >
                {this.t(T_LOGIN.forgotPassword)}
              </p>
            </span>
            <Link to={this.state.disableButton ? "#" : "/"}>
              <p
                className={
                  this.state.disableButton ? "disabled-link back" : "back"
                }
              >
                {this.t(T_LOGIN.back)}
              </p>
            </Link>
            <div className="lastLogin">
              {this.t(T_LOGIN.lastLogin) + dateText}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(LoginPassword);
