import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "./i18n";
import { initializeTranslationKeys } from "./constants/translations";
// To turn off i18n, just remove this line without refactoring all t(GROUP.key) calls
initializeTranslationKeys();

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.Suspense fallback="loading">
    <MsalProvider instance={msalInstance}>
      <App timeout={1000 * 60 * 15} />
    </MsalProvider>
  </React.Suspense>,
  document.getElementById("root")
);
