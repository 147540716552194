/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateSiteConfigurations } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SERVICE, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Site Entitlement Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomSiteSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    let objs = Object.entries(this.props.siteEntitlements)
      .flatMap(item => item[1].flatMap(it => it.name))
      .map(i => {
        let j = Object.entries(
          this.props.configurations ? this.props.configurations : {}
        ).flatMap(some => some[1]);
        return [
          i,
          j.find(k => k.name === i)
            ? j.find(k => k.name === i).value
            : Object.entries(this.props.siteEntitlements)
                .flatMap(i => i[1])
                .find(l => l.name === i).type === "BOOLEAN"
            ? false
            : ""
        ];
      });
    let objsWithValues = Object.fromEntries(objs);

    this.state = { isLoaded: true, ...objsWithValues };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {}
  /**
   * Submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    this.setState({ isLoaded: false });
    let objs = Object.entries(this.props.siteEntitlements)
      .flatMap(item => item[1].flatMap(it => it.name))
      .map(i => {
        return { name: i, value: this.state[i] };
      })
      .filter(
        el =>
          el.value !== "" && el.value != null && typeof el.value !== "undefined"
      );
    // eslint-disable-next-line no-console
    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateSiteConfigurations +
        "/" +
        this.props.siteId,
      objs
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.t(T_SITES.toast.siteConfigSettingsUpdatedSuccessfully)
          );
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  onChange(e, type, charLimit) {
    if (
      (type === "STRING" && charLimit && e.target.value.length <= charLimit) ||
      (type !== "STRING" && type !== "INTEGER") ||
      (type === "STRING" && !charLimit)
    )
      this.setState({ [e.target.name]: e.target.value });
    if (
      type === "INTEGER" &&
      (/^[0-9\b]+$/.test(e.target.value) || e.target.value === "")
    ) {
      this.setState({
        [e.target.name]: e.target.value === "" ? "" : parseInt(e.target.value)
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner site-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SERVICE.viewSites.siteConfigurations.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              {/* <h6>Random heading</h6>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">DR AI</div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      // checked={this.state.aiEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ aiEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup> */}
              {(() => {
                return Object.entries(this.props.siteEntitlements)
                  ?.map(([name, obj]) => ({ name, ...obj }))
                  ?.map(item => {
                    return (
                      <>
                        <h6>{item.name}</h6>
                        {(() => {
                          return Object.assign([], item)?.map(formItem => {
                            // eslint-disable-next-line no-console
                            console.log(formItem);
                            return (
                              <>
                                <FormGroup
                                  key={formItem.name}
                                  controlId="email"
                                  bsSize="large"
                                >
                                  <div className="row formfont">
                                    <div className="col-md-6 formfont enable-label">
                                      {formItem.name}
                                    </div>
                                    <div className="col-md-6">
                                      {(() => {
                                        switch (formItem.type) {
                                          case "STRING":
                                          case "INTEGER":
                                            return (
                                              <FormControl
                                                type="text"
                                                name={formItem.name}
                                                value={
                                                  this.state[formItem.name]
                                                }
                                                onChange={e =>
                                                  this.onChange(
                                                    e,
                                                    formItem.type,
                                                    formItem.charLimit
                                                  )
                                                }
                                              />
                                            );
                                          case "BOOLEAN":
                                            return (
                                              <BootstrapSwitchButton
                                                width={100}
                                                height={10}
                                                checked={
                                                  this.state[formItem.name]
                                                }
                                                onChange={checked => {
                                                  this.setState({
                                                    [formItem.name]: checked
                                                  });
                                                }}
                                                onstyle="success"
                                              />
                                            );
                                          case "ENUM":
                                            return (
                                              <select
                                                name={formItem.name}
                                                value={
                                                  this.state[formItem.name]
                                                }
                                                onChange={e =>
                                                  this.onChange(
                                                    e,
                                                    formItem.type
                                                  )
                                                }
                                              >
                                                <option key={-1} value="">
                                                  {this.t(
                                                    T_GENERAL.selectAnOption
                                                  )}
                                                </option>
                                                {(() => {
                                                  return formItem.possibleValues.map(
                                                    (ddItem, key) => (
                                                      <option
                                                        key={key}
                                                        value={ddItem}
                                                      >
                                                        {ddItem}
                                                      </option>
                                                    )
                                                  );
                                                })()}
                                              </select>
                                            );
                                        }
                                      })()}
                                    </div>
                                  </div>
                                </FormGroup>
                              </>
                            );
                          });
                        })()}
                      </>
                    );
                  });
              })()}

              <Button
                onClick={this.onSubmit}
                className="loginbtn1 site-settings-btn submit-button"
                // disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomSiteSettingsModal);
