/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { reverifyAccount } from "../../constants/endpoints";
import { putUnauthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";
import history from "../../utils/history";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the Reverify Account page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.27 <br>
 * PSL - 5.3.14.27 <br>
 *
 * @component
 */
class ReverifyAccount extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  /**
   * Triggers the verify account API call
   *
   *
   *
   */
  onSubmit() {
    this.setState({ disableButton: true });
    putUnauthenticatedData(
      queryString.parse(this.props.location.search)?.domain +
        reverifyAccount +
        "?verificationType=ACCOUNT_VERIFICATION",
      {},
      {
        Authorization:
          "Bearer " + queryString.parse(this.props.location.search)?.token
      }
    )
      .then(result => {
        this.setState({ disableButton: false });
        if (result.status == 200) {
          toast.success(this.t(T_LOGIN.toast.reverifyAccountSuccessfully));
          history.push("/login");
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </p>
          <p className="centerrecover">{this.t(T_LOGIN.needToReverify)}</p>
          <div>
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                this.t(T_LOGIN.reverifyMyAccount)
              )}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(ReverifyAccount);
