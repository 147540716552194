/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button } from "react-bootstrap";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import { Table } from "reactstrap";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import { T_STATISTICS } from "../../constants/translations";

/**
 * This is the component for the Gateway Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomStatisticsChartModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      categories:
        this.props.siteRadio === "specific-site"
          ? this.props.analytics.siteCounters
              .find(el => el.siteName === this.props.selectedSite)
              .cat2Counters.sort((a, b) => b.count - a.count)
              .map(el => {
                return {
                  name: el.name,
                  count: el.count
                };
              })
              .filter(el => el.count !== 0)
          : this.props.analytics.cat2Counters
              .map(el => {
                return {
                  name: el.name,
                  count: el.count
                };
              })
              .filter(el => el.count !== 0),
      filteredCategories:
        this.props.siteRadio === "specific-site"
          ? this.props.analytics.siteCounters
              .find(el => el.siteName === this.props.selectedSite)
              .cat2Counters.sort((a, b) => b.count - a.count)
              .map(el => {
                return {
                  name: el.name,
                  count: el.count
                };
              })
              .filter(el => el.count !== 0)
          : this.props.analytics.cat2Counters
              .map(el => {
                return {
                  name: el.name,
                  count: el.count
                };
              })
              .filter(el => el.count !== 0),
      table: this.props.table,
      chartPage: this.props.chartNumber ? this.props.chartNumber : 1,
      diseases: this.props.diseases.filter(
        el => el.diseases.reduce((acc, el) => acc + el.count, 0) !== 0
      )
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.openChart = this.openChart.bind(this);
    this.showTable = this.showTable.bind(this);
  }
  componentDidMount() {}

  goToPreviousPage() {
    this.setState({ chartPage: this.state.chartPage - 1 });
  }

  goToNextPage() {
    this.setState({ chartPage: this.state.chartPage + 1 });
  }

  handleSearch(e) {
    e.preventDefault();
  }

  showTable() {
    this.setState({ table: true, chartPage: 1 });
  }

  openChart(index, item) {
    // eslint-disable-next-line no-console
    console.log(index, item);
    if (index && index !== null) {
      this.setState({
        table: false,
        chartPage: index + 1,
        filteredCategories: this.state.categories
      });
    } else {
      let otherIndex = this.state.categories.findIndex(
        el => el.name === item.name
      );
      this.setState({
        table: false,
        chartPage: otherIndex + 1,
        filteredCategories: this.state.categories
      });
    }
  }

  handleChange(e) {
    this.setState({
      filteredCategories: this.state.categories.filter(item =>
        item.name.match(new RegExp(e, "i"))
      )
    });
  }

  handleClear() {
    this.setState({ filteredCategories: this.state.categories });
  }

  render() {
    let diseaseOptions = {
      chart: {
        type: "donut"
      },
      colors: [
        "#4DBC79",
        "#9FDD8A",
        "#FFD466",
        "#FF8266",
        "#F55F6C",
        "#39ADC8",
        "#3972C8",
        "#855BB5",
        "#999EA3",
        "#59646D"
      ],
      dataLabels: { enabled: false },
      tooltip: {
        enabled: true,
        custom: function({ series, seriesIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex];
          return (
            "<div class='custom-tooltip'><span class='tooltip-title'>" +
            w.config.labels[seriesIndex] +
            "</span>" +
            ": " +
            selected +
            " (" +
            ((selected / total) * 100).toFixed(2) +
            "%)" +
            "</div>"
          );
        }
      },
      labels: this.state.diseases[this.state.chartPage - 1].diseases
        .filter(el => el.count !== 0)
        .map(disease => disease.name),
      legend: { position: "left" },
      plotOptions: {
        pie: {
          donut: {
            size: "70%"
          }
        }
      }
    };
    let diseaseSeries = this.state.diseases[this.state.chartPage - 1].diseases
      .filter(disease => disease.count !== 0)
      .map(disease => disease.count);
    return (
      <div className="custommodal">
        <div className="custommodalinner statistics-modal">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            {(() => {
              if (this.props.siteRadio === "all-sites") {
                if (this.props.timelineRadio === "last-seven-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.diagnosesInLastDays, {
                        name: sessionStorage.getItem("orgName"),
                        count: 7
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "last-thirty-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.diagnosesInLastDays, {
                        name: sessionStorage.getItem("orgName"),
                        count: 30
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "specific-month") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.diagnosesInMonth, {
                        name: sessionStorage.getItem("orgName"),
                        month: this.props.month
                      })}
                    </h6>
                  );
                }
              } else {
                if (this.props.timelineRadio === "last-seven-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.diagnosesInLastDays, {
                        name: this.props.selectedSite,
                        count: 7
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "last-thirty-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.diagnosesInLastDays, {
                        name: this.props.selectedSite,
                        count: 30
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "specific-month") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.diagnosesInMonth, {
                        name: this.props.selectedSite,
                        month: this.props.month
                      })}
                    </h6>
                  );
                }
              }
            })()}
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            {(() => {
              if (this.state.table) {
                return (
                  <div className="firstpage">
                    <SearchBar
                      autoFocus
                      shouldRenderClearButton
                      renderClearButton
                      renderSearchButton
                      placeholder={this.t(T_STATISTICS.searchDisease)}
                      onChange={this.handleChange}
                      onClear={this.handleClear}
                      onSearch={this.handleSearch}
                      styles={styles}
                    />
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_STATISTICS.diagnosis)}</th>
                            <th>{this.t(T_STATISTICS.numberOfCases)}</th>
                            <th>{this.t(T_STATISTICS.action)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filteredCategories?.map?.(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item?.name}</td>
                                <td>{item?.count}</td>
                                <td>
                                  <a
                                    className="editbtn"
                                    onClick={() => this.openChart(null, item)}
                                  >
                                    {this.t(T_STATISTICS.seeDetails)}
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                );
              } else {
                return (
                  <>
                    <div className="title-container">
                      {(() => {
                        if (this.state.chartPage === 1) {
                          return (
                            <div className="previous disabled">
                              <img
                                src={require("../../assets/images/left-arrow.svg")}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="previous"
                              onClick={this.goToPreviousPage}
                            >
                              <img
                                src={require("../../assets/images/left-arrow.svg")}
                              />
                            </div>
                          );
                        }
                      })()}
                      {(() => {
                        return (
                          <div className="chart-title paginated">
                            {
                              this.state.diseases[this.state.chartPage - 1]
                                .categoryName
                            }
                          </div>
                        );
                      })()}
                      {(() => {
                        if (
                          this.state.chartPage !== this.state.diseases.length
                        ) {
                          return (
                            <div className="next" onClick={this.goToNextPage}>
                              <img
                                src={require("../../assets/images/right-arrow.svg")}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div className="next disabled">
                              <img
                                src={require("../../assets/images/right-arrow.svg")}
                              />
                            </div>
                          );
                        }
                      })()}
                    </div>
                    <div className="chart-container">
                      <div className="chart">
                        <Chart
                          key={1}
                          options={diseaseOptions}
                          series={diseaseSeries}
                          type="donut"
                          height="300"
                        ></Chart>
                      </div>
                    </div>
                    <div className="diagnoses see-more-container">
                      <button onClick={this.showTable}>
                        {this.t(T_STATISTICS.viewAllDiseases)}
                      </button>
                    </div>
                  </>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomStatisticsChartModal);
