/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomAddOrgModal from "./CustomAddOrgModal";
import CustomEMRPacsModal from "./CustomEMRPacsModal";
import CustomOrganizationEntitlementSettingsModal from "./CustomOrganizationEntitlementSettingsModal";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import {
  getAllOrgData,
  getUser,
  editOrg,
  deleteOrg,
  getAllOrgEntitlements,
  getAllOrgTags
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  putAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_GENERAL,
  T_PAGINATION,
  T_SERVICE
} from "../../constants/translations";

/**
 * This is the component for the Service page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.15, 5.3.14.16, 5.3.14.24 <br>
 * PSL - 5.3.14.15, 5.3.14.16, 5.3.14.24 <br>
 * FOP - 5.3.14.15, 5.3.14.16, 5.3.14.25 <br>
 *
 * @component
 */
class ServiceDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: this.props.history.action === "POP" ? true : false,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      isTableLoading: true,
      pageNumber: 1,
      dataQueueEnabled: false,
      gatewayIPPort: "",
      imagesWebhookEnabled: false,
      doctorReportWebhookEnabled: false,
      aiReportWebhookEnabled: false,
      webhookURL: "",
      showIntegrationModal: false,
      orgId: 0,
      prevPage:
        sessionStorage.getItem("sDashPrevPage") !== "null"
          ? sessionStorage.getItem("sDashPrevPage")
          : null,
      nextPage:
        sessionStorage.getItem("sDashNextPage") !== "null"
          ? sessionStorage.getItem("sDashNextPage")
          : null,
      organizations: null,
      suggestions: [],
      showAddOrganizationModal: false,
      showEditModal: false,
      currentId: 0,
      currentUrl: "",
      /*         sessionStorage.getItem("sDashUrl")?.includes(getAllOrgData)
          ? sessionStorage.getItem("sDashUrl")
          : "", */
      searchValue:
        this.props.history.action === "POP"
          ? sessionStorage.getItem("sDashSearchValue")
          : "",
      editorganizationname: "",
      editButtonDisabled: true,
      isEditLoaded: true,
      showDeleteModal: false,
      deleteOrgId: 0,
      deleteOrgName: "",
      deleteButtonDisabled: false,
      showEntitlementSettingsModal: false,
      entitlementSettings: {},
      entitlementOrganization: 0,
      orgEntitlements: [],
      organizationTagsList: [],
      organizationTags: ""
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.openAddOrganizationModal = this.openAddOrganizationModal.bind(this);
    this.toggleAddAdminAccountModal = this.toggleAddAdminAccountModal.bind(
      this
    );
    this.resetList = this.resetList.bind(this);
    this.openEditOrgModal = this.openEditOrgModal.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.openDeleteOrgModal = this.openDeleteOrgModal.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.openEntitlementsModal = this.openEntitlementsModal.bind(this);
    this.closeEntitlementsModal = this.closeEntitlementsModal.bind(this);
    this.openIntegrationModal = this.openIntegrationModal.bind(this);
    this.closeIntegrationModal = this.closeIntegrationModal.bind(this);
  }
  /**
   * Triggers the API call to get a list of all organizations
   *
   *
   *
   */
  componentDidMount() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getAllOrgTags).then(
      res => {
        this.setState({ organizationTagsList: res.data });
      }
    );
    this.state.userResults.then(
      () => {
        this.setState({
          currentUrl:
            this.props.history.action === "POP"
              ? sessionStorage.getItem("sDashUrl")
              : sessionStorage.getItem("domain") +
                getAllOrgData +
                "?paginate=true"
        });
        if (this.props.history.action !== "POP") {
          getAuthenticatedData(
            sessionStorage.getItem("domain") + getAllOrgData + "?paginate=true"
          )
            .then(result => {
              this.setState({
                organizations: result?.data,
                prevPage: result?.paging?.prevUrl,
                nextPage: result?.paging?.nextUrl
              });
            })
            .then(() => {
              getAuthenticatedData(
                sessionStorage.getItem("domain") + getAllOrgEntitlements
              ).then(
                res => {
                  this.setState({
                    orgEntitlements: res?.data,
                    isLoaded: true,
                    isTableLoading: false
                  });
                },
                () => {
                  this.setState({ isLoaded: true });
                }
              );
            });
        } else {
          this.setInput(sessionStorage.getItem("sDashSearchValue"));
          this.setState({ isTableLoading: true });
          getAuthenticatedData(this.state.currentUrl)
            .then(result => {
              this.setState({
                organizations: result?.data,
                prevPage: result?.paging?.prevUrl,
                nextPage: result?.paging?.nextUrl,
                pageNumber: parseInt(sessionStorage.getItem("sDashPageNumber"))
              });
            })
            .then(
              () => {
                getAuthenticatedData(
                  sessionStorage.getItem("domain") + getAllOrgEntitlements
                ).then(
                  res => {
                    this.setState({
                      orgEntitlements: res?.data,
                      isLoaded: true,
                      isTableLoading: false
                    });
                  },
                  () => {
                    this.setState({ isLoaded: true });
                  }
                );
              },
              () => {
                this.setState({ isLoaded: true });
              }
            );
        }
      },
      () => {
        this.setState({ isLoaded: true });
      }
    );
  }

  componentWillUnmount() {
    sessionStorage.setItem("sDashPrevPage", this.state.prevPage);
    sessionStorage.setItem("sDashNextPage", this.state.nextPage);
    sessionStorage.setItem("sDashPageNumber", this.state.pageNumber);
    sessionStorage.setItem("sDashSearchValue", this.state.searchValue);
    sessionStorage.setItem("sDashUrl", this.state.currentUrl);
  }

  /**
   * Goes to the previous page
   *
   *
   *
   */
  goToPreviousPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.prevPage
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isTableLoading: false,
          isLoaded: true,
          pageNumber: this.state.pageNumber - 1,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          currentUrl: sessionStorage.getItem("domain") + this.state.prevPage
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Goes to the next page
   *
   *
   *
   */
  goToNextPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.nextPage
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isTableLoading: false,
          isLoaded: true,
          pageNumber: this.state.pageNumber + 1,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          currentUrl: sessionStorage.getItem("domain") + this.state.nextPage
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Clears the search field
   *
   *
   *
   */
  handleClear() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getAllOrgData + "?paginate=true"
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          isTableLoading: false,
          pageNumber: 1,
          currentUrl:
            sessionStorage.getItem("domain") + getAllOrgData + "?paginate=true",
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          searchValue: ""
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Searches for a particular organization
   * @param {string} value The search string
   *
   *
   *
   */
  handleSearch(value) {
    if (value) {
      this.setState({ isTableLoading: true });
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getAllOrgData +
          "?paginate=true&search=" +
          value
      ).then(
        result => {
          this.setState({
            organizations: result?.data,
            isLoaded: true,
            isTableLoading: false,
            pageNumber: 1,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            searchValue: value,
            currentUrl:
              sessionStorage.getItem("domain") +
              getAllOrgData +
              "?paginate=true&search=" +
              value
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}
  /**
   * Resets the list of orgs (triggers the API call again) and clears all filters
   *
   *
   *
   */
  resetList() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getAllOrgData + "?paginate=true"
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          isTableLoading: false,
          pageNumber: 1,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          currentUrl:
            sessionStorage.getItem("domain") + getAllOrgData + "?paginate=true"
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  openEntitlementsModal(entitlementSettings, orgId) {
    if (!entitlementSettings) {
      entitlementSettings = [];
    }
    this.setState({
      showEntitlementSettingsModal: true,
      entitlementSettings,
      entitlementOrganization: orgId
    });
  }

  openIntegrationModal(
    orgId,
    dataQueueEnabled,
    gatewayIPPort,
    doctorReportWebhookEnabled,
    aiReportWebhookEnabled,
    imagesWebhookEnabled,
    webhookURL
  ) {
    this.setState({
      orgId,
      showIntegrationModal: true,
      dataQueueEnabled,
      gatewayIPPort,
      doctorReportWebhookEnabled,
      aiReportWebhookEnabled,
      imagesWebhookEnabled,
      webhookURL
    });
  }

  closeIntegrationModal() {
    this.setState({ showIntegrationModal: false });
  }

  closeEntitlementsModal() {
    this.setState({ showEntitlementSettingsModal: false });
  }

  openAddOrganizationModal() {
    this.setState({ showAddOrganizationModal: true });
  }

  toggleAddAdminAccountModal() {
    this.setState({
      showAddOrganizationModal: !this.state.showAddOrganizationModal
    });
  }

  openEditOrgModal(item) {
    this.setState({
      currentId: item?.id,
      editorganizationname: item?.orgName,
      organizationTags: item.organisationTags ? item?.organisationTags[0] : "",
      showEditModal: true
    });
  }

  handleChangeEdit(e) {
    this.setState({
      [e.target.name]: e.target.value,
      editButtonDisabled: false
    });
  }

  handleEditButtonClick(e) {
    e.preventDefault();
    if (!this.state.editButtonDisabled) {
      this.setState({ editButtonDisabled: true, isEditLoaded: false });
      const data = {
        orgName: this.state.editorganizationname,
        organisationTags:
          this.state.organizationTags !== ""
            ? [this.state.organizationTags]
            : []
      };
      putAuthenticatedData(
        sessionStorage.getItem("domain") + editOrg + "/" + this.state.currentId,
        data
      ).then(() => {
        this.setState({
          showEditModal: false,
          isEditLoaded: true
        });
        toast.success(this.t(T_SERVICE.toast.editOrgSuccessful));
        this.resetList();
      });
    }
  }

  openDeleteOrgModal(item) {
    this.setState({
      showDeleteModal: true,
      deleteOrgId: item?.id,
      deleteOrgName: item?.orgName
    });
  }

  handleDeleteButtonClick() {
    this.setState({ deleteButtonDisabled: true });
    deleteAuthenticatedData(
      sessionStorage.getItem("domain") +
        deleteOrg +
        "/" +
        this.state.deleteOrgId,
      {}
    ).then(result => {
      if (result.status === 200) {
        this.setState({ deleteButtonDisabled: false, showDeleteModal: false });
        toast.success(this.t(T_SERVICE.toast.deleteOrgSuccessful));
        this.resetList();
      } else {
        toast.error(this.t(T_SERVICE.toast.cantDeleteWithoutDeletingSites));
        this.setState({ deleteButtonDisabled: false, showDeleteModal: false });
        this.resetList();
      }
    });
  }

  closeEditModal() {
    this.setState({ showEditModal: false });
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  render() {
    const { isLoaded, organizations } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="7" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                <div className="addaccount-container">
                  <Button
                    className="addaccount"
                    onClick={this.openAddOrganizationModal}
                  >
                    {this.t(T_SERVICE.addOrganizationButton)}
                  </Button>
                  {this.state.showAddOrganizationModal ? (
                    <CustomAddOrgModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.toggleAddAdminAccountModal}
                      resetList={this.resetList}
                      makeAdmin={true}
                      orgId={this?.props?.location?.state?.id}
                    />
                  ) : null}
                </div>
              </div>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder={this.t(T_SERVICE.searchOrganizations)}
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
                onRef={ref => {
                  this.clearInput = ref.clearInput;
                  this.setInput = ref.setInput;
                }}
              />
              {(() => {
                if (this.state.isTableLoading) {
                  return (
                    <div className="table-error">
                      <p>
                        <img src={require("../../assets/images/loading.gif")} />
                      </p>
                    </div>
                  );
                } else if (!organizations || organizations.length === 0) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>
                              {this.t(T_ENTITIES.organization.organiation)}
                            </th>
                            <th>{this.t(T_ENTITIES.organization.adEnabled)}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {organizations?.map?.(item => (
                            <tr key={item.siteId}>
                              <td id={"dName" + item.id}>{item.orgName}</td>
                              <td>
                                {item.isADOrg
                                  ? this.t(T_GENERAL.yes)
                                  : this.t(T_GENERAL.no)}
                              </td>
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openEditOrgModal(item);
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    {this.t(T_SERVICE.table.edit)}
                                  </span>
                                </a>
                              </td>
                              <td className="tablemodal">
                                <Link
                                  to={{
                                    pathname: "/service-sites",
                                    state: {
                                      type: "site",
                                      id: item.id
                                    }
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    {this.t(T_SERVICE.table.viewSites)}
                                  </span>
                                </Link>
                              </td>
                              <td>
                                <a
                                  onClick={() => {
                                    this.openEntitlementsModal(
                                      item.entitlements,
                                      item.id
                                    );
                                  }}
                                >
                                  <span className="editbtn">
                                    {this.t(T_SERVICE.table.orgEntitlements)}
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a
                                  onClick={() => {
                                    this.openIntegrationModal(
                                      item.id,
                                      item.orgGatewaySettings.dataQueueEnabled,
                                      item.orgGatewaySettings.gatewayIPPort,
                                      item.orgGatewaySettings?.webhookSettings
                                        ?.doctorReportWebhookEnabled,
                                      item.orgGatewaySettings?.webhookSettings
                                        ?.aiReportWebhookEnabled,
                                      item.orgGatewaySettings?.webhookSettings
                                        ?.imagesWebhookEnabled,
                                      item.orgGatewaySettings?.webhookSettings
                                        ?.webhookURL
                                    );
                                  }}
                                >
                                  <span className="editbtn">
                                    {this.t(
                                      T_SERVICE.table.emrPacsIntegrationSettings
                                    )}
                                  </span>
                                </a>
                              </td>
                              <td className="tablemodal">
                                <Link
                                  to={{
                                    pathname: "/service-admins",
                                    state: {
                                      id: item.id
                                    }
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    {this.t(T_SERVICE.table.viewAllAdmins)}
                                  </span>
                                </Link>
                              </td>
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openDeleteOrgModal(item);
                                  }}
                                >
                                  <span
                                    id={item.userId}
                                    className="editbtn delete-link"
                                  >
                                    {this.t(T_SERVICE.table.delete)}
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; {this.t(T_PAGINATION.previous)}</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          let startRow = this.state.pageNumber * 10 - 9;
                          let endRow = startRow + organizations.length - 1;
                          return (
                            <span className="rowcount-text">
                              {this.t(T_PAGINATION.showingRows, {
                                from: startRow,
                                to: endRow
                              })}
                            </span>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>{this.t(T_PAGINATION.next)} &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showEditModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form
                      className="loginpad custom-add-exam-patient-modal"
                      onSubmit={this.handleEditButtonClick}
                    >
                      <h6 className="modal-heading">
                        {this.t(T_SERVICE.edit.header)}
                      </h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeEditModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_SERVICE.edit.organizationName)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.editorganizationname}
                              onChange={this.handleChangeEdit}
                              type="text"
                              name="editorganizationname"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="name" bsSize="medium">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_SERVICE.addOrganization.organizationTag)}
                          </div>
                          <div className="col-md-9">
                            <select
                              name="organizationTags"
                              value={this.state.organizationTags}
                              onChange={this.handleChangeEdit}
                            >
                              <option value="">None</option>
                              {this.state.organizationTagsList.map(orgTag => {
                                return (
                                  <option value={orgTag} key={orgTag}>
                                    {orgTag}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </FormGroup>
                      <Button
                        onClick={this.handleEditButtonClick}
                        disabled={this.state.editButtonDisabled}
                        className={
                          "loginbtn2 edit-account-btn btn-primary fill edit-org-btn"
                        }
                      >
                        {!this.state.isEditLoaded ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          <>{this.t(T_SERVICE.edit.edit)}</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showDeleteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad">
                      <h6 className="modal-heading">
                        {this.t(T_SERVICE.delete.header)}
                      </h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeDeleteModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <p className="delete-user-admin-text">
                        {this.t(T_SERVICE.delete.areYouSure)}{" "}
                        {this.state.deleteOrgName}?
                      </p>
                      <Button
                        onClick={this.handleDeleteButtonClick}
                        disabled={this.state.deleteButtonDisabled}
                        className={"loginbtn2 edit-account-btn warning fill"}
                      >
                        {this.state.deleteButtonDisabled ? (
                          <img
                            src={require("../../assets/images/warning-button-loading.gif")}
                          />
                        ) : (
                          <>{this.t(T_GENERAL.delete)}</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showEntitlementSettingsModal && (
                <CustomOrganizationEntitlementSettingsModal
                  closePopup={this.closeEntitlementsModal}
                  resetList={this.resetList}
                  entitlementSettings={this.state.entitlementSettings}
                  organizationId={this.state.entitlementOrganization}
                  orgEntitlements={this.state.orgEntitlements}
                />
              )}
              {this.state.showIntegrationModal ? (
                <CustomEMRPacsModal
                  closePopup={this.closeIntegrationModal}
                  resetList={this.resetList}
                  orgId={this.state.orgId}
                  dataQueueEnabled={this.state.dataQueueEnabled}
                  gatewayIPPort={this.state.gatewayIPPort}
                  imagesWebhookEnabled={this.state.imagesWebhookEnabled}
                  doctorReportWebhookEnabled={
                    this.state.doctorReportWebhookEnabled
                  }
                  aiReportWebhookEnabled={this.state.aiReportWebhookEnabled}
                  webhookURL={this.state.webhookURL}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(ServiceDashboard);
