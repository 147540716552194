/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateSiteEntitlements } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SERVICE, T_SITES } from "../../constants/translations";

/**
 * This is the component for the Site Entitlement Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomSiteEntitlementSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: false,
      aiEnabled: this.props.entitlementSettings.aiEnabled,
      glaucomaAiEnabled: this.props.entitlementSettings.glaucomaAiEnabled,
      aiAutocaptureEnabled: this.props.entitlementSettings.aiAutocaptureEnabled,
      montageEnabled: this.props.entitlementSettings.montageEnabled,
      internalFixationEnabled: this.props.entitlementSettings
        .internalFixationEnabled
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {}
  /**
   * Submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    var data = {
      aiEnabled: this.state.aiEnabled ? this.state.aiEnabled : false,
      glaucomaAiEnabled: this.state.glaucomaAiEnabled
        ? this.state.glaucomaAiEnabled
        : false,
      aiAutocaptureEnabled: this.state.aiAutocaptureEnabled
        ? this.state.aiAutocaptureEnabled
        : false,
      montageEnabled: this.state.montageEnabled
        ? this.state.montageEnabled
        : false,
      internalFixationEnabled: this.state.internalFixationEnabled
        ? this.state.internalFixationEnabled
        : false,
      siteId: this.props.siteId
    };
    this.setState({ isLoaded: false, disableButton: true });

    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateSiteEntitlements +
        "/" +
        this.props.siteId,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.t(T_SITES.toast.siteEntitlementsUpdatedSuccessfully)
          );
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner entitlement-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SERVICE.viewSites.siteEntitlements.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SERVICE.viewSites.siteEntitlements.drAi)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.aiEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ aiEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SERVICE.viewSites.siteEntitlements.glaucomaAi)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.glaucomaAiEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ glaucomaAiEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SERVICE.viewSites.siteEntitlements.autoCapture)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.aiAutocaptureEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ aiAutocaptureEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(
                      T_SERVICE.viewSites.siteEntitlements.internalFixation
                    )}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.internalFixationEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ internalFixationEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_SERVICE.viewSites.siteEntitlements.montage)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.montageEnabled}
                      onstyle="success"
                      onChange={checked =>
                        this.setState({ montageEnabled: checked })
                      }
                    />
                  </div>
                </div>
              </FormGroup>
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomSiteEntitlementSettingsModal);
