/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { verifyEmail } from "../../constants/endpoints";
import { postUnauthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";
import history from "../../utils/history";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the Verify Email page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.27 <br>
 * PSL - 5.3.14.27 <br>
 *
 * @component
 */
class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem("ADClicked");
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  /**
   * Triggers the Verify Email API call
   *
   *
   *
   */
  onSubmit() {
    this.setState({ disableButton: true });
    postUnauthenticatedData(
      sessionStorage.getItem("domain") +
        verifyEmail +
        "/" +
        this.props.location.email,
      {}
    )
      .then(result => {
        this.setState({ disableButton: false });
        if (result.status == 200) {
          toast.success(this.t(T_LOGIN.toast.checkEmailToVerify));
          history.push("/login");
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </p>
          <p className="centerrecover">{this.t(T_LOGIN.accountNotVerified)}</p>
          <div>
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                this.t(T_LOGIN.resendVerificationEmail)
              )}
            </Button>
          </div>
          <Link to="/">
            <p className="backrecover">{this.t(T_GENERAL.back)}</p>
          </Link>
        </form>
      </div>
    );
  }
}

export default withTranslation()(VerifyEmail);
