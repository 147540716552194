import React from "react";

class RedirectHandler extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div></div>;
  }
}

export default RedirectHandler;
