/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Chart from "react-apexcharts";
import CustomStatisticsTableModal from "./CustomStatisticsTableModal";
import { getShortMonth } from "../../utils/date";
import { withTranslation } from "react-i18next";
import { T_STATISTICS } from "../../constants/translations";

/**
 * The component for the Referred Exams tab of the statistics page. It uses react-apexcharts to render the graphs.
 *
 * @component
 */
class ReferredExams extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      page: 1,
      showStatisticsTableModal: false
    };

    this.goToNextPage = this.goToNextPage.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.openStatisticsTableModal = this.openStatisticsTableModal.bind(this);
    this.closeStatisticsTableModal = this.closeStatisticsTableModal.bind(this);
  }

  componentDidMount() {}

  openStatisticsTableModal() {
    this.setState({ showStatisticsTableModal: true });
  }

  closeStatisticsTableModal() {
    this.setState({ showStatisticsTableModal: false });
  }

  goToPreviousPage() {
    this.setState({ page: 1 });
  }

  goToNextPage() {
    this.setState({ page: 2 });
  }

  render() {
    let siteName = this.props.selectedSite;
    let siteCounters = this.props.analytics.siteCounters;
    let index;
    for (let i = 0; i < siteCounters.length; i++) {
      if (siteCounters[i].siteName === siteName) {
        index = i;
        break;
      }
    }
    let sevenDayOptions = siteCounters[index]?.dailySiteCounters?.map(el => {
      return [
        new Date(el.date).getDate() +
          " " +
          getShortMonth(new Date(el.date).getMonth())
      ];
    });
    let allSiteExamSeries = [
      {
        name: this.t(T_STATISTICS.referredExams),
        data: [...this.props.analytics.siteCounters]
          ?.sort((a, b) => b.totalReferred - a.totalReferred)
          .map(el => el.totalReferred)
      }
    ];
    let sevenDaySpecificSiteSeries = [
      {
        name: this.t(T_STATISTICS.referredExams),
        data: siteCounters[index]?.dailySiteCounters
          // ?.sort((a, b) => b.totalReferred - a.totalReferred)
          .map(el => el.totalReferred)
      }
    ];
    let allSiteExamOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
        height: 350
      },
      colors: ["#25c881"],
      grid: { show: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          vertical: true,
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        categories: [...this.props.analytics.siteCounters]
          ?.sort((a, b) => b.totalReports - a.totalReports)
          .map(el => el.siteName),
        labels: {
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        }
      }
    };
    let sevenDaySpecificSiteOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        },
        height: 350
      },
      colors: ["#25c881"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          vertical: true,
          borderRadius: 10
        }
      },
      grid: { show: false },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: { formatter: value => value }
      },
      xaxis: {
        categories: sevenDayOptions,
        labels: {
          formatter: value =>
            value.length > 13 ? value.substring(0, 13) + "..." : value,
          style: {
            fontSize: "0.8rem"
          }
        }
      }
    };
    // let thirtyDaySpecificSiteOptions = {
    //   chart: {
    //     type: "bar",
    //     toolbar: {
    //       show: false
    //     },
    //     height: "500px"
    //   },
    //   tooltip: {
    //     theme: "dark",
    //     x: {
    //       show: false
    //     },
    //     y: {
    //       title: {
    //         formatter: function() {
    //           return "";
    //         }
    //       }
    //     }
    //   },
    //   colors: ["#82D0A1"],
    //   plotOptions: {
    //     bar: {
    //       vertical: true
    //     }
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   xaxis: {
    //     categories: sevenDayOptions
    //   }
    // };
    // let thirtyDaySpecificSiteSeries = [
    //   {
    //     data: [
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       20,
    //       10,
    //       30,
    //       4,
    //       3,
    //       45,
    //       23,
    //       32,
    //       11
    //     ]
    //   }
    // ];
    if (this.props.siteRadio === "all-sites") {
      return (
        <div className="graph-container">
          <div className="title-container">
            {(() => {
              if (this.props.timelineRadio === "last-seven-days") {
                return (
                  <div className="chart-title">
                    {this.t(T_STATISTICS.referredExamsInLastDays, { count: 7 })}
                  </div>
                );
              } else if (this.props.timelineRadio === "last-thirty-days") {
                return (
                  <div className="chart-title">
                    {this.t(T_STATISTICS.referredExamsInLastDays, {
                      count: 30
                    })}
                  </div>
                );
              } else {
                return (
                  <div className="chart-title">
                    {this.t(T_STATISTICS.referredExamsInMonth, {
                      month: this.props.month
                    })}
                  </div>
                );
              }
            })()}
          </div>
          {(() => {
            if (
              allSiteExamSeries[0].data.reduce((acc, val) => acc + val, 0) === 0
            ) {
              return (
                <div className="chart-container">
                  <div className="chart no-results-container">
                    <img src={require("../../assets/images/no-results.svg")} />
                    <div className="no-results">
                      {this.t(T_STATISTICS.noResultsFound)}
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div className="chart-container">
                <div className="chart">
                  <Chart
                    key={13}
                    options={allSiteExamOptions}
                    series={allSiteExamSeries}
                    type="bar"
                    height="300"
                  ></Chart>
                </div>
              </div>
            );
          })()}
          {(() => {
            return (
              <div className="see-more-container">
                <button onClick={this.openStatisticsTableModal}>
                  {this.t(T_STATISTICS.seeMoreButton)}
                </button>
              </div>
            );
          })()}

          {this.state.showStatisticsTableModal ? (
            <CustomStatisticsTableModal
              closePopup={this.closeStatisticsTableModal}
              analytics={this.props.analytics}
              month={this.props.month}
              selectedSite={this.props.selectedSite}
              siteRadio={this.props.siteRadio}
              timelineRadio={this.props.timelineRadio}
            />
          ) : null}
        </div>
      );
    } else if (this.props.siteRadio === "specific-site") {
      return (
        <div className="graph-container">
          <div className="title-container">
            {(() => {
              if (this.props.timelineRadio === "last-seven-days") {
                return (
                  <div className="chart-title paginated">
                    {this.t(T_STATISTICS.referredExamsInLastDays, { count: 7 })}
                  </div>
                );
              } else if (this.props.timelineRadio === "last-thirty-days") {
                return (
                  <div className="chart-title paginated">
                    {this.t(T_STATISTICS.referredExamsInLastDays, {
                      count: 30
                    })}
                  </div>
                );
              } else {
                return (
                  <div className="chart-title paginated">
                    {this.t(T_STATISTICS.referredExamsInMonth, {
                      month: this.props.month
                    })}
                  </div>
                );
              }
            })()}
          </div>
          {(() => {
            if (this.props.timelineRadio === "last-seven-days") {
              if (
                sevenDaySpecificSiteSeries[0].data.reduce(
                  (acc, val) => acc + val,
                  0
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="chart-container">
                  <div className="chart">
                    <Chart
                      key={14}
                      options={sevenDaySpecificSiteOptions}
                      series={sevenDaySpecificSiteSeries}
                      type="bar"
                      height="300"
                    ></Chart>
                  </div>
                </div>
              );
            } else if (this.props.timelineRadio === "last-thirty-days") {
              if (
                sevenDaySpecificSiteSeries[0].data.reduce(
                  (acc, val) => acc + val,
                  0
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="chart-container">
                  <div className="chart thirty">
                    <Chart
                      key={15}
                      options={sevenDaySpecificSiteOptions}
                      series={sevenDaySpecificSiteSeries}
                      type="bar"
                      height="300"
                    ></Chart>
                  </div>
                </div>
              );
            } else if (this.props.timelineRadio === "specific-month") {
              if (
                sevenDaySpecificSiteSeries[0].data.reduce(
                  (acc, val) => acc + val,
                  0
                ) === 0
              ) {
                return (
                  <div className="chart-container">
                    <div className="chart no-results-container">
                      <img
                        src={require("../../assets/images/no-results.svg")}
                      />
                      <div className="no-results">
                        {this.t(T_STATISTICS.noResultsFound)}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="chart-container">
                  <div className="chart thirty">
                    <Chart
                      key={16}
                      options={sevenDaySpecificSiteOptions}
                      series={sevenDaySpecificSiteSeries}
                      type="bar"
                      height="300"
                    ></Chart>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      );
    }
  }
}

export default withTranslation()(ReferredExams);
