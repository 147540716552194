/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import FsLightbox from "fslightbox-react";
import $ from "jquery";
import CustomGenerateReportModal from "./CustomGenerateReportModal";
import CustomGenerateReportWithoutImagesModal from "./CustomGenerateReportWithoutImagesModal";
import CustomEditExamDetailsModal from "./CustomEditExamDetailsModal";
import CustomEditPatientExamModal from "./CustomEditPatientExamModal";
import {
  getImages,
  getVideos,
  getUser,
  getReportLink,
  getExamDetails,
  getAIReportLink,
  getEyrisReportLink,
  assignExam,
  zipExam,
  getPathForDownload
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  putAuthenticatedData,
  postAuthenticatedData
} from "../../utils/async";
import { Button } from "react-bootstrap";
import { convertEpochToDate, convertEpochToTime } from "../../utils/date";
import { toast } from "react-toastify";
import LightGallery from "../../lib/lightgallery/react";
import lgThumbnail from "../../lib/lightgallery/plugins/thumbnail";
import "../../lib/lightgallery/css/lightgallery.css";
import "../../lib/lightgallery/css/lg-thumbnail.css";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { T_ENTITIES, T_EXAMS, T_REPORT } from "../../constants/translations";

// import { Link } from "react-router-dom";
/**
 * This is the component for the Exam Details pages.<br>
 * It uses fs-lightbox-react for the gallery view. <br>
 *
 * SRS IDs: <br>
 * AIM - 5.3.14.5, 5.3.14.6, 5.3.14.20, 5.3.14.23, 5.3.14.25, 5.3.14.26, 5.3.14.29 <br>
 * PSL - 5.3.14.5, 5.3.14.6, 5.3.14.20, 5.3.14.23, 5.3.14.25, 5.3.14.26, 5.3.14.29 <br>
 * FOP - 5.3.14.5, 5.3.14.6, 5.3.14.20, 5.3.14.24, 5.3.14.26, 5.3.14.27 <br>
 *
 * @component
 */
class ExamDetails extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      images: [],
      imagesBig: [],
      imageCaptions: [],
      videos: [],
      videoThumbnails: [],
      slideVideos: 999,
      togglerVideos: false,
      small: [],
      smallEdited: [],
      toggler: false,
      examId: this.props.location.state.exam.examDetails.id,
      showGenerateReportModal: false,
      showGenerateReportWithoutImagesModal: false,
      showEditExamDetailsModal: false,
      ocularHistory: "",
      systemicHistory: "",
      showEditPatientModal: false,
      currentMedication: "",
      allergies: "",
      isDoctor: false,
      slide: 999,
      report: {},
      patientDetails: null,
      editableAttributes: {},
      selectedAttribute: "ocularHistory",
      selectedTab: 3,
      examDetails: {},
      OneSecondOver: false,
      visionAssessment: this.props.location.state.exam.visionAssessment,
      toggledSegments: {},
      toggledSegmentsEdited: {},
      rawImages: []
    };
    this.viewImages = this.viewImages.bind(this);
    this.viewVideos = this.viewVideos.bind(this);
    this.openGenerateReportModal = this.openGenerateReportModal.bind(this);
    this.hideGenerateReportModal = this.hideGenerateReportModal.bind(this);
    this.hideGenerateReportWithoutImagesModal = this.hideGenerateReportWithoutImagesModal.bind(
      this
    );
    this.hideEditExamDetailsModal = this.hideEditExamDetailsModal.bind(this);
    this.toggleEditPatientModal = this.toggleEditPatientModal.bind(this);
    this.viewReport = this.viewReport.bind(this);
    this.viewAIReport = this.viewAIReport.bind(this);
    this.resetList = this.resetList.bind(this);
    this.editAttribute = this.editAttribute.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.startGrading = this.startGrading.bind(this);
    this.startGradingWithoutImages = this.startGradingWithoutImages.bind(this);
    this.setTab = this.setTab.bind(this);
    this.zipExam = this.zipExam.bind(this);
    this.download = this.download.bind(this);
    this.onSlideItemLoad = this.onSlideItemLoad.bind(this);
    this.onInit = this.onInit.bind(this);
    this.onAfterSlide = this.onAfterSlide.bind(this);
    this.viewGlaucomaReport = this.viewGlaucomaReport.bind(this);
    this.viewEyrisReport = this.viewEyrisReport.bind(this);
    this.viewMediosAIReport = this.viewMediosAIReport.bind(this);
    this.groupAndSortImages = this.groupAndSortImages.bind(this);
    this.toggleSegment = this.toggleSegment.bind(this);
    this.toggleSegmentEdited = this.toggleSegmentEdited.bind(this);
    this.renderImagesByDate = this.renderImagesByDate.bind(this);
  }

  componentDidMount() {
    this.resetList();
    this.updateValue();
    this.state.userResults.then(result => {
      if (result?.data?.roles?.includes("DOCTOR")) {
        this.setState({ isDoctor: true });
      }
    });
    let mrn = this.props.location.state.exam.patientDetails?.mrn;
    let firstName = this.props.location.state.exam.patientDetails?.firstName;
    let lastName = this.props.location.state.exam.patientDetails?.lastName;
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getImages +
        "/" +
        this?.props?.location?.state?.exam?.examDetails?.id
    ).then(result => {
      let images,
        editedImages = [];
      this.setState({ rawImages: result });
      images = result?.data?.fopImages?.["STANDARD"]
        .concat(result?.data?.aimImages?.["STANDARD"])
        .concat(result?.data?.pslImages?.["STANDARD"])
        .concat(result?.data?.otherImages?.["STANDARD"])
        .concat(result?.data?.pristineImages?.["STANDARD"]);
      editedImages = result?.data?.fopImages?.["EDITED"]
        .concat(result?.data?.aimImages?.["EDITED"])
        .concat(result?.data?.pslImages?.["EDITED"])
        .concat(result?.data?.otherImages?.["EDITED"])
        .concat(result?.data?.pristineImages?.["EDITED"]);
      let imagesThumbnails = images?.map(image => image.thumbnailPath);
      let imageCaptions = images?.concat(editedImages)?.map(
        image =>
          `<h4 className="img-caption-heading">
            ${mrn +
              " - " +
              firstName +
              " " +
              lastName +
              " - " +
              (image.laterality === "LEFT"
                ? this.t(T_EXAMS.examDetails.generateReport.leftEye)
                : this.t(T_EXAMS.examDetails.generateReport.rightEye))}
          </h4>`
      );
      this.setState({
        images: imagesThumbnails,
        imagesBig: images?.concat(editedImages).map(image => image.path),
        small: images,
        smallEdited: editedImages,
        editedImages: editedImages?.map(image => image.thumbnailPath),
        isLoaded: true,
        imageCaptions
      });
    });
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getVideos +
        "/" +
        this?.props?.location?.state?.exam?.examDetails?.id
    ).then(result => {
      let videos = result?.data?.map?.(video => video.path);
      let videoThumbnails = result?.data?.map?.(video => video.thumbnailPath);
      this.setState({
        videos,
        videoThumbnails
      });
    });
    this.setState({ report: this.props?.location?.state?.exam?.report });
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  onInit() {
    this.setState({ OneSecondOver: true });
  }

  resetList() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getExamDetails +
        "/" +
        this?.props?.location?.state?.exam?.examDetails?.id
    ).then(result => {
      this.setState({
        report: result?.data?.report,
        patientDetails: result?.data?.patientDetails,
        examDetails: result?.data?.examDetails,
        visionAssessment: result?.data?.visionAssessment
      });
    });
  }

  toDataURL(url) {
    return fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
  }
  async download(url) {
    var link = document.createElement("a");
    link.href = await this.toDataURL(url);
    link.download = Math.floor(100000 + Math.random() * 900000);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onSlideItemLoad() {
    wheelzoom($(".lg-current").find(".lg-image")[0]);
  }

  onAfterSlide() {
    document
      .querySelectorAll("img")
      .forEach(el => el.dispatchEvent(new CustomEvent("wheelzoom.reset")));
  }

  toggleSegment(date, segment) {
    this.setState(prevState => ({
      toggledSegments: {
        ...prevState.toggledSegments,
        [date]: segment
      }
    }));
  }

  toggleSegmentEdited(date, segment) {
    this.setState(prevState => ({
      toggledSegmentsEdited: {
        ...prevState.toggledSegmentsEdited,
        [date]: segment
      }
    }));
  }

  renderImagesByDate(date, imagesBySegment) {
    // Use the toggledSegments state to determine which segment to show
    const selectedSegment = this.state.toggledSegments[date] || "POSTERIOR";
    const imagesToShow = imagesBySegment[selectedSegment];

    return (
      <div className="image-grid">
        {imagesToShow.map(image => (
          <a className="gallery-item" href={image.path} key={image.id}>
            <img src={image.thumbnailPath} />
          </a>
        ))}
      </div>
    );
  }

  zipExam() {
    postAuthenticatedData(
      sessionStorage.getItem("domain") +
        zipExam +
        "/" +
        this.state.examDetails.id
    )
      .then(res => {
        return res.json();
      })
      .then(res => {
        // eslint-disable-next-line no-console
        toast.success(
          this.t(T_EXAMS.toast.examDownloadForPatient, {
            name:
              this.state.patientDetails.firstName +
              (this.state.patientDetails.lastName
                ? " " + this.state.patientDetails.lastName
                : "")
          })
        );
        let interval = setInterval(() => {
          postAuthenticatedData(
            sessionStorage.getItem("domain") +
              getPathForDownload +
              "/" +
              res.data
          )
            .then(res => {
              return res.json();
            })
            .then(res => {
              // eslint-disable-next-line no-console
              if (res.status.statusCode === "OK") {
                clearInterval(interval);
                this.download(res.data);
              }
            });
        }, 1000);
      });
  }

  setTab(tab) {
    this.setState({ selectedTab: tab });
  }

  startGrading() {
    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        assignExam +
        "/" +
        this?.props?.location?.state?.exam?.examDetails?.id
    )
      .then(result => {
        if (result.status === 200) {
          this.props?.history?.push?.({
            pathname: "/grader-flow",
            state: {
              big: this.state.imagesBig,
              exam: this.props.location.state.exam,
              deviceType: this.props.location.state.deviceType,
              hasAssessment: !!this.state.visionAssessment,
              visionAssessment: this.state.visionAssessment
            }
          });
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
    // this.props?.history?.push?.({
    //   pathname: "/grader-flow",
    //   state: {
    //     big: this.state.imagesBig,
    //     exam: this.props.location.state.exam,
    //     deviceType: this.props.location.state.deviceType
    //   }
    // });
  }

  updateValue() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getExamDetails +
        "/" +
        this?.props?.location?.state?.exam?.examDetails?.id
    ).then(result => {
      this.setState({
        ocularHistory: result?.data?.examDetails?.ocularHistory,
        systemicHistory: result?.data?.examDetails?.medicalHistory,
        currentMedication: result?.data?.examDetails?.currentMedication,
        allergies: result?.data?.examDetails?.allergies,
        examState: result?.data?.examDetails?.examState,
        selectedTab:
          result?.data?.examDetails?.deviceType.includes("INSTAREF") &&
          result?.data?.visionAssessment &&
          result?.data?.images === null
            ? 2
            : 3,
        editableAttributes: {
          ocularHistory: {
            text: "Ocular History",
            enum: "OCULAR_HISTORY",
            currentValue: result?.data?.examDetails?.ocularHistory
          },
          medicalHistory: {
            text: "Systemic History",
            enum: "MEDICAL_HISTORY",
            currentValue: result?.data?.examDetails?.medicalHistory
          },
          currentMedication: {
            text: "Current Medication",
            enum: "CURRENT_MEDICATIONS",
            currentValue: result?.data?.examDetails?.currentMedication
          },
          allergies: {
            text: "Allergies",
            enum: "ALLERGIES",
            currentValue: result?.data?.examDetails?.allergies
          }
        }
      });
    });
  }
  /**
   * Triggers the gallery view for images
   * @param {number} number the slide number
   *
   *
   */
  viewImages(number) {
    this.setState({ slide: number }, () => {
      this.setState({ toggler: !this.state.toggler });
    });
  }

  /**
   * Triggers the gallery view for videos
   * @param {number} number the slide number
   *
   *
   */
  viewVideos(number) {
    this.setState(
      {
        slideVideos: number
      },
      () => {
        this.setState({ togglerVideos: !this.state.togglerVideos });
      }
    );
  }

  /**
   * Hides the generate report modal
   *
   *
   *
   */
  hideGenerateReportModal() {
    this.setState({ showGenerateReportModal: false });
  }

  hideGenerateReportWithoutImagesModal() {
    this.setState({ showGenerateReportWithoutImagesModal: false });
  }

  groupAndSortImages(images) {
    function dateStringToTimestamp(dateString) {
      const isItalian = i18next.language === "it";
      if (isItalian) {
        const monthNames = {
          Gennaio: 0,
          Febbraio: 1,
          Marzo: 2,
          Aprile: 3,
          Maggio: 4,
          Giugno: 5,
          Luglio: 6,
          Agosto: 7,
          Settembre: 8,
          Ottobre: 9,
          Novembre: 10,
          Dicembre: 11
        };

        const [day, month, year] = dateString.split(" ");

        const monthIndex = monthNames[month];

        if (monthIndex === undefined) {
          throw new Error("Invalid month name");
        }

        const date = new Date(year, monthIndex, day);
        return date.getTime();
      }
      return new Date(dateString).getTime();
    }
    if (images && images.length > 0) {
      const groups = images.reduce(
        (acc, image) => {
          const date = convertEpochToDate(image.date); // Convert timestamp to a date string
          if (!acc.map[date]) {
            acc.map[date] = { ANTERIOR: [], POSTERIOR: [] };
            acc.dates.push(dateStringToTimestamp(date)); // Keep track of dates for sorting
          }
          acc.map[date][image.imageSegment]?.push(image);
          return acc;
        },
        { map: {}, dates: [] }
      );

      // Sort dates in reverse chronological order
      groups.dates.sort((a, b) => b - a);

      // Create a new object with sorted dates
      const sortedGroupedByDate = {};
      groups.dates.forEach(timestamp => {
        const date = convertEpochToDate(timestamp); // Convert back to a date string
        sortedGroupedByDate[date] = groups.map[date];

        // Sort each group by date in reverse chronological order
        sortedGroupedByDate[date]?.ANTERIOR?.sort((a, b) => b.date - a.date);
        sortedGroupedByDate[date]?.POSTERIOR?.sort((a, b) => b.date - a.date);
      });

      return sortedGroupedByDate;
    }
  }

  /**
   * Hides the exam details modal
   *
   *
   *
   */
  hideEditExamDetailsModal() {
    this.setState({ showEditExamDetailsModal: false });
  }

  toggleAddAdminAccountModal() {
    this.setState({
      showAddAccountModal: !this.state.showAddAccountModal
    });
  }

  toggleEditPatientModal() {
    this.setState({ showEditPatientModal: !this.state.showEditPatientModal });
  }

  openGenerateReportModal() {
    this.setState({ showGenerateReportModal: true });
  }

  startGradingWithoutImages() {
    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        assignExam +
        "/" +
        this?.props?.location?.state?.exam?.examDetails?.id
    )
      .then(result => {
        if (result.status === 200) {
          this.setState({ showGenerateReportWithoutImagesModal: true });
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }
  /**
   * Opens the modal to edit an exam attribute
   * @param {string} attribute the attribute to be edited
   *
   *
   *
   */
  editAttribute(attribute) {
    this.setState({
      selectedAttribute: attribute,
      showEditExamDetailsModal: true
    });
  }
  /**
   * Triggers the API call to view a report
   *
   *
   *
   */
  viewReport() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getReportLink +
        `/${this?.props?.location?.state?.exam?.examDetails?.id}`
    ).then(
      result => {
        if (result.status.statusCode === "OK") {
          window.open(result.data, "_blank");
        } else {
          toast.error(this.t(T_EXAMS.toast.tryAgainAfterSometime));
        }
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  viewMediosAIReport() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAIReportLink +
        `/${this?.props?.location?.state?.exam?.examDetails?.id}`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  viewGlaucomaReport() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAIReportLink +
        `/${this?.props?.location?.state?.exam?.examDetails?.id}?diseaseName=GMA`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  viewEyrisReport() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getEyrisReportLink +
        `/${this?.props?.location?.state?.exam?.examDetails?.id}`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  viewAIReport() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAIReportLink +
        `/${this?.props?.location?.state?.exam?.examDetails?.id}?diseaseName=DR`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  resetPatientDetails(patient) {
    this.setState({ patientDetails: patient });
  }

  render() {
    const {
      isLoaded,
      toggler,
      imagesBig,
      images,
      examId,
      slideVideos,
      slide,
      videos,
      videoThumbnails,
      togglerVideos,
      imageCaptions
    } = this.state;
    let primaryExamTemplate,
      visionAssessmentTemplate,
      imagesTemplate,
      reportsTemplate = null;
    if (this.state.selectedTab === 1) {
      primaryExamTemplate = (
        <div className="content-container">
          <div className="medical-history">
            <div className="history-container">
              <h5 className="medical-history-heading">
                {this.t(T_EXAMS.examDetails.primaryExamDetails.ocularHistory)}
              </h5>
              <div
                className="edit-medical-history"
                onClick={() => this.editAttribute("ocularHistory")}
              >
                <img src={require("../../assets/images/edit-icon.svg")} />
              </div>
            </div>
            <div className="medical-history-contents">
              {this.state.ocularHistory && this.state.ocularHistory !== ""
                ? this.state.ocularHistory
                : this.t(T_EXAMS.examDetails.primaryExamDetails.none)}
            </div>
          </div>
        </div>
      );
    }
    if (this.state.selectedTab === 2) {
      visionAssessmentTemplate = (
        <div>
          {(() => {
            if (this.state.visionAssessment) {
              return (
                <div className="content-container">
                  <div className="readings-big-container">
                    <p className="row-title">
                      {this.t(T_EXAMS.examDetails.visionAssessment.rightEye)}
                    </p>
                    <div className="reading-container">
                      <div className="table-heading">
                        {this.t(T_EXAMS.examDetails.visionAssessment.reading1)}
                      </div>
                      <div className="table-readings">
                        <div className="table-row-heading top-left">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.spherical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading1?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading2?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading3?.sph
                          }
                        </div>
                        <div className="table-aggregate top-right">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.average.sph
                          }
                        </div>
                        <div className="table-row-heading">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.cylindrical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading1?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading2?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading3?.cyl
                          }
                        </div>
                        <div className="table-aggregate">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.average.cyl
                          }
                        </div>
                        <div className="table-row-heading bottom-left">
                          {this.t(T_EXAMS.examDetails.visionAssessment.axis)}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading1?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading2?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.reading3?.axis
                          }
                        </div>
                        <div className="table-aggregate bottom-right">
                          {
                            this.state.visionAssessment.readingSet1?.reReading
                              ?.average.axis
                          }
                        </div>
                      </div>
                    </div>
                    <div className="reading-container">
                      <div className="table-heading">
                        {this.t(T_EXAMS.examDetails.visionAssessment.reading2)}
                      </div>
                      <div className="table-readings">
                        <div className="table-row-heading top-left">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.spherical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading1?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading2?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading3?.sph
                          }
                        </div>
                        <div className="table-aggregate top-right">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.average.sph
                          }
                        </div>
                        <div className="table-row-heading">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.cylindrical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading1?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading2?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading3?.cyl
                          }
                        </div>
                        <div className="table-aggregate">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.average.cyl
                          }
                        </div>
                        <div className="table-row-heading bottom-left">
                          {this.t(T_EXAMS.examDetails.visionAssessment.axis)}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading1?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading2?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.reading3?.axis
                          }
                        </div>
                        <div className="table-aggregate bottom-right">
                          {
                            this.state.visionAssessment.readingSet2?.reReading
                              ?.average.axis
                          }
                        </div>
                      </div>
                    </div>
                    <p className="row-title">
                      {this.t(T_EXAMS.examDetails.visionAssessment.leftEye)}
                    </p>
                    <div className="reading-container">
                      <div className="table-readings">
                        <div className="table-row-heading top-left">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.spherical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading1?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading2?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading3?.sph
                          }
                        </div>
                        <div className="table-aggregate top-right">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.average.sph
                          }
                        </div>
                        <div className="table-row-heading">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.cylindrical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading1?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading2?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading3?.cyl
                          }
                        </div>
                        <div className="table-aggregate">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.average.cyl
                          }
                        </div>
                        <div className="table-row-heading bottom-left">
                          {this.t(T_EXAMS.examDetails.visionAssessment.axis)}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading1?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading2?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.reading3?.axis
                          }
                        </div>
                        <div className="table-aggregate bottom-right">
                          {
                            this.state.visionAssessment.readingSet1?.leReading
                              ?.average.axis
                          }
                        </div>
                      </div>
                    </div>
                    <div className="reading-container">
                      <div className="table-readings">
                        <div className="table-row-heading">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.spherical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading1?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading2?.sph
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading3?.sph
                          }
                        </div>
                        <div className="table-aggregate">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.average.sph
                          }
                        </div>
                        <div className="table-row-heading">
                          {this.t(
                            T_EXAMS.examDetails.visionAssessment.cylindrical
                          )}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading1?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading2?.cyl
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading3?.cyl
                          }
                        </div>
                        <div className="table-aggregate">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.average.cyl
                          }
                        </div>
                        <div className="table-row-heading">
                          {this.t(T_EXAMS.examDetails.visionAssessment.axis)}
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading1?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading2?.axis
                          }
                        </div>
                        <div className="table-contents">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.reading3?.axis
                          }
                        </div>
                        <div className="table-aggregate">
                          {
                            this.state.visionAssessment.readingSet2?.leReading
                              ?.average.axis
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="content-container">
                  <div className="no-values-container">
                    <img src={require("../../assets/images/scan.png")} />
                    <p>
                      {this.t(T_EXAMS.examDetails.visionAssessment.noValues)}
                    </p>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      );
    }
    if (
      this.state.selectedTab === 3 &&
      !this.state.examDetails?.deviceType?.includes("PRISTINE")
    ) {
      imagesTemplate = (
        <div className="content-container">
          <div className="exam-images">
            <div className="image-heading">
              {this.t(T_EXAMS.examDetails.imagesAndVideos.images)}
            </div>
            <div className="image-grid">
              {(() => {
                if (!this.state?.images || this.state?.images?.length === 0) {
                  return (
                    <p>
                      {this.t(T_EXAMS.examDetails.imagesAndVideos.noImages)}
                    </p>
                  );
                }
                let imageHTML = this.state?.images?.map?.((image, key) => (
                  <>
                    <a
                      className="gallery-item"
                      data-sub-html={imageCaptions[key]}
                      href={imagesBig[key]}
                      key={key}
                    >
                      <img src={image} key={key} />
                      {(() => {
                        if (this.state.OneSecondOver) {
                          return (
                            <img
                              key={key}
                              className="download-btn"
                              src={require("../../assets/images/download.svg")}
                              onClick={() => {
                                this.download(this.state.imagesBig[key]);
                              }}
                            />
                          );
                        }
                      })()}
                    </a>
                    {/* <img
                      key={key}
                      className="download-btn"
                      src={require("../../assets/images/download.svg")}
                      onClick={() => {
                        this.download(this.state.imagesBig[key]);
                      }}
                    /> */}
                  </>
                ));
                return (
                  <LightGallery
                    onInit={this.onInit}
                    onSlideItemLoad={this.onSlideItemLoad}
                    onAfterSlide={this.onAfterSlide}
                  >
                    {imageHTML}
                  </LightGallery>
                );
              })()}
            </div>
          </div>
          <div className="exam-images">
            <div className="image-heading">
              {this.t(T_EXAMS.examDetails.imagesAndVideos.editedImages)}
            </div>
            <div className="image-grid">
              {(() => {
                if (
                  !this.state?.editedImages ||
                  this.state?.editedImages?.length === 0
                ) {
                  return (
                    <p>
                      {this.t(T_EXAMS.examDetails.imagesAndVideos.noImages)}
                    </p>
                  );
                }
                let imageEditedHTML = this.state?.editedImages?.map?.(
                  (image, key) => (
                    <>
                      <a
                        className="gallery-item"
                        data-sub-html={
                          imageCaptions[key + this.state?.images?.length]
                        }
                        href={imagesBig[key + this.state?.images?.length]}
                        key={key}
                      >
                        <img src={image} key={key} />
                        {(() => {
                          if (this.state.OneSecondOver) {
                            return (
                              <img
                                key={key}
                                className="download-btn"
                                src={require("../../assets/images/download.svg")}
                                onClick={() => {
                                  this.download(
                                    this.state.smallEdited[key].path
                                  );
                                }}
                              />
                            );
                          }
                        })()}
                      </a>
                      {/* <img
                        key={key}
                        className="download-btn"
                        src={require("../../assets/images/download.svg")}
                        onClick={() => {
                          this.download(this.state.smallEdited[key].path);
                        }}
                      /> */}
                    </>
                  )
                );
                return (
                  <LightGallery
                    onInit={this.onInit}
                    onSlideItemLoad={this.onSlideItemLoad}
                    onAfterSlide={this.onAfterSlide}
                  >
                    {imageEditedHTML}
                  </LightGallery>
                );
              })()}
            </div>
          </div>
          <div className="exam-images">
            <div className="image-heading">
              {this.t(T_EXAMS.examDetails.imagesAndVideos.videos)}
            </div>
            <div className="image-grid image-grid-videos">
              {(() => {
                if (!this.state?.videos || this.state?.videos?.length === 0) {
                  return (
                    <p>
                      {this.t(T_EXAMS.examDetails.imagesAndVideos.noVideos)}
                    </p>
                  );
                }
                return this.state?.videoThumbnails?.map?.((video, key) => (
                  <div key={key}>
                    <img onClick={() => this.viewVideos(key)} src={video} />
                    <img
                      onClick={() => this.viewVideos(key)}
                      className="play-btn"
                      src={require("../../assets/images/play-icon.svg")}
                    />
                    <img
                      key={key}
                      className="download-btn"
                      src={require("../../assets/images/download.svg")}
                      onClick={() => {
                        this.download(this.state.videos[key]);
                      }}
                    />
                  </div>
                ));
              })()}
            </div>
          </div>
        </div>
      );
    } else if (
      this.state.selectedTab === 3 &&
      this.state.examDetails?.deviceType?.includes("PRISTINE")
    ) {
      const groupedImages = this.groupAndSortImages(this.state.small);
      const groupedEditedImages = this.groupAndSortImages(
        this.state.smallEdited
      );
      // eslint-disable-next-line no-console
      console.log(groupedImages);
      imagesTemplate = (
        <div className="content-container">
          <div className="exam-images">
            <div className="image-heading">
              {this.t(T_EXAMS.examDetails.imagesAndVideos.images)}
            </div>
            {(() => {
              if (
                !groupedImages ||
                Object.getOwnPropertyNames(groupedImages).length === 0
              ) {
                return (
                  <p>{this.t(T_EXAMS.examDetails.imagesAndVideos.noImages)}</p>
                );
              } else {
                return Object.entries(groupedImages).map(
                  ([date, imagesBySegment]) => {
                    const selectedSegment =
                      this.state.toggledSegments[date] || "POSTERIOR";
                    const imagesToShow = imagesBySegment[selectedSegment];
                    // eslint-disable-next-line no-console
                    console.log(imagesToShow);
                    return (
                      <>
                        <div className="pristine-heading-container">
                          <div className="pristine-date">{date}</div>
                          <div className="toggle-collection">
                            <div
                              className={`left-toggle toggle ${
                                this.state.toggledSegments[date] ===
                                  "POSTERIOR" ||
                                !this.state.toggledSegments[date]
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                this.toggleSegment(date, "POSTERIOR")
                              }
                            >
                              {this.t(
                                T_EXAMS.examDetails.imagesAndVideos.posterior
                              )}
                            </div>
                            <div
                              className={`right-toggle toggle ${
                                this.state.toggledSegments[date] === "ANTERIOR"
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                this.toggleSegment(date, "ANTERIOR")
                              }
                            >
                              {this.t(
                                T_EXAMS.examDetails.imagesAndVideos.anterior
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="image-grid">
                          {(() => {
                            if (!imagesToShow || imagesToShow.length === 0) {
                              return (
                                <p>
                                  {this.t(
                                    T_EXAMS.examDetails.imagesAndVideos.noImages
                                  )}
                                </p>
                              );
                            }
                            let imageHTML = imagesToShow?.map?.(
                              (image, key) => (
                                <>
                                  <a
                                    className="gallery-item"
                                    data-sub-html={`<h4 className="img-caption-heading">
                      ${this.props.location.state.exam.patientDetails?.mrn +
                        " - " +
                        this.props.location.state.exam.patientDetails
                          ?.firstName +
                        " " +
                        this.props.location.state.exam.patientDetails
                          ?.lastName +
                        " - " +
                        (image.laterality === "LEFT"
                          ? this.t(T_EXAMS.examDetails.generateReport.leftEye)
                          : this.t(
                              T_EXAMS.examDetails.generateReport.rightEye
                            ))}
                    </h4>`}
                                    href={image.path}
                                    key={key}
                                  >
                                    <img src={image.thumbnailPath} key={key} />
                                    {(() => {
                                      if (this.state.OneSecondOver) {
                                        return (
                                          <img
                                            key={key}
                                            className="download-btn"
                                            src={require("../../assets/images/download.svg")}
                                            onClick={() => {
                                              this.download(image.path);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </a>
                                </>
                              )
                            );
                            // eslint-disable-next-line no-console
                            console.log(imageHTML);
                            return (
                              <LightGallery
                                key={selectedSegment}
                                onInit={this.onInit}
                                onSlideItemLoad={this.onSlideItemLoad}
                                onAfterSlide={this.onAfterSlide}
                              >
                                {imageHTML}
                              </LightGallery>
                            );
                          })()}
                        </div>
                      </>
                    );
                  }
                );
              }
            })()}
          </div>
          <div className="exam-images">
            <div className="image-heading">
              {this.t(T_EXAMS.examDetails.imagesAndVideos.editedImages)}
            </div>
            {(() => {
              if (
                !groupedEditedImages ||
                Object.getOwnPropertyNames(groupedEditedImages).length === 0
              ) {
                return (
                  <p>{this.t(T_EXAMS.examDetails.imagesAndVideos.noImages)}</p>
                );
              } else {
                return Object.entries(groupedEditedImages).map(
                  ([date, imagesBySegment]) => {
                    const selectedSegmentEdited =
                      this.state.toggledSegmentsEdited[date] || "POSTERIOR";
                    const imagesToShowEdited =
                      imagesBySegment[selectedSegmentEdited];
                    // eslint-disable-next-line no-console
                    console.log(imagesToShowEdited);
                    return (
                      <>
                        <div className="pristine-heading-container">
                          <div className="pristine-date">{date}</div>
                          <div className="toggle-collection">
                            <div
                              className={`left-toggle toggle ${
                                this.state.toggledSegmentsEdited[date] ===
                                  "POSTERIOR" ||
                                !this.state.toggledSegmentsEdited[date]
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                this.toggleSegmentEdited(date, "POSTERIOR")
                              }
                            >
                              {this.t(
                                T_EXAMS.examDetails.imagesAndVideos.posterior
                              )}
                            </div>
                            <div
                              className={`right-toggle toggle ${
                                this.state.toggledSegmentsEdited[date] ===
                                "ANTERIOR"
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                this.toggleSegmentEdited(date, "ANTERIOR")
                              }
                            >
                              {this.t(
                                T_EXAMS.examDetails.imagesAndVideos.anterior
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="image-grid">
                          {(() => {
                            if (
                              !imagesToShowEdited ||
                              imagesToShowEdited.length === 0
                            ) {
                              return (
                                <p>
                                  {this.t(
                                    T_EXAMS.examDetails.imagesAndVideos.noImages
                                  )}
                                </p>
                              );
                            }
                            let imageHTML = imagesToShowEdited?.map?.(
                              (image, key) => (
                                <>
                                  <a
                                    className="gallery-item"
                                    data-sub-html={`<h4 className="img-caption-heading">
                      ${this.props.location.state.exam.patientDetails?.mrn +
                        " - " +
                        this.props.location.state.exam.patientDetails
                          ?.firstName +
                        " " +
                        this.props.location.state.exam.patientDetails
                          ?.lastName +
                        " - " +
                        (image.laterality === "LEFT"
                          ? this.t(T_EXAMS.examDetails.generateReport.leftEye)
                          : this.t(
                              T_EXAMS.examDetails.generateReport.rightEye
                            ))}
                    </h4>`}
                                    href={image.path}
                                    key={key}
                                  >
                                    <img src={image.thumbnailPath} key={key} />
                                    {(() => {
                                      if (this.state.OneSecondOver) {
                                        return (
                                          <img
                                            key={key}
                                            className="download-btn"
                                            src={require("../../assets/images/download.svg")}
                                            onClick={() => {
                                              this.download(image.path);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </a>
                                </>
                              )
                            );
                            // eslint-disable-next-line no-console
                            console.log(imageHTML);
                            return (
                              <LightGallery
                                key={selectedSegmentEdited}
                                onInit={this.onInit}
                                onSlideItemLoad={this.onSlideItemLoad}
                                onAfterSlide={this.onAfterSlide}
                              >
                                {imageHTML}
                              </LightGallery>
                            );
                          })()}
                        </div>
                      </>
                    );
                  }
                );
              }
            })()}
          </div>
        </div>
      );
    }
    if (this.state.selectedTab === 4) {
      reportsTemplate = (
        <div className="content-container">
          <div className="report-buttons-container">
            <div className="report-megacontainer">
              {(() => {
                if (this.state.report) {
                  return (
                    <div
                      onClick={() => this.viewReport()}
                      className="view-report-container"
                    >
                      <img src={require("../../assets/images/report.png")} />
                      <div className="report-label">
                        {this.t(T_EXAMS.examDetails.reports.report)}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="view-report-container disabled">
                      <img src={require("../../assets/images/report.png")} />
                      <div className="report-label">
                        {this.t(T_EXAMS.examDetails.reports.report)}
                      </div>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (this.props?.location?.state?.exam?.mediosAIReport) {
                  return (
                    <div
                      onClick={() => this.viewMediosAIReport()}
                      className="view-report-container"
                    >
                      <img src={require("../../assets/images/ai-report.png")} />
                      <div className="report-label">
                        {this.t(T_EXAMS.examDetails.reports.mediosAiReport)}
                      </div>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (
                  this.props?.location?.state?.exam?.aiReport &&
                  !this.props?.location?.state?.exam?.mediosAIReport
                ) {
                  return (
                    <div
                      onClick={() => this.viewAIReport()}
                      className="view-report-container"
                    >
                      <img
                        src={require("../../assets/images/dr-ai-report.svg")}
                      />
                      <div className="report-label">
                        {this.t(T_EXAMS.examDetails.reports.drAiReport)}
                      </div>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (
                  this.props?.location?.state?.exam?.gmaReport &&
                  !this.props?.location?.state?.exam?.mediosAIReport
                ) {
                  return (
                    <div
                      onClick={() => this.viewGlaucomaReport()}
                      className="view-report-container"
                    >
                      <img src={require("../../assets/images/glaucoma.svg")} />
                      <div className="report-label">
                        {this.t(T_EXAMS.examDetails.reports.glaucomaAiReport)}
                      </div>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (
                  this.props?.location?.state?.exam?.eyrisReport &&
                  !this.props?.location?.state?.exam?.mediosAIReport
                ) {
                  return (
                    <div
                      onClick={() => this.viewEyrisReport()}
                      className="view-report-container"
                    >
                      <img
                        src={require("../../assets/images/eyris-report.png")}
                      />
                      <div className="report-label">
                        {this.t(T_EXAMS.examDetails.reports.eyrisReport)}
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      );
    }
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row exam-details-page">
            <div className="col-md-2 sidebar">
              <Sidebar from="3" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding exam-dark-background">
              <div className="new-exam-details-header">
                <img
                  className="exam-back"
                  onClick={this.props.history.goBack}
                  src={require("../../assets/images/left-arrow-black.svg")}
                />
                <div className="new-exam-details-patient-name">
                  {this.state.patientDetails?.firstName}{" "}
                  {this.state.patientDetails?.lastName
                    ? this.state.patientDetails?.lastName
                    : ""}
                </div>
                <div className="new-exam-details-patient-details-container">
                  <div className="new-exam-details-patient-details">
                    <div>
                      {this.t(T_ENTITIES.patient.mrn)}:{" "}
                      <span className="new-exam-details-value">
                        {this.state.patientDetails?.mrn}
                      </span>
                    </div>
                    <div>
                      {this.t(T_ENTITIES.patient.gender)}:{" "}
                      <span className="new-exam-details-value">
                        {this.state.patientDetails?.gender === "MALE"
                          ? this.t(T_REPORT.male)
                          : this.t(T_REPORT.female)}
                      </span>
                    </div>
                    <div>
                      {this.t(T_ENTITIES.patient.dateOfBirth)}:{" "}
                      <span className="new-exam-details-value">
                        {convertEpochToDate(
                          this.state.patientDetails?.dateOfBirth
                        )}
                      </span>
                    </div>
                    {(() => {
                      if (sessionStorage.getItem("tele") === "true") {
                        return (
                          <div>
                            {this.t(T_ENTITIES.exam.examState)}:{" "}
                            <span className="new-exam-details-value">
                              {(() => {
                                switch (this.state.examState) {
                                  case "ACTIVE":
                                    return this.t(
                                      T_ENTITIES.exam.examStateTypes.active
                                    );

                                  case "ASSIGNED":
                                    return this.t(
                                      T_ENTITIES.exam.examStateTypes.assigned
                                    );

                                  case "GRADED":
                                    return this.t(
                                      T_ENTITIES.exam.examStateTypes.graded
                                    );

                                  case "ALL":
                                    return this.t(
                                      T_ENTITIES.exam.examStateTypes.all
                                    );
                                }
                              })()}
                            </span>
                          </div>
                        );
                      }
                    })()}
                    <div>
                      {this.t(T_ENTITIES.exam.examCode)}:{" "}
                      <span className="new-exam-details-value">
                        {this.state.examDetails?.examCustomId &&
                        this.state.examDetails?.examCustomId !== ""
                          ? this.state.examDetails?.examCustomId
                          : "-"}
                      </span>
                    </div>
                    <div>
                      {this.t(T_ENTITIES.exam.examDate)}:{" "}
                      <span className="new-exam-details-value">
                        {convertEpochToDate(
                          this.props?.location?.state?.exam?.examDetails
                            ?.examDate
                        )}
                      </span>
                    </div>
                    <div>
                      {this.t(T_ENTITIES.exam.deviceType)}:{" "}
                      <span className="new-exam-details-value">
                        {this.state?.examDetails?.deviceType?.reduce(
                          (acc, val) => `${acc} / ${val}`
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="right-heading-container">
                    <div
                      className="edit-patient-details"
                      onClick={() => this.toggleEditPatientModal()}
                    >
                      <img src={require("../../assets/images/edit-icon.svg")} />
                    </div>
                    <div className="gen-btn-container">
                      {(() => {
                        if (this.state.images && this.state.editedImages) {
                          return (
                            <Button
                              className="download-exam-btn"
                              onClick={this.zipExam}
                            >
                              {this.t(T_EXAMS.examDetails.downloadExamButton)}
                            </Button>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          typeof this.state.rawImages.data === "string" ||
                          (this.state.rawImages?.data?.pristineImages?.[
                            "STANDARD"
                          ].concat(
                            this.state.rawImages?.data?.pristineImages?.[
                              "EDITED"
                            ]
                          ).length > 0 &&
                            new Date().getTime() -
                              this.state.examDetails.examDate >=
                              48 * 60 * 60 * 1000 &&
                            !this.state.visionAssessment) ||
                          (this.state.rawImages?.data?.fopImages?.["STANDARD"]
                            .concat(
                              this.state.rawImages?.data?.aimImages?.[
                                "STANDARD"
                              ]
                            )
                            .concat(
                              this.state.rawImages?.data?.pslImages?.[
                                "STANDARD"
                              ]
                            )
                            .concat(
                              this.state.rawImages?.data?.otherImages?.[
                                "STANDARD"
                              ]
                            )
                            .concat(
                              this.state.rawImages?.data?.pristineImages?.[
                                "STANDARD"
                              ].filter(el => el.imageSegment === "POSTERIOR")
                            ).length === 0 &&
                            this.state.rawImages?.data?.fopImages?.["EDITED"]
                              .concat(
                                this.state.rawImages?.data?.aimImages?.[
                                  "EDITED"
                                ]
                              )
                              .concat(
                                this.state.rawImages?.data?.pslImages?.[
                                  "EDITED"
                                ]
                              )
                              .concat(
                                this.state.rawImages?.data?.otherImages?.[
                                  "EDITED"
                                ]
                              )
                              .concat(
                                this.state.rawImages?.data?.pristineImages?.[
                                  "EDITED"
                                ].filter(el => el.imageSegment === "POSTERIOR")
                              ).length === 0 &&
                            !this.state.visionAssessment) ||
                          !this.state.isDoctor
                        ) {
                          return null;
                        } else if (sessionStorage.getItem("tele") === "true") {
                          if (
                            typeof this.state.rawImages.data === "string" ||
                            (this.state.rawImages?.data?.pristineImages?.[
                              "STANDARD"
                            ].concat(
                              this.state.rawImages?.data?.pristineImages?.[
                                "EDITED"
                              ]
                            ).length > 0 &&
                              new Date().getTime() -
                                this.state.examDetails.examDate >=
                                48 * 60 * 60 * 1000) ||
                            (this.state.rawImages?.data?.fopImages?.["STANDARD"]
                              .concat(
                                this.state.rawImages?.data?.aimImages?.[
                                  "STANDARD"
                                ]
                              )
                              .concat(
                                this.state.rawImages?.data?.pslImages?.[
                                  "STANDARD"
                                ]
                              )
                              .concat(
                                this.state.rawImages?.data?.otherImages?.[
                                  "STANDARD"
                                ]
                              )
                              .concat(
                                this.state.rawImages?.data?.pristineImages?.[
                                  "STANDARD"
                                ].filter(el => el.imageSegment === "POSTERIOR")
                              ).length === 0 &&
                              this.state.rawImages?.data?.fopImages?.["EDITED"]
                                .concat(
                                  this.state.rawImages?.data?.aimImages?.[
                                    "EDITED"
                                  ]
                                )
                                .concat(
                                  this.state.rawImages?.data?.pslImages?.[
                                    "EDITED"
                                  ]
                                )
                                .concat(
                                  this.state.rawImages?.data?.otherImages?.[
                                    "EDITED"
                                  ]
                                )
                                .concat(
                                  this.state.rawImages?.data?.pristineImages?.[
                                    "EDITED"
                                  ].filter(
                                    el => el.imageSegment === "POSTERIOR"
                                  )
                                ).length === 0)
                          ) {
                            return (
                              <Button
                                className="generate-report-btn"
                                onClick={this.startGradingWithoutImages}
                              >
                                {this.t(T_EXAMS.examDetails.startGradingButton)}
                              </Button>
                            );
                          }
                          return (
                            <Button
                              className="generate-report-btn"
                              onClick={this.startGrading}
                            >
                              {this.t(T_EXAMS.examDetails.startGradingButton)}
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              className="generate-report-btn"
                              onClick={this.openGenerateReportModal}
                            >
                              {this.t(T_EXAMS.examDetails.generateReportButton)}
                            </Button>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-content-container">
                <div className="tabs-container">
                  <div
                    onClick={() => {
                      this.setTab(1);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 1 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_EXAMS.examDetails.primaryExamDetailsTab)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(() => {
                    if (
                      this.state.examDetails?.deviceType?.includes("INSTAREF")
                    ) {
                      return (
                        <div
                          onClick={() => {
                            this.setTab(2);
                          }}
                          className={`tab ${
                            this.state.selectedTab === 2 ? "selected" : ""
                          }`}
                        >
                          <div className="tab-container">
                            <div className="tab-contents-container">
                              <div className="tab-heading">
                                {this.t(
                                  T_EXAMS.examDetails.visionAssessmentTab
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}

                  <div
                    onClick={() => {
                      this.setTab(3);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 3 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_EXAMS.examDetails.imagesAndVideosTab)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.setTab(4);
                    }}
                    className={`tab ${
                      this.state.selectedTab === 4 ? "selected" : ""
                    }`}
                  >
                    <div className="tab-container">
                      <div className="tab-contents-container">
                        <div className="tab-heading">
                          {this.t(T_EXAMS.examDetails.reportsTab)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {primaryExamTemplate}
                {visionAssessmentTemplate}
                {imagesTemplate}
                {reportsTemplate}
              </div>
            </div>
          </div>
          {this.state.showGenerateReportModal ? (
            <CustomGenerateReportModal
              text='Click "Close Button" to hide popup'
              closePopup={this.hideGenerateReportModal}
              resetList={this.resetList}
              makeAdmin={true}
              hasImages={
                !(
                  !this.state?.images ||
                  (this.state.rawImages?.data?.fopImages?.["STANDARD"]
                    .concat(this.state.rawImages?.data?.aimImages?.["STANDARD"])
                    .concat(this.state.rawImages?.data?.pslImages?.["STANDARD"])
                    .concat(
                      this.state.rawImages?.data?.otherImages?.["STANDARD"]
                    )
                    .concat(
                      this.state.rawImages?.data?.pristineImages?.[
                        "STANDARD"
                      ].filter(el => el.imageSegment === "POSTERIOR")
                    ).length === 0 &&
                    this.state.rawImages?.data?.fopImages?.["EDITED"]
                      .concat(this.state.rawImages?.data?.aimImages?.["EDITED"])
                      .concat(this.state.rawImages?.data?.pslImages?.["EDITED"])
                      .concat(
                        this.state.rawImages?.data?.otherImages?.["EDITED"]
                      )
                      .concat(
                        this.state.rawImages?.data?.pristineImages?.[
                          "EDITED"
                        ].filter(el => el.imageSegment === "POSTERIOR")
                      ).length === 0)
                )
              }
              hasAssessment={!!this.state.visionAssessment}
              visionAssessment={this.state.visionAssessment}
              exam={this.props.location.state.exam}
              deviceType={this.props.location.state.deviceType}
              small={this.state.small}
              smallEdited={this.state.smallEdited}
              history={this.props.history}
            />
          ) : null}
          {this.state.showGenerateReportWithoutImagesModal ? (
            <CustomGenerateReportWithoutImagesModal
              text='Click "Close Button" to hide popup'
              closePopup={this.hideGenerateReportWithoutImagesModal}
              resetList={this.resetList}
              makeAdmin={true}
              hasImages={
                !(!this.state?.images || this.state?.images?.length === 0)
              }
              history={this.props.history}
              hasAssessment={!!this.state.visionAssessment}
              visionAssessment={this.state.visionAssessment}
              exam={this.props.location.state.exam}
              deviceType={this.props.location.state.deviceType}
              small={this.state.small}
              smallEdited={this.state.smallEdited}
            />
          ) : null}
          {this.state.showEditExamDetailsModal ? (
            <CustomEditExamDetailsModal
              closePopup={this.hideEditExamDetailsModal}
              resetList={this.resetList}
              selectedAttribute={
                this.state.editableAttributes[this.state.selectedAttribute]
              }
              attr={this.state.selectedAttribute}
              examId={this.state.examId}
              updateValue={this.updateValue}
            />
          ) : null}
          {this.state.showEditPatientModal ? (
            <CustomEditPatientExamModal
              text='Click "Close Button" to hide popup'
              closePopup={this.toggleEditPatientModal}
              resetList={this.resetList}
              patient={
                this.state.patientDetails
                  ? this.state.patientDetails
                  : this.props?.location?.state?.exam?.patientDetails
              }
              examCode={this.state.examDetails?.examCustomId}
              deviceType={this.state?.examDetails?.deviceType}
              examId={this.state.examDetails?.id}
            />
          ) : null}
          {(() => {
            if (!images || !imagesBig) {
              return null;
            } else {
              return (
                <FsLightbox
                  toggler={toggler}
                  sources={imagesBig}
                  sourceIndex={slide}
                  thumbs={images}
                  captions={imageCaptions}
                  initialAnimation="scale-in-long"
                  type="image"
                  key={"image" + this.state.slide}
                  showThumbsOnMount={false}
                />
              );
            }
          })()}
          {(() => {
            if (!videos || !videoThumbnails) {
              return null;
            } else {
              return (
                <FsLightbox
                  toggler={togglerVideos}
                  sources={videos}
                  sourceIndex={slideVideos}
                  thumbs={videoThumbnails}
                  type="video"
                  key={"video" + slideVideos}
                  showThumbsOnMount={true}
                />
              );
            }
          })()}
        </div>
      );
      // return (
      //   <div
      //     className={
      //       this.state.showPopup || this.state.showModal
      //         ? "prevent-scrolling"
      //         : ""
      //     }
      //   >
      //     <AppNavbar />
      //     <div className="row">
      //       <div className="col-md-2 sidebar">
      //         <Sidebar from="3" results={this.state.userResults} />
      //       </div>
      //       <div className="col-md-10 sidepadding">
      //         <div className="row rowmargin abovetable custom-flex-wrapper">
      //           <a className="back-link" onClick={this.props.history.goBack}>
      //             &lt; Back
      //           </a>
      //           <div className="report-buttons">
      //             {(() => {
      //               if (
      //                 (!this.state.images && !this.state.editedImages) ||
      //                 !this.state.isDoctor
      //               ) {
      //                 return null;
      //               } else if (sessionStorage.getItem("tele") === "true") {
      //                 return (
      //                   <Button
      //                     className="addaccount"
      //                     onClick={this.startGrading}
      //                   >
      //                     Start Grading
      //                   </Button>
      //                 );
      //               } else {
      //                 return (
      //                   <Button
      //                     className="addaccount"
      //                     onClick={this.openGenerateReportModal}
      //                   >
      //                     Generate Report
      //                   </Button>
      //                 );
      //               }
      //             })()}
      //           </div>
      //         </div>
      //         <h4 className="patient-name">
      //           {this.state.patientDetails?.firstName}{" "}
      //           {this.state.patientDetails?.lastName}
      //         </h4>
      //         <div className="patient-details-big-container">
      //           <div className="patient-details-heading-container">
      //             <div className="patient-details-heading">Patient Details</div>
      //             <div
      //               className="edit-patient-details"
      //               onClick={() => this.toggleEditPatientModal()}
      //             >
      //               <img src={require("../../assets/images/edit-icon.svg")} />
      //             </div>
      //           </div>
      //           <div className="patient-details-container">
      //             <div>
      //               <span className="patient-details-heading">Gender: </span>
      //               <div className="patient-gender">
      //                 {this.state.patientDetails?.gender}
      //               </div>
      //             </div>
      //             <div>
      //               <span className="patient-details-heading">MRN: </span>
      //               {this.state.patientDetails?.mrn}
      //             </div>
      //           </div>
      //           <div className="patient-details-container">
      //             <div>
      //               <span className="patient-details-heading">DOB: </span>
      //               {convertEpochToDate(this.state.patientDetails?.dateOfBirth)}
      //             </div>
      //           </div>
      //         </div>
      //         <div className="exam-details">
      //           <h5 className="exam-details-heading">Exam Details</h5>
      //           <div className="patient-details-container">
      //             <div>
      //               <span className="patient-details-heading">Exam Date: </span>
      //               {convertEpochToDate(
      //                 this.props?.location?.state?.exam?.examDetails?.examDate
      //               )}
      //             </div>
      //             <div>
      //               <span className="patient-details-heading">
      //                 Device Type:{" "}
      //               </span>
      //               {this.props?.location?.state?.exam?.examDetails?.deviceType.reduce(
      //                 (acc, val) => `${acc} / ${val}`
      //               )}
      //             </div>
      //           </div>
      //           <div className="patient-details-container">
      //             <div>
      //               <span className="patient-details-heading">Exam Code: </span>
      //               {this.props?.location?.state?.exam?.examDetails
      //                 ?.examCustomId &&
      //               this.props?.location?.state?.exam?.examDetails
      //                 ?.examCustomId !== ""
      //                 ? this.props?.location?.state?.exam?.examDetails
      //                     ?.examCustomId
      //                 : "-"}
      //             </div>
      //             {(() => {
      //               if (sessionStorage.getItem("tele") === "true") {
      //                 return (
      //                   <div>
      //                     <span className="patient-details-heading">
      //                       Exam State:{" "}
      //                     </span>
      //                     {this.state.examState}
      //                   </div>
      //                 );
      //               }
      //             })()}
      //           </div>
      //         </div>

      //       </div>
      //     </div>

      //   </div>
      // );
    }
  }
}

export default withTranslation()(ExamDetails);
