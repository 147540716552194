/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { createPatientExam } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import DatePicker from "react-datepicker";
import subYears from "date-fns/subYears";
import differenceInYears from "date-fns/differenceInYears";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { T_ENTITIES, T_EXAMS, T_GENERAL } from "../../constants/translations";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
registerLocale("it", it);

/**
 * This is the component for the Add Exam / Patient modal on the Exam Data page <br>
 * It uses the React Datepicker module for generating a Date Picker <br>
 * It also uses the date-fns module for convenient date calculation <br>
 * It takes a prop named `device` which contains the device type selected in the parent component. <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.3 <br>
 * PSL - 5.3.14.3 <br>
 * FOP - 5.3.14.3 <br>
 *
 * @component
 */
class CustomAddExamPatientModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.modal = React.createRef();

    this.state = {
      isLoaded: true,
      disableFirstButton: true,
      disableSecondButton: true,
      activePage: 1,
      startDate: undefined,
      editDeviceType: [this.props.device],
      mrn: "",
      firstname: "",
      lastname: "",
      gender: "MALE",
      examCustomId: "",
      doctorfirstname: "",
      doctorlastname: "",
      doctoremail: "",
      medicalhistory: "",
      ocularhistory: "",
      currentmedication: "",
      allergies: "",
      leftucva: "",
      rightucva: "",
      leftsph: "",
      rightsph: "",
      leftcyl: "",
      rightcyl: "",
      leftaxis: "",
      rightaxis: "",
      leftbcva: "",
      rightbcva: "",
      leftadd: "",
      rightadd: "",
      leftiop: "",
      rightiop: "",
      leftpg: "",
      rightpg: "",
      collapsed: false,
      site: this.props.currentSite
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFirstPageProceed = this.onFirstPageProceed.bind(this);
    this.onSecondPageProceed = this.onSecondPageProceed.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
    this.goToSecondPage = this.goToSecondPage.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.expand = this.expand.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  componentDidMount() {
    this.setState({ collapsed: true });
    this.modal.current.scrollTo(0, 0);
    //eslint-disable-next-line
    console.log(this.i18n.language);
  }
  /**
   * Handles changes to the device selection in the form
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    if (
      e.target.name !== "age" &&
      (e.target.name !== "mrn" ||
        !/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(e.target.value))
    ) {
      this.setState(
        {
          [e.target.name]: e.target.value
        },
        () => {
          this.setState({
            disableFirstButton:
              this.state.mrn === "" ||
              this.state.firstname === "" ||
              this.state.editDeviceType.length === 0 ||
              !this.state.startDate,
            disableSecondButton:
              this.state.doctorfirstname === "" ||
              this.state.doctorlastname === "" ||
              this.state.doctoremail === ""
          });
        }
      );
    } else {
      if (
        (e.target.name === "age" && e.target.value === "") ||
        parseInt(e.target.value) < 100
      ) {
        this.setState(
          {
            [e.target.name]: e.target.value
          },
          () => {
            this.setState({
              disableFirstButton:
                this.state.mrn === "" ||
                this.state.firstname === "" ||
                this.state.editDeviceType.length === 0 ||
                !this.state.startDate,
              disableSecondButton:
                this.state.doctorfirstname === "" ||
                this.state.doctorlastname === "" ||
                this.state.doctoremail === "",
              startDate: new Date(
                new Date().getFullYear() - this.state.age,
                0,
                1
              ).getTime()
            });
          }
        );
      }
    }
  }

  expand() {
    this.setState({ collapsed: false }, () => {
      this.modal.current.scrollTo(0, this.modal.current.scrollHeight);
    });
  }

  collapse() {
    this.setState({ collapsed: true });
  }
  handleChange(e) {
    switch (e.currentTarget.name) {
      case "editfop":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "FOP"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "FOP"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editpsl":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "PSL"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "PSL"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editaim":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "AIM"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "AIM"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editinstaref":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "INSTAREF"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "INSTAREF"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editpristine":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "PRISTINE"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: ["PRISTINE"]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
      case "editother":
        if (!e.currentTarget.checked) {
          this.setState(
            {
              editDeviceType: this?.state?.editDeviceType?.filter?.(
                e => e !== "OTHER"
              )
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        } else {
          this.setState(
            {
              editDeviceType: [
                ...this.state.editDeviceType.filter?.(e => e !== "PRISTINE"),
                "OTHER"
              ]
            },
            () => {
              this.setState({
                disableFirstButton:
                  this.state.mrn === "" ||
                  this.state.firstname === "" ||
                  this.state.editDeviceType.length === 0 ||
                  !this.state.startDate,
                disableSecondButton:
                  this.state.doctorfirstname === "" ||
                  this.state.doctorlastname === "" ||
                  this.state.doctoremail === ""
              });
            }
          );
        }
        break;
    }
  }
  /**
   * Triggered when a date is selected, and updates the state accordingly
   *
   *
   * @param {object} date      selected date
   */
  dateChange(date) {
    this.setState(
      {
        startDate: date?.getTime()
      },
      () => {
        this.setState({
          disableFirstButton:
            this.state.mrn === "" ||
            this.state.firstname === "" ||
            this.state.lastname === "" ||
            this.state.editDeviceType.length === 0 ||
            !this.state.startDate,
          disableSecondButton:
            this.state.doctorfirstname === "" ||
            this.state.doctorlastname === "" ||
            this.state.doctoremail === "",
          age: isNaN(differenceInYears(new Date().getTime(), date?.getTime()))
            ? ""
            : differenceInYears(new Date().getTime(), date?.getTime())
        });
      }
    );
  }
  /**
   * Proceeds to the second page
   *
   *
   */
  onFirstPageProceed() {
    this.setState({ activePage: 2 });
  }
  /**
   * Proceeds to the third page
   *
   *
   */
  onSecondPageProceed() {
    this.setState({ activePage: 3 });
  }
  /**
   * Gets triggered on pressing the submit button, constructs the object, and submits the API call
   *
   *
   */
  onSubmit() {
    let devices = [];
    for (let el of this.state.editDeviceType) {
      devices.push(el);
    }
    let optionalStuff = {};
    if (this.state.examCustomId !== "") {
      optionalStuff.examCustomId = this.state.examCustomId;
    }
    if (this.state.medicalhistory !== "") {
      optionalStuff.medicalHistory = this.state.medicalhistory;
    }
    if (this.state.ocularhistory !== "") {
      optionalStuff.ocularHistory = this.state.ocularhistory;
    }
    if (this.state.currentmedication !== "") {
      optionalStuff.currentMedication = this.state.currentmedication;
    }
    if (this.state.allergies !== "") {
      optionalStuff.allergies = this.state.allergies;
    }
    if (
      this.state.leftucva !== "" ||
      this.state.leftpg !== "" ||
      this.state.leftsph !== "" ||
      this.state.leftcyl !== "" ||
      this.state.leftaxis !== "" ||
      this.state.leftbcva !== "" ||
      this.state.leftadd !== "" ||
      this.state.leftiop !== ""
    ) {
      optionalStuff.leftEyeVisionAssessment = {};
    }
    if (this.state.leftucva !== "") {
      optionalStuff.leftEyeVisionAssessment.ucva = this.state.leftucva;
    }
    if (this.state.leftpg !== "") {
      optionalStuff.leftEyeVisionAssessment.visionWithPG = this.state.leftpg;
    }
    if (this.state.leftsph !== "") {
      optionalStuff.leftEyeVisionAssessment.sph = this.state.leftsph;
    }
    if (this.state.leftcyl !== "") {
      optionalStuff.leftEyeVisionAssessment.cyl = this.state.leftcyl;
    }
    if (this.state.leftaxis !== "") {
      optionalStuff.leftEyeVisionAssessment.axis = this.state.leftaxis;
    }
    if (this.state.leftbcva !== "") {
      optionalStuff.leftEyeVisionAssessment.bcva = this.state.leftbcva;
    }
    if (this.state.leftadd !== "") {
      optionalStuff.leftEyeVisionAssessment.nearAdd = this.state.leftadd;
    }
    if (this.state.leftiop !== "") {
      optionalStuff.leftEyeVisionAssessment.iop = this.state.leftiop;
    }
    if (
      this.state.rightucva !== "" ||
      this.state.rightpg !== "" ||
      this.state.rightsph !== "" ||
      this.state.rightcyl !== "" ||
      this.state.rightaxis !== "" ||
      this.state.rightbcva !== "" ||
      this.state.rightadd !== "" ||
      this.state.rightiop !== ""
    ) {
      optionalStuff.rightEyeVisionAssessment = {};
    }
    if (this.state.rightucva !== "") {
      optionalStuff.rightEyeVisionAssessment.ucva = this.state.rightucva;
    }
    if (this.state.rightpg !== "") {
      optionalStuff.rightEyeVisionAssessment.visionWithPG = this.state.rightpg;
    }
    if (this.state.rightsph !== "") {
      optionalStuff.rightEyeVisionAssessment.sph = this.state.rightsph;
    }
    if (this.state.rightcyl !== "") {
      optionalStuff.rightEyeVisionAssessment.cyl = this.state.rightcyl;
    }
    if (this.state.rightaxis !== "") {
      optionalStuff.rightEyeVisionAssessment.axis = this.state.rightaxis;
    }
    if (this.state.rightbcva !== "") {
      optionalStuff.rightEyeVisionAssessment.bcva = this.state.rightbcva;
    }
    if (this.state.rightadd !== "") {
      optionalStuff.rightEyeVisionAssessment.nearAdd = this.state.rightadd;
    }
    if (this.state.rightiop !== "") {
      optionalStuff.rightEyeVisionAssessment.iop = this.state.rightiop;
    }
    if (
      this.state.doctorfirstname !== "" ||
      this.state.doctorlastname !== "" ||
      this.state.doctoremail !== ""
    ) {
      optionalStuff.orderingProvider = {
        firstName: this.state.doctorfirstname,
        lastName: this.state.doctorlastname,
        email: this.state.doctoremail
      };
    }
    let data = {
      patient: {
        mrn: this.state.mrn !== "" ? this.state.mrn : undefined,
        firstName:
          this.state.firstname !== "" ? this.state.firstname : undefined,
        lastName: this.state.lastname !== "" ? this.state.lastname : undefined,
        dateOfBirth: this.state.startDate,
        gender: this.state.gender,
        siteId: parseInt(this.state.site)
      },
      examLocalId: "WEB-DASHBOARD" + "::" + new Date().getTime(),
      examDate: new Date().getTime(),
      deviceType: devices,
      ...optionalStuff
    };
    this.setState({ isLoaded: false, disableSecondButton: true });

    postAuthenticatedData(
      sessionStorage.getItem("domain") + createPatientExam,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 200) {
          toast.success(this.t(T_EXAMS.toast.examAddedSuccessfully));
          this.setState({
            organizationname: "",
            sitedomain: "",
            sitename: "",
            firstname: "",
            lastname: "",
            email: "",
            employeeid: "",
            activePage: 1
          });
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  /**
   * Goes to the first page
   *
   *
   */
  goToFirstPage() {
    this.setState({ activePage: 1 });
  }
  goToSecondPage() {
    this.setState({ activePage: 2 });
  }
  render() {
    return (
      <div className="custommodal">
        <div
          className="custommodalinner custommodalinnerdatepicker"
          ref={this.modal}
        >
          {(() => {
            if (this.state.activePage === 2) {
              return (
                <a className="add-org-back" onClick={this.goToFirstPage}>
                  &lt; {this.t(T_GENERAL.back)}
                </a>
              );
            }
          })()}
          {(() => {
            if (this.state.activePage === 3) {
              return (
                <a className="add-org-back" onClick={this.goToSecondPage}>
                  &lt; {this.t(T_GENERAL.back)}
                </a>
              );
            }
          })()}
          <form
            autoComplete="off"
            className="loginpad custom-add-exam-patient-modal"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">{this.t(T_EXAMS.addExam.header)}</h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            {(() => {
              if (this.state.activePage === 1) {
                return (
                  <div className="firstpage">
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.exam.site)}*
                        </div>
                        <div className="col-md-9">
                          <select
                            name="site"
                            onChange={this.onChange}
                            value={this.state.site}
                          >
                            {this.props.sites.map(e => (
                              <option value={e?.siteId} key={e?.siteId}>
                                {e?.siteName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </FormGroup>
                    <h6 className="exam-heading">
                      {this.t(T_EXAMS.addExam.patient)}
                    </h6>
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.patient.mrn)}*
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            autoFocus
                            required
                            type="text"
                            name="mrn"
                            maxLength={20}
                            pattern="[a-zA-Z0-9]+"
                            value={this.state.mrn}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.patient.firstName)}*
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="firstname"
                            maxLength={30}
                            value={this.state.firstname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.patient.lastName)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="lastname"
                            maxLength={30}
                            value={this.state.lastname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.patient.gender)}*
                        </div>
                        <div className="col-md-9">
                          <select name="gender" onChange={this.onChange}>
                            <option value="MALE" key="MALE">
                              {this.t(T_ENTITIES.patient.genderTypes.male)}
                            </option>
                            <option value="FEMALE" key="FEMALE">
                              {this.t(T_ENTITIES.patient.genderTypes.female)}
                            </option>
                          </select>
                        </div>
                      </div>
                    </FormGroup>
                    <div className="new-dob">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.patient.dateOfBirth)}*
                        </div>
                        <div className="col-md-3">
                          <div className="customDatePicker">
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.dateChange}
                              minDate={subYears(new Date(), 100)}
                              maxDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              locale={this.i18n.language}
                            />
                          </div>
                        </div>
                        <span className="col-md-3 or-word">
                          {this.t(T_EXAMS.addExam.or)}
                        </span>
                        <div className="col-md-1 formfont">
                          {this.t(T_ENTITIES.patient.age)}*
                        </div>
                        <div className="col-md-2">
                          <FormControl
                            required
                            type="text"
                            name="age"
                            value={this.state.age}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <h6 className="exam-heading">
                      {this.t(T_EXAMS.addExam.exam)}
                    </h6>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row formfont">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.exam.deviceType)}*
                        </div>
                        <div className="checkbox">
                          <FormControl
                            className="check"
                            type="checkbox"
                            name="editfop"
                            checked={this?.state?.editDeviceType?.includes?.(
                              "FOP"
                            )}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="checkbox-label formfont">
                          {this.t(T_ENTITIES.exam.deviceTypes.fop)}
                        </div>
                        <div className="checkbox">
                          <FormControl
                            className="check"
                            type="checkbox"
                            name="editpsl"
                            checked={this?.state?.editDeviceType?.includes?.(
                              "PSL"
                            )}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="checkbox-label formfont">
                          {this.t(T_ENTITIES.exam.deviceTypes.psl)}
                        </div>
                        <div className="checkbox">
                          <FormControl
                            className="check"
                            type="checkbox"
                            name="editaim"
                            checked={this?.state?.editDeviceType?.includes?.(
                              "AIM"
                            )}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="checkbox-label formfont">
                          {this.t(T_ENTITIES.exam.deviceTypes.aim)}
                        </div>
                        <div className="checkbox">
                          <FormControl
                            className="check"
                            type="checkbox"
                            name="editinstaref"
                            checked={this?.state?.editDeviceType?.includes?.(
                              "INSTAREF"
                            )}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="checkbox-label formfont">
                          {this.t(T_ENTITIES.exam.deviceTypes.instaref)}
                        </div>
                        <div className="checkbox">
                          <FormControl
                            className="check"
                            type="checkbox"
                            name="editpristine"
                            checked={this?.state?.editDeviceType?.includes?.(
                              "PRISTINE"
                            )}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="checkbox-label formfont">
                          {this.t(T_ENTITIES.exam.deviceTypes.pristine)}
                        </div>
                        <div className="checkbox">
                          <FormControl
                            className="check"
                            type="checkbox"
                            name="editother"
                            checked={this?.state?.editDeviceType?.includes?.(
                              "OTHER"
                            )}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="checkbox-label formfont">
                          {this.t(T_ENTITIES.exam.deviceTypes.other)}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="customidentifier" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.exam.examCode)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="examCustomId"
                            maxLength="20"
                            value={this.state.examCustomId}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_ENTITIES.exam.ocularHistory)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            as="textarea"
                            name="ocularhistory"
                            value={this.state.ocularhistory}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <div
                      className={`collapsible ${
                        this.state.collapsed ? "collapsed" : ""
                      }`}
                    >
                      <div className="col-heading">
                        <h6 className="exam-heading">
                          {this.t(T_EXAMS.addExam.prescribingDoctor)}
                        </h6>
                        {(() => {
                          if (this.state.collapsed) {
                            return (
                              <img
                                src={require("../../assets/images/plus.svg")}
                                onClick={this.expand}
                              />
                            );
                          } else {
                            return (
                              <img
                                src={require("../../assets/images/minus.svg")}
                                onClick={this.collapse}
                              />
                            );
                          }
                        })()}
                      </div>
                      <FormGroup controlId="name" bsSize="medium">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_EXAMS.addExam.firstName)}*
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              autoFocus
                              required
                              type="text"
                              name="doctorfirstname"
                              value={this.state.doctorfirstname}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_EXAMS.addExam.lastName)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              required
                              type="text"
                              name="doctorlastname"
                              value={this.state.doctorlastname}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_EXAMS.addExam.email)}*
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              required
                              type="text"
                              name="doctoremail"
                              value={this.state.doctoremail}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn submit-button"
                      disabled={this.state.disableFirstButton}
                    >
                      {this.state.isLoaded ? (
                        this.t(T_GENERAL.submit)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              } else if (this.state.activePage === 2) {
                return (
                  <div className="firstpage">
                    <h6>{this.t(T_EXAMS.addExam.prescribingDoctor)}*</h6>
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.firstName)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            autoFocus
                            required
                            type="text"
                            name="doctorfirstname"
                            value={this.state.doctorfirstname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.lastName)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="doctorlastname"
                            value={this.state.doctorlastname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.email)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="doctoremail"
                            value={this.state.doctoremail}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <h6>{this.t(T_EXAMS.addExam.medicalHistory)}</h6>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.ocularHistory)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            as="textarea"
                            name="ocularhistory"
                            value={this.state.ocularhistory}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.systemicHistory)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            as="textarea"
                            name="medicalhistory"
                            value={this.state.medicalhistory}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.currentMedication)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="currentmedication"
                            value={this.state.currentmedication}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.addExam.allergies)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="allergies"
                            value={this.state.allergies}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <Button
                      onClick={this.onSecondPageProceed}
                      className="loginbtn1 create-account-btn submit-button"
                      disabled={this.state.disableSecondButton}
                    >
                      {this.state.isLoaded ? (
                        this.t(T_EXAMS.addExam.proceed)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              } else if (this.state.activePage === 3) {
                return (
                  <div className="firstpage">
                    <table className="visual-acuity-container">
                      <thead>
                        <th></th>
                        <th>{this.t(T_EXAMS.addExam.rightEye)}</th>
                        <th>{this.t(T_EXAMS.addExam.leftEye)}</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.ucva)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightucva"
                                value={this.state.rightucva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftucva"
                                value={this.state.leftucva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.visionWithPG)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightpg"
                                value={this.state.rightpg}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftpg"
                                value={this.state.leftpg}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.spherical)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightsph"
                                value={this.state.rightsph}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftsph"
                                value={this.state.leftsph}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.cylindrical)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightcyl"
                                value={this.state.rightcyl}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftcyl"
                                value={this.state.leftcyl}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.axis)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightaxis"
                                value={this.state.rightaxis}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftaxis"
                                value={this.state.leftaxis}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.bcva)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightbcva"
                                value={this.state.rightbcva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftbcva"
                                value={this.state.leftbcva}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.nearAdd)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightadd"
                                value={this.state.rightadd}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftadd"
                                value={this.state.leftadd}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td>{this.t(T_EXAMS.addExam.iop)}</td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="rightiop"
                                value={this.state.rightiop}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup controlId="email" bsSize="large">
                              <FormControl
                                required
                                type="text"
                                name="leftiop"
                                value={this.state.leftiop}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn submit-button"
                      disabled={!this.state.isLoaded}
                    >
                      {this.state.isLoaded ? (
                        this.t(T_GENERAL.submit)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomAddExamPatientModal);
