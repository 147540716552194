/**
 * @file Exports all the API endpoints used throughout the app
 * @author Arun Mahadevan
 *
 */
export const getUser = "/api/user/getUser";
export const getUserAndOrg = "/api/user/getUserAndOrg";
export const getAllUsers = "/api/admin/getAllUsers";
export const changePassword = "/api/user/changePassword";
export const createUser = "/api/admin/createUser";
export const forgotPassword = "/api/user/forgotPassword";
export const loginPassword = "/api/user/loginUser";
export const loginUsername = "/api/user/userPreLogin";
export const domainForUser = "/api/domain/domainForUser";
export const verifyAccount = "/api/user/verifyAccount";
export const updateUser = "/api/admin/updateUser";
export const updateStatus = "/api/admin/updateStatus";
export const deleteUser = "/api/admin/deleteUser";
export const resetPassword = "/api/user/resetPassword";
export const unblockEmail = "/api/user/sendRequestAccountUnblockEmail";
export const verifyEmail = "/api/user/resendVerificationEmail";
export const reverifyAccount = "/api/user/reVerifyAccount";
export const getSites = "/api/org/getSites";
export const getExams = "/api/exam/getExamsForSites";
export const getAIReportLink = "/api/aiReport/getAIReportLink";
export const getReportLink = "/api/report/getReportLink";
export const getAllSiteAnalytics = "/api/analytics/getAllSiteAnalytics";
export const getUserAnalytics = "/api/analytics/getUserAnalytics";
export const getSiteAnalytics = "/api/analytics/getSiteAnalytics";
export const createSite = "/api/org/createSite";
export const deleteOrg = "/api/org/deleteOrg";
export const deleteSite = "/api/org/deleteSite";
export const editOrg = "/api/org/editOrg";
export const editSite = "/api/org/editSite";
export const getAdminsForOrg = "/api/org/getAdminsForOrg";
export const getAllOrgData = "/api/org/getOrgs";
export const serviceDeleteUser = "/api/org/deleteUser";
export const createOrgSiteAndUser = "/api/org/createOrgSiteAndUser";
export const createAdminAccount = "/api/org/createAdminAccount";
export const createPatientExam = "/api/exam/createPatientExam";
export const getImages = "/api/image/getImages";
export const getVideos = "/api/video/getVideos";
export const gradeExam = "/api/report/generateReportV3";
export const getPatients = "/api/patient/getPatients";
export const getPatientWithExams = "/api/patient/getPatientWithExams";
export const createPatient = "/api/patient/createPatient";
export const editPatient = "/api/patient/editPatient";
export const updatePatientExam = "/api/exam/updatePatientExam";
export const uploadSiteLogo = "/api/org/uploadSiteLogo";
export const createExam = "/api/exam/createExam";
export const getExamDetails = "/api/exam/get";
export const updateExamMetadata = "/api/exam/updateExamMetadata";
export const updateAdminStatus = "/api/org/updateAdminStatus";
export const resendVerificationEmail = "/api/admin/resendVerificationEmail";
export const updateSiteGatewaySettings =
  "/api/siteInfra/updateSiteGatewaySettings";
export const updateCloudSyncSettings = "/api/org/updateCloudSyncSettings";
export const updateSiteEntitlements = "/api/siteInfra/updateSiteEntitlements";
export const getSiteLogo = "/api/org/getSiteLogo";
export const getAllOrgEntitlements = "/api/org/getAllOrgEntitlements";
export const updateOrgEntitlements = "/api/org/updateOrgEntitlements";
export const logoutAPI = "/api/user/logout";
export const assignedUserForExam = "/api/exam/assignedUserForExam";
export const removeAssignment = "/api/exam/removeAssignment";
export const assignExam = "/api/exam/assignExam";
export const getDiseases = "/api/report/getDiseaseList";
export const getTeleMedConfig = "/api/siteInfra/getTelemedConfig";
export const updateTeleMedConfig = "/api/siteInfra/updateTelemedConfig";
export const getCat2List = "/api/analytics/getCat2List";
export const getCDSSAccessToken = "/api/user/getCDSSAccessToken";
export const getSecurityMode = "/api/org/getSecurityMode";
export const setSecurityMode = "/api/org/setSecurityMode";
export const zipExam = "/api/exam/zipExam";
export const getPathForDownload = "/api/exam/getPathForDownload";
export const updateSiteSubscriptionSettings =
  "/api/org/updateSiteSubscriptionSettings";
export const updateEyrisSettingsForSite =
  "/api/siteInfra/updateEyrisSettingsForSite";
export const getEyrisReportLink = "/api/eyrisReport/getEyrisReportLink";
export const getAllSiteEntitlements =
  "/api/org/getAllSiteConfigurationDefinitions";
export const updateSiteConfigurations = "/api/org/updateSiteConfigurations";
export const getUserSignature = "/api/user/getUserSignature";
export const uploadUserSignature = "/api/user/uploadUserSignature";
export const deleteUserSignature = "/api/user/deleteUserSignature";
export const updateSiteSettings = "/api/org/updateSiteSettings";
export const updateIntegrationConfigs =
  "/api/siteInfra/updateIntegrationConfigs";
export const getAllOrgTags = "/api/org/getAllOrgTags";
