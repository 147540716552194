const languages = {
  en: {
    nativeName: "English"
  },
  it: {
    nativeName: "Italiano"
  }
};

module.exports = { languages };
