/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomAddExamPatientModal from "./CustomAddExamPatientModal";
import { Button, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getExams,
  getUser,
  getAIReportLink,
  getSites,
  assignedUserForExam,
  zipExam,
  getPathForDownload
} from "../../constants/endpoints";
import SearchBar from "../SearchBar";
import { convertEpochToDate } from "../../utils/date";
import { getAuthenticatedData, postAuthenticatedData } from "../../utils/async";
import styles from "../SearchBar/search.module.css";
import MultiSelect from "react-multi-select-component";
import CustomShowAssignedUserModal from "./CustomShowAssignedUserModal";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_EXAMS,
  T_GENERAL,
  T_PAGINATION
} from "../../constants/translations";

// import { toast } from "react-toastify";
/**
 * This is the component for the page that lists all exams.<br>
 * Pagination, Device Type are cached using Session Storage. <br>
 * The API call to fetch exams is made depending on whether the user <br>
 * came into the page directly, or using the browser back button, <br>
 * in which case, it retains the same state and data that existed before. <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.3, 5.3.14.4, 5.3.14.7, 5.3.14.24 <br>
 * PSL - 5.3.14.3, 5.3.14.4, 5.3.14.7, 5.3.14.24 <br>
 * FOP - 5.3.14.3, 5.3.14.4, 5.3.14.7, 5.3.14.25 <br>
 *
 * @component
 */
class ExamData extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: this.props.history.action === "POP" ? true : false,
      exams: [],
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      prevPage:
        sessionStorage.getItem("ePrevPage") !== "null"
          ? sessionStorage.getItem("ePrevPage")
          : null,
      nextPage:
        sessionStorage.getItem("eNextPage") !== "null"
          ? sessionStorage.getItem("eNextPage")
          : null,
      pageNumber: 1,
      examId: 0,
      examDates: [],
      gradedDates: [],
      examSites: [],
      suggestions: [],
      isTableLoading: true,
      queryObjectId: "",
      isAdmin: false,
      currentUrl: sessionStorage.getItem("eUrl"),
      searchValue:
        this.props.history.action === "POP"
          ? sessionStorage.getItem("eSearchValue")
          : "",
      sortBy: 0,
      id: 0,
      siteIds:
        this.props.history.action === "POP"
          ? JSON.parse(sessionStorage.getItem("eSiteIds"))
          : [],
      showAddExamPatientModal: false,
      deviceType:
        this.props.history.action === "POP" &&
        sessionStorage.getItem("eDeviceType") !== null
          ? sessionStorage.getItem("eDeviceType")
          : "FOP",
      sites: [],
      userSite: null,
      userRoles: [],
      options: [],
      queryType:
        this.props.history.action === "POP"
          ? sessionStorage.getItem("eQueryType")
          : "",
      selected:
        this.props.history.action === "POP"
          ? JSON.parse(sessionStorage.getItem("eSelected"))
          : [],
      showMultiSelect: false,
      showShowAssignedUserModal: false,
      graderName: "",
      assignedTime: "",
      assignedExamId: 0,
      primarySiteDetails: [],
      manuallyCleared: false
    };

    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openAddExamPatientModal = this.openAddExamPatientModal.bind(this);
    this.toggleAddExamPatientModal = this.toggleAddExamPatientModal.bind(this);
    this.changeDeviceType = this.changeDeviceType.bind(this);
    this.resetList = this.resetList.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.generateSiteIdUrl = this.generateSiteIdUrl.bind(this);
    this.changeExamStatus = this.changeExamStatus.bind(this);
    this.showAssignedUser = this.showAssignedUser.bind(this);
    this.openShowAssignedUserModal = this.openShowAssignedUserModal.bind(this);
    this.getExamSites = this.getExamSites.bind(this);
    this.closeShowAssignedUserModal = this.closeShowAssignedUserModal.bind(
      this
    );
    this.menuToggle = this.menuToggle.bind(this);
    this.zipExam = this.zipExam.bind(this);
    this.download = this.download.bind(this);
    this.getExamStateTranslation = this.getExamStateTranslation.bind(this);
  }

  generateSiteIdUrl(siteIds) {
    if (siteIds.length === 0) {
      return "";
    }
    return (
      "siteId=" +
      siteIds.reduce((acc, el) => {
        return acc + "&siteId=" + el;
      })
    );
  }

  componentDidMount() {
    this.getDates(this.state.exams);
    this.state.userResults.then(
      result => {
        let id;
        if (result?.data?.roles?.includes("ADMIN")) {
          this.setState({ isAdmin: true });
        }
        getAuthenticatedData(sessionStorage.getItem("domain") + getSites).then(
          res => {
            this.setState({
              sites: res?.data,
              options: res?.data.map(el => {
                return { label: el.siteName, value: el.siteId };
              }),
              siteIds:
                this.props.history.action === "POP"
                  ? JSON.parse(sessionStorage.getItem("eSiteIds"))
                  : [result?.data?.siteId],
              primarySiteDetails: [
                {
                  label: result?.data?.siteName,
                  value: result?.data.siteId
                }
              ],
              selected:
                this.props.history.action === "POP" &&
                sessionStorage.getItem("eSelected") &&
                sessionStorage.getItem("eSelected") !== "null" &&
                sessionStorage.getItem("eSelected") !== "[]"
                  ? JSON.parse(sessionStorage.getItem("eSelected"))
                  : [
                      {
                        label: result?.data?.siteName,
                        value: result?.data.siteId
                      }
                    ],
              showMultiSelect: true
            });
          }
        );
        id = result?.data?.userId;
        this.setState({
          queryObjectId: id,
          siteIds:
            this.props.history.action === "POP"
              ? JSON.parse(sessionStorage.getItem("eSiteIds"))
              : [result?.data?.siteId],
          userSite: result?.data?.siteId,
          userSiteName: result?.data?.siteName,
          userRoles: result?.data?.roles,
          currentUrl:
            this.props.history.action === "POP"
              ? sessionStorage.getItem("eUrl")
              : sessionStorage.getItem("domain") +
                getExams +
                `?siteId=${result?.data?.siteId}&deviceType=${this.state.deviceType}`
        });
        if (
          this.props.history.action !== "POP" ||
          this.state.currentUrl === null
        ) {
          let url =
            sessionStorage.getItem("domain") +
            getExams +
            `?siteId=${result?.data?.siteId}&deviceType=${this.state.deviceType}`;
          this.setState({
            isTableLoading: true,
            currentUrl: url
          });
          getAuthenticatedData(url).then(
            result => {
              this.setState({
                isTableLoading: false,
                exams: result?.data,
                isLoaded: true,
                prevPage: result?.paging?.prevUrl,
                nextPage: result?.paging?.nextUrl
              });
              this.getDates(result?.data);
              this.getGradedDates(result?.data);
              this.getExamSites(result?.data);
            },
            () => {
              this.setState({
                isLoaded: true
              });
            }
          );
        } else {
          this.setInput(sessionStorage.getItem("eSearchValue"));
          this.setState({ isTableLoading: true });
          getAuthenticatedData(this.state.currentUrl).then(
            result => {
              this.setState({
                exams: result?.data,
                isTableLoading: false,
                isLoaded: true,
                prevPage: result?.paging?.prevUrl,
                nextPage: result?.paging?.nextUrl,
                pageNumber: parseInt(sessionStorage.getItem("ePageNumber"))
              });
              this.getDates(result?.data);
              this.getGradedDates(result?.data);
              this.getExamSites(result?.data);
            },
            () => {
              this.setState({ isLoaded: true });
            }
          );
        }
      },
      () => {
        this.setState({ isLoaded: true });
      }
    );
  }

  saveToSessionStorage() {
    sessionStorage.setItem("ePrevPage", this.state.prevPage);
    sessionStorage.setItem("eNextPage", this.state.nextPage);
    sessionStorage.setItem("ePageNumber", this.state.pageNumber);
    sessionStorage.setItem("eSearchValue", this.state.searchValue);
    sessionStorage.setItem("eSiteIds", JSON.stringify(this.state.siteIds));
    sessionStorage.setItem("eDeviceType", this.state.deviceType);
    sessionStorage.setItem("eUrl", this.state.currentUrl);
    sessionStorage.setItem("eSelected", JSON.stringify(this.state.selected));
    sessionStorage.setItem("eQueryType", this.state.queryType);
  }

  /**
   * Restores state from session storage
   *
   *
   *
   */
  componentWillUnmount() {
    this.saveToSessionStorage();
  }

  openShowAssignedUserModal() {
    this.setState({ showShowAssignedUserModal: true });
  }

  closeShowAssignedUserModal() {
    this.setState({ showShowAssignedUserModal: false });
  }

  getExamStateTranslation(examState) {
    let translated = "";
    switch (examState) {
      case "ACTIVE":
        translated = this.t(T_ENTITIES.exam.examStateTypes.active);
        break;
      case "ASSIGNED":
        translated = this.t(T_ENTITIES.exam.examStateTypes.assigned);
        break;
      case "GRADED":
        translated = this.t(T_ENTITIES.exam.examStateTypes.graded);
        break;
    }
    return translated.toLocaleUpperCase();
  }

  zipExam(id, name) {
    postAuthenticatedData(sessionStorage.getItem("domain") + zipExam + "/" + id)
      .then(res => {
        return res.json();
      })
      .then(res => {
        // eslint-disable-next-line no-console
        if (res.status.statusCode === "OK") {
          toast.success(
            this.t(T_EXAMS.toast.examDownloadForPatient, { name: name })
          );
          let interval = setInterval(() => {
            postAuthenticatedData(
              sessionStorage.getItem("domain") +
                getPathForDownload +
                "/" +
                res.data
            )
              .then(res => {
                return res.json();
              })
              .then(res => {
                // eslint-disable-next-line no-console
                if (res.status.statusCode === "OK") {
                  clearInterval(interval);
                  this.download(res.data);
                }
              });
          }, 1000);
        }
      });
  }

  download(url) {
    var link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  changeExamStatus(e) {
    let qt = e.target.value;
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getExams +
        `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
          this.state.deviceType
        }${e.target.value !== "" ? "&queryType=" + e.target?.value : ""}`
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1,
          queryType: qt,
          currentUrl:
            sessionStorage.getItem("domain") +
            getExams +
            `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
              this.state.deviceType
            }&queryType=${qt}`
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
        this.getExamSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  menuToggle(open) {
    if (!open) {
      this.clearInput();
      this.resetList();
    }
  }

  setSelected(e) {
    if (e.length === 0) {
      this.setState({
        selected: this.state.primarySiteDetails,
        siteIds: this.state.primarySiteDetails.map(el => el.value),
        manuallyCleared: true
      });
    } else {
      this.setState({
        selected: e,
        siteIds: e.map(el => el.value),
        manuallyCleared: true
      });
    }
  }
  /**
   * Calculates the string value of all the dates in order to display them
   * @param {array} items array containing all the exams
   *
   *
   */
  getDates(items) {
    let examDates = items?.map?.(item =>
      convertEpochToDate(item?.examDetails?.examDate)
    );
    this.setState({ examDates });
  }

  changeDeviceType(e) {
    this.setState(
      {
        deviceType: e.target.value,
        isTableLoading: true,
        manuallyCleared: true,
        currentUrl:
          sessionStorage.getItem("domain") +
          getExams +
          `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
            e.target.value
          }${
            this.state.queryType !== "" &&
            this.state.queryType !== "null" &&
            typeof this.state.queryType !== "undefined" &&
            this.state.queryType !== "undefined"
              ? "&queryType=" + this.state.queryType
              : ""
          }`
      },
      () => {
        this.clearInput();
      }
    );
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getExams +
        `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
          e.target.value
        }`
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          isTableLoading: false,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
        this.getExamSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  getGradedDates(items) {
    let gradedDates = items?.map?.(item => {
      if (item?.examDetails?.gradedDate === 0) return "-";
      return convertEpochToDate(item?.examDetails?.gradedDate);
    });
    this.setState({ gradedDates });
  }

  getExamSites(items) {
    const siteArray = this.state.sites.map(el => el.siteId);
    let examSites = items?.map?.(item => {
      let index = 0;
      if (siteArray.includes(item.patientDetails.siteId)) {
        index = siteArray.indexOf(item.patientDetails.siteId);
      }
      return this.state.sites[index]?.siteName;
    });
    this.setState({ examSites });
  }

  showAssignedUser(id) {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + assignedUserForExam + "/" + id
    ).then(res => {
      this.setState({
        graderName: res?.data?.graderName,
        assignedTime: res?.data?.assignedTime,
        assignedExamId: id
      });
      this.openShowAssignedUserModal();
    });
  }

  openAddExamPatientModal() {
    this.setState({ showAddExamPatientModal: true });
  }

  // viewReport(item) {
  //   getAuthenticatedData(
  //     sessionStorage.getItem("domain") + getPdfLink + `/${item.examDetails.id}`
  //   ).then(
  //     result => {
  //       window.open(result.data, "_blank");
  //     },
  //     () => {
  //       this.setState({
  //         isLoaded: true
  //       });
  //     }
  //   );
  // }
  /**
   * Fetches the AI report of an exam
   * @param {object} item exam whose AI report needs to be viewed
   *
   *
   */
  viewAIReport(item) {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAIReportLink +
        `/${item.examDetails.id}`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  toggleAddExamPatientModal() {
    this.setState({
      showAddExamPatientModal: !this.state.showAddExamPatientModal
    });
  }
  /**
   * Refreshes the list of exams
   *
   *
   *
   */
  resetList() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getExams +
        `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
          this.state.deviceType
        }`
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: 1,
          isTableLoading: false,
          currentUrl:
            sessionStorage.getItem("domain") +
            getExams +
            `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
              this.state.deviceType
            }${
              this.state.queryType !== "" &&
              this.state.queryType !== "null" &&
              typeof this.state.queryType !== "undefined" &&
              this.state.queryType !== "undefined"
                ? "&queryType=" + this.state.queryType
                : ""
            }`
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
        this.getExamSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Goes to the previous page
   *
   *
   *
   */
  goToPreviousPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.prevPage
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isTableLoading: false,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber - 1,
          currentUrl: sessionStorage.getItem("domain") + this.state.prevPage
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
        this.getExamSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Goes to the next page
   *
   *
   *
   */
  goToNextPage() {
    this.setState({ isTableLoading: true });
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.nextPage
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isTableLoading: false,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          pageNumber: this.state.pageNumber + 1,
          currentUrl: sessionStorage.getItem("domain") + this.state.nextPage
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
        this.getExamSites(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   *
   * Clears the search input
   *
   *
   */
  handleClear() {
    if (!this.state.manuallyCleared) {
      this.setState({ isTableLoading: true });
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getExams +
          `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
            this.state.deviceType
          }${
            this.state.queryType !== "" &&
            this.state.queryType !== "null" &&
            typeof this.state.queryType !== "undefined" &&
            this.state.queryType !== "undefined"
              ? "&queryType=" + this.state.queryType
              : ""
          }`
      ).then(
        result => {
          this.setState({
            exams: result?.data,
            isLoaded: true,
            isTableLoading: false,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            pageNumber: 1,
            searchValue: "",
            currentUrl:
              sessionStorage.getItem("domain") +
              getExams +
              `?${this.generateSiteIdUrl(this.state.siteIds)}&deviceType=${
                this.state.deviceType
              }${
                this.state.queryType !== "" &&
                this.state.queryType !== "null" &&
                typeof this.state.queryType !== "undefined" &&
                this.state.queryType !== "undefined"
                  ? "&queryType=" + this.state.queryType
                  : ""
              }`
          });
          this.getDates(result?.data);
          this.getGradedDates(result?.data);
          this.getExamSites(result?.data);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
    this.setState({ manuallyCleared: false });
  }
  /**
   * Triggers a search for an exam
   * @param {string} value search string
   *
   *
   */
  handleSearch(value) {
    if (value) {
      this.setState({ isTableLoading: true });
      // eslint-disable-next-line no-console
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getExams +
          `?${this.generateSiteIdUrl(this.state.siteIds)}&search=${value}`
      ).then(
        result => {
          this.setState({
            exams: result?.data,
            isTableLoading: false,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            pageNumber: 1,
            searchValue: value,
            currentUrl:
              sessionStorage.getItem("domain") +
              getExams +
              `?${this.generateSiteIdUrl(this.state.siteIds)}&search=${value}`
          });
          this.getDates(result?.data);
          this.getGradedDates(result?.data);
          this.getExamSites(result?.data);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleChange() {}

  render() {
    const { isLoaded, exams, examDates, isTableLoading } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from={"3"} results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div
                className={`row rowmargin abovetable custom-flex-wrapper ${
                  isTableLoading ? "not-visible" : ""
                }`}
              >
                {(() => {
                  if (this.state.showMultiSelect) {
                    return (
                      <MultiSelect
                        options={this.state.options}
                        onMenuToggle={this.menuToggle}
                        value={this.state.selected}
                        onChange={this.setSelected}
                        labelledBy="Select"
                      />
                    );
                  }
                })()}
                {(() => {
                  return (
                    <div className="addaccount-container">
                      {(() => {
                        return (
                          <Button
                            className="addaccount"
                            onClick={this.openAddExamPatientModal}
                          >
                            {this.t(T_EXAMS.addExamButton)}
                          </Button>
                        );
                      })()}
                      {this.state.showAddExamPatientModal ? (
                        <CustomAddExamPatientModal
                          text='Click "Close Button" to hide popup'
                          closePopup={this.toggleAddExamPatientModal}
                          resetList={this.resetList}
                          makeAdmin={true}
                          device={this?.state?.deviceType}
                          sites={this?.state?.sites}
                          currentSite={this?.state?.siteIds[0]}
                        />
                      ) : null}
                    </div>
                  );
                })()}
              </div>

              <div
                className={`exam-options ${
                  isTableLoading ? "not-visible" : ""
                }`}
              >
                <div
                  className={`search-container ${
                    sessionStorage.getItem("tele") === "true" ? "" : "normal"
                  }`}
                >
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">
                        {this.t(T_ENTITIES.exam.device)}
                      </div>
                      <div className="col-md-6">
                        <select
                          name="device"
                          value={this.state.deviceType}
                          onChange={this.changeDeviceType}
                        >
                          <option value="FOP" key="FOP">
                            {this.t(T_ENTITIES.exam.deviceTypes.fop)}
                          </option>
                          <option value="PSL" key="PSL">
                            {this.t(T_ENTITIES.exam.deviceTypes.psl)}
                          </option>
                          <option value="AIM" key="AIM">
                            {this.t(T_ENTITIES.exam.deviceTypes.aim)}
                          </option>
                          <option value="INSTAREF" key="INSTAREF">
                            {this.t(T_ENTITIES.exam.deviceTypes.instaref)}
                          </option>
                          <option value="PRISTINE" key="PRISTINE">
                            {this.t(T_ENTITIES.exam.deviceTypes.pristine)}
                          </option>
                          <option value="OTHER" key="OTHER">
                            {this.t(T_ENTITIES.exam.deviceTypes.other)}
                          </option>
                        </select>
                      </div>
                    </div>
                  </FormGroup>
                  {(() => {
                    if (sessionStorage.getItem("tele") === "true") {
                      return (
                        <FormGroup controlId="email" bsSize="large">
                          <div className="row">
                            <div className="col-md-5 formfont">
                              {this.t(T_ENTITIES.exam.examState)}
                            </div>
                            <div className="col-md-6">
                              <select
                                name="device"
                                value={this.state.queryType}
                                onChange={this.changeExamStatus}
                              >
                                <option value="" key="all">
                                  {this.t(T_ENTITIES.exam.examStateTypes.all)}
                                </option>
                                <option value="active" key="active">
                                  {this.t(
                                    T_ENTITIES.exam.examStateTypes.active
                                  )}
                                </option>
                                <option value="assigned" key="assigned">
                                  {this.t(
                                    T_ENTITIES.exam.examStateTypes.assigned
                                  )}
                                </option>
                                <option value="graded" key="graded">
                                  {this.t(
                                    T_ENTITIES.exam.examStateTypes.graded
                                  )}
                                </option>
                              </select>
                            </div>
                          </div>
                        </FormGroup>
                      );
                    }
                  })()}
                  <div className="or-box">{this.t(T_EXAMS.or)}</div>
                  <SearchBar
                    autoFocus
                    shouldRenderClearButton
                    shouldRenderSearchButton
                    renderClearButton
                    renderSearchButton
                    placeholder={this.t(T_EXAMS.searchExamData)}
                    onChange={this.handleChange}
                    onClear={this.handleClear}
                    onSearch={this.handleSearch}
                    suggestions={this.state.suggestions}
                    styles={styles}
                    onRef={ref => {
                      this.clearInput = ref.clearInput;
                      this.setInput = ref.setInput;
                    }}
                  />
                </div>
              </div>
              {(() => {
                if (this.state.isTableLoading) {
                  return (
                    <div className="table-error">
                      <p>
                        <img src={require("../../assets/images/loading.gif")} />
                      </p>
                    </div>
                  );
                } else if (
                  !exams ||
                  exams.length === 0 ||
                  typeof exams !== "object"
                ) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_ENTITIES.exam.name)}</th>
                            <th>{this.t(T_ENTITIES.exam.mrn)}</th>
                            <th>{this.t(T_ENTITIES.exam.examDate)}</th>
                            <th>{this.t(T_ENTITIES.exam.examCode)}</th>
                            <th>{this.t(T_ENTITIES.exam.device)}</th>
                            <th>{this.t(T_ENTITIES.exam.site)}</th>
                            {(() => {
                              if (sessionStorage.getItem("tele") === "true") {
                                return (
                                  <>
                                    <th>
                                      {this.t(T_ENTITIES.exam.numberOfImages)}
                                    </th>
                                    <th>{this.t(T_ENTITIES.exam.examState)}</th>
                                  </>
                                );
                              }
                            })()}
                            <th>{this.t(T_EXAMS.table.action)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {exams?.map?.((item, index) => (
                            <tr key={item.examDetails.id}>
                              <td>
                                <Link
                                  to={{
                                    pathname: "/exam-details",
                                    state: {
                                      exam: item,
                                      deviceType: this.state.deviceType
                                    }
                                  }}
                                >
                                  <span className="editbtn">
                                    {item.patientDetails.firstName}{" "}
                                    {item.patientDetails.lastName}
                                  </span>
                                </Link>
                              </td>
                              <td>{item.patientDetails.mrn}</td>
                              <td>
                                {examDates?.[index] ? examDates?.[index] : "-"}
                              </td>
                              <td>
                                {item?.examDetails?.examCustomId &&
                                item?.examDetails?.examCustomId !== ""
                                  ? item?.examDetails?.examCustomId
                                  : "-"}
                              </td>
                              <td>
                                {item?.examDetails?.deviceType.reduce(
                                  (acc, val) => `${acc} / ${val}`
                                )}
                              </td>
                              <td>{this.state.examSites?.[index]}</td>
                              {(() => {
                                if (sessionStorage.getItem("tele") === "true") {
                                  let noi = item?.images
                                    ? item?.images?.aimImages["EDITED"].length +
                                      item?.images?.aimImages["STANDARD"]
                                        .length +
                                      item?.images?.fopImages["EDITED"].length +
                                      item?.images?.fopImages["STANDARD"]
                                        .length +
                                      item?.images?.otherImages["EDITED"]
                                        .length +
                                      item?.images?.otherImages["STANDARD"]
                                        .length +
                                      item?.images?.pslImages["EDITED"].length +
                                      item?.images?.pslImages["STANDARD"]
                                        .length +
                                      item?.images?.pristineImages["EDITED"]
                                        .length +
                                      item?.images?.pristineImages["STANDARD"]
                                        .length
                                    : 0;
                                  return (
                                    <>
                                      <td>{noi}</td>
                                      <td>
                                        {this.state.isAdmin &&
                                        item?.examDetails?.examState ===
                                          "ASSIGNED" ? (
                                          <a
                                            onClick={() =>
                                              this.showAssignedUser(
                                                item?.examDetails?.id
                                              )
                                            }
                                            className="editbtn"
                                          >
                                            {this.getExamStateTranslation(
                                              item?.examDetails?.examState
                                            )}
                                          </a>
                                        ) : (
                                          this.getExamStateTranslation(
                                            item?.examDetails?.examState
                                          )
                                        )}
                                      </td>
                                    </>
                                  );
                                }
                              })()}
                              <td>
                                {(() => {
                                  let noi = item?.images
                                    ? item?.images?.aimImages["EDITED"].length +
                                      item?.images?.aimImages["STANDARD"]
                                        .length +
                                      item?.images?.fopImages["EDITED"].length +
                                      item?.images?.fopImages["STANDARD"]
                                        .length +
                                      item?.images?.otherImages["EDITED"]
                                        .length +
                                      item?.images?.otherImages["STANDARD"]
                                        .length +
                                      item?.images?.pslImages["EDITED"].length +
                                      item?.images?.pslImages["STANDARD"]
                                        .length +
                                      item?.images?.pristineImages["STANDARD"]
                                        .length +
                                      item?.images?.pristineImages["EDITED"]
                                        .length
                                    : 0;
                                  if (noi !== 0) {
                                    return (
                                      <a
                                        onClick={() =>
                                          this.zipExam(
                                            item?.examDetails?.id,
                                            item?.patientDetails?.firstName +
                                              (item?.patientDetails?.lastName
                                                ? " " +
                                                  item?.patientDetails?.lastName
                                                : "")
                                          )
                                        }
                                        className="editbtn"
                                      >
                                        {this.t(T_EXAMS.table.download)}
                                      </a>
                                    );
                                  } else {
                                    return (
                                      <span className="disabled-download">
                                        {this.t(T_EXAMS.table.download)}
                                      </span>
                                    );
                                  }
                                })()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <span></span>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; {this.t(T_PAGINATION.previous)}</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          let startRow = this.state.pageNumber * 10 - 9;
                          let endRow = startRow + exams.length - 1;
                          return (
                            <span className="rowcount-text">
                              {this.t(T_PAGINATION.showingRows, {
                                from: startRow,
                                to: endRow
                              })}
                            </span>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <span></span>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>{this.t(T_PAGINATION.next)} &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showShowAssignedUserModal ? (
                <CustomShowAssignedUserModal
                  graderName={this.state.graderName}
                  assignedTime={this.state.assignedTime}
                  closePopup={this.closeShowAssignedUserModal}
                  id={this.state.assignedExamId}
                  resetList={this.resetList}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(ExamData);
