/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import CustomSiteEntitlementSettingsModal from "./CustomSiteEntitlementSettingsModal";
import CustomSiteSettingsModal from "./CustomSiteSettingsModal";
import CustomGatewaySettingsModal from "./CustomGatewaySettingsModal";
import CustomSubscriptionModal from "./CustomSubscriptionModal";
import CustomEyrisModal from "./CustomEyrisModal";
import {
  getSites,
  getUser,
  createSite,
  editSite,
  deleteSite,
  getAllSiteEntitlements
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  postAuthenticatedData,
  putAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { Button, FormGroup, FormControl } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import { withTranslation } from "react-i18next";
import {
  T_ENTITIES,
  T_GENERAL,
  T_PAGINATION,
  T_SERVICE
} from "../../constants/translations";

/**
 * This is the component for the Service Sites page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.24 <br>
 * PSL - 5.3.14.24 <br>
 * FOP - 5.3.14.25 <br>
 *
 * @component
 */
class ServiceSites extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      prevPage: null,
      nextPage: null,
      sites: null,
      suggestions: [],
      showAddSiteModal: false,
      addsitename: "",
      addsitedomain: "",
      addsitetenant: "",
      addButtonDisabled: true,
      isAddLoaded: true,
      showEditSiteModal: false,
      editsitename: "",
      editsitedomain: "",
      editsitetenant: "",
      editButtonDisabled: true,
      isEditLoaded: true,
      showDeleteSiteModal: false,
      deleteSiteName: "",
      deleteSiteId: 0,
      deleteButtonDisabled: false,
      isDeleteLoaded: true,
      showGatewaySettingsModal: false,
      showEntitlementSettingsModal: false,
      showEyrisSettingsModal: false,
      showSiteSettingsModal: false,
      subscriptionSite: 0,
      eyrisSite: 0,
      showSubscriptionModal: false,
      entitlementSettings: {},
      eyrisSettings: {},
      entitlementSite: 0,
      siteEntitlements: [],
      siteConfigurations: [],
      dataStorageLocation: "",
      siteCustomIdentifier: "",
      gatewaySettings: {}
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.openAddSiteModal = this.openAddSiteModal.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openEditSiteModal = this.openEditSiteModal.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openDeleteSiteModal = this.openDeleteSiteModal.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.openGatewayModal = this.openGatewayModal.bind(this);
    this.closeGatewayModal = this.closeGatewayModal.bind(this);
    this.openEntitlementsModal = this.openEntitlementsModal.bind(this);
    this.closeEntitlementsModal = this.closeEntitlementsModal.bind(this);
    this.openSiteSettingsModal = this.openSiteSettingsModal.bind(this);
    this.closeSiteSettingsModal = this.closeSiteSettingsModal.bind(this);
    this.openSubscriptionModal = this.openSubscriptionModal.bind(this);
    this.closeSubscriptionModal = this.closeSubscriptionModal.bind(this);
    this.openEyrisModal = this.openEyrisModal.bind(this);
    this.closeEyrisModal = this.closeEyrisModal.bind(this);
    this.resetList = this.resetList.bind(this);
  }
  /**
   * Triggers the API call to list all the sites on component mount
   *
   *
   *
   */
  componentDidMount() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getSites +
        "?orgId=" +
        this?.props?.location?.state?.id +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getAllSiteEntitlements
    ).then(res => {
      this.setState({ siteEntitlements: res.data });
    });
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }
  /**
   * Opens the Gateway Modal
   * @param {object} gatewaySettings Gateway Settings
   * @param {number} siteID site ID
   *
   *
   */
  openGatewayModal(
    siteCustomIdentifier,
    dataStorageLocation,
    siteId,
    gatewaySettings
  ) {
    this.setState({
      showGatewaySettingsModal: true,
      dataStorageLocation,
      siteId,
      gatewaySettings
    });
  }

  openEntitlementsModal(entitlementSettings, siteId) {
    this.setState({
      showEntitlementSettingsModal: true,
      entitlementSettings,
      entitlementSite: siteId
    });
  }

  openSiteSettingsModal(configurations, siteId) {
    this.setState({
      entitlementSite: siteId,
      siteConfigurations: configurations,
      showSiteSettingsModal: true
    });
  }

  openSubscriptionModal(subscriptionSettings, siteId) {
    this.setState({
      showSubscriptionModal: true,
      subscriptionSettings,
      subscriptionSite: siteId
    });
  }

  openEyrisModal(eyrisSettings, siteId) {
    let localEyrisSettings = {};
    if (eyrisSettings === null) {
      localEyrisSettings = {
        eyrisAIEnabled: false,
        eyrisUsername: "",
        eyrisPassword: ""
      };
    } else {
      localEyrisSettings = eyrisSettings;
    }
    this.setState({
      showEyrisSettingsModal: true,
      eyrisSite: siteId,
      eyrisSettings: localEyrisSettings
    });
  }

  closeEyrisModal() {
    this.setState({ showEyrisSettingsModal: false });
  }
  /**
   * Closes the Gateway Modal
   *
   *
   *
   */
  closeGatewayModal() {
    this.setState({ showGatewaySettingsModal: false });
  }

  closeEntitlementsModal() {
    this.setState({ showEntitlementSettingsModal: false });
  }

  closeSiteSettingsModal() {
    this.setState({ showSiteSettingsModal: false });
  }

  closeSubscriptionModal() {
    this.setState({ showSubscriptionModal: false });
  }
  /**
   * Goes to the previous page
   *
   *
   *
   */
  goToPreviousPage() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.prevPage
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Goes to the next page
   *
   *
   *
   */
  goToNextPage() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + this.state.nextPage
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Clears the search input
   *
   *
   *
   */
  handleClear() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getSites +
        "?orgId=" +
        this?.props?.location?.state?.id +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Triggers a search
   * @param {string} value The search string
   *
   *
   */
  handleSearch(value) {
    if (value) {
      getAuthenticatedData(
        sessionStorage.getItem("domain") +
          getSites +
          "?orgId=" +
          this?.props?.location?.state?.id +
          "&paginate=true&search=" +
          value
      ).then(
        result => {
          this.setState({
            sites: result?.data,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}

  openAddSiteModal() {
    this.setState({ showAddSiteModal: true });
  }
  /**
   * Opens the Edit Site Modal
   * @param {object} item The site to be edited
   *
   *
   *
   */
  openEditSiteModal(item) {
    this.setState({
      showEditSiteModal: true,
      editsitedomain: item?.siteDomain,
      editsitename: item?.siteName,
      editsitetenant: item?.adTenantId,
      editSiteId: item?.siteId
    });
  }

  openDeleteSiteModal(item) {
    this.setState({
      showDeleteSiteModal: true,
      deleteSiteName: item?.siteName,
      deleteSiteId: item?.siteId
    });
  }

  handleChangeAdd(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          addButtonDisabled:
            this.state.addsitedomain === "" ||
            this.state.addsitename === "" ||
            (this.state.sites[0].adTenantId && this.state.addsitetenant === "")
        });
      }
    );
  }

  handleChangeEdit(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          editButtonDisabled:
            this.state.editsitedomain === "" ||
            this.state.editsitename === "" ||
            (this.state.sites[0].adTenantId && this.state.editsitetenant === "")
        });
      }
    );
  }
  /**
   * Adds a site
   *
   *
   *
   */
  handleAddButtonClick() {
    const data = {
      domain: this.state.addsitedomain,
      name: this.state.addsitename,
      isParentSite: false,
      ...(this.state.sites[0] && { adTenantId: this.state.addsitetenant })
    };
    this.setState({ isAddLoaded: false, addButtonDisabled: true });
    postAuthenticatedData(
      sessionStorage.getItem("domain") +
        createSite +
        "/" +
        this?.props?.location?.state?.id,
      data
    )
      .then(result => {
        this.setState({
          addsitedomain: "",
          addsitename: "",
          showAddSiteModal: false,
          isAddLoaded: true,
          addsitetenant: ""
        });
        if (result.status === 200) {
          toast.success(this.t(T_SERVICE.toast.addSiteSuccessful));
          this.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }
  /**
   * Edits a site
   *
   *
   *
   */
  handleEditButtonClick() {
    const data = {
      domain: this.state.editsitedomain,
      name: this.state.editsitename,
      isParentSite: false,
      ...(this.state.sites[0].adTenantId && {
        adTenantId: this.state.editsitetenant
      })
    };
    this.setState({ isEditLoaded: false, editButtonDisabled: true });
    putAuthenticatedData(
      sessionStorage.getItem("domain") + editSite + "/" + this.state.editSiteId,
      data
    ).then(result => {
      this.setState({
        editsitedomain: "",
        editsitename: "",
        editsitetenant: "",
        showEditSiteModal: false,
        isEditLoaded: true
      });
      if (result.status === 400) {
        toast.error(this.t(T_SERVICE.toast.nameOrDomainInvalid));
      } else if (result.status === 200) {
        toast.success(this.t(T_SERVICE.toast.addSiteSuccessful));
        this.resetList();
      } else {
        toast.error(this.t(T_GENERAL.somethingWentWrong));
      }
      return result.json();
    });
  }
  /**
   * Deletes a site
   *
   *
   *
   */
  handleDeleteButtonClick() {
    this.setState({ isDeleteLoaded: false, deleteButtonDisabled: true });
    deleteAuthenticatedData(
      sessionStorage.getItem("domain") +
        deleteSite +
        "/" +
        this.state.deleteSiteId,
      {}
    ).then(result => {
      this.setState({
        showDeleteSiteModal: false,
        isDeleteLoaded: true,
        deleteButtonDisabled: false
      });
      if (result.status === 400) {
        toast.error(this.t(T_SERVICE.toast.removeAllUsersBeforeDeleting));
      } else if (result.status === 200) {
        toast.success(this.t(T_SERVICE.toast.deleteSiteSuccessful));
        this.resetList();
      } else {
        toast.error(this.t(T_GENERAL.somethingWentWrong));
      }
      return result.json();
    });
  }

  resetList() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getSites +
        "?orgId=" +
        this?.props?.location?.state?.id +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  closeAddModal() {
    this.setState({ showAddSiteModal: false });
  }

  closeEditModal() {
    this.setState({ showEditSiteModal: false });
  }

  closeDeleteModal() {
    this.setState({ showDeleteSiteModal: false });
  }

  render() {
    const { isLoaded, sites } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="2" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable custom-flex-wrapper">
                {/* <Link
                  to={{ pathname: "/service-dashboard" }}
                  className="back-link"
                  onClick={this.props.history.goBack}
                >
                  &lt; Back
                </Link> */}
                <img
                  className="exam-back"
                  onClick={this.props.history.goBack}
                  src={require("../../assets/images/left-arrow-black.svg")}
                />
                <div>
                  <Button
                    className="addaccount"
                    onClick={this.openAddSiteModal}
                  >
                    {this.t(T_SERVICE.viewSites.addSiteButton)}
                  </Button>
                  {this.state.showPopup ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.togglePopup}
                      resetList={this.resetList}
                    />
                  ) : null}
                </div>
              </div>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder={this.t(T_SERVICE.viewSites.searchSites)}
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
              />
              {(() => {
                if (!sites || sites.length === 0) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_ENTITIES.site.siteName)}</th>
                            <th>{this.t(T_ENTITIES.site.siteDomain)}</th>
                            {(() => {
                              if (sites[0].adTenantId) {
                                return (
                                  <th>
                                    {this.t(T_ENTITIES.site.siteTenantID)}
                                  </th>
                                );
                              }
                            })()}
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sites?.map?.(item => (
                            <tr key={item.siteId}>
                              <td id={"dName" + item.siteId}>
                                {item?.siteName}
                              </td>
                              <td id={"dName" + item.siteId}>
                                {item?.siteDomain}
                              </td>
                              {(() => {
                                if (sites[0].adTenantId) {
                                  return (
                                    <td id={"dName" + item.siteId}>
                                      {item?.adTenantId}
                                    </td>
                                  );
                                }
                              })()}
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openEditSiteModal(item);
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    {this.t(T_GENERAL.edit)}
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a
                                  onClick={() => {
                                    this.openSiteSettingsModal(
                                      item.configurations,
                                      item.siteId
                                    );
                                  }}
                                >
                                  <span className="editbtn">
                                    {this.t(
                                      T_SERVICE.viewSites.table
                                        .siteConfigurations
                                    )}
                                  </span>
                                </a>
                              </td>

                              <td>
                                <a
                                  onClick={() => {
                                    this.openGatewayModal(
                                      item.siteCustomIdentifier,
                                      item.dataStorageLocation,
                                      item.siteId,
                                      item.gatewaySettings
                                    );
                                  }}
                                >
                                  <span className="editbtn">
                                    {this.t(
                                      T_SERVICE.viewSites.table.siteSettings
                                    )}
                                  </span>
                                </a>
                              </td>

                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openDeleteSiteModal(item);
                                  }}
                                >
                                  <span
                                    id={item.userId}
                                    className="editbtn delete-link"
                                  >
                                    {this.t(T_GENERAL.delete)}
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; {this.t(T_PAGINATION.previous)}</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>{this.t(T_PAGINATION.next)} &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showAddSiteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">
                        {this.t(T_SERVICE.viewSites.addSite.header)}
                      </h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeAddModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.site.siteName)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.addsitename}
                              onChange={this.handleChangeAdd}
                              type="text"
                              name="addsitename"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.site.siteDomain)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.addsitedomain}
                              onChange={this.handleChangeAdd}
                              type="text"
                              name="addsitedomain"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      {(() => {
                        if (this.state.sites[0].adTenantId) {
                          return (
                            <FormGroup controlId="email" bsSize="large">
                              <div className="row">
                                <div className="col-md-3 formfont">
                                  {this.t(T_ENTITIES.site.siteTenantID)}
                                </div>
                                <div className="col-md-9">
                                  <FormControl
                                    value={this.state.addsitetenant}
                                    onChange={this.handleChangeAdd}
                                    type="text"
                                    name="addsitetenant"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          );
                        }
                      })()}
                      <Button
                        onClick={this.handleAddButtonClick}
                        disabled={this.state.addButtonDisabled}
                        className={
                          "loginbtn2 edit-account-btn btn-primary fill edit-org-btn"
                        }
                      >
                        {!this.state.isAddLoaded ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          <>{this.t(T_SERVICE.viewSites.addSite.header)}</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showEditSiteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">
                        {this.t(T_SERVICE.viewSites.edit.header)}
                      </h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeEditModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.site.siteName)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.editsitename}
                              onChange={this.handleChangeEdit}
                              type="text"
                              name="editsitename"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            {this.t(T_ENTITIES.site.siteDomain)}
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.editsitedomain}
                              onChange={this.handleChangeEdit}
                              type="text"
                              name="editsitedomain"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      {(() => {
                        if (sites[0].adTenantId) {
                          return (
                            <FormGroup controlId="email" bsSize="large">
                              <div className="row">
                                <div className="col-md-3 formfont">
                                  {this.t(T_ENTITIES.site.siteTenantID)}
                                </div>
                                <div className="col-md-9">
                                  <FormControl
                                    value={this.state.editsitetenant}
                                    onChange={this.handleChangeEdit}
                                    type="text"
                                    name="editsitetenant"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          );
                        }
                      })()}

                      <Button
                        onClick={this.handleEditButtonClick}
                        disabled={this.state.editButtonDisabled}
                        className={
                          "loginbtn2 edit-account-btn btn-primary fill edit-org-btn"
                        }
                      >
                        {!this.state.isEditLoaded ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          <>{this.t(T_SERVICE.viewSites.edit.header)}</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showGatewaySettingsModal ? (
                <CustomGatewaySettingsModal
                  closePopup={this.closeGatewayModal}
                  resetList={this.resetList}
                  siteId={this.state.siteId}
                  dataStorageLocation={this.state.dataStorageLocation}
                  gatewaySettings={this.state.gatewaySettings}
                />
              ) : null}
              {this.state.showEntitlementSettingsModal ? (
                <CustomSiteEntitlementSettingsModal
                  closePopup={this.closeEntitlementsModal}
                  resetList={this.resetList}
                  entitlementSettings={this.state.entitlementSettings}
                  siteId={this.state.entitlementSite}
                />
              ) : null}
              {this.state.showSiteSettingsModal ? (
                <CustomSiteSettingsModal
                  closePopup={this.closeSiteSettingsModal}
                  resetList={this.resetList}
                  siteEntitlements={this.state.siteEntitlements}
                  siteId={this.state.entitlementSite}
                  configurations={this.state.siteConfigurations}
                />
              ) : null}
              {this.state.showSubscriptionModal ? (
                <CustomSubscriptionModal
                  closePopup={this.closeSubscriptionModal}
                  resetList={this.resetList}
                  subscriptionSettings={this.state.subscriptionSettings}
                  siteId={this.state.subscriptionSite}
                />
              ) : null}
              {this.state.showEyrisSettingsModal ? (
                <CustomEyrisModal
                  closePopup={this.closeEyrisModal}
                  resetList={this.resetList}
                  eyrisSettings={this.state.eyrisSettings}
                  siteId={this.state.eyrisSite}
                />
              ) : null}
              {this.state.showDeleteSiteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">
                        {this.t(T_SERVICE.viewSites.delete.header)}
                      </h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeDeleteModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <p className="delete-user-admin-text">
                        {this.t(T_SERVICE.viewSites.delete.areYouSure)}{" "}
                        {this.state.deleteSiteName}?
                      </p>
                      <Button
                        onClick={this.handleDeleteButtonClick}
                        disabled={this.state.deleteButtonDisabled}
                        className={"loginbtn2 edit-account-btn warning fill"}
                      >
                        {this.state.deleteButtonDisabled ? (
                          <img
                            src={require("../../assets/images/warning-button-loading.gif")}
                          />
                        ) : (
                          <>{this.t(T_SERVICE.viewSites.delete.header)}</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(ServiceSites);
