/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the page after a user has successfully created an account
 *
 * @component
 */
class UserRegistered extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit() {
    //UVA_API_Call
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (this?.state?.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <img
            className="loginlogo"
            src={require("../../assets/images/remidio.svg")}
          />
          <p className="center">
            {this.t(T_LOGIN.userRegistered.congratulationsTop)}
          </p>
          <p className="centeruser">
            {this.t(T_LOGIN.userRegistered.congratulationsBottom)}
          </p>
          <Link to="/Login">
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              {this.t(T_LOGIN.userRegistered.exit)}{" "}
            </Button>
          </Link>
        </form>
      </div>
    );
  }
}

export default withTranslation()(UserRegistered);
