import {
  commonAuthenticatedHeaders,
  commonUnauthenticatedHeaders
} from "../constants/headers";
import history from "./history";
import { toast } from "react-toastify";
import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { t } from "i18next";
import { T_GENERAL, T_LOGIN } from "../constants/translations";

export async function getAuthenticatedImage(url = "") {
  if (sessionStorage.getItem("ad")) {
    try {
      let accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: JSON.parse(sessionStorage.getItem("account"))
      });
      sessionStorage.setItem("bearerToken", accessTokenResponse.accessToken);
      return fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          ...commonAuthenticatedHeaders()
        }
      })
        .catch(e => {
          toast.error(t(T_GENERAL.somethingWentWrong));
          throw e;
        })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            logOutUser();
          }
          return response.blob();
        })
        .then(response => {
          return response;
        });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: loginRequest.scopes,
          account: JSON.parse(sessionStorage.getItem("account"))
        });
      }
    }
  } else {
    return fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        ...commonAuthenticatedHeaders()
      }
    })
      .catch(e => {
        toast.error(t(T_GENERAL.somethingWentWrong));
        throw e;
      })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          logOutUser();
        }
        return response.blob();
      })
      .then(response => {
        return response;
      });
  }
}

export async function getAuthenticatedData(url = "") {
  if (sessionStorage.getItem("ad")) {
    try {
      let accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: JSON.parse(sessionStorage.getItem("account"))
      });
      sessionStorage.setItem("bearerToken", accessTokenResponse.accessToken);
      return fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          ...commonAuthenticatedHeaders()
        }
      })
        .catch(e => {
          toast.error(t(T_GENERAL.somethingWentWrong));
          throw e;
        })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            logOutUser();
          }
          return response.json();
        })
        .then(response => {
          return response;
        });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: loginRequest.scopes,
          account: JSON.parse(sessionStorage.getItem("account"))
        });
      }
    }
  } else {
    return fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        ...commonAuthenticatedHeaders()
      }
    })
      .catch(e => {
        toast.error(t(T_GENERAL.somethingWentWrong));
        throw e;
      })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          logOutUser();
        }
        return response.json();
      })
      .then(response => {
        return response;
      });
  }
}

export async function deleteAuthenticatedData(url = "", data = {}) {
  if (sessionStorage.getItem("ad")) {
    try {
      let accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: JSON.parse(sessionStorage.getItem("account"))
      });
      sessionStorage.setItem("bearerToken", accessTokenResponse.accessToken);
      return fetch(url, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          ...commonAuthenticatedHeaders()
        },
        body: JSON.stringify(data)
      })
        .catch(e => {
          toast.error(t(T_GENERAL.somethingWentWrong));
          throw e;
        })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            logOutUser();
          }
          return response;
        });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: loginRequest.scopes,
          account: JSON.parse(sessionStorage.getItem("account"))
        });
      }
    }
  } else {
    return fetch(url, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        ...commonAuthenticatedHeaders()
      },
      body: JSON.stringify(data)
    })
      .catch(e => {
        toast.error(t(T_GENERAL.somethingWentWrong));
        throw e;
      })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          logOutUser();
        }
        return response;
      });
  }
}

export function getUnauthenticatedData(url = "") {
  return fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonUnauthenticatedHeaders()
    }
  })
    .catch(e => {
      toast.error(t(T_GENERAL.somethingWentWrong));
      throw e;
    })
    .then(response => {
      return response;
    });
}

export async function postAuthenticatedFormData(url = "", data = {}) {
  if (sessionStorage.getItem("ad")) {
    try {
      let accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: JSON.parse(sessionStorage.getItem("account"))
      });
      sessionStorage.setItem("bearerToken", accessTokenResponse.accessToken);
      return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          ...commonAuthenticatedHeaders()
        },
        body: data
      })
        .catch(e => {
          toast.error(t(T_GENERAL.somethingWentWrong));
          throw e;
        })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            logOutUser();
          }
          return response;
        });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: loginRequest.scopes,
          account: JSON.parse(sessionStorage.getItem("account"))
        });
      }
    }
  } else {
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        ...commonAuthenticatedHeaders()
      },
      body: data
    })
      .catch(e => {
        toast.error(t(T_GENERAL.somethingWentWrong));
        throw e;
      })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          logOutUser();
        }
        return response;
      });
  }
}

export async function postAuthenticatedData(url = "", data = {}) {
  if (sessionStorage.getItem("ad")) {
    try {
      let accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: JSON.parse(sessionStorage.getItem("account"))
      });
      sessionStorage.setItem("bearerToken", accessTokenResponse.accessToken);
      return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          ...commonAuthenticatedHeaders()
        },
        body: JSON.stringify(data)
      })
        .catch(e => {
          toast.error(t(T_GENERAL.somethingWentWrong));
          throw e;
        })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            logOutUser();
          }
          return response;
        });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: loginRequest.scopes,
          account: JSON.parse(sessionStorage.getItem("account"))
        });
      }
    }
  } else {
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        ...commonAuthenticatedHeaders()
      },
      body: JSON.stringify(data)
    })
      .catch(e => {
        toast.error(t(T_GENERAL.somethingWentWrong));
        throw e;
      })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          logOutUser();
        }
        return response;
      });
  }
}

export async function putAuthenticatedData(url = "", data = {}) {
  if (sessionStorage.getItem("ad")) {
    try {
      let accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: JSON.parse(sessionStorage.getItem("account"))
      });
      sessionStorage.setItem("bearerToken", accessTokenResponse.accessToken);
      return fetch(url, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          ...commonAuthenticatedHeaders()
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
        .catch(e => {
          toast.error(t(T_GENERAL.somethingWentWrong));
          throw e;
        })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            logOutUser();
          }
          return response;
        });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect({
          scopes: loginRequest.scopes,
          account: JSON.parse(sessionStorage.getItem("account"))
        });
      }
    }
  } else {
    return fetch(url, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        ...commonAuthenticatedHeaders()
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .catch(e => {
        toast.error(t(T_GENERAL.somethingWentWrong));
        throw e;
      })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          logOutUser();
        }
        return response;
      });
  }
}

export function putUnauthenticatedData(url = "", data = {}, extraHeaders = {}) {
  return fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonUnauthenticatedHeaders(),
      ...extraHeaders
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .catch(e => {
      toast.error(t(T_GENERAL.somethingWentWrong));
      throw e;
    })
    .then(response => {
      return response;
    });
}

export function postUnauthenticatedData(url = "", data = {}) {
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonUnauthenticatedHeaders()
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .catch(e => {
      toast.error(t(T_GENERAL.somethingWentWrong));
      throw e;
    })
    .then(response => {
      return response;
    });
}

function logOutUser() {
  if (sessionStorage.getItem("bearerToken")) {
    sessionStorage.removeItem("bearerToken");
  }
  if (!sessionStorage.getItem("calledLogoutOnce")) {
    toast.error(t(T_LOGIN.toast.sessionTerminated));
  }
  sessionStorage.clear();
  sessionStorage.setItem("calledLogoutOnce", true);
  history.push("/login");
}
