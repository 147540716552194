/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button } from "react-bootstrap";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import { Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import { T_STATISTICS } from "../../constants/translations";

/**
 * This is the component for the Gateway Settings modal in the Sites page. <br>
 * The site ID is passed as a prop called `siteID`
 *
 * @component
 */
class CustomStatisticsTableModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      exams: this.props.analytics.siteCounters.map(el => {
        return {
          name: el.siteName,
          totalPatients: el.totalPatients,
          totalExams: el.totalExams,
          gradedExams: el.totalReports,
          referredExams: el.totalReferred
        };
      }),
      filteredExams: this.props.analytics.siteCounters.map(el => {
        return {
          name: el.siteName,
          totalPatients: el.totalPatients,
          totalExams: el.totalExams,
          gradedExams: el.totalReports,
          referredExams: el.totalReferred
        };
      })
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }
  componentDidMount() {}

  handleSearch(e) {
    e.preventDefault();
  }

  handleChange(e) {
    this.setState({
      filteredExams: this.state.exams.filter(item =>
        item.name.match(new RegExp(e, "i"))
      )
    });
  }

  handleClear() {
    this.setState({ filteredExams: this.state.exams });
  }

  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner statistics-modal">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            {(() => {
              if (this.props.siteRadio === "all-sites") {
                if (this.props.timelineRadio === "last-seven-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.seeMore.statisticsInLastDays, {
                        name: sessionStorage.getItem("orgName"),
                        count: 7
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "last-thirty-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.seeMore.statisticsInLastDays, {
                        name: sessionStorage.getItem("orgName"),
                        count: 30
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "specific-month") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.seeMore.statisticsInMonth, {
                        name: sessionStorage.getItem("orgName"),
                        month: this.props.month
                      })}
                    </h6>
                  );
                }
              } else {
                if (this.props.timelineRadio === "last-seven-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.seeMore.statisticsInLastDays, {
                        name: this.props.selectedSite,
                        count: 7
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "last-thirty-days") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.seeMore.statisticsInLastDays, {
                        name: this.props.selectedSite,
                        count: 30
                      })}
                    </h6>
                  );
                } else if (this.props.timelineRadio === "specific-month") {
                  return (
                    <h6 className="modal-heading">
                      {this.t(T_STATISTICS.seeMore.statisticsInMonth, {
                        name: this.props.selectedSite,
                        month: this.props.month
                      })}
                    </h6>
                  );
                }
              }
            })()}
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <SearchBar
                autoFocus
                shouldRenderClearButton
                renderClearButton
                renderSearchButton
                placeholder={this.t(T_STATISTICS.seeMore.searchSite)}
                onChange={this.handleChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                styles={styles}
              />
              <div className="table-container">
                <Table className="tablebox">
                  <thead>
                    <tr>
                      <th>{this.t(T_STATISTICS.seeMore.site)}</th>
                      <th>{this.t(T_STATISTICS.totalPatients)}</th>
                      <th>{this.t(T_STATISTICS.totalExams)}</th>
                      <th>{this.t(T_STATISTICS.gradedExams)}</th>
                      <th>{this.t(T_STATISTICS.referredExams)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.filteredExams?.map?.((item, index) => (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.totalPatients}</td>
                        <td>{item?.totalExams}</td>
                        <td>{item?.gradedExams}</td>
                        <td>{item?.referredExams}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomStatisticsTableModal);
