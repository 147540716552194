/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import {
  getSites,
  getAdminsForOrg,
  getUser,
  updateAdminStatus,
  resendVerificationEmail,
  deleteUser
} from "../../constants/endpoints";
import {
  putAuthenticatedData,
  getAuthenticatedData,
  postAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { Button } from "react-bootstrap";
import { convertEpochToDate } from "../../utils/date";
// import { Link } from "react-router-dom";
import { roles } from "../../constants/roles";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { T_ENTITIES, T_GENERAL, T_SERVICE } from "../../constants/translations";
// import { Link } from "react-router-dom";

/**
 * This is the component for the Service Admins page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.28 <br>
 * PSL - 5.3.14.28 <br>
 *
 * @component
 */
class ServiceAdmins extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(
        sessionStorage.getItem("domain") + getUser
      ),
      admins: null,
      sites: [],
      currentUser: {},
      deleteButtonDisabled: false,
      showModal: false,
      showAddAccountModal: false,
      action: "",
      delete: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.openAddAccountModal = this.openAddAccountModal.bind(this);
    this.toggleAddAdminAccountModal = this.toggleAddAdminAccountModal.bind(
      this
    );
    this.resetList = this.resetList.bind(this);
  }
  /**
   * Triggers the API call to get a list of all Admins on component mount
   *
   *
   *
   */
  componentDidMount() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getSites).then(
      result => {
        this.setState({
          sites: result?.data
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAdminsForOrg +
        "/" +
        this?.props?.location?.state?.id
    ).then(
      result => {
        this.getDates(result?.data);
        this.setState({
          admins: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  toggleAddAdminAccountModal() {
    this.setState({
      showAddAccountModal: !this.state.showAddAccountModal
    });
  }
  /**
   * Resets the list of admins and clears all filters
   *
   *
   *
   */
  resetList() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") +
        getAdminsForOrg +
        "/" +
        this?.props?.location?.state?.id
    ).then(
      result => {
        this.getDates(result?.data);
        this.setState({
          admins: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  /**
   * Deletes an admin
   *
   *
   *
   */
  handleDeleteButtonClick() {
    this.setState({ deleteButtonDisabled: true });
    // deleteAuthenticatedData(
    //   sessionStorage.getItem("domain") +
    //     serviceDeleteUser +
    //     "/" +
    //     this.state.currentUser.userId,
    //   {}
    // )
    //   .then(result => {
    //     if (result.status === 200) {
    //       toast.success("Account successfully deleted");
    //     }
    //     this.setState({ deleteButtonDisabled: false, showModal: false });
    //     this.resetList();
    //     return result.json();
    //   })
    //   .then(resp => {
    //     if (resp.status.message.toLowerCase() !== "http - status ok" ||
    //       toast.error(resp.status.message);
    //     }
    //   });
    if (
      this.calculateAction(this.state.statusOfOpenedUser) ===
        this.t(T_GENERAL.block) ||
      this.calculateAction(this.state.statusOfOpenedUser) ===
        this.t(T_GENERAL.unblock)
    ) {
      putAuthenticatedData(
        sessionStorage.getItem("domain") + updateAdminStatus,
        { userId: this.state.currentUser.userId }
      )
        .then(result => {
          if (result.status === 200) {
            toast.success(this.t(T_SERVICE.toast.actionCompleted));
          }
          this.setState({ deleteButtonDisabled: false, showModal: false });
          this.resetList();
          return result.json();
        })
        .then(resp => {
          if (
            resp.status.statusCode !== "OK" &&
            resp.status.statusCode !== "FORBIDDEN" &&
            resp.status.statusCode !== "NOT_AUTHORIZED"
          ) {
            toast.error(resp.status.message);
          }
        });
    } else if (this.state.delete) {
      deleteAuthenticatedData(
        sessionStorage.getItem("domain") +
          deleteUser +
          "/" +
          this.state.currentUser.userId
      ).then(result => {
        this.setState({
          disableColoredBtn: false,
          deleteButtonDisabled: false
        });
        if (result.status === 200) {
          this.closeModal();
          toast.success(this.t(T_SERVICE.toast.userDeletedSuccessfully));
          this.resetList();
        } else {
          this.closeModal();
          toast.error(this.t(T_GENERAL.somethingWentWrong));
        }
      });
    } else if (
      this.calculateAction(this.state.statusOfOpenedUser) ===
      this.t(T_SERVICE.viewAllAdmins.resendVerificationEmailText)
    ) {
      postAuthenticatedData(
        sessionStorage.getItem("domain") +
          resendVerificationEmail +
          "/" +
          this.state.currentUser.email,
        {}
      )
        .then(result => {
          if (result.status === 200) {
            toast.success(this.t(T_SERVICE.toast.verificationEmailSent));
          }
          this.setState({ deleteButtonDisabled: false, showModal: false });
          this.resetList();
          return result.json();
        })
        .then(resp => {
          this.setState({ deleteButtonDisabled: false, showModal: false });
          if (
            resp.status.statusCode !== "OK" &&
            resp.status.statusCode !== "FORBIDDEN" &&
            resp.status.statusCode !== "NOT_AUTHORIZED"
          ) {
            toast.error(resp.status.message);
          }
        });
    }
  }
  /**
   * Used to figure out a legal action that can be taken on a user admin based on their status
   *
   * @param {string} status      the user admin's status
   */
  calculateAction(status) {
    switch (status) {
      case "SUSPENDED":
        return this.t(T_GENERAL.unblock);
      case "ACTIVE":
        return this.t(T_GENERAL.block);
      case "INACTIVE":
      case "UNVERIFIED":
        return this.t(T_SERVICE.viewAllAdmins.resendVerificationEmailText);
    }
  }
  /**
   * Figures out the text to display in the modal depending on the status
   * @param {string} status the user status
   *
   *
   */
  calculateActionText(status) {
    switch (status) {
      case "SUSPENDED":
        return this.t(T_SERVICE.viewAllAdmins.actionText.unblock);
      case "ACTIVE":
        return this.t(T_SERVICE.viewAllAdmins.actionText.block);
      case "INACTIVE":
      case "UNVERIFIED":
        if (this.state.delete === true) {
          return this.t(T_SERVICE.viewAllAdmins.actionText.delete);
        }
        return this.t(
          T_SERVICE.viewAllAdmins.actionText.resendVerificationEmail
        );
    }
  }

  openAddAccountModal() {
    this.setState({ showAddAccountModal: true });
  }

  openModal(user, deleteUser) {
    this.setState({
      showModal: true,
      currentUser: user,
      action: deleteUser ? "Delete" : this.calculateAction(user.accountStatus),
      statusOfOpenedUser: user.accountStatus,
      delete: deleteUser
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }
  /**
   * Converts all the epochs to user readable date
   * @param {array} items The list of all admins
   *
   *
   *
   */
  getDates(items) {
    let dates = items?.map?.(item => convertEpochToDate(item.lastActiveDate));
    this.setState({ dates });
  }

  render() {
    const { isLoaded, admins } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="2" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable custom-flex-wrapper">
                {/* <Link
                  to={{ pathname: "/service-dashboard" }}
                  className="back-link"
                >
                  &lt; Back
                </Link> */}
                <img
                  className="exam-back"
                  onClick={this.props.history.goBack}
                  src={require("../../assets/images/left-arrow-black.svg")}
                />
                <div>
                  <Button
                    className="addaccount"
                    onClick={this.openAddAccountModal}
                  >
                    {this.t(T_SERVICE.viewAllAdmins.addAdminAccountButton)}
                  </Button>
                  {this.state.showAddAccountModal ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.toggleAddAdminAccountModal}
                      resetList={this.resetList}
                      makeAdmin={true}
                      orgId={this?.props?.location?.state?.id}
                    />
                  ) : null}
                </div>
              </div>
              {(() => {
                if (!admins || admins.length === 0) {
                  return (
                    <div className="table-error">
                      <p>{this.t(T_GENERAL.noResultsToDisplay)}</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>{this.t(T_ENTITIES.user.name)}</th>
                            <th>{this.t(T_ENTITIES.user.site)}</th>
                            <th>{this.t(T_ENTITIES.user.emailID)}</th>
                            <th>
                              {this.t(T_ENTITIES.user.registrationNumber)}
                            </th>
                            <th>{this.t(T_ENTITIES.user.roles)}</th>
                            <th>{this.t(T_ENTITIES.user.status)}</th>
                            <th>{this.t(T_ENTITIES.user.lastActive)}</th>
                            <th>
                              {this.t(T_SERVICE.viewAllAdmins.table.action)}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {admins?.map?.((item, index) => (
                            <tr key={item.userId}>
                              <td id={"dName" + item.userId}>
                                {item.firstName} {item.lastName}
                              </td>
                              <td id={"dName" + item.userId}>
                                {item.siteName}
                              </td>
                              <td id={"dEmail" + item.userId}>{item.email}</td>
                              <td id={"userType" + item.userId}>
                                {item.employeeId}
                              </td>
                              <td id={"id" + item.userId}>
                                {item.roles
                                  .map(e => roles[e])
                                  .reduce((acc, val) => `${acc} / ${val}`)}
                              </td>
                              <td id={"status" + item.userId}>
                                {item.accountStatus}
                              </td>
                              <td id={"created_at" + item.userId}>
                                {this.state.dates?.[index]}
                              </td>
                              <td className="tablemodal">
                                <a
                                  id={item.userId}
                                  className={
                                    "editbtn" +
                                    (this.calculateAction(
                                      item.accountStatus
                                    ) === this.t(T_GENERAL.block)
                                      ? " delete-link"
                                      : "")
                                  }
                                  onClick={() => this.openModal(item, false)}
                                >
                                  {this.calculateAction(item.accountStatus)}
                                </a>
                                {(() => {
                                  return item.accountStatus === "UNVERIFIED" ? (
                                    <>
                                      {" "}
                                      |{" "}
                                      <a
                                        id={item.userId}
                                        className={"editbtn delete-link"}
                                        onClick={() =>
                                          this.openModal(item, true)
                                        }
                                      >
                                        {this.t(T_GENERAL.delete)}
                                      </a>
                                    </>
                                  ) : null;
                                })()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          {this.state.showModal && (
            <div className="custommodal">
              <div className="custommodaldeleteuser">
                <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                  <h6 className="modal-heading">{this.state.action}</h6>
                  <Button
                    // eslint-disable-next-line react/prop-types
                    onClick={this.closeModal}
                    className="cancle"
                  >
                    {" "}
                    X{" "}
                  </Button>
                  <hr className="hrpadding" />
                  <p className="delete-user-admin-text">
                    Are you sure you want to{" "}
                    {this.calculateActionText(this.state.statusOfOpenedUser) +
                      " "}
                    {this.state.currentUser.firstName +
                      " " +
                      this.state.currentUser.lastName}
                    ?
                  </p>
                  <Button
                    onClick={this.handleDeleteButtonClick}
                    disabled={this.state.deleteButtonDisabled}
                    className={
                      "loginbtn2 edit-account-btn" +
                      (this.calculateAction(this.state.statusOfOpenedUser) ===
                        this.t(T_GENERAL.block) || this.state.delete
                        ? " warning fill"
                        : " loginbtn1")
                    }
                  >
                    {this.state.deleteButtonDisabled ? (
                      this.calculateAction(this.state.statusOfOpenedUser) ===
                        this.t(T_GENERAL.block) || this.state.delete ? (
                        <img
                          src={require("../../assets/images/warning-button-loading.gif")}
                        />
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )
                    ) : (
                      <>
                        {this.state.delete
                          ? this.t(T_GENERAL.delete)
                          : this.calculateAction(this.state.statusOfOpenedUser)}
                      </>
                    )}
                  </Button>
                </form>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default withTranslation()(ServiceAdmins);
