/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getDiseases,
  getSites,
  getUser,
  getSiteLogo,
  getUserSignature,
  gradeExam
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  postAuthenticatedFormData
} from "../../utils/async";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { convertEpochToDate } from "../../utils/date";
import { pdf } from "@react-pdf/renderer";
import PDF from "./PDF";
import { withTranslation } from "react-i18next";
import { T_ENTITIES, T_EXAMS, T_GENERAL } from "../../constants/translations";

/**
 * This is the component for the Generate Report modal <br>
 * It takes a prop called `exam` which is the entire exam object passed from the exam details page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.6, 5.3.14.30 <br>
 * PSL - 5.3.14.6, 5.3.14.30 <br>
 * FOP - 5.3.14.6 <br>
 *
 * @component
 */
class CustomGenerateReportWithoutImagesModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      data: [],
      firstRadio: [""],
      secondRadio: [""],
      thirdRadio: [""],
      firstradiovalue: null,
      secondradiovalue: null,
      thirdradiovalue: null,
      rightfirstradiovalue: null,
      rightsecondradiovalue: null,
      rightthirdradiovalue: null,
      lefteyecomments: "",
      righteyecomments: "",
      isLoaded: true,
      selectedImages: [],
      selectedImagesEdited: [],
      disableFirstButton: true,
      disableFourthButton: true,
      showLeftSecond: false,
      showLeftThird: false,
      showRightSecond: false,
      showRightThird: false,
      showLeftAdd: true,
      showRightAdd: true,
      prescription: "",
      chars: 450,
      fullData: [],
      visionAssessment: undefined,
      referRequired: undefined,
      siteName: "",
      first: false,
      second: false,
      userId: 0,
      signatureExists: false,
      signature: ""
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFirstPageProceed = this.onFirstPageProceed.bind(this);
    this.onSecondPageProceed = this.onSecondPageProceed.bind(this);
    this.onThirdPageProceed = this.onThirdPageProceed.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
    this.goToSecondPage = this.goToSecondPage.bind(this);
    this.goToThirdPage = this.goToThirdPage.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.toggleSelectEdited = this.toggleSelectEdited.bind(this);
    this.addLeft = this.addLeft.bind(this);
    this.addRight = this.addRight.bind(this);
    this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
    this.getDiseases = this.getDiseases.bind(this);
    this.changeVA = this.changeVA.bind(this);
    this.getSites = this.getSites.bind(this);
    this.getUserId = this.getUserId.bind(this);
  }
  componentDidMount() {
    this.getDiseases();
    this.getSites();
    this.getUserId();
    this.setState({ firstRadio: this.state.data });
    this.getSignature();
  }

  getUserId() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getUser).then(
      result => {
        this.setState({ userId: result?.data?.userId });
      }
    );
  }

  getSignature() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getUserSignature
    ).then(res => {
      if (res.status.statusCode === "NOT_FOUND") {
        this.setState({ signatureExists: false });
      } else {
        this.setState({
          signatureExists: true,
          signature: res.data
        });
      }
    });
  }

  getSites() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getSites)
      .then(res => {
        // eslint-disable-next-line no-console
        const siteArray = res?.data?.map?.(el => el.siteId);
        let index = 0;
        if (siteArray.includes(this.props.exam.patientDetails.siteId)) {
          index = siteArray.indexOf(this.props.exam.patientDetails.siteId);
        }
        this.setState({
          siteName: res.data[index].siteName,
          hasLogo: res.data[index].hasLogo
        });
      })
      .then(() => {
        getAuthenticatedData(
          sessionStorage.getItem("domain") +
            getSiteLogo +
            "/" +
            this.props.exam.patientDetails.siteId
        ).then(
          result => {
            this.setState({ siteLogo: result.data });
          },
          () => {
            this.setState({
              isLoaded: true
            });
          }
        );
      });
  }

  getDiseases() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getDiseases).then(
      res => {
        this.setState({
          data: res.data.map(el => el.displayName),
          firstRadio: res.data.map(el => el.displayName),
          fullData: res.data
        });
      }
    );
  }

  changeVA(e) {
    if (e.target.checked && e.target.value === "first") {
      this.setState({ first: true });
    } else if (e.target.checked && e.target.value === "second") {
      this.setState({ second: true });
    } else if (!e.target.checked && e.target.value === "first") {
      this.setState({ first: false });
    } else {
      this.setState({ second: false });
    }
    this.setState(
      { [e.target.name]: e.target.checked ? e.target.value : undefined },
      () => {
        this.setState({
          disableFourthButton: !(
            this.state.visionAssessment && this.state.referRequired
          )
        });
      }
    );
  }
  /**
   * Triggered when an autocomplete option is selected
   * Also handles the case when an autocomplete option is cleared
   *
   *
   * @param {object} e      event listener
   * @param (string) value  the autocomplete option selected
   */
  onAutocompleteSelect(e, value) {
    let targetName;
    if (e.target.id.split("-")[0]) {
      targetName = e.target.id.split("-")[0];
    } else {
      targetName = e.currentTarget.parentElement.parentElement.children[0].id;
    }
    // eslint-disable-next-line no-console
    console.log(e.target, value);
    this.setState(
      {
        [targetName]: value
      },
      () => {
        // eslint-disable-next-line no-console
        if (targetName === "firstradiovalue" && value === null) {
          this.setState({
            secondradiovalue: null,
            thirdradiovalue: null
          });
        } else if (targetName === "secondradiovalue" && value === null) {
          this.setState({ thirdradiovalue: null });
        } else if (targetName === "rightfirstradiovalue" && value === null) {
          this.setState({
            rightsecondradiovalue: null,
            rightthirdradiovalue: null
          });
        } else if (targetName === "rightsecondradiovalue" && value === null) {
          this.setState({
            rightthirdradiovalue: null
          });
        }
      }
    );
  }
  /**
   * Handles changes to the device selection in the form
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    // eslint-disable-next-line no-console
    console.log(e.target);
    if (
      !(e.target.name === "prescription" && e.target.value.length > 450) &&
      !(
        (e.target.name === "lefteyecomments" ||
          e.target.name === "righteyecomments") &&
        e.target.value.length > 240
      )
    ) {
      this.setState({ [e.target.name]: e.target.value }, () => {
        let chars = 0;
        if (this.state.prescription.length >= 450) {
          chars = 0;
        } else {
          chars = 450 - this.state.prescription.length;
        }
        this.setState({
          chars,
          disableFourthButton: !(
            this.state.visionAssessment && this.state.referRequired
          )
        });
      });
    }
  }
  /**
   * Goes to the second page
   *
   */
  onFirstPageProceed() {
    this.setState({ activePage: 2 });
  }
  onSecondPageProceed() {
    this.setState({ activePage: 3, lefteyecomments: "" });
  }
  onThirdPageProceed() {
    this.setState({ activePage: 4 });
  }
  /**
   * Allows the user to add another diagnosis for the left eye
   *
   *
   */
  addLeft() {
    if (!this.state.showLeftSecond) {
      this.setState({ showLeftSecond: true });
    } else if (!this.state.showLeftThird) {
      this.setState({ showLeftThird: true, showLeftAdd: false });
    }
  }
  /**
   * Allows the user to add another diagnosis for the right eye
   *
   *
   */
  addRight() {
    if (!this.state.showRightSecond) {
      this.setState({ showRightSecond: true });
    } else if (!this.state.showRightThird) {
      this.setState({ showRightThird: true, showRightAdd: false });
    }
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * Submits the form
   *
   *
   */
  async onSubmit() {
    this.setState({ isLoaded: false });
    let leftDiagnoses = [];
    if (!this.state.firstradiovalue) {
      leftDiagnoses = ["Not Applicable"];
    } else if (!this.state.secondradiovalue) {
      leftDiagnoses = [this.state.firstradiovalue];
    } else if (!this.state.thirdradiovalue) {
      leftDiagnoses = [this.state.firstradiovalue, this.state.secondradiovalue];
    } else {
      leftDiagnoses = [
        this.state.firstradiovalue,
        this.state.secondradiovalue,
        this.state.thirdradiovalue
      ];
    }
    leftDiagnoses = leftDiagnoses.reduce((acc, curr) => `${acc}, ${curr}`);
    let rightDiagnoses = [];
    if (!this.state.rightfirstradiovalue) {
      rightDiagnoses = ["Not Applicable"];
    } else if (!this.state.rightsecondradiovalue) {
      rightDiagnoses = [this.state.rightfirstradiovalue];
    } else if (!this.state.rightthirdradiovalue) {
      rightDiagnoses = [
        this.state.rightfirstradiovalue,
        this.state.rightsecondradiovalue
      ];
    } else {
      rightDiagnoses = [
        this.state.rightfirstradiovalue,
        this.state.rightsecondradiovalue,
        this.state.rightthirdradiovalue
      ];
    }
    rightDiagnoses = rightDiagnoses.reduce((acc, curr) => `${acc}, ${curr}`);
    const blob = await pdf(
      <PDF
        exam={this.props.exam}
        leftDiagnoses={leftDiagnoses}
        rightDiagnoses={rightDiagnoses}
        diagnosis={this.state.lefteyecomments}
        examDate={convertEpochToDate(this.props.exam.examDetails.examDate)}
        age={Math.floor(
          (new Date() -
            new Date(this.props.exam.patientDetails.dateOfBirth).getTime()) /
            3.15576e10
        )}
        selectedImages={[]}
        hasLogo={this.state.hasLogo}
        siteLogo={this.state.siteLogo}
        siteName={this.state.siteName}
        referRequired={this.state.referRequired === "yes" ? true : false}
        showRefractive={this.props.hasAssessment}
        visionAssessment={this.props.visionAssessment}
        showFirst={this.state.first}
        showSecond={this.state.second}
        signatureExists={this.state.signatureExists}
        signature={this.state.signature}
      ></PDF>
    ).toBlob();
    leftDiagnoses = [];
    if (!this.state.firstradiovalue) {
      leftDiagnoses = ["Not Applicable"];
    } else if (!this.state.secondradiovalue) {
      leftDiagnoses = [this.state.firstradiovalue];
    } else if (!this.state.thirdradiovalue) {
      leftDiagnoses = [this.state.firstradiovalue, this.state.secondradiovalue];
    } else {
      leftDiagnoses = [
        this.state.firstradiovalue,
        this.state.secondradiovalue,
        this.state.thirdradiovalue
      ];
    }
    rightDiagnoses = [];
    if (!this.state.rightfirstradiovalue) {
      rightDiagnoses = ["Not Applicable"];
    } else if (!this.state.rightsecondradiovalue) {
      rightDiagnoses = [this.state.rightfirstradiovalue];
    } else if (!this.state.rightthirdradiovalue) {
      rightDiagnoses = [
        this.state.rightfirstradiovalue,
        this.state.rightsecondradiovalue
      ];
    } else {
      rightDiagnoses = [
        this.state.rightfirstradiovalue,
        this.state.rightsecondradiovalue,
        this.state.rightthirdradiovalue
      ];
    }
    leftDiagnoses = leftDiagnoses.map(el => {
      let displayNames = this.state.fullData.map(el => el.displayName);
      let index = displayNames.indexOf(el);
      return this.state.fullData[index].icd10;
    });
    rightDiagnoses = rightDiagnoses.map(el => {
      let displayNames = this.state.fullData.map(el => el.displayName);
      let index = displayNames.indexOf(el);
      return this.state.fullData[index].icd10;
    });
    var data = {
      examId: this.props.exam.examDetails.id,
      localId: this.props.exam.examDetails.localId + "-report",
      patientId: this.props.exam.patientDetails.id,
      leftEyeDiagnosis: {
        diagnoses: leftDiagnoses
      },
      rightEyeDiagnosis: {
        diagnoses: rightDiagnoses
      },
      prescriptionTreatment: this.state.prescription,
      imageIds: [],
      reportingDoctorId: this.state.userId,
      includeVAReading1: this.state.first,
      includeVAReading2: this.state.second,
      referRequired: this.state.referRequired === "yes" ? true : false,
      comments: this.state.lefteyecomments
    };

    const formData = new FormData();
    formData.append(
      "dto",
      new Blob([JSON.stringify(data)], { type: "application/json" })
    );
    formData.append("pdf", blob);

    postAuthenticatedFormData(
      sessionStorage.getItem("domain") + gradeExam,
      formData
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 200) {
          toast.success(this.t(T_EXAMS.toast.generateSuccess));
          this.setState({
            organizationname: "",
            sitedomain: "",
            sitename: "",
            firstname: "",
            lastname: "",
            email: "",
            employeeid: "",
            activePage: 1
          });
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  goToFirstPage() {
    if (this.state.hasImages) {
      this.setState({ activePage: 1 });
    } else {
      this.setState({ activePage: 0 });
    }
  }
  goToSecondPage() {
    this.setState({ activePage: 2 });
  }
  goToThirdPage() {
    this.setState({ activePage: 3 });
  }
  /**
   * Toggles the selection of a (non-edited) image
   *
   * @param {number} index      index of the image that was clicked
   */
  toggleSelect(index) {
    if (this.state.selectedImages.includes(index)) {
      this.setState(
        {
          selectedImages: this.state.selectedImages.filter(el => el !== index)
        },
        () => {
          this.setState({
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              !this.state.first &&
              !this.state.second
          });
        }
      );
    } else {
      this.setState(
        {
          selectedImages: [...this.state.selectedImages, index]
        },
        () => {
          this.setState({
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              !this.state.first &&
              !this.state.second
          });
        }
      );
    }
  }

  /**
   * Toggles the selection of an edited image
   *
   * @param {number} index      index of the image that was clicked
   */
  toggleSelectEdited(index) {
    if (this.state.selectedImagesEdited.includes(index)) {
      this.setState(
        {
          selectedImagesEdited: this.state.selectedImagesEdited.filter(
            el => el !== index
          )
        },
        () => {
          this.setState({
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              !this.state.first &&
              !this.state.second
          });
        }
      );
    } else {
      this.setState(
        {
          selectedImagesEdited: [...this.state.selectedImagesEdited, index]
        },
        () => {
          this.setState({
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              !this.state.first &&
              !this.state.second
          });
        }
      );
    }
  }
  render() {
    const filterOptions = (options, { inputValue }) => {
      if (!inputValue) {
        return options;
      }
      const re = new RegExp(inputValue, "i");
      return options.filter(
        (option, i) =>
          (this.state.fullData[i].displayName &&
            this.state.fullData[i].displayName.match(re)) ||
          (this.state.fullData[i].icd10 &&
            this.state.fullData[i].icd10.match(re)) ||
          (this.state.fullData[i].searchBy &&
            this.state.fullData[i].searchBy.match(re))
      );
    };
    return (
      <div className="custommodal">
        <div className="custommodalinner generate-report-modal">
          {(() => {
            if (this.state.activePage === 2) {
              return (
                <a className="add-org-back" onClick={this.goToFirstPage}>
                  &lt; {this.t(T_EXAMS.examDetails.generateReport.back)}
                </a>
              );
            } else if (this.state.activePage === 3) {
              return (
                <a className="add-org-back" onClick={this.goToSecondPage}>
                  &lt; {this.t(T_EXAMS.examDetails.generateReport.back)}
                </a>
              );
            } else if (this.state.activePage === 4) {
              return (
                <a className="add-org-back" onClick={this.goToThirdPage}>
                  &lt; {this.t(T_EXAMS.examDetails.generateReport.back)}
                </a>
              );
            }
          })()}
          <form
            className="generate-report-without-images-modal loginpad"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">
              {this.t(T_EXAMS.examDetails.generateReport.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding generate-report-line" />
            {(() => {
              if (this.props.hasImages || this.props.hasAssessment) {
                return (
                  <>
                    <div className="firstpage">
                      <h5 className="name">
                        {this.props.exam.patientDetails.firstName}{" "}
                        {this.props.exam.patientDetails.lastName}
                      </h5>
                      <div className="grader-details-container">
                        <div>
                          <span className="grader-report-heading">
                            {this.t(T_ENTITIES.patient.mrn)}:
                          </span>{" "}
                          {this.props.exam.patientDetails.mrn}
                        </div>
                        <div>
                          <span className="grader-report-heading">
                            {this.t(T_ENTITIES.patient.dateOfBirth)}:
                          </span>{" "}
                          {convertEpochToDate(
                            this.props.exam.patientDetails.dateOfBirth
                          )}
                        </div>
                      </div>
                      <div className="grader-details-container">
                        <div>
                          <span className="grader-report-heading">
                            {this.t(T_ENTITIES.patient.gender)}:
                          </span>{" "}
                          {this.props.exam.patientDetails.gender}
                        </div>
                        <div>
                          <span className="grader-report-heading">
                            {this.t(T_ENTITIES.exam.site)}:
                          </span>{" "}
                          {this.state.siteName}
                        </div>
                      </div>
                      <div className="grader-details-container">
                        <div>
                          <span className="grader-report-heading">
                            {this.t(T_ENTITIES.exam.examDate)}:
                          </span>{" "}
                          {convertEpochToDate(
                            this.props.exam.examDetails.examDate
                          )}
                        </div>
                      </div>
                      <div>
                        <h5>
                          {this.t(
                            T_EXAMS.examDetails.generateReport
                              .objectiveRefraction
                          )}
                        </h5>
                        {(() => {
                          if (
                            this.props.visionAssessment.readingSet1?.reReading
                              ?.average.sph
                          ) {
                            return (
                              <>
                                <p className="va-subheading">
                                  {this.t(
                                    T_EXAMS.examDetails.generateReport.reading1
                                  )}
                                </p>
                                <div className="va-selection-container">
                                  <input
                                    type="checkbox"
                                    className="va-radio"
                                    name="visionAssessment"
                                    disabled={
                                      !this.props.visionAssessment.readingSet1
                                        ?.reReading?.average.sph
                                    }
                                    value={"first"}
                                    checked={this.state.first}
                                    onChange={this.changeVA}
                                  />
                                  <div className="aggregate-table-container-container">
                                    <div className="aggregate-subheading">
                                      {this.t(
                                        T_EXAMS.examDetails.generateReport
                                          .rightEye
                                      )}
                                    </div>
                                    <div className="aggregate-table-container">
                                      <div className="aggregate-table-heading top-left">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .spherical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .cylindrical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading top-right">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .axis
                                        )}
                                      </div>
                                      <div className="aggregate-table-contents bottom-left">
                                        {
                                          this.props.visionAssessment
                                            .readingSet1?.reReading?.average.sph
                                        }
                                      </div>
                                      <div className="aggregate-table-contents">
                                        {
                                          this.props.visionAssessment
                                            .readingSet1?.reReading?.average.cyl
                                        }
                                      </div>
                                      <div className="aggregate-table-contents bottom-right">
                                        {
                                          this.props.visionAssessment
                                            .readingSet1?.reReading?.average
                                            .axis
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="aggregate-table-container-container">
                                    <div className="aggregate-subheading">
                                      {this.t(
                                        T_EXAMS.examDetails.generateReport
                                          .leftEye
                                      )}
                                    </div>
                                    <div className="aggregate-table-container">
                                      <div className="aggregate-table-heading top-left">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .spherical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .cylindrical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading top-right">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .axis
                                        )}
                                      </div>
                                      <div className="aggregate-table-contents bottom-left">
                                        {
                                          this.props.visionAssessment
                                            .readingSet1?.leReading?.average.sph
                                        }
                                      </div>
                                      <div className="aggregate-table-contents">
                                        {
                                          this.props.visionAssessment
                                            .readingSet1?.leReading?.average.cyl
                                        }
                                      </div>
                                      <div className="aggregate-table-contents bottom-right">
                                        {
                                          this.props.visionAssessment
                                            .readingSet1?.leReading?.average
                                            .axis
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })()}
                        {(() => {
                          if (
                            this.props.visionAssessment.readingSet2?.reReading
                              ?.average.sph
                          ) {
                            return (
                              <>
                                <p className="va-subheading">
                                  {this.t(
                                    T_EXAMS.examDetails.generateReport.reading2
                                  )}
                                </p>
                                <div className="va-selection-container last">
                                  <input
                                    type="checkbox"
                                    className="va-radio"
                                    name="visionAssessment"
                                    disabled={
                                      !this.props.visionAssessment.readingSet2
                                        ?.reReading?.average.sph
                                    }
                                    value={"second"}
                                    checked={this.state.second}
                                    onChange={this.changeVA}
                                  />
                                  <div className="aggregate-table-container-container">
                                    <div className="aggregate-subheading">
                                      {this.t(
                                        T_EXAMS.examDetails.generateReport
                                          .rightEye
                                      )}
                                    </div>
                                    <div className="aggregate-table-container">
                                      <div className="aggregate-table-heading top-left">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .spherical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .cylindrical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading top-right">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .axis
                                        )}
                                      </div>
                                      <div className="aggregate-table-contents bottom-left">
                                        {
                                          this.props.visionAssessment
                                            .readingSet2?.reReading?.average.sph
                                        }
                                      </div>
                                      <div className="aggregate-table-contents">
                                        {
                                          this.props.visionAssessment
                                            .readingSet2?.reReading?.average.cyl
                                        }
                                      </div>
                                      <div className="aggregate-table-contents bottom-right">
                                        {
                                          this.props.visionAssessment
                                            .readingSet2?.reReading?.average
                                            .axis
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="aggregate-table-container-container">
                                    <div className="aggregate-subheading">
                                      {this.t(
                                        T_EXAMS.examDetails.generateReport
                                          .leftEye
                                      )}
                                    </div>
                                    <div className="aggregate-table-container">
                                      <div className="aggregate-table-heading top-left">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .spherical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .cylindrical
                                        )}
                                      </div>
                                      <div className="aggregate-table-heading top-right">
                                        {this.t(
                                          T_EXAMS.examDetails.generateReport
                                            .axis
                                        )}
                                      </div>
                                      <div className="aggregate-table-contents bottom-left">
                                        {
                                          this.props.visionAssessment
                                            .readingSet2?.leReading?.average.sph
                                        }
                                      </div>
                                      <div className="aggregate-table-contents">
                                        {
                                          this.props.visionAssessment
                                            .readingSet2?.leReading?.average.cyl
                                        }
                                      </div>
                                      <div className="aggregate-table-contents bottom-right">
                                        {
                                          this.props.visionAssessment
                                            .readingSet2?.leReading?.average
                                            .axis
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })()}
                      </div>
                      {(() => {
                        if (this.props.hasImages) {
                          let gridImages = this.props.small.map(
                            (image, key) => (
                              <div
                                key={image.id}
                                onClick={() => this.toggleSelect(image.id, key)}
                                className={
                                  this.state.selectedImages.includes(image.id)
                                    ? "image-grid-item selected"
                                    : "image-grid-item"
                                }
                              >
                                <img
                                  className="tick"
                                  src={require("../../assets/images/tickIcon.png")}
                                />
                                <img src={image.thumbnailPath} />
                              </div>
                            )
                          );
                          let gridImagesEdited = this.props.smallEdited.map(
                            (image, key) => (
                              <div
                                key={image.id}
                                onClick={() =>
                                  this.toggleSelectEdited(image.id, key)
                                }
                                className={
                                  this.state.selectedImagesEdited.includes(
                                    image.id
                                  )
                                    ? "image-grid-item selected"
                                    : "image-grid-item"
                                }
                              >
                                <img
                                  className="tick"
                                  src={require("../../assets/images/tickIcon.png")}
                                />
                                <img src={image.thumbnailPath} />
                              </div>
                            )
                          );
                          return (
                            <>
                              <h6 className="select-images-heading">
                                {this.t(
                                  T_EXAMS.examDetails.generateReport
                                    .selectImages
                                )}
                              </h6>
                              <div className="select-grid-container">
                                {gridImages}
                                {gridImagesEdited}
                              </div>
                            </>
                          );
                        }
                      })()}
                    </div>
                    <div className="firstpage">
                      <h5>
                        {this.t(T_EXAMS.examDetails.generateReport.rightEye)}
                      </h5>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-2 formfont">
                            {this.t(
                              T_EXAMS.examDetails.generateReport.diagnosis
                            )}
                            *
                          </div>
                          <div className="col-md-10">
                            <Autocomplete
                              id="rightfirstradiovalue"
                              options={this.state.data}
                              getOptionLabel={option => option}
                              style={{ width: 300 }}
                              filterOptions={filterOptions}
                              name="rightfirstradiovalue"
                              onChange={this.onAutocompleteSelect}
                              value={this.state.rightfirstradiovalue}
                              renderInput={params => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </div>
                        </div>
                      </FormGroup>
                      {(() => {
                        if (this.state.showRightSecond) {
                          return (
                            <FormGroup controlId="email" bsSize="large">
                              <div className="row">
                                <div className="col-md-2 formfont">
                                  {this.t(
                                    T_EXAMS.examDetails.generateReport.diagnosis
                                  )}
                                </div>
                                <div className="col-md-10">
                                  <Autocomplete
                                    id="rightsecondradiovalue"
                                    options={this.state.data.filter(
                                      item =>
                                        item !== this.state.rightfirstradiovalue
                                    )}
                                    getOptionLabel={option => option}
                                    filterOptions={filterOptions}
                                    style={{ width: 300 }}
                                    name="rightsecondradiovalue"
                                    onChange={this.onAutocompleteSelect}
                                    value={this.state.rightsecondradiovalue}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          );
                        }
                      })()}
                      {(() => {
                        if (this.state.showRightThird) {
                          return (
                            <FormGroup controlId="email" bsSize="large">
                              <div className="row">
                                <div className="col-md-2 formfont">
                                  {this.t(
                                    T_EXAMS.examDetails.generateReport.diagnosis
                                  )}
                                </div>
                                <div className="col-md-10">
                                  <Autocomplete
                                    id="rightthirdradiovalue"
                                    options={this.state.data.filter(
                                      item =>
                                        item !==
                                          this.state.rightfirstradiovalue &&
                                        item !==
                                          this.state.rightsecondradiovalue
                                    )}
                                    getOptionLabel={option => option}
                                    style={{ width: 300 }}
                                    name="rightthirdradiovalue"
                                    onChange={this.onAutocompleteSelect}
                                    value={this.state.rightthirdradiovalue}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          );
                        }
                      })()}
                      {(() => {
                        if (this.state.showRightAdd) {
                          return (
                            <div className="add-diagnosis">
                              <a onClick={this.addRight}>
                                {this.t(
                                  T_EXAMS.examDetails.generateReport
                                    .addAnotherDiagnosis
                                )}
                              </a>
                            </div>
                          );
                        }
                      })()}
                    </div>
                    <div className="firstpage">
                      <h5>
                        {this.t(T_EXAMS.examDetails.generateReport.leftEye)}
                      </h5>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-2 formfont">
                            {this.t(
                              T_EXAMS.examDetails.generateReport.diagnosis
                            )}
                            *
                          </div>
                          <div className="col-md-10">
                            <Autocomplete
                              id="firstradiovalue"
                              options={this.state.data}
                              getOptionLabel={option => option}
                              style={{ width: 300 }}
                              name="firstradiovalue"
                              onChange={this.onAutocompleteSelect}
                              value={this.state.firstradiovalue}
                              renderInput={params => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </div>
                        </div>
                      </FormGroup>
                      {(() => {
                        if (this.state.showLeftSecond) {
                          return (
                            <FormGroup controlId="email" bsSize="large">
                              <div className="row">
                                <div className="col-md-2 formfont">
                                  {this.t(
                                    T_EXAMS.examDetails.generateReport.diagnosis
                                  )}
                                </div>
                                <div className="col-md-10">
                                  <Autocomplete
                                    id="secondradiovalue"
                                    options={this.state.data.filter(
                                      item =>
                                        item !== this.state.firstradiovalue
                                    )}
                                    getOptionLabel={option => option}
                                    style={{ width: 300 }}
                                    name="secondradiovalue"
                                    onChange={this.onAutocompleteSelect}
                                    value={this.state.secondradiovalue}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          );
                        }
                      })()}
                      {(() => {
                        if (this.state.showLeftThird) {
                          return (
                            <FormGroup controlId="email" bsSize="large">
                              <div className="row">
                                <div className="col-md-2 formfont">
                                  {this.t(
                                    T_EXAMS.examDetails.generateReport.diagnosis
                                  )}
                                </div>
                                <div className="col-md-10">
                                  <Autocomplete
                                    id="thirdradiovalue"
                                    options={this.state.data.filter(
                                      item =>
                                        item !== this.state.firstradiovalue &&
                                        item !== this.state.secondradiovalue
                                    )}
                                    getOptionLabel={option => option}
                                    style={{ width: 300 }}
                                    name="thirdradiovalue"
                                    onChange={this.onAutocompleteSelect}
                                    value={this.state.thirdradiovalue}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          );
                        }
                      })()}
                      {(() => {
                        if (this.state.showLeftAdd) {
                          return (
                            <div className="add-diagnosis">
                              <a onClick={this.addLeft}>
                                {this.t(
                                  T_EXAMS.examDetails.generateReport
                                    .addAnotherDiagnosis
                                )}
                              </a>
                            </div>
                          );
                        }
                      })()}
                      <h5>
                        {this.t(T_EXAMS.examDetails.generateReport.comments)}
                      </h5>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-2 formfont">
                            {this.t(
                              T_EXAMS.examDetails.generateReport.comments
                            )}
                          </div>
                          <div className="col-md-10">
                            <textarea
                              name="lefteyecomments"
                              onChange={this.onChange}
                              value={this.state.lefteyecomments}
                            ></textarea>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="firstpage">
                      <h5>
                        {this.t(
                          T_EXAMS.examDetails.generateReport.miscellaneous
                        )}
                      </h5>
                      <div className="refer">
                        <div className="formfont">
                          {this.t(
                            T_EXAMS.examDetails.generateReport.referToSpecialist
                          )}
                        </div>
                        <div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="radio"
                              onChange={this.onChange}
                              name="referRequired"
                              value={"yes"}
                            />
                          </div>
                          <div className="radio-label first">
                            &nbsp;&nbsp;{this.t(T_GENERAL.yes)}
                          </div>
                          <div className="checkbox">
                            <FormControl
                              className="check"
                              type="radio"
                              onChange={this.onChange}
                              name="referRequired"
                              value={"no"}
                            />
                          </div>
                          <div className="radio-label">
                            &nbsp;&nbsp;{this.t(T_GENERAL.no)}
                          </div>
                        </div>
                      </div>
                      <Button
                        onClick={this.onSubmit}
                        className="loginbtn1 create-account-btn select-images-button"
                        disabled={
                          this.state.disableFourthButton || !this.state.isLoaded
                        }
                      >
                        {this.state.isLoaded ? (
                          this.t(T_GENERAL.submit)
                        ) : (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        )}
                      </Button>
                      {/* <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn select-images-button"
                      disabled={this.state.disableThirdButton}
                    >
                      {this.state.isLoaded ? (
                        "Submit"
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button> */}
                    </div>
                  </>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomGenerateReportWithoutImagesModal);
