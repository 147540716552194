/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateOrgEntitlements } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SERVICE } from "../../constants/translations";

/**
 * This is the component for the Organization Entitlement Settings modal in the Sites Dashboard page.
 *
 * @component
 */
class CustomOrganizationEntitlementSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: false,
      enabledEntitlements: this.props.entitlementSettings
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {}
  /**
   * Submits the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    const data = this.state.enabledEntitlements;
    putAuthenticatedData(
      sessionStorage.getItem("domain") +
        updateOrgEntitlements +
        "/" +
        this.props.organizationId,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(this.t(T_SERVICE.toast.orgEntitlementsUpdated));
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner entitlement-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_SERVICE.orgEntitlements.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              {this.props.orgEntitlements.map((entitlement, index) => {
                return (
                  <FormGroup controlId="email" bsSize="large" key={index}>
                    <div className="row formfont">
                      <div className="col-md-6 formfont enable-label">
                        {entitlement}
                      </div>
                      <div className="col-md-6">
                        <BootstrapSwitchButton
                          width={100}
                          height={10}
                          checked={this.state.enabledEntitlements.includes(
                            entitlement
                          )}
                          onstyle="success"
                          onChange={checked => {
                            if (!checked) {
                              const index = this.state.enabledEntitlements.indexOf(
                                entitlement
                              );
                              if (index > -1) {
                                this.state.enabledEntitlements.splice(index, 1);
                              }
                            } else {
                              this.state.enabledEntitlements.push(entitlement);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </FormGroup>
                );
              })}
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomOrganizationEntitlementSettingsModal);
