/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
import {
  getDiseases,
  getUser,
  getSites,
  getSiteLogo,
  getUserSignature,
  gradeExam
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  postAuthenticatedFormData
} from "../../utils/async";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { convertEpochToDate } from "../../utils/date";
import { toast } from "react-toastify";
import PDF from "./PDF";
import { pdf } from "@react-pdf/renderer";
import imageCompression from "browser-image-compression";
import { withTranslation } from "react-i18next";
import { T_EXAMS, T_GENERAL } from "../../constants/translations";
import mask from "../../assets/images/captureMaskWhite.png";

/**
 * This is the component for the Generate Report modal <br>
 * It takes a prop called `exam` which is the entire exam object passed from the exam details page <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.6, 5.3.14.30 <br>
 * PSL - 5.3.14.6, 5.3.14.30 <br>
 * FOP - 5.3.14.6 <br>
 *
 * @component
 */
class CustomGenerateReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      data: [],
      firstRadio: [""],
      secondRadio: [""],
      thirdRadio: [""],
      firstradiovalue: null,
      secondradiovalue: null,
      thirdradiovalue: null,
      rightfirstradiovalue: null,
      rightsecondradiovalue: null,
      rightthirdradiovalue: null,
      lefteyecomments: "",
      righteyecomments: "",
      isLoaded: true,
      selectedImages: [],
      selectedImagesEdited: [],
      disableFirstButton: true,
      disableFourthButton: false,
      activePage: 1,
      showLeftSecond: false,
      showLeftThird: false,
      showRightSecond: false,
      showRightThird: false,
      showLeftAdd: true,
      showRightAdd: true,
      prescription: "",
      chars: 450,
      fullData: [],
      visionAssessment: undefined,
      first: false,
      second: false,
      userId: 0,
      siteName: "",
      hasLogo: false,
      signatureExists: false,
      signature: "",
      replacedSelectedPristineMontageImages: [],
      replacedSelectedPristineRegularImages: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFirstPageProceed = this.onFirstPageProceed.bind(this);
    this.onSecondPageProceed = this.onSecondPageProceed.bind(this);
    this.onThirdPageProceed = this.onThirdPageProceed.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
    this.goToSecondPage = this.goToSecondPage.bind(this);
    this.goToThirdPage = this.goToThirdPage.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.toggleSelectEdited = this.toggleSelectEdited.bind(this);
    this.addLeft = this.addLeft.bind(this);
    this.addRight = this.addRight.bind(this);
    this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
    this.getDiseases = this.getDiseases.bind(this);
    this.changeVA = this.changeVA.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.getSites = this.getSites.bind(this);
    this.replaceBlackWithTransparent = this.replaceBlackWithTransparent.bind(
      this
    );
  }
  componentDidMount() {
    this.getDiseases();
    this.getUserId();
    this.getSites();
    this.setState({ firstRadio: this.state.data });
    this.getSignature();
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = error => reject(error);
    });
  }

  getSignature() {
    getAuthenticatedData(
      sessionStorage.getItem("domain") + getUserSignature
    ).then(res => {
      if (res.status.statusCode === "NOT_FOUND") {
        this.setState({ signatureExists: false });
      } else {
        this.setState({
          signatureExists: true,
          signature: res.data
        });
      }
    });
  }

  getSites() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getSites)
      .then(res => {
        // eslint-disable-next-line no-console
        const siteArray = res?.data?.map?.(el => el.siteId);
        let index = 0;
        if (siteArray.includes(this.props.exam.patientDetails.siteId)) {
          index = siteArray.indexOf(this.props.exam.patientDetails.siteId);
        }
        this.setState({
          siteName: res.data[index].siteName,
          hasLogo: res.data[index].hasLogo
        });
      })
      .then(() => {
        getAuthenticatedData(
          sessionStorage.getItem("domain") +
            getSiteLogo +
            "/" +
            this.props.exam.patientDetails.siteId
        ).then(
          result => {
            this.setState({ siteLogo: result.data });
          },
          () => {
            this.setState({
              isLoaded: true
            });
          }
        );
      });
  }

  getUserId() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getUser).then(
      result => {
        this.setState({ userId: result?.data?.userId });
      }
    );
  }

  getDiseases() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getDiseases).then(
      res => {
        this.setState({
          data: res.data.map(el => el.displayName),
          firstRadio: res.data.map(el => el.displayName),
          fullData: res.data
        });
      }
    );
  }

  changeVA(e) {
    this.setState({ [e.target.value]: e.target.checked });
    this.setState(
      { [e.target.name]: e.target.checked ? e.target.value : undefined },
      () => {
        this.setState({
          disableFirstButton:
            this.state.selectedImages.length === 0 &&
            this.state.selectedImagesEdited.length === 0 &&
            typeof this.state.visionAssessment === "undefined"
        });
      }
    );
  }
  /**
   * Triggered when an autocomplete option is selected
   * Also handles the case when an autocomplete option is cleared
   *
   *
   * @param {object} e      event listener
   * @param (string) value  the autocomplete option selected
   */
  onAutocompleteSelect(e, value) {
    let targetName;
    if (e.target.id.split("-")[0]) {
      targetName = e.target.id.split("-")[0];
    } else {
      targetName = e.currentTarget.parentElement.parentElement.children[0].id;
    }
    // eslint-disable-next-line no-console
    console.log(e.target, value);
    this.setState(
      {
        [targetName]: value
      },
      () => {
        // eslint-disable-next-line no-console
        if (targetName === "firstradiovalue" && value === null) {
          this.setState({
            secondradiovalue: null,
            thirdradiovalue: null
          });
        } else if (targetName === "secondradiovalue" && value === null) {
          this.setState({ thirdradiovalue: null });
        } else if (targetName === "rightfirstradiovalue" && value === null) {
          this.setState({
            rightsecondradiovalue: null,
            rightthirdradiovalue: null
          });
        } else if (targetName === "rightsecondradiovalue" && value === null) {
          this.setState({
            rightthirdradiovalue: null
          });
        }
      }
    );
  }
  async loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Enable CORS for the image
      img.onload = () => resolve(img);
      img.onerror = () =>
        reject(new Error(`Failed to load the image from URL: ${url}`));
      img.src = url;
    });
  }

  calculateTargetSize(baseWidth, baseHeight, overlayWidth, overlayHeight) {
    const baseAspectRatio = baseWidth / baseHeight;
    const overlayAspectRatio = overlayWidth / overlayHeight;

    let targetWidth, targetHeight;

    if (overlayAspectRatio > baseAspectRatio) {
      // Overlay is wider than base
      targetWidth = baseWidth;
      targetHeight = baseWidth / overlayAspectRatio;
    } else {
      // Overlay is taller than base
      targetHeight = baseHeight;
      targetWidth = baseHeight * overlayAspectRatio;
    }

    return { targetWidth, targetHeight };
  }

  async resizeImage(image, targetWidth, targetHeight) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
    return canvas;
  }

  async removeBlackPixelsFromMask(maskCanvas) {
    const ctx = maskCanvas.getContext("2d");
    const imageData = ctx.getImageData(
      0,
      0,
      maskCanvas.width,
      maskCanvas.height
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
        // Check if the pixel is black
        data[i + 3] = 0; // Set alpha to 0 to make the pixel transparent
      }
    }

    ctx.putImageData(imageData, 0, 0);
  }

  async placeOverlayOnTop(baseImageUrl, overlayImageUrl) {
    try {
      const baseImage = await this.loadImage(baseImageUrl);
      const overlayImage = await this.loadImage(overlayImageUrl);

      // const { targetWidth, targetHeight } = this.calculateTargetSize(
      //   baseImage.width,
      //   baseImage.height,
      //   overlayImage.width,
      //   overlayImage.height
      // );

      const baseCanvas = document.createElement("canvas");
      const baseCtx = baseCanvas.getContext("2d");
      baseCanvas.width = baseImage.width;
      baseCanvas.height = baseImage.height;

      // Clear the canvas to avoid any artifacts
      baseCtx.clearRect(0, 0, baseCanvas.width, baseCanvas.height);

      baseCtx.drawImage(baseImage, 0, 2);

      const resizedOverlayCanvas = await this.resizeImage(
        overlayImage,
        baseImage.width,
        baseImage.height
      );
      await this.removeBlackPixelsFromMask(resizedOverlayCanvas);

      baseCtx.globalCompositeOperation = "source-over";

      // const xOffset = (baseImage.width - targetWidth) / 2;
      // const yOffset = (baseImage.height - targetHeight) / 2;

      baseCtx.drawImage(
        resizedOverlayCanvas,
        0,
        0,
        baseImage.width,
        baseImage.height
      );
      return baseCanvas.toDataURL();
    } catch (error) {
      // console.error(`Error processing image: ${error}`);
      return null; // Return null or some error indicator for failed load
    }
  }

  /**
   * Handles changes to the device selection in the form
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    // eslint-disable-next-line no-console
    console.log(e.target);
    if (
      !(e.target.name === "prescription" && e.target.value.length > 450) &&
      !(
        (e.target.name === "lefteyecomments" ||
          e.target.name === "righteyecomments") &&
        e.target.value.length > 240
      )
    ) {
      this.setState({ [e.target.name]: e.target.value }, () => {
        let chars = 0;
        if (this.state.prescription.length >= 450) {
          chars = 0;
        } else {
          chars = 450 - this.state.prescription.length;
        }
        this.setState({
          chars,
          disableFourthButton: this.state.prescription.length > 450
        });
      });
    }
  }
  /**
   * Goes to the second page
   *
   */
  onFirstPageProceed() {
    this.setState({ activePage: 2 });
  }
  onSecondPageProceed() {
    this.setState({ activePage: 3, lefteyecomments: "" });
  }
  onThirdPageProceed() {
    this.setState({ activePage: 4 });
  }
  /**
   * Allows the user to add another diagnosis for the left eye
   *
   *
   */
  addLeft() {
    if (!this.state.showLeftSecond) {
      this.setState({ showLeftSecond: true });
    } else if (!this.state.showLeftThird) {
      this.setState({ showLeftThird: true, showLeftAdd: false });
    }
  }
  /**
   * Allows the user to add another diagnosis for the right eye
   *
   *
   */
  addRight() {
    if (!this.state.showRightSecond) {
      this.setState({ showRightSecond: true });
    } else if (!this.state.showRightThird) {
      this.setState({ showRightThird: true, showRightAdd: false });
    }
  }

  async compressImage(url, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const compressedImage = await imageCompression(blob, options);
        const reader = new FileReader();
        reader.readAsDataURL(compressedImage);
        reader.onloadend = () => resolve(reader.result);
      } catch (error) {
        reject(error);
      }
    });
  }
  /**
   * Submits the form
   *
   *
   */
  async onSubmit() {
    this.setState({ isLoaded: false });
    let allImages = [
      ...this.state.selectedImages,
      ...this.state.selectedImagesEdited
    ];
    let noOfAnteriorImages = allImages.filter(
      el => el.deviceType === "AIM" || el.deviceType === "PSL"
    ).length;
    let noOfFOPImages = allImages
      .filter(el => el.deviceType === "FOP")
      .reduce(
        (acc, curr) =>
          curr.editOperations?.includes?.("MONTAGE") ? acc + 2 : acc + 1,
        0
      );
    let noOfPristineImages = allImages.filter(
      el => el.deviceType === "PRISTINE"
    ).length;
    if (noOfPristineImages > 12) {
      toast.error(this.t(T_EXAMS.toast.pristineImageLimit));
      this.setState({ isLoaded: true });
    } else if (noOfAnteriorImages > 8) {
      toast.error(this.t(T_EXAMS.toast.anteriorImageLimit));
      this.setState({ isLoaded: true });
    } else if (noOfFOPImages > 8) {
      toast.error(this.t(T_EXAMS.toast.tooManyImages));
      this.setState({ isLoaded: true });
    } else {
      let leftDiagnoses = [];
      if (!this.state.firstradiovalue) {
        leftDiagnoses = ["Not Applicable"];
      } else if (!this.state.secondradiovalue) {
        leftDiagnoses = [this.state.firstradiovalue];
      } else if (!this.state.thirdradiovalue) {
        leftDiagnoses = [
          this.state.firstradiovalue,
          this.state.secondradiovalue
        ];
      } else {
        leftDiagnoses = [
          this.state.firstradiovalue,
          this.state.secondradiovalue,
          this.state.thirdradiovalue
        ];
      }
      leftDiagnoses = leftDiagnoses.reduce((acc, curr) => `${acc}, ${curr}`);
      let rightDiagnoses = [];
      if (!this.state.rightfirstradiovalue) {
        rightDiagnoses = ["Not Applicable"];
      } else if (!this.state.rightsecondradiovalue) {
        rightDiagnoses = [this.state.rightfirstradiovalue];
      } else if (!this.state.rightthirdradiovalue) {
        rightDiagnoses = [
          this.state.rightfirstradiovalue,
          this.state.rightsecondradiovalue
        ];
      } else {
        rightDiagnoses = [
          this.state.rightfirstradiovalue,
          this.state.rightsecondradiovalue,
          this.state.rightthirdradiovalue
        ];
      }
      rightDiagnoses = rightDiagnoses.reduce((acc, curr) => `${acc}, ${curr}`);
      const compressionOptions = {
        maxSizeMB: 1, // Maximum file size (in MB) for compressed image
        maxWidthOrHeight: 500, // Maximum width or height of compressed image
        useWebWorker: true // Use Web Workers for better performance
      };
      const compressedImages = await Promise.all(
        this.state.selectedImages.map(async el => {
          let path = await this.compressImage(el.path, compressionOptions);
          return { ...el, path };
        })
      );
      const compressedImagesEdited = await Promise.all(
        this.state.selectedImagesEdited.map(async el => {
          let path = await this.compressImage(el.path, compressionOptions);
          return { ...el, path };
        })
      );
      const replacedSelectedPristineMontageImages = await Promise.all(
        this.state.replacedSelectedPristineMontageImages.map(async el => {
          let path = await this.replaceBlackWithTransparent(el);
          path = await this.compressImage(path, compressionOptions);
          return path;
        })
      );
      const replacedSelectedPristineRegularImages = await Promise.all(
        this.state.replacedSelectedPristineRegularImages.map(async el => {
          let path = await this.compressImage(el, compressionOptions);
          path = await this.placeOverlayOnTop(path, mask);
          return path;
        })
      );
      const blob = await pdf(
        <PDF
          exam={this.props.exam}
          leftDiagnoses={leftDiagnoses}
          rightDiagnoses={rightDiagnoses}
          diagnosis={this.state.righteyecomments}
          replacedSelectedPristineMontageImages={
            replacedSelectedPristineMontageImages
          }
          replacedSelectedPristineRegularImages={
            replacedSelectedPristineRegularImages
          }
          examDate={convertEpochToDate(this.props.exam.examDetails.examDate)}
          age={Math.floor(
            (new Date() -
              new Date(this.props.exam.patientDetails.dateOfBirth).getTime()) /
              3.15576e10
          )}
          selectedImages={[...compressedImages, ...compressedImagesEdited].map(
            el => {
              return {
                id: el.id,
                src: el.path,
                edited: el.editOperations?.includes?.("MONTAGE"),
                device: el.deviceType,
                laterality: el.laterality,
                subHtml:
                  el.laterality === "RIGHT"
                    ? "<p class='grader-eye-label'>" +
                      this.t(T_EXAMS.examDetails.generateReport.rightEye) +
                      "</p>"
                    : "<p class='grader-eye-label'>" +
                      this.t(T_EXAMS.examDetails.generateReport.leftEye) +
                      "</p>"
              };
            }
          )}
          hasLogo={this.state.hasLogo}
          siteLogo={this.state.siteLogo}
          siteName={this.state.siteName}
          referRequired={undefined}
          showRefractive={
            this.props.hasAssessment && (this.state.first || this.state.second)
          }
          visionAssessment={this.props.visionAssessment}
          showFirst={this.state.first}
          showSecond={this.state.second}
          signatureExists={this.state.signatureExists}
          signature={this.state.signature}
        ></PDF>
      ).toBlob();
      leftDiagnoses = [];
      if (!this.state.firstradiovalue) {
        leftDiagnoses = ["NA"];
      } else if (!this.state.secondradiovalue) {
        leftDiagnoses = [
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.firstradiovalue
            )
          ].icd10
        ];
      } else if (!this.state.thirdradiovalue) {
        leftDiagnoses = [
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.firstradiovalue
            )
          ].icd10,
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.secondradiovalue
            )
          ].icd10
        ];
      } else {
        leftDiagnoses = [
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.firstradiovalue
            )
          ].icd10,
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.secondradiovalue
            )
          ].icd10,
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.thirdradiovalue
            )
          ].icd10
        ];
      }
      rightDiagnoses = [];
      if (!this.state.rightfirstradiovalue) {
        rightDiagnoses = ["NA"];
      } else if (!this.state.rightsecondradiovalue) {
        rightDiagnoses = [
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.rightfirstradiovalue
            )
          ].icd10
        ];
      } else if (!this.state.rightthirdradiovalue) {
        rightDiagnoses = [
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.rightfirstradiovalue
            )
          ].icd10,
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.rightsecondradiovalue
            )
          ].icd10
        ];
      } else {
        rightDiagnoses = [
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.rightfirstradiovalue
            )
          ].icd10,
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.rightsecondradiovalue
            )
          ].icd10,
          this.state.fullData[
            this.state.fullData.findIndex(
              e => e.displayName === this.state.rightthirdradiovalue
            )
          ].icd10
        ];
      }
      var data = {
        examId: this.props.exam.examDetails.id,
        localId: this.props.exam.examDetails.localId + "-report",
        patientId: this.props.exam.patientDetails.id,
        leftEyeDiagnosis: {
          diagnoses: leftDiagnoses
        },
        rightEyeDiagnosis: {
          diagnoses: rightDiagnoses
        },
        prescriptionTreatment: this.state.prescription,
        imageIds: [
          ...this.state.selectedImages,
          ...this.state.selectedImagesEdited
        ].map(el => el.id),
        reportingDoctorId: this.state.userId,
        includeVAReading1:
          typeof this.state.visionAssessment !== "undefined" &&
          this.state.first,
        includeVAReading2:
          typeof this.state.visionAssessment !== "undefined" &&
          this.state.second,
        comments: this.state.righteyecomments
      };

      const formData = new FormData();
      formData.append(
        "dto",
        new Blob([JSON.stringify(data)], { type: "application/json" })
      );
      formData.append("pdf", blob);

      // this.props.history.push({
      //   pathname: "/pdf",
      //   state: {
      //     exam: this.props.exam,
      //     leftDiagnoses: leftDiagnoses,
      //     rightDiagnoses: rightDiagnoses,
      //     diagnosis: this.state.righteyecomments,
      //     examDate: convertEpochToDate(this.props.exam.examDetails.examDate),
      //     age: Math.floor(
      //       (new Date() -
      //         new Date(this.props.exam.patientDetails.dateOfBirth).getTime()) /
      //         3.15576e10
      //     ),
      //     selectedImages: [...compressedImages, ...compressedImagesEdited].map(
      //       el => {
      //         return {
      //           id: el.id,
      //           src: el.path,
      //           edited: el.editOperations?.includes?.("MONTAGE"),
      //           device: el.deviceType,
      //           subHtml:
      //             el.laterality === "RIGHT"
      //               ? "<p class='grader-eye-label'>" +
      //                 this.t(T_EXAMS.examDetails.generateReport.rightEye) +
      //                 "</p>"
      //               : "<p class='grader-eye-label'>" +
      //                 this.t(T_EXAMS.examDetails.generateReport.leftEye) +
      //                 "</p>"
      //         };
      //       }
      //     ),
      //     hasLogo: this.state.hasLogo,
      //     siteLogo: this.state.siteLogo,
      //     siteName: this.state.siteName,
      //     referRequired: undefined,
      //     showRefractive:
      //       this.props.hasAssessment && (this.state.first || this.state.second),

      //     visionAssessment: this.props.visionAssessment,
      //     showFirst: this.state.first,
      //     showSecond: this.state.second,
      //     signatureExists: this.state.signatureExists,
      //     signature: this.state.signature
      //   }
      // });

      postAuthenticatedFormData(
        sessionStorage.getItem("domain") + gradeExam,
        formData
      )
        .then(result => {
          this.setState({ isLoaded: true });
          if (result.status === 200) {
            toast.success(this.t(T_EXAMS.toast.generateSuccess));
            this.setState({
              organizationname: "",
              sitedomain: "",
              sitename: "",
              firstname: "",
              lastname: "",
              email: "",
              employeeid: "",
              activePage: 1
            });
            this.props.closePopup();
            this.props.resetList();
          }
          return result.json();
        })
        .then(resp => {
          if (
            resp.status.statusCode !== "OK" &&
            resp.status.statusCode !== "FORBIDDEN" &&
            resp.status.statusCode !== "NOT_AUTHORIZED"
          ) {
            toast.error(resp.status.message);
          }
        });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  goToFirstPage() {
    this.setState({ activePage: 1 });
  }
  goToSecondPage() {
    this.setState({ activePage: 2 });
  }
  goToThirdPage() {
    this.setState({ activePage: 3 });
  }
  /**
   * Toggles the selection of a (non-edited) image
   *
   * @param {number} index      index of the image that was clicked
   */
  toggleSelect(image) {
    if (this.state.selectedImages.includes(image)) {
      this.setState(
        {
          selectedImages: this.state.selectedImages.filter(el => el !== image)
        },
        async () => {
          const pristineImages = this.state.selectedImages.filter(
            el => el.deviceType === "PRISTINE"
          );
          const pristineImagesEdited = this.state.selectedImagesEdited.filter(
            el => el.deviceType === "PRISTINE"
          );

          this.setState({
            replacedSelectedPristineRegularImages: pristineImages.map(
              el => el.path
            ),
            replacedSelectedPristineMontageImages: pristineImagesEdited.map(
              el => el.path
            ),
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              typeof this.state.visionAssessment === "undefined"
          });
        }
      );
    } else {
      this.setState(
        {
          selectedImages: [...this.state.selectedImages, image]
        },
        async () => {
          const pristineImages = this.state.selectedImages.filter(
            el => el.deviceType === "PRISTINE"
          );
          const pristineImagesEdited = this.state.selectedImagesEdited.filter(
            el => el.deviceType === "PRISTINE"
          );

          this.setState({
            replacedSelectedPristineRegularImages: pristineImages.map(
              el => el.path
            ),
            replacedSelectedPristineMontageImages: pristineImagesEdited.map(
              el => el.path
            ),
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              typeof this.state.visionAssessment === "undefined"
          });
        }
      );
    }
  }

  async replaceBlackWithTransparent(url) {
    const loadImage = url => {
      return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.crossOrigin = "Anonymous"; // Enable CORS for the image
        img.onload = () => resolve(img);
        img.onerror = () =>
          reject(new Error(`Failed to load the image from URL: ${url}`));
        img.src = url;
      });
    };

    const processImage = img => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        if (data[i] < 3 && data[i + 1] < 3 && data[i + 2] < 3) {
          // Check if the pixel is black
          data[i + 3] = 0; // Set alpha to 0 to make the pixel transparent
        }
      }

      ctx.putImageData(imageData, 0, 0);
      return canvas.toDataURL();
    };

    try {
      const img = await loadImage(url);
      return processImage(img);
    } catch (error) {
      // console.error(`Error processing image: ${error}`);
      return null; // Return null or some error indicator for failed load
    }
  }

  /**
   * Toggles the selection of an edited image
   *
   * @param {number} index      index of the image that was clicked
   */
  toggleSelectEdited(image) {
    if (
      this.state.selectedImagesEdited.includes(image) &&
      image.editOperations.includes("MONTAGE")
    ) {
      this.setState(
        {
          selectedImagesEdited: this.state.selectedImagesEdited.filter(
            el => el !== image
          )
        },
        async () => {
          const pristineImages = this.state.selectedImages.filter(
            el => el.deviceType === "PRISTINE"
          );
          const pristineImagesEdited = this.state.selectedImagesEdited.filter(
            el => el.deviceType === "PRISTINE"
          );

          this.setState({
            replacedSelectedPristineRegularImages: pristineImages.map(
              el => el.path
            ),
            replacedSelectedPristineMontageImages: pristineImagesEdited.map(
              el => el.path
            ),
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              typeof this.state.visionAssessment === "undefined"
          });
        }
      );
    } else if (image.editOperations.includes("MONTAGE")) {
      this.setState(
        {
          selectedImagesEdited: [...this.state.selectedImagesEdited, image]
        },
        async () => {
          const pristineImages = this.state.selectedImages.filter(
            el => el.deviceType === "PRISTINE"
          );
          const pristineImagesEdited = this.state.selectedImagesEdited.filter(
            el => el.deviceType === "PRISTINE"
          );

          this.setState({
            replacedSelectedPristineRegularImages: pristineImages.map(
              el => el.path
            ),
            replacedSelectedPristineMontageImages: pristineImagesEdited.map(
              el => el.path
            ),
            disableFirstButton:
              this.state.selectedImages.length === 0 &&
              this.state.selectedImagesEdited.length === 0 &&
              typeof this.state.visionAssessment === "undefined"
          });
        }
      );
    } else {
      this.toggleSelect(image);
    }
  }
  render() {
    const filterOptions = (options, { inputValue }) => {
      if (!inputValue) {
        return options;
      }
      const re = new RegExp(inputValue, "i");
      return options.filter(
        (option, i) =>
          (this.state.fullData[i].displayName &&
            this.state.fullData[i].displayName.match(re)) ||
          (this.state.fullData[i].icd10 &&
            this.state.fullData[i].icd10.match(re)) ||
          (this.state.fullData[i].searchBy &&
            this.state.fullData[i].searchBy.match(re))
      );
    };
    return (
      <div className="custommodal">
        <div className="custommodalinner generate-report-modal">
          {(() => {
            if (this.state.activePage === 2) {
              return (
                <a className="add-org-back" onClick={this.goToFirstPage}>
                  &lt; {this.t(T_EXAMS.examDetails.generateReport.back)}
                </a>
              );
            } else if (this.state.activePage === 3) {
              return (
                <a className="add-org-back" onClick={this.goToSecondPage}>
                  &lt; {this.t(T_EXAMS.examDetails.generateReport.back)}
                </a>
              );
            } else if (this.state.activePage === 4) {
              return (
                <a className="add-org-back" onClick={this.goToThirdPage}>
                  &lt; {this.t(T_EXAMS.examDetails.generateReport.back)}
                </a>
              );
            }
          })()}
          <form
            className="generate-report-modal loginpad"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">
              {this.t(T_EXAMS.examDetails.generateReport.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding generate-report-line" />
            {(() => {
              if (
                this.state.activePage === 1 &&
                (this.props.hasImages || this.props.hasAssessment)
              ) {
                return (
                  <div className="firstpage">
                    {(() => {
                      if (this.props.hasAssessment) {
                        return (
                          <div>
                            <h5>
                              {this.t(
                                T_EXAMS.examDetails.generateReport
                                  .objectiveRefraction
                              )}
                            </h5>
                            {(() => {
                              if (
                                this.props.visionAssessment.readingSet1
                                  ?.reReading?.average.sph
                              ) {
                                return (
                                  <>
                                    <p className="va-subheading">
                                      {this.t(
                                        T_EXAMS.examDetails.generateReport
                                          .reading1
                                      )}
                                    </p>
                                    <div className="va-selection-container">
                                      <input
                                        type="checkbox"
                                        className="va-radio"
                                        name="visionAssessment"
                                        disabled={
                                          !this.props.visionAssessment
                                            .readingSet1?.reReading?.average.sph
                                        }
                                        value={"first"}
                                        checked={this.state.first}
                                        onChange={this.changeVA}
                                      />
                                      <div className="aggregate-table-container-container">
                                        <div className="aggregate-subheading">
                                          {this.t(
                                            T_EXAMS.examDetails.generateReport
                                              .rightEye
                                          )}
                                        </div>
                                        <div className="aggregate-table-container">
                                          <div className="aggregate-table-heading top-left">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .spherical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .cylindrical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading top-right">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .axis
                                            )}
                                          </div>
                                          <div className="aggregate-table-contents bottom-left">
                                            {
                                              this.props.visionAssessment
                                                .readingSet1?.reReading?.average
                                                .sph
                                            }
                                          </div>
                                          <div className="aggregate-table-contents">
                                            {
                                              this.props.visionAssessment
                                                .readingSet1?.reReading?.average
                                                .cyl
                                            }
                                          </div>
                                          <div className="aggregate-table-contents bottom-right">
                                            {
                                              this.props.visionAssessment
                                                .readingSet1?.reReading?.average
                                                .axis
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="aggregate-table-container-container">
                                        <div className="aggregate-subheading">
                                          {this.t(
                                            T_EXAMS.examDetails.generateReport
                                              .leftEye
                                          )}
                                        </div>
                                        <div className="aggregate-table-container">
                                          <div className="aggregate-table-heading top-left">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .spherical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .cylindrical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading top-right">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .axis
                                            )}
                                          </div>
                                          <div className="aggregate-table-contents bottom-left">
                                            {
                                              this.props.visionAssessment
                                                .readingSet1?.leReading?.average
                                                .sph
                                            }
                                          </div>
                                          <div className="aggregate-table-contents">
                                            {
                                              this.props.visionAssessment
                                                .readingSet1?.leReading?.average
                                                .cyl
                                            }
                                          </div>
                                          <div className="aggregate-table-contents bottom-right">
                                            {
                                              this.props.visionAssessment
                                                .readingSet1?.leReading?.average
                                                .axis
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })()}
                            {(() => {
                              if (
                                this.props.visionAssessment.readingSet2
                                  ?.reReading?.average.sph
                              ) {
                                return (
                                  <>
                                    <p className="va-subheading">
                                      {this.t(
                                        T_EXAMS.examDetails.generateReport
                                          .reading2
                                      )}
                                    </p>
                                    <div className="va-selection-container">
                                      <input
                                        type="checkbox"
                                        className="va-radio"
                                        name="visionAssessment"
                                        disabled={
                                          !this.props.visionAssessment
                                            .readingSet2?.reReading?.average.sph
                                        }
                                        value={"second"}
                                        checked={this.state.second}
                                        onChange={this.changeVA}
                                      />
                                      <div className="aggregate-table-container-container">
                                        <div className="aggregate-subheading">
                                          {this.t(
                                            T_EXAMS.examDetails.generateReport
                                              .rightEye
                                          )}
                                        </div>
                                        <div className="aggregate-table-container">
                                          <div className="aggregate-table-heading top-left">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .spherical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .cylindrical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading top-right">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .axis
                                            )}
                                          </div>
                                          <div className="aggregate-table-contents bottom-left">
                                            {
                                              this.props.visionAssessment
                                                .readingSet2?.reReading?.average
                                                .sph
                                            }
                                          </div>
                                          <div className="aggregate-table-contents">
                                            {
                                              this.props.visionAssessment
                                                .readingSet2?.reReading?.average
                                                .cyl
                                            }
                                          </div>
                                          <div className="aggregate-table-contents bottom-right">
                                            {
                                              this.props.visionAssessment
                                                .readingSet2?.reReading?.average
                                                .axis
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="aggregate-table-container-container">
                                        <div className="aggregate-subheading">
                                          {this.t(
                                            T_EXAMS.examDetails.generateReport
                                              .leftEye
                                          )}
                                        </div>
                                        <div className="aggregate-table-container">
                                          <div className="aggregate-table-heading top-left">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .spherical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .cylindrical
                                            )}
                                          </div>
                                          <div className="aggregate-table-heading top-right">
                                            {this.t(
                                              T_EXAMS.examDetails.generateReport
                                                .axis
                                            )}
                                          </div>
                                          <div className="aggregate-table-contents bottom-left">
                                            {
                                              this.props.visionAssessment
                                                .readingSet2?.leReading?.average
                                                .sph
                                            }
                                          </div>
                                          <div className="aggregate-table-contents">
                                            {
                                              this.props.visionAssessment
                                                .readingSet2?.leReading?.average
                                                .cyl
                                            }
                                          </div>
                                          <div className="aggregate-table-contents bottom-right">
                                            {
                                              this.props.visionAssessment
                                                .readingSet2?.leReading?.average
                                                .axis
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })()}
                          </div>
                        );
                      }
                    })()}

                    {(() => {
                      if (this.props.hasImages) {
                        let gridImages = this.props.small
                          .filter(
                            el =>
                              !(
                                el.deviceType === "PRISTINE" &&
                                el.imageSegment === "ANTERIOR"
                              )
                          )
                          .map((image, key) => (
                            <div
                              key={image.id}
                              onClick={() => this.toggleSelect(image, key)}
                              className={
                                this.state.selectedImages.includes(image)
                                  ? "image-grid-item selected"
                                  : "image-grid-item"
                              }
                            >
                              <img
                                className="tick"
                                src={require("../../assets/images/tickIcon.png")}
                              />
                              <div className="laterality">
                                {image.laterality === "RIGHT"
                                  ? this.t(T_GENERAL.re)
                                  : this.t(T_GENERAL.le)}
                              </div>
                              <img src={image.thumbnailPath} />
                            </div>
                          ));
                        let gridImagesEdited = this.props.smallEdited
                          .filter(
                            el =>
                              !(
                                el.deviceType === "PRISTINE" &&
                                el.imageSegment === "ANTERIOR"
                              )
                          )
                          .map((image, key) => (
                            <div
                              key={image.id}
                              onClick={() =>
                                this.toggleSelectEdited(image, key)
                              }
                              className={
                                this.state.selectedImagesEdited.includes(
                                  image
                                ) || this.state.selectedImages.includes(image)
                                  ? "image-grid-item selected"
                                  : "image-grid-item"
                              }
                            >
                              <img
                                className="tick"
                                src={require("../../assets/images/tickIcon.png")}
                              />
                              <img src={image.thumbnailPath} />
                            </div>
                          ));
                        return (
                          <>
                            <h5 className="select-images-heading">
                              {this.t(
                                T_EXAMS.examDetails.generateReport.selectImages
                              )}
                            </h5>
                            <div className="select-grid-container">
                              {gridImages}
                              {gridImagesEdited}
                            </div>
                          </>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.props.deviceType.includes("PRISTINE")) {
                        return (
                          <Button
                            onClick={this.onSubmit}
                            className="loginbtn1 create-account-btn select-images-button"
                            disabled={
                              this.state.disableFourthButton ||
                              !this.state.isLoaded
                            }
                          >
                            {this.state.isLoaded ? (
                              this.t(T_GENERAL.submit)
                            ) : (
                              <img
                                src={require("../../assets/images/button-loading.gif")}
                              />
                            )}
                          </Button>
                        );
                      }
                      return (
                        <Button
                          onClick={this.onFirstPageProceed}
                          className="loginbtn1 create-account-btn select-images-button"
                          disabled={this.state.disableFirstButton}
                        >
                          {this.state.isLoaded ? (
                            this.t(T_EXAMS.examDetails.generateReport.proceed)
                          ) : (
                            <img
                              src={require("../../assets/images/button-loading.gif")}
                            />
                          )}
                        </Button>
                      );
                    })()}
                  </div>
                );
              } else if (this.state.activePage === 2) {
                return (
                  <div className="firstpage">
                    <h6>
                      {this.t(T_EXAMS.examDetails.generateReport.rightEye)}
                    </h6>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.examDetails.generateReport.diagnosis)}
                          *
                        </div>
                        <div className="col-md-9">
                          <Autocomplete
                            id="rightfirstradiovalue"
                            options={this.state.data}
                            getOptionLabel={option => option}
                            style={{ width: 300 }}
                            filterOptions={filterOptions}
                            name="rightfirstradiovalue"
                            onChange={this.onAutocompleteSelect}
                            value={this.state.rightfirstradiovalue}
                            renderInput={params => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    {(() => {
                      if (this.state.showRightSecond) {
                        return (
                          <FormGroup controlId="email" bsSize="large">
                            <div className="row">
                              <div className="col-md-3 formfont">
                                {this.t(
                                  T_EXAMS.examDetails.generateReport.diagnosis
                                )}
                              </div>
                              <div className="col-md-9">
                                <Autocomplete
                                  id="rightsecondradiovalue"
                                  options={this.state.data.filter(
                                    item =>
                                      item !== this.state.rightfirstradiovalue
                                  )}
                                  getOptionLabel={option => option}
                                  filterOptions={filterOptions}
                                  style={{ width: 300 }}
                                  name="rightsecondradiovalue"
                                  onChange={this.onAutocompleteSelect}
                                  value={this.state.rightsecondradiovalue}
                                  renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                  )}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.state.showRightThird) {
                        return (
                          <FormGroup controlId="email" bsSize="large">
                            <div className="row">
                              <div className="col-md-3 formfont">
                                {this.t(
                                  T_EXAMS.examDetails.generateReport.diagnosis
                                )}
                              </div>
                              <div className="col-md-9">
                                <Autocomplete
                                  id="rightthirdradiovalue"
                                  options={this.state.data.filter(
                                    item =>
                                      item !==
                                        this.state.rightfirstradiovalue &&
                                      item !== this.state.rightsecondradiovalue
                                  )}
                                  getOptionLabel={option => option}
                                  style={{ width: 300 }}
                                  name="rightthirdradiovalue"
                                  onChange={this.onAutocompleteSelect}
                                  value={this.state.rightthirdradiovalue}
                                  renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                  )}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.state.showRightAdd) {
                        return (
                          <div className="add-diagnosis">
                            <a onClick={this.addRight}>
                              {this.t(
                                T_EXAMS.examDetails.generateReport
                                  .addAnotherDiagnosis
                              )}
                            </a>
                          </div>
                        );
                      }
                    })()}
                    <Button
                      onClick={this.onSecondPageProceed}
                      className="loginbtn1 create-account-btn select-images-button"
                    >
                      {this.state.isLoaded ? (
                        this.t(T_EXAMS.examDetails.generateReport.proceed)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              } else if (this.state.activePage === 3) {
                return (
                  <div className="firstpage">
                    <h6>
                      {this.t(T_EXAMS.examDetails.generateReport.leftEye)}
                    </h6>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.examDetails.generateReport.diagnosis)}
                          *
                        </div>
                        <div className="col-md-9">
                          <Autocomplete
                            id="firstradiovalue"
                            options={this.state.data}
                            getOptionLabel={option => option}
                            style={{ width: 300 }}
                            name="firstradiovalue"
                            onChange={this.onAutocompleteSelect}
                            value={this.state.firstradiovalue}
                            renderInput={params => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    {(() => {
                      if (this.state.showLeftSecond) {
                        return (
                          <FormGroup controlId="email" bsSize="large">
                            <div className="row">
                              <div className="col-md-3 formfont">
                                {this.t(
                                  T_EXAMS.examDetails.generateReport.diagnosis
                                )}
                              </div>
                              <div className="col-md-9">
                                <Autocomplete
                                  id="secondradiovalue"
                                  options={this.state.data.filter(
                                    item => item !== this.state.firstradiovalue
                                  )}
                                  getOptionLabel={option => option}
                                  style={{ width: 300 }}
                                  name="secondradiovalue"
                                  onChange={this.onAutocompleteSelect}
                                  value={this.state.secondradiovalue}
                                  renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                  )}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.state.showLeftThird) {
                        return (
                          <FormGroup controlId="email" bsSize="large">
                            <div className="row">
                              <div className="col-md-3 formfont">
                                {this.t(
                                  T_EXAMS.examDetails.generateReport.diagnosis
                                )}
                              </div>
                              <div className="col-md-9">
                                <Autocomplete
                                  id="thirdradiovalue"
                                  options={this.state.data.filter(
                                    item =>
                                      item !== this.state.firstradiovalue &&
                                      item !== this.state.secondradiovalue
                                  )}
                                  getOptionLabel={option => option}
                                  style={{ width: 300 }}
                                  name="thirdradiovalue"
                                  onChange={this.onAutocompleteSelect}
                                  value={this.state.thirdradiovalue}
                                  renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                  )}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.state.showLeftAdd) {
                        return (
                          <div className="add-diagnosis">
                            <a onClick={this.addLeft}>
                              {this.t(
                                T_EXAMS.examDetails.generateReport
                                  .addAnotherDiagnosis
                              )}
                            </a>
                          </div>
                        );
                      }
                    })()}
                    <Button
                      onClick={this.onThirdPageProceed}
                      className="loginbtn1 create-account-btn select-images-button"
                    >
                      {this.state.isLoaded ? (
                        this.t(T_EXAMS.examDetails.generateReport.proceed)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              }
              {
                return (
                  <div className="firstpage">
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_EXAMS.examDetails.generateReport.comments)}
                        </div>
                        <div className="col-md-9">
                          <textarea
                            name="righteyecomments"
                            onChange={this.onChange}
                            value={this.state.righteyecomments}
                          ></textarea>
                        </div>
                      </div>
                    </FormGroup>
                    <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn select-images-button"
                      disabled={
                        this.state.disableFourthButton || !this.state.isLoaded
                      }
                    >
                      {this.state.isLoaded ? (
                        this.t(T_GENERAL.submit)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                    {/* <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn select-images-button"
                      disabled={this.state.disableThirdButton}
                    >
                      {this.state.isLoaded ? (
                        "Submit"
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button> */}
                  </div>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomGenerateReportModal);
