/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { T_ABOUT } from "../../constants/translations";

/**
 * This is the component for the Add Organization modal in the Service Page. <br>
 * There are two screens in this modal, controlled by state variables. <br>
 * The first one is collect organization info, while the second is to collect user info.
 *
 * @component
 */
class CustomAboutScreenModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;
  }

  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner about-container">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">{this.t(T_ABOUT.header)}</h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="about-main">
              <img src={require("../../assets/images/remidio-normal.svg")} />
              <p>Remidio Connect</p>
              <p>(DI) 08906096520069</p>
              <p>(PI) V7.1.0</p>
            </div>
            <div className="about-bottom">
              <div className="about-address">
                <div className="about-heading">
                  Remidio Innovative Solutions Pvt Ltd
                </div>
                <p>
                  No. 1-51-2/12, Vacuum Techniques Compound,
                  <br />
                  Opp Volvo Factory, Peenya Industrial Area, Phase-I,
                  <br /> Bengaluru-560058, INDIA
                  <br />
                  <br />
                  {this.t(T_ABOUT.email)}: contactus@remidio.com. Ph: +91
                  7676759999
                  <br />
                  {this.t(T_ABOUT.website)}: https://remidio.com/
                </p>
                <div className="about-heading">
                  {this.t(T_ABOUT.madeInIndia)}
                </div>
              </div>
              <div className="regulatory">
                <img src={require("../../assets/images/i-logo.svg")} />
                <a>{this.t(T_ABOUT.instructionsForUse)}</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomAboutScreenModal);
