/**
 * @file Adds common headers to every request, for example authentication headers and client tokens.
 * @author Arun Mahadevan
 *
 *
 */
import { apiToken } from "../config";

/**
 * Returns the common headers while authenticated
 *
 *
 * @returns {object} the common headers
 */
export const commonAuthenticatedHeaders = () => {
  return {
    Authorization: "Bearer " + sessionStorage.getItem("bearerToken"),
    tokenType: sessionStorage.getItem("ad") ? "ad" : "rem",
    clientIdentificationToken: apiToken,
    clientName: "WEB_DASHBOARD"
  };
};
/**
 * Returns the common headers while logged out
 *
 *
 * @returns {object} the common headers
 */
export const commonUnauthenticatedHeaders = () => {
  return { clientIdentificationToken: apiToken, clientName: "WEB_DASHBOARD" };
};
