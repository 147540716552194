export const msalConfig = {
  auth: {
    clientId: "aff0bcd9-31a5-466c-b522-28ce59f2486b",
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri:
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port +
      "/login",
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["api://aff0bcd9-31a5-466c-b522-28ce59f2486b/Data.Access"],
  prompt: "login"
};
