/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { createOrgSiteAndUser, getAllOrgTags } from "../../constants/endpoints";
import { getAuthenticatedData, postAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SERVICE } from "../../constants/translations";

/**
 * This is the component for the Add Organization modal in the Service Page. <br>
 * There are two screens in this modal, controlled by state variables. <br>
 * The first one is collect organization info, while the second is to collect user info.
 *
 * @component
 */
class CustomAddOrgModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      organizationname: "",
      sitedomain: "",
      sitename: "",
      firstname: "",
      lastname: "",
      email: "",
      employeeid: "",
      isLoaded: true,
      disableFirstButton: true,
      disableSecondButton: true,
      activePage: 1,
      isADEnabled: false,
      sitetenant: "",
      organizationTagsList: [],
      organizationTags: ""
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFirstPageProceed = this.onFirstPageProceed.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
  }

  componentDidMount() {
    getAuthenticatedData(sessionStorage.getItem("domain") + getAllOrgTags).then(
      res => {
        this.setState({ organizationTagsList: res.data });
      }
    );
  }
  /**
   * Handles changes to any field in the form
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          disableFirstButton:
            this.state.organizationname === "" ||
            this.state.sitedomain === "" ||
            this.state.sitename === "" ||
            (this.state.isADEnabled && this.state.sitetenant === ""),
          disableSecondButton:
            this.state.firstname === "" ||
            this.state.lastname === "" ||
            this.state.email === "" ||
            this.state.employeeid === ""
        });
      }
    );
  }
  /**
   * Proceeds to the second page
   *
   *
   */
  onFirstPageProceed() {
    this.setState({ activePage: 2 });
  }
  /**
   * Submits the form and makes an API call
   *
   *
   */
  onSubmit() {
    var data = {
      orgInfo: {
        orgName: this.state.organizationname,
        isADOrg: this.state.isADEnabled,
        organisationTags:
          this.state.organizationTags !== ""
            ? [this.state.organizationTags]
            : []
      },
      siteInfo: {
        domain: this.state.sitedomain,
        name: this.state.sitename,
        isParentSite: true,
        ...(this.state.isADEnabled && { adTenantId: this.state.sitetenant })
      },
      userInfo: {
        employeeId: this.state.employeeid,
        email: this.state.email + "@" + this.state.sitedomain,
        firstName: this.state.firstname,
        lastName: this.state.lastname
      }
    };
    this.setState({ isLoaded: false, disableSecondButton: true });

    postAuthenticatedData(
      sessionStorage.getItem("domain") + createOrgSiteAndUser,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 200) {
          toast.success(this.t(T_SERVICE.toast.addOrgSuccessful));
          this.setState({
            organizationname: "",
            sitedomain: "",
            sitename: "",
            firstname: "",
            lastname: "",
            email: "",
            employeeid: "",
            activePage: 1,
            sitetenant: "",
            isADEnabled: false
          });
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  /**
   * Goes to the first page
   *
   *
   */
  goToFirstPage() {
    this.setState({ activePage: 1 });
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner">
          {(() => {
            if (this.state.activePage === 2) {
              return (
                <a className="add-org-back" onClick={this.goToFirstPage}>
                  &lt; {this.t(T_GENERAL.back)}
                </a>
              );
            }
          })()}
          <form
            className="loginpad custom-add-exam-patient-modal"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">
              {this.t(T_SERVICE.addOrganization.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            {(() => {
              if (this.state.activePage === 1) {
                return (
                  <div className="firstpage">
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.name)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            autoFocus
                            required
                            type="text"
                            name="organizationname"
                            value={this.state.organizationname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row formfont">
                        <div className="col-md-3 formfont enable-label">
                          {this.t(T_SERVICE.addOrganization.adEnabled)}
                        </div>
                        <div className="col-md-9">
                          <BootstrapSwitchButton
                            width={100}
                            height={10}
                            checked={this.state.isADEnabled}
                            onstyle="success"
                            onChange={checked =>
                              this.setState({
                                isADEnabled: checked,
                                sitetenant: "",
                                disableFirstButton:
                                  this.state.organizationname === "" ||
                                  this.state.sitedomain === "" ||
                                  this.state.sitename === "" ||
                                  (checked && this.state.sitetenant === ""),
                                disableSecondButton:
                                  this.state.firstname === "" ||
                                  this.state.lastname === "" ||
                                  this.state.email === "" ||
                                  this.state.employeeid === ""
                              })
                            }
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.organizationTag)}
                        </div>
                        <div className="col-md-9">
                          <select
                            name="organizationTags"
                            onChange={this.onChange}
                          >
                            <option value="">None</option>
                            {this.state.organizationTagsList.map(orgTag => {
                              return (
                                <option value={orgTag} key={orgTag}>
                                  {orgTag}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </FormGroup>
                    <hr className="org-line" />
                    <h6 className="org-subheading">
                      {this.t(T_SERVICE.addOrganization.primarySite)}
                    </h6>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.siteDomain)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="sitedomain"
                            value={this.state.sitedomain}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.siteName)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="sitename"
                            value={this.state.sitename}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    {(() => {
                      if (this.state.isADEnabled) {
                        return (
                          <FormGroup controlId="email" bsSize="large">
                            <div className="row">
                              <div className="col-md-3 formfont">
                                {this.t(T_SERVICE.addOrganization.siteTenantID)}
                              </div>
                              <div className="col-md-9">
                                <FormControl
                                  required
                                  type="text"
                                  name="sitetenant"
                                  value={this.state.sitetenant}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        );
                      }
                    })()}

                    <Button
                      onClick={this.onFirstPageProceed}
                      className="loginbtn1 create-account-btn create-org-btn"
                      disabled={this.state.disableFirstButton}
                    >
                      {this.state.isLoaded ? (
                        this.t(T_SERVICE.addOrganization.proceed)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              } else {
                return (
                  <div className="firstpage">
                    <h6 className="org-subheading">
                      {this.t(T_SERVICE.addOrganization.primaryUser)}
                    </h6>
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.firstName)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            autoFocus
                            required
                            type="text"
                            name="firstname"
                            value={this.state.firstname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.lastName)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="lastname"
                            value={this.state.lastname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row input-email">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.email)}
                        </div>
                        <div className="col-md-4">
                          <FormControl
                            required
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="col-md-5 domain-name">
                          {this.state.sitedomain === "" ? "" : "@"}
                          {this.state.sitedomain}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          {this.t(T_SERVICE.addOrganization.employeeId)}
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="employeeid"
                            value={this.state.employeeid}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn create-org-btn"
                      disabled={this.state.disableSecondButton}
                    >
                      {this.state.isLoaded ? (
                        this.t(T_GENERAL.submit)
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomAddOrgModal);
