/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateTeleMedConfig } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import MultiSelect from "react-multi-select-component";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_SITES, T_USERS } from "../../constants/translations";

/**
 * This is the component for the Data Sync modal in the Sites Page <br>
 * It uses a toggle button from the BootstrapSwitchButton component. <br>
 * It receives an object called `settings` as a prop, which contain the existing Data Sync settings <br>
 *
 *
 * @component
 */
class CustomNotificationSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      disableButton: false,
      timeOptions: [
        { time: "9:00am", value: 9.0 },
        { time: "9:30am", value: 9.5 },
        { time: "10:00am", value: 10.0 },
        { time: "10:30am", value: 10.5 },
        { time: "11:00am", value: 11.0 },
        { time: "11:30am", value: 11.5 },
        { time: "12:00pm", value: 12.0 },
        { time: "12:30pm", value: 12.5 },
        { time: "1:00pm", value: 13.0 },
        { time: "1:30pm", value: 13.5 },
        { time: "2:00pm", value: 14.0 },
        { time: "2:30pm", value: 14.5 },
        { time: "3:00pm", value: 15.0 },
        { time: "3:30pm", value: 15.5 },
        { time: "4:00pm", value: 16.0 },
        { time: "4:30pm", value: 16.5 },
        { time: "5:00pm", value: 17.0 },
        { time: "5:30pm", value: 17.5 },
        { time: "6:00pm", value: 18.0 },
        { time: "6:30pm", value: 18.5 },
        { time: "7:00pm", value: 19.0 },
        { time: "7:30pm", value: 19.5 },
        { time: "8:00pm", value: 20.0 },
        { time: "8:30pm", value: 20.5 },
        { time: "9:00pm", value: 21.0 }
      ],
      options: this.props.telemedUsers?.map(el => ({
        label: el.firstName + " " + el.lastName,
        value: el.userId
      })),
      notificationsEnabled: this.props.notificationsEnabled,
      hourToSend: this.props.hourToSend,
      subscribedUsers: this.props.subscribedUsers?.map(el => ({
        label: el.firstName + " " + el.lastName,
        value: el.userId
      }))
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }
  componentDidMount() {}
  /**
   * Listens to changes in the identifier field
   *
   *
   * @param {object} e      event listener
   */
  onChange(e) {
    this.setState({
      hourToSend: parseFloat(e.target.value)
    });
  }

  setSelected(e) {
    this.setState({ subscribedUsers: e });
  }
  /**
   * Constructs the object and triggers the API call to submit the form
   *
   *
   * @param {object} e      event listener
   */
  onSubmit() {
    var data = {
      notificationsEnabled: this.state.notificationsEnabled,
      hourToSend: this.state.hourToSend,
      subscribedUserIds: this.state.subscribedUsers.map(el => el.value)
    };
    this.setState({ isLoaded: false, disableButton: true });

    putAuthenticatedData(
      sessionStorage.getItem("domain") + updateTeleMedConfig,
      data
    )
      .then(result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          this.setState({ isLoaded: true, disableButton: false });
        } else if (result.status === 200) {
          toast.success(
            this.t(T_SITES.toast.notificationSettingsUpdatedSuccessfully)
          );
          this.props.closePopup();
          this.props.resetList();
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner notification-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">
              {this.t(T_USERS.notificationSettings.header)}
            </h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label">
                    {this.t(T_USERS.notificationSettings.enabled)}
                  </div>
                  <div className="col-md-9">
                    <BootstrapSwitchButton
                      width={100}
                      height={10}
                      checked={this.state.notificationsEnabled}
                      onstyle="success"
                      onChange={checked => {
                        this.setState({ notificationsEnabled: checked });
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_USERS.notificationSettings.timeToSend)}
                  </div>
                  <div className="col-md-9">
                    <select
                      name="hourToSend"
                      onChange={this.onChange}
                      value={this.state.hourToSend}
                    >
                      {this.state.timeOptions.map(e => (
                        <option value={e?.value} key={e?.value}>
                          {e?.time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </FormGroup>
              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_USERS.notificationSettings.peopleToNotify)}
                  </div>
                  <div className="col-md-9">
                    <MultiSelect
                      options={this.state.options}
                      value={this.state.subscribedUsers}
                      onChange={this.setSelected}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              </FormGroup>

              <Button
                onClick={this.onSubmit}
                className="loginbtn1 gateway-settings-btn submit-button"
                disabled={this.state.disableFirstButton}
              >
                {this.state.isLoaded ? (
                  this.t(T_GENERAL.submit)
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomNotificationSettingsModal);
