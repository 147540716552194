/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { unblockEmail } from "../../constants/endpoints";
import { postUnauthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";
import history from "../../utils/history";
import { withTranslation } from "react-i18next";
import { T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the Recover Account page
 *
 * @component
 */
class Recover extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      isLoaded: true,
      cameFromPasswordPage: this.props.location.cameFromPasswordPage,
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem("ADClicked");
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  /**
   * Submits the recover password API call
   *
   *
   *
   */
  onSubmit() {
    this.setState({ disableButton: true });
    postUnauthenticatedData(
      sessionStorage.getItem("domain") +
        unblockEmail +
        "/" +
        this.props.location.email,
      {}
    )
      .then(result => {
        this.setState({ disableButton: false });
        history.push("/login");
        if (result.status == 200) {
          toast.success(this.t(T_LOGIN.toast.adminInformedOfUnblockRequest));
        }
        return result.json();
      })
      .then(resp => {
        if (
          resp.status.statusCode !== "OK" &&
          resp.status.statusCode !== "FORBIDDEN" &&
          resp.status.statusCode !== "NOT_AUTHORIZED"
        ) {
          toast.error(resp.status.message);
        }
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const MainText = () => {
      if (this.state.cameFromPasswordPage) {
        return <p className="centerrecover">{this.t(T_LOGIN.accountLocked)}</p>;
      } else {
        return (
          <p className="centerrecover">{this.t(T_LOGIN.accountSuspended)}</p>
        );
      }
    };
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </p>
          <MainText></MainText>
          <div>
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                this.t(T_LOGIN.recoverAccount)
              )}
            </Button>
          </div>
          <Link to="/">
            <p className="backrecover">{this.t(T_LOGIN.back)}</p>
          </Link>
        </form>
      </div>
    );
  }
}

export default withTranslation()(Recover);
