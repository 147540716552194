/* eslint-disable react/prop-types */
/**
 * @file Contains a method to disallow access to logged in routes while unauthenticated
 * @author Arun Mahadevan
 *
 */
import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";

/**
 * Checks if the user is authenticated
 * @returns {boolean}
 *
 *
 */
const isAuthenticated = () => {
  const token = sessionStorage.getItem("bearerToken");
  if (token && token !== "") {
    return true;
  }
  return false;
};

/**
 * Disables access to protected routes when unauthenticated. Redirects to /login in that case
 * @param {object} props props to be passed through
 * @returns {component}
 *
 */
const PrivateRoute = props => {
  let routeProps = {
    path: props.path,
    component: props.component,
    exact: props.exact
  };

  return isAuthenticated() ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={"/login"} />
  );
};

export default PrivateRoute;
