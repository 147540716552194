/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { resetPassword } from "../../constants/endpoints";
import { putUnauthenticatedData } from "../../utils/async";
import queryString from "query-string";
import { toast } from "react-toastify";
import history from "../../utils/history";
import { withTranslation } from "react-i18next";
import { T_GENERAL, T_LOGIN } from "../../constants/translations";

/**
 * This is the component for the Reset Password page
 *
 * @component
 */
class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      password: "",
      confirmPassword: "",
      errorMessage: "",
      containsEightCharacters: false,
      containsBothCases: false,
      containsNumberOrSymbol: false,
      passwordsMatch: true,
      disableButton: true,
      showLoader: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(
      this
    );
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Validates the password against all four checks
   *
   * @param {object} e event handler
   *
   */
  validatePassword(e) {
    let password = e.target.value;
    let capsCount,
      smallCount,
      numberCount,
      symbolCount,
      containsEightCharacters,
      containsBothCases,
      containsNumberAndSymbol,
      passwordsMatch,
      disableButton;
    capsCount = (password.match(/[A-Z]/g) || []).length;
    smallCount = (password.match(/[a-z]/g) || []).length;
    numberCount = (password.match(/\d/g) || []).length;
    symbolCount = (password.match(/[^\w\s]|_/g) || []).length;
    containsEightCharacters = password.length >= 8;
    containsBothCases = capsCount > 0 && smallCount > 0;
    containsNumberAndSymbol = numberCount > 0 && symbolCount > 0;
    passwordsMatch = password === this.state.confirmPassword;
    disableButton = !(
      containsEightCharacters &&
      containsBothCases &&
      containsNumberAndSymbol &&
      passwordsMatch
    );
    this.setState({
      containsEightCharacters,
      containsBothCases,
      containsNumberOrSymbol: containsNumberAndSymbol,
      passwordsMatch,
      disableButton
    });
  }
  /**
   * Checks if the two password fields match
   * @param {object} e event handler
   *
   *
   */
  validateConfirmPassword(e) {
    let confirmPassword = e.target.value;
    let disableButton = !(
      this.state.containsEightCharacters &&
      this.state.containsBothCases &&
      this.state.containsNumberOrSymbol &&
      confirmPassword === this.state.password
    );
    this.setState({
      passwordsMatch: confirmPassword === this.state.password,
      disableButton
    });
  }
  handleChangePassword(e) {
    this.validatePassword(e);
    this.setState({ password: e.target.value });
  }
  handleChangeConfirmPassword(e) {
    this.validateConfirmPassword(e);
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  /**
   * Submits the form
   *
   *
   *
   */
  onSubmit() {
    let data = {
      password: this.state.password,
      rePassword: this.state.confirmPassword
    };
    this.setState({ disableButton: true, showLoader: true });
    putUnauthenticatedData(
      queryString.parse(this.props.location.search)?.domain +
        resetPassword +
        "?verificationType=PASSWORD_RESET",
      data,
      {
        Authorization:
          "Bearer " + queryString.parse(this.props.location.search)?.token
      }
    ).then(result => {
      this.setState({ disableButton: false, showLoader: false });
      if (result.status == 200) {
        toast.success(this.t(T_LOGIN.toast.passwordResetSuccessfully));
        history.push("/login");
      } else {
        toast.error(this.t(T_LOGIN.toast.somethingWentWrongResetLink));
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const EightChars = () => {
      if (this.state.containsEightCharacters) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_LOGIN.regexCheck.eightChars)}
          </p>
        );
      }
    };
    const BothCases = () => {
      if (this.state.containsBothCases) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_LOGIN.regexCheck.bothCases)}
          </p>
        );
      }
    };
    const SpecialChars = () => {
      if (this.state.containsNumberOrSymbol) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_LOGIN.regexCheck.numberAndSymbol)}
          </p>
        );
      }
    };
    const Matching = () => {
      if (this.state.passwordsMatch) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span>{" "}
            {this.t(T_LOGIN.regexCheck.passwordShouldMatch)}
          </p>
        );
      }
    };
    return (
      <div className="Login">
        <form className="loginpad" onSubmit={this.handleSubmit}>
          <div className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </div>
          <p className="center">{this.t(T_LOGIN.chooseNewPassword)}</p>
          <FormGroup controlId="password" bsSize="large">
            <FormControl
              autoFocus
              required
              type="password"
              value={this.state.password}
              name="password"
              placeholder={this.t(T_LOGIN.password)}
              onChange={this.handleChangePassword}
            />
          </FormGroup>
          <FormGroup controlId="confirmpassword" bsSize="large">
            <FormControl
              required
              type="password"
              name="confirmPassword"
              placeholder={this.t(T_LOGIN.confirmPassword)}
              onChange={this.handleChangeConfirmPassword}
            />
          </FormGroup>

          <EightChars></EightChars>
          <BothCases></BothCases>
          <SpecialChars></SpecialChars>
          <Matching></Matching>

          <Button
            block
            bsSize="large"
            onClick={this.onSubmit}
            type="submit"
            className="loginbtn"
            disabled={this.state.disableButton}
          >
            {this.state.showLoader ? (
              <img src={require("../../assets/images/button-loading.gif")} />
            ) : (
              this.t(T_GENERAL.submit)
            )}
          </Button>
        </form>
      </div>
    );
  }
}

export default withTranslation()(ResetPassword);
