/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
// import { toast } from "react-toastify";
import { removeAssignment } from "../../constants/endpoints";
import { putAuthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";
//import { convertEpochToDate, convertEpochToTime } from "../../utils/date";

// import DatePicker from "react-datepicker";
// import subYears from "date-fns/subYears";

import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { T_DATE, T_EXAMS } from "../../constants/translations";

/**
 * This is the component for the Add Patient modal <br>
 * Most of the code here is dedicated to form validations.
 * SRS IDs: <br>
 * AIM - 5.3.14.9 <br>
 * PSL - 5.3.14.9 <br>
 * FOP - 5.3.14.9 <br>
 *
 * @component
 */
class CustomShowAssignedUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      btnFill: false,
      buttonText: this.t(T_EXAMS.assigned.removeAssignment),
      clickedOnce: false,
      disableColoredBtn: false
    };

    this.handleActionButtonClick = this.handleActionButtonClick.bind(this);
  }

  handleActionButtonClick() {
    if (!this.state.clickedOnce) {
      this.setState({
        clickedOnce: true,
        buttonText: this.t(T_EXAMS.assigned.confirmRemoval),
        btnFill: true
      });
    } else {
      this.setState({ disableColoredBtn: true });
      putAuthenticatedData(
        sessionStorage.getItem("domain") +
          removeAssignment +
          "/" +
          this.props.id
      )
        .then(result => {
          if (result.status === 200) {
            toast.success(this.t(T_EXAMS.toast.removedAssignmentSuccessfully));
            this.setState({ disableColoredBtn: false });
            this.props.closePopup();
            this.props.resetList();
          }
          return result.json();
        })
        .then(resp => {
          if (
            resp.status.statusCode !== "OK" &&
            resp.status.statusCode !== "FORBIDDEN" &&
            resp.status.statusCode !== "NOT_AUTHORIZED"
          ) {
            toast.error(resp.status.message);
            this.setState({ disableColoredBtn: false });
            this.props.closePopup();
            this.props.resetList();
          }
        });
    }
  }

  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner custommodalinnerdatepicker">
          <form
            className="loginpad custom-modal-add-patient"
            onSubmit={this.handleSubmit}
          >
            <h6 className="modal-heading">{this.t(T_EXAMS.assigned.header)}</h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="name" bsSize="medium">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_EXAMS.assigned.graderName)}
                  </div>
                  <div className="col-md-9">
                    <strong>{this.props.graderName}</strong>
                  </div>
                </div>
              </FormGroup>

              <FormGroup controlId="email" bsSize="large">
                <div className="row">
                  <div className="col-md-3 formfont">
                    {this.t(T_EXAMS.assigned.assignedTime)}
                  </div>
                  <div className="col-md-9">
                    <strong>
                      {this.t(T_DATE.time, {
                        time: new Date(this.props.assignedTime)
                      }) +
                        this.t(T_EXAMS.assigned.on) +
                        this.t(T_DATE.date, {
                          date: new Date(this.props.assignedTime)
                        })}
                    </strong>
                  </div>
                </div>
              </FormGroup>

              {(() => {
                if (this.state.buttonText) {
                  return (
                    <FormGroup>
                      <Button
                        onClick={this.handleActionButtonClick}
                        disabled={this.state.disableColoredBtn}
                        className={
                          "loginbtn2 edit-account-btn change-state-btn warning" +
                          (this.state.btnFill ? " fill" : "")
                        }
                      >
                        {this.state.disableColoredBtn ? (
                          <img
                            src={require("../../assets/images/warning-button-loading.gif")}
                          />
                        ) : (
                          this.state.buttonText
                        )}
                      </Button>
                    </FormGroup>
                  );
                }
              })()}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomShowAssignedUserModal);
