/**
 * @file Exports enums of roles and the corresponding strings
 * @author Arun Mahadevan
 *
 */
export const roles = {
  ADMIN: "Admin",
  OPERATOR: "Operator",
  DOCTOR: "Doctor",
  SERVICE: "Service"
};
