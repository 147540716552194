/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { backendBaseUrl } from "../../config";
import {
  loginUsername,
  domainForUser,
  getUserAndOrg
} from "../../constants/endpoints";
import {
  postUnauthenticatedData,
  getAuthenticatedData
} from "../../utils/async";
import { toast } from "react-toastify";
import { withMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

import { withTranslation } from "react-i18next";
import { languages } from "../../constants/languages";
import { T_LOGIN } from "../../constants/translations";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import { loginRequest } from "../../authConfig";

/**
 * This is the component for the Username page. <br>
 * The common server is contacted initially <br>
 * which then returns a regional server, based on the username. <br>
 * This is then stored in sessionStorage. <br>
 * SRS IDs: <br>
 * AIM - 5.3.14.1 <br>
 * PSL - 5.3.14.1 <br>
 * FOP - 5.3.14.1 <br>
 * @component
 */
class Loginusername extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.i18n = props.i18n;

    this.state = {
      dEmail: "",
      Name: "",
      statusCheck: "",
      token: "",
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAD = this.onSubmitAD.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    const instance = this.props.msalContext.instance;
    if (sessionStorage.getItem("ADClicked")) {
      this.setState({ disableButton: true });
      instance
        .handleRedirectPromise()
        .then(accessTokenResponse => {
          if (accessTokenResponse) {
            let accessToken = accessTokenResponse.accessToken;

            const ADClicked = sessionStorage.getItem("ADClicked");
            // const accounts = this.props.msalContext.accounts;
            // const accessTokenRequest = {
            //   scopes: loginRequest.scopes,
            //   account: accounts[0]
            // };
            sessionStorage.setItem("ad", "true");
            // eslint-disable-next-line no-console
            console.log(
              instance.getAllAccounts().length > 0 && ADClicked !== null
            );
            sessionStorage.setItem(
              "account",
              JSON.stringify(instance.getAllAccounts()[0])
            );
            if (instance.getAllAccounts().length > 0 && ADClicked !== null) {
              // Acquire token silent success
              // Call your API with token

              // eslint-disable-next-line no-console
              console.log(accessTokenResponse);
              const email =
                accessTokenResponse.idTokenClaims["preferred_username"];
              postUnauthenticatedData(backendBaseUrl + domainForUser, {
                emailAddress: email
              })
                .then(result => {
                  if (result.status === 200) {
                    return result.json();
                  } else {
                    this.setState({ disableButton: false });
                    if (result.status === 404) {
                      return toast.error(this.t(T_LOGIN.toast.userNotFound));
                    } else {
                      return toast.error(
                        this.t(T_LOGIN.toast.somethingWentWrong)
                      );
                    }
                  }
                })
                .then(result => {
                  sessionStorage.setItem("domain", result?.data?.serverDomain);
                  sessionStorage.setItem(
                    "imgProcessingDomain",
                    result?.data?.imgProcessingDomain
                  );
                  postUnauthenticatedData(
                    sessionStorage.getItem("domain") + loginUsername,
                    { emailAddress: email }
                  )
                    .then(result => {
                      if (result.status === 200) {
                        return result.json();
                      } else {
                        this.setState({ disableButton: false });
                        if (result.status === 404) {
                          return toast.error(
                            this.t(T_LOGIN.toast.userNotFound)
                          );
                        } else {
                          return toast.error(
                            this.t(T_LOGIN.toast.somethingWentWrong)
                          );
                        }
                      }
                    })
                    .then(result => {
                      let status = result?.data?.accountStatus;
                      if (status === "ACTIVE") {
                        getAuthenticatedData(
                          sessionStorage.getItem("domain") + getUserAndOrg
                        ).then(result => {
                          sessionStorage.setItem("bearerToken", accessToken);
                          sessionStorage.removeItem("ADClicked");
                          sessionStorage.setItem(
                            "orgName",
                            result?.data?.orgAndSiteResponse?.orgName
                          );
                          sessionStorage.setItem(
                            "userName",
                            result?.data?.userInfo?.firstName +
                              " " +
                              result?.data?.userInfo?.lastName
                          );
                          if (
                            result?.data?.orgAndSiteResponse?.entitlements?.includes(
                              "TELE_MEDICINE"
                            )
                          ) {
                            sessionStorage.setItem("tele", "true");
                          } else {
                            sessionStorage.setItem("tele", "false");
                          }
                          if (
                            result?.data?.orgAndSiteResponse
                              ?.lowSecurityModeEnabled
                          ) {
                            sessionStorage.setItem(
                              "lowSecurityModeEnabled",
                              "true"
                            );
                          }
                          if (
                            result?.data?.orgAndSiteResponse?.entitlements?.includes(
                              "SECONDARY_SITES"
                            )
                          ) {
                            sessionStorage.setItem("secondary", "true");
                          } else {
                            sessionStorage.setItem("secondary", "false");
                          }
                          if (
                            result?.data?.userInfo?.roles?.includes("DOCTOR") ||
                            result?.data?.userInfo?.roles?.includes("OPERATOR")
                          ) {
                            this.props?.history?.push?.("/exam-data");
                          } else {
                            this.props?.history?.push?.("/edit-admin");
                          }
                        });
                      } else if (
                        status === "INACTIVE" ||
                        status === "UNVERIFIED"
                      ) {
                        this.props.history.push({
                          pathname: "/verify-email",
                          email: result.data.email
                        });
                      } else if (status === "SUSPENDED") {
                        this.props.history.push({
                          pathname: "/recover",
                          email: result.data.email,
                          cameFromPasswordPage: false
                        });
                      }
                    });
                });
            }
          } else {
            this.setState({ disableButton: false });
            sessionStorage.removeItem("ADClicked");
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          toast.error(this.t(T_LOGIN.toast.accountIncorrectlyConfigured));
          this.setState({ disableButton: false });
          sessionStorage.removeItem("ADClicked");
        });
    }
  }
  /**
   * Listens to changes in the username field
   *
   * @param {object} e the event handler
   *
   */
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  /**
   * Submits the form, handles edge cases regarding user status and account not found
   *
   *
   *
   */

  onSubmitAD() {
    sessionStorage.setItem("ADClicked", "true");
    this.setState({ disableButton: true });
    const msalInstance = this.props.msalContext.instance;
    msalInstance.loginRedirect(loginRequest);
  }

  onSubmit() {
    if (this.state.dEmail == "") {
      toast.error(this.t(T_LOGIN.toast.pleaseFillEmail));
    } else {
      sessionStorage.removeItem("ADClicked");
      this.setState({ disableButton: true });
      postUnauthenticatedData(backendBaseUrl + domainForUser, {
        emailAddress: this.state.dEmail
      })
        .then(result => {
          if (result.status === 200) {
            return result.json();
          } else {
            this.setState({ disableButton: false });
            if (result.status === 404) {
              toast.error(this.t(T_LOGIN.toast.userNotFound));
              return Promise.reject();
            } else {
              toast.error(this.t(T_LOGIN.toast.somethingWentWrong));
              return Promise.reject();
            }
          }
        })
        .then(result => {
          sessionStorage.setItem("domain", result?.data?.serverDomain);
          sessionStorage.setItem(
            "imgProcessingDomain",
            result?.data?.imgProcessingDomain
          );
          postUnauthenticatedData(
            sessionStorage.getItem("domain") + loginUsername,
            { emailAddress: this.state.dEmail }
          )
            .then(result => {
              if (result.status === 200) {
                return result.json();
              } else {
                this.setState({ disableButton: false });
                if (result.status === 404) {
                  return toast.error(this.t(T_LOGIN.toast.userNotFound));
                } else {
                  return toast.error(this.t(T_LOGIN.toast.somethingWentWrong));
                }
              }
            })
            .then(result => {
              let status = result?.data?.accountStatus;
              if (status === "ACTIVE") {
                this.props.history.push({
                  pathname: "/login-password",
                  name: result.data.firstName,
                  email: result.data.email,
                  userId: result.data.userId,
                  lastActiveDate: result.data.lastActiveDate
                });
              } else if (status === "INACTIVE" || status === "UNVERIFIED") {
                this.props.history.push({
                  pathname: "/verify-email",
                  email: result.data.email
                });
              } else if (status === "SUSPENDED") {
                this.props.history.push({
                  pathname: "/recover",
                  email: result.data.email,
                  cameFromPasswordPage: false
                });
              }
            });
        });
    }
  }

  changeLanguage(e) {
    console.log(e.currentTarget.value);
    this.i18n.changeLanguage(e.currentTarget.value);
    console.log(this.i18n.language);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div className="Login">
        <form
          className="loginpad1"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.svg")}
            />
          </p>
          <Button
            block
            bsSize="large"
            onClick={this.onSubmitAD}
            className="loginbtn active-directory-btn"
            disabled={this.state.disableButton}
          >
            {this.state.disableButton && sessionStorage.getItem("ADClicked") ? (
              <img src={require("../../assets/images/button-loading.gif")} />
            ) : (
              this.t(T_LOGIN.signInAD)
            )}
          </Button>
          <div className="separator">
            <div className="line"></div>
            <span className="or-text">{this.t(T_LOGIN.or)}</span>
            <div className="line"></div>
          </div>
          <FormGroup controlId="email" bsSize="large">
            <FormControl
              autoFocus
              required
              type="email"
              name="dEmail"
              placeholder={this.t(T_LOGIN.username)}
              autoComplete="off"
              onChange={this.onChange}
              disabled={this.state.disableButton}
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            onClick={this.onSubmit}
            type="submit"
            className="loginbtn"
            disabled={this.state.disableButton}
          >
            {this.state.disableButton &&
            !sessionStorage.getItem("ADClicked") ? (
              <img src={require("../../assets/images/button-loading.gif")} />
            ) : (
              this.t(T_LOGIN.next)
            )}
          </Button>
        </form>
        <div className="languages">
          <span className="chooseLanguageText">Choose Language:</span>
          <select
            name="language"
            value={this.i18n.language}
            onChange={this.changeLanguage}
          >
            {Object.keys(languages).map(lng => (
              // eslint-disable-next-line react/no-unknown-property
              <option value={lng} key={lng}>
                {languages[lng].nativeName}
              </option>
            ))}
          </select>
          {/* {Object.keys(languages).map(lng => (
            <Button
              lng={lng}
              key={lng}
              onClick={this.changeLanguage}
              disabled={this.i18n.language === lng}
            >
              {languages[lng].nativeName}
            </Button>
          ))} */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withMsal(Loginusername));
